import { Component, ViewChild, TemplateRef } from '@angular/core';
import { ServiceCraService } from '../../services/service-cra.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { Observable, Subject, finalize, forkJoin, of, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SubscriptionService } from '../../../../shared/services/subscription.service';
@Component({
  selector: 'app-lista-cra',
  templateUrl: './lista-cra.component.html',
  styleUrls: ['./lista-cra.component.css'],
})
export class ListaCraComponent {
  @ViewChild('rejected_reason', { static: true }) rejected_reason!: TemplateRef<any>;
  modalRef?: BsModalRef;
  safeDescription!: SafeHtml;
  esnName : string = '';
  groupedByDate: any[] = [];
  filterDataStatus : Boolean = false;
  historyData : Boolean = false; 
  pagination : boolean =false;
  totalElements!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  state = [
    { id: 1, name: 'Non envoyer' },
    { id: 2, name: 'Envoyé' },
    { id: 3, name: 'Validé' },
    { id: 4, name: 'Rejeté' },
    { id: 5, name: 'Approuvé' },
  ];

  annee = [
    { id: 2024, name: '2024' },
    { id: 2025, name: '2025' },
    { id: 2026, name: '2026' },
    { id: 2027, name: '2027' },
  ];
  startSearching:boolean = false;
  currentYear!: number;
  currentUser: any;
  id: any;
  cras: any;
  listOfMonths: any;
  listOfMonthsSearch : any;
  listHistorty: any;
  listYearMonth:any;
  namesentreprise:any;
  today: any;
  selectedMonth: any;
  testDateInFuture: boolean = false;
  pageSizeOptions = [5, 10, 20];
  searchForm: FormGroup = this.createSearchForm();
  private unsubscribeAll: Subject<void> = new Subject();
  
  constructor(
    private serviceCra: ServiceCraService,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService,

  ) {}

  ngOnInit(): void {
    forkJoin([this.getCurrentYear(), this.getIdUser()]).subscribe(() => {
      this.getListOfMonths();
      this.getlistHistory();
    });
    // Assuming you receive the language preference from the API
    const userLanguage = 'fr'; // Replace with the actual language received from the API

    this.translate.setDefaultLang('en');
    this.translate.use(userLanguage);

    // Optionally, you can load translations dynamically based on the user's language
    this.translate.getTranslation(userLanguage).subscribe((translations) => {
      this.translate.setTranslation(userLanguage, translations, true);
    });
    // this.searchYearCra()
  }

  getCurrentYear(): Observable<void> {
    const now = new Date();
    this.currentYear = now.getFullYear();
    return of(undefined);
  }

  getIdUser(): Observable<void> {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    return of(undefined);
  }
  reset() {
    this.filterDataStatus = false;
    this.searchForm.reset();
    this.getListOfMonths();
  }
 
  getListOfMonths() {
    let dataPayload = { year: this.currentYear, user_id: this.id };
    this.spinner.show();

    this.serviceCra
      .getAllListOfMonthsOfYear(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listOfMonths = res?.data?.data;
          this.listYearMonth = (res.data?.data || []).map((item: any) => ({
            id: `${item.year}-${(item.monthId ? item.monthId.toString().padStart(2, '0') : '00')}`,
            label: `${item.month || 'Unknown Month'} ${item.year || 'Unknown Year'}`,
          }));
          const data = this.listOfMonths;   
          const grouped: { [key: string]: any } = {};
          data.forEach((item: any) => {
            const monthYear = new Date(item.date).toLocaleString('default', { year: 'numeric', month: 'long' });
            if (!grouped[monthYear]) {
              grouped[monthYear] = item;
            } else {
              const existingDate = new Date(grouped[monthYear].updated_at);
              const newDate = new Date(item.updated_at);
              if (newDate > existingDate) {
                grouped[monthYear] = item;
              }
            }
          });
          this.listOfMonths = Object.values(grouped);
          if (this.listOfMonths.length === 0) {
            this.historyData = true;
            this.serviceCra.getHistoryCompany().subscribe({
              next: (res) => {
                this.listOfMonths = res?.data?.data; 
              },
              error: (err) => {
                console.error('Erreur lors de la récupération des données :', err);
              }
            });
          } else {
            this.historyData = false;
          }
        },
        (error) => {
          console.error('Error fetching listOfMonths:', error);
        }
      );
  }
  getlistHistory() {
    this.serviceCra.getHistoryCompany().subscribe({
      next: (res) => {
        this.listHistorty= res?.data?.data;
        this.namesentreprise = res?.data.data.map((item: any) => item.e_s_n.name);
        const filterData = res.data.data.filter((item:any) => item.archived === "1").map((item:any) => ({
          name: item.e_s_n.name,
          updated_at: item.updated_at,
        }));
        const latestItem = filterData.reduce((latest: any, current: any) => {
          return new Date(current.updated_at) > new Date(latest.updated_at) ? current : latest;
        }, filterData[0]);
        if(latestItem){
          this.esnName = latestItem.name;
          this.namesentreprise.sort((a:any, b:any) => {
            return a === this.esnName ? -1 : b === this.esnName ? 1 : 0;
          });
        }
        else {
          this.esnName = 'n\'existe pas';
        }
       // const latestUpdatedAt = latestItem.updated_at;
      },
      error: (err) => {
        console.error('Error fetching history company:', err);
      }
    });
  }
  
  formatDate(date: Date | string): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Mois de 01 à 12
    return `${year}-${month}`;
  }
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      year: [data ? data.year : ''],
      state: [data ? data.state : null],
      esn_name: [data ? data.esn_name : null],
      month_cra: [data ? this.formatDate(data.month_cra) : '' ],
      month_timeSheet:  [data ? this.formatDate(data.month_timeSheet) : '' ],
    });
  }

  limitMonthYearLength(event: KeyboardEvent): void {
    const allowedKeys = [8, 46, 37, 39];
    if (event.key.length === 1 && !/[\d-]/.test(event.key)) {
      event.preventDefault();
    }
    const input = (event.target as HTMLInputElement).value;
    if (input.length >= 7 && !allowedKeys.includes(event.keyCode)) {
      event.preventDefault();
    }
  }
  onDateChange(date: Date | string): void {
    if (!date || (date instanceof Date && isNaN(date.getTime()))) {
      this.searchForm.get('yearMonth')?.setValue(''); 
    }else {
      const formattedDate = this.formatDate(date);
      this.searchForm.get('month_cra')?.setValue(formattedDate);
    }
  }
  onMonthYearInput(event: any): void {
    const value = event.target.value;
    const isValid = /^\d{4}-\d{2}$/.test(value);
    if (!isValid && value.length > 0) {
      console.error('La date entrée est invalide');
    }
  }
  searchYearCra(pageNumber : number = this.currentPage) {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    let data = {
      date: this.searchForm.value.month_cra,
      status: this.searchForm.value.state,
      esn_name: this.searchForm.value.esn_name,
      user_id: this.id,
      per_page: this.itemsPerPage,
      page: this.currentPage,
    };
    this.spinner.show();
    this.serviceCra
      .searshCra(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.totalElements = res.total;
          this.filterDataStatus = true;
          this.listOfMonths = res.data;
            if (!res.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.searchYearCra();
            } else {
              this.spinner.hide();
              this.listOfMonths = res.data.slice(0, this.endIndex);
              this.totalElements =  res.total;
            }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  checkAndCreateCRAs() {
    this.serviceCra.checkAndCreateCRAs().subscribe({
      next: (res) => {
        console.log('response format:', res);
      },
      error: (res) => {
        console.log('response error:', res);
      }
    })
  }
  openModalRejectedReason(template: TemplateRef<any>, month: any) {
    this.modalRef = this.modalService.show(template);
    const rawHtml = month.history_cra[0].report || '';
    this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
  }
  closeModal() {
    this.modalRef?.hide();
}
  voirListCraEntreprisesByMonth(month: any) {
    this.today = new Date();
    this.selectedMonth = new Date(month.year, month.monthId - 1, 1);

    if (
      this.selectedMonth instanceof Date &&
      this.selectedMonth.getTime() > this.today?.getTime()
    ) {
      const diffMonths =
        this.selectedMonth.getMonth() -
        this.today.getMonth() +
        12 * (this.selectedMonth.getFullYear() - this.today.getFullYear());
      if (diffMonths <= 3) {
        this.testDateInFuturePopup(month);
      } else {
        this.testDateInFuture = true;
        this.toastrService.info(
          'Vous ne pouvez remplir le CRA que pour les trois prochains mois.'
        );
      }
    } else {
      this.router.navigate([
        '/wegestu/pointage/list-cra-entreprises',
        { month: month.monthId, year: month.year },
      ]);
    }
  }
  testDateInFuturePopup(month: any) {
    Swal.fire({
      title: 'Remplir le CRA',
      text: 'Êtes-vous certain de vouloir remplir le CRA pour une date future?',
      icon: 'info',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        if(month.Cras.length === 1){
          this.router.navigate([
            '/wegestu/pointage/details-cra',
            { idCra: month.Cras[0].id },
          ]);
        }else
        this.router.navigate([
          '/wegestu/pointage/list-cra-entreprises',
          { month: month.monthId, year: month.year },
        ]);
      }
    });
  }

  voirListDetailsCra(month: any) {
      this.today = new Date();
      this.selectedMonth = new Date(month.year, month.monthId - 1, 1);
      if (this.selectedMonth.getTime() > this.today.getTime()) {
        const diffMonths =
          this.selectedMonth.getMonth() -
          this.today.getMonth() +
          12 * (this.selectedMonth.getFullYear() - this.today.getFullYear());
        if (diffMonths <= 3) {
          this.testDateInFuturePopup(month);
        } else {
          console.log(
            'Vous ne pouvez remplir le CRA que pour les trois prochains mois.'
          );
        }
      }
      else if(month.archived == 2){
        this.router.navigate([
              '/wegestu/pointage/details-cra',
              { idCra: month.cra[0].id },
            ]);
      }
      else {
        this.router.navigate([
          '/wegestu/pointage/details-cra',
          { idCra: month.id },
        ]);
      }
  }

  isCurrentMonth(month: number): boolean {
    const now = new Date();
    return now.getMonth() === month - 1;
  }

getItems(event?: any) {
  if (event) {
    this.startIndex = event.startIndex;
    this.endIndex = event.endIndex;
    (this.itemsPerPage = event.itemsPerPage),
      (this.currentPage = event.currentPage);
    if (this.searchForm.dirty) {
      this.searchYearCra();
    } 
    else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
      this.pagination = true
      this.getListOfMonths();
    }
  }
}
}
