import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ICountry, State } from 'country-state-city';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  Subject,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  Observable,
} from 'rxjs';

import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import Swal from 'sweetalert2';
import { CCandidate, listContracts } from '../../models/candidate';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import {
  checkRequiredInputs,
  emailExist,
  fileTypeExtention,
  gotoTest,
  invalidSelectedDate,
  linkdinExist,
  maxSizeFile,
  noThingChanged,
  phoneNumberExist,
  serverError,
  succesdisassociateConsultant,
  successEditProfile,
} from '../../../../../app/shared/shared-models/message';
import { environment } from '../../../../../environnements/environment';
import {
  linkedInRegExp,
  patternEmail,
  patternInfoUser,
} from '../../../../shared/shared-models/pattern';
import { CandidateService } from '../../services/candidate.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { PaysService } from '../../../../shared/services/pays.service';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { filter } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ModuleService } from '../../../../shared/services/module.service';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ExportService } from 'src/app/shared/services/export.service';

export interface addSkill {
  name: string;
}

interface certifSkill {
  name: string;
}
@Component({
  selector: 'app-modifier-dossier',
  templateUrl: './modifier-dossier.component.html',
  styleUrls: ['./modifier-dossier.component.css'],
})
export class ModifierDossierComponent {
  @ViewChild('ngSelectComponent') ngSelect!: NgSelectComponent;
  showMessage: boolean = false;
  toolbarOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['task-list'],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],
  };
  editcv: boolean = JSON.parse(localStorage.getItem('editcv') || 'false');
  firstName: string = '';
  lastName: string = '';
  desiredWorkstation: string = '';
  email: string = ''; 
  currentCountry: string = ''; 
  phone: number | null = null;
  linkedinLink: string = ''; 
  savoirFaire: string = ''; 
  softSkills: string[] = [];
  langueUser: Langue[] = [];
  candidatSkills: SkillsCandidat[] = [];
  certifications: Certificat[] = [];
  graduation: Diplome[] = [];
  experienceProf : Experience[] = [];
  isAnnuler: any = false;
  viewExperiance: boolean = true;
  isFormatCompetenceAdmin: boolean = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  isSubmitsCertificats: boolean[] = [];
  announcer = inject(LiveAnnouncer);
  companyOptions: any[] = [];
  inputcompetance: string | null = null;
  soft = [
    { id: 1, name: 'Créativité', value: 'La créativité' },
    { id: 2, name: 'Flexibilité', value: 'La flexibilité' },
    { id: 3, name: 'Communication', value: 'La communication' },
    { id: 4, name: 'Curiosité', value: 'La curiosité' },
    { id: 5, name: 'Travail en équipe', value: 'Le travail en équipe' },
    { id: 6, name: "Capacité d'adaptation", value: 'La capacité d"adaptation' },
    { id: 7, name: 'Gestion du stress', value: 'La gestion du stress' },
    { id: 8, name: 'Esprit critique', value: 'L"esprit critique' },
    { id: 9, name: 'Leadership/Management', value: 'Leadership/Management' },
    { id: 10, name: 'Motivation', value: 'La motivation' },
    { id: 11, name: 'Négociation', value: 'La négociation' },
    {
      id: 12,
      name: 'Jugement et la prise de décision',
      value: 'Le jugement et la prise de décision',
    },
    { id: 13, name: "L'autonomie", value: 'L"autonomie' },
    { id: 14, name: 'Coopération', value: 'La coopération' },
  ];
  competences = [
    { id: 0, name: 'HTML' },
    { id: 1, name: 'CSS' },
    { id: 2, name: 'Java' },
    { id: 3, name: 'Javascript' },
    { id: 4, name: 'Angular' },
    { id: 5, name: 'Node' },
  ];
  disponibilite = [
    { id: 1, name: 'Immédiate', value: '1' },
    { id: 2, name: 'Sous 1 mois', value: '2' },
    { id: 3, name: '2 mois', value: '3' },
    { id: 4, name: '3 mois', value: '4' },
    { id: 5, name: '4 mois', value: '5' },
    { id: 6, name: '5 mois', value: '6' },
  ];
  experience: boolean = false;
  languages: any[] = [
    { id: 1, name: 'Abkhaze' },
    { id: 2, name: 'Afrikaans' },
    { id: 3, name: 'Albanais' },
    { id: 4, name: 'Allemand' },
    { id: 5, name: 'Amharique' },
    { id: 6, name: 'Anglais' },
    { id: 7, name: 'Arabe' },
    { id: 8, name: 'Arménien' },
    { id: 9, name: 'Assamais' },
    { id: 10, name: 'Aymara' },
    { id: 11, name: 'Azéri' },
    { id: 12, name: 'Basque' },
    { id: 13, name: 'Bengali' },
    { id: 14, name: 'Biélorusse' },
    { id: 15, name: 'Birman' },
    { id: 16, name: 'Bosniaque' },
    { id: 17, name: 'Bulgare' },
    { id: 18, name: 'Catalan' },
    { id: 19, name: 'Cebuano' },
    { id: 20, name: 'Chewa' },
    { id: 21, name: 'Chichewa' },
    { id: 22, name: 'Chinois' },
    { id: 23, name: 'Coréen' },
    { id: 24, name: 'Corse' },
    { id: 25, name: 'Croate' },
    { id: 26, name: 'Danois' },
    { id: 27, name: 'Dzongkha' },
    { id: 28, name: 'Espagnol' },
    { id: 29, name: 'Estonien' },
    { id: 30, name: 'Finnois' },
    { id: 31, name: 'Flamand' },
    { id: 32, name: 'Français' },
    { id: 33, name: 'Frison' },
    { id: 34, name: 'Gaélique' },
    { id: 35, name: 'Galicien' },
    { id: 36, name: 'Géorgien' },
    { id: 37, name: 'Grec' },
    { id: 38, name: 'Groenlandais' },
    { id: 39, name: 'Gujarati' },
    { id: 40, name: 'Haoussa' },
    { id: 41, name: 'Hébreu' },
    { id: 42, name: 'Hindi' },
    { id: 43, name: 'Hmong' },
    { id: 44, name: 'Hongrois' },
    { id: 45, name: 'Ibo' },
    { id: 46, name: 'Islandais' },
    { id: 47, name: 'Indonésien' },
    { id: 48, name: 'Inuktitut' },
    { id: 49, name: 'Italien' },
    { id: 50, name: 'Japonais' },
    { id: 51, name: 'Javanais' },
    { id: 52, name: 'Kannada' },
    { id: 53, name: 'Kashmiri' },
    { id: 54, name: 'Kazakh' },
    { id: 55, name: 'Khmer' },
    { id: 56, name: 'Kinyarwanda' },
    { id: 57, name: 'Kirghize' },
    { id: 58, name: 'Kirundi' },
    { id: 59, name: 'Kurde' },
    { id: 60, name: 'Laotien' },
    { id: 61, name: 'Latin' },
    { id: 62, name: 'Letton' },
    { id: 63, name: 'Lituanien' },
    { id: 64, name: 'Luxembourgeois' },
    { id: 65, name: 'Macédonien' },
    { id: 66, name: 'Malais' },
    { id: 67, name: 'Malayalam' },
    { id: 68, name: 'Malgache' },
    { id: 69, name: 'Maltais' },
    { id: 70, name: 'Maori' },
    { id: 71, name: 'Marathi' },
    { id: 72, name: 'Mongol' },
    { id: 73, name: 'Néerlandais' },
    { id: 74, name: 'Népalais' },
    { id: 75, name: 'Norvégien' },
    { id: 76, name: 'Ouzbek' },
    { id: 77, name: 'Pachtou' },
    { id: 78, name: 'Papiamento' },
    { id: 79, name: 'Pashto' },
    { id: 80, name: 'Persan (farsi)' },
    { id: 81, name: 'Polonais' },
    { id: 82, name: 'Portugais' },
    { id: 83, name: 'Punjabi' },
    { id: 84, name: 'Quechua' },
    { id: 85, name: 'Roumain' },
    { id: 86, name: 'Russe' },
    { id: 87, name: 'Somali' },
    { id: 88, name: 'Samoan' },
    { id: 89, name: 'Sanskrit' },
    { id: 90, name: 'Serbe' },
    { id: 91, name: 'Sesotho' },
    { id: 92, name: 'Shona' },
    { id: 93, name: 'Sindhi' },
    { id: 94, name: 'Slovaque' },
    { id: 95, name: 'Slovène' },
    { id: 96, name: 'Sotho' },
    { id: 97, name: 'Soundanais' },
    { id: 98, name: 'Suédois' },
    { id: 99, name: 'Swahili' },
    { id: 100, name: 'Tadjik' },
  ];
  searchTerm: string = '';

  mention = [
    { id: 1, name: 'Assez Bien' },
    { id: 2, name: 'Bien' },
    { id: 3, name: 'Très Bien' },
    { id: 4, name: 'Excellent' },
  ];
  CreateCandidate = new CCandidate();
  // candidateForm!:FormGroup
  addOnBlur = true;
  addSkills: addSkill[] = [];
  certifSkills: certifSkill[] = [];
  skillsAdd: any[] = [];
  phoneNumber: string | null = null;
  visual: boolean = false;
  mobilities: any[] = [];
  countries: any[] = [];
  daysDifference: number = 0;
  file: any;
  selectedImage: any;
  imageAdded: any
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  today = new Date();
  separateDialCode: boolean = false;
  selectedCountryCode: string = 'FR';
  categories: any = [];
  listCategories: any = [];
  colors = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];
  selectedSubcategoryIds: any[] = [];
  selectedSubcategories: string[] = [];
  skillsList: { name_FR: any; id: any }[] = [];
  skillsCandidate: any;
  skillsCandidat: any[] = [];
  idSubCategory: any;
  deteleCV: any;
  SkillsStars: number[][] = [];
  flattenedStars: number[] = [];
  clickedStars: number[][] = [];
  arrSkilles: any[] = [];
  /* formData */
  formData = new FormData();
  /* form group */
  phoneForm!: FormGroup;
  ExperienceForm!: FormGroup;
  diplomeForm!: FormGroup;
  certificatForm!: FormGroup;
  langueForm!: FormGroup;
  showEndDate = true;
  candidateForm: FormGroup = this.createCandidateForm(new CCandidate());
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  cvCandidat: any;
  tab = 1;
  /* object */
  selectedCandidate: any;
  /* arrays */

  submittedCertificats: boolean[] = []; // Track submission state for each certificate
  submittedDeplomes: boolean[] = []; // Track submission state for each diploma
  submittedLanguages: boolean[] = []; // Track submission state for each language

  paysList: ICountry[] = [];
  checkeddatefin: any[] = [];
  stateList: any = [];
  listCantrat = listContracts;
  yearsList: number[] = [];
  /* string */
  url: string = environment.baseUrl + '/api';
  fileName: string = '';
  existCv: boolean = false;
  selectedAvailability: string = '0';
  showSkills: string = 'info';
  photo: any;
  currentUser: any;
  id: any;
  /* boolean */
  CheckedMission: any;
  maxSubSkills: number = 0;
  _afficherBloc: boolean = false;
  _afficherBlocExperience: boolean = false;
  _afficherBlocLangue: boolean = false;
  _afficherBlocCertificat: boolean = false;
  searchLang$ = new Subject<string>();
  filteredLanguages$!: Observable<any[]>;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  currentDate: string;
  minDate: string;
  maxDate: Date;

  constructor(
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    private candidatService: CandidateService,
    private toastrService: ToastrService,
    private moduleService: ModuleService,
    private router: Router,
    private el: ElementRef,
    private paysServices: PaysService,
    private primengConfig: PrimeNGConfig,
    private datePipe: DatePipe,
     private exportService: ExportService,
  ) {
    const currentYear = new Date().getFullYear();
    this.maxDate = new Date(currentYear, 11, 31);
    this.languages = this.languages.map((language: any) => ({
      ...language,
      isFiltered: false,
    }));

    const day = String(this.today.getDate()).padStart(2, '0');
    const month = String(this.today.getMonth() + 1).padStart(2, '0');
    const year = this.today.getFullYear();
    this.currentDate = `${year}-${month}-${day}`;
    const minYear = 1950;
    this.minDate = `${minYear}-${month}-${day}`;
  }

  ngOnInit(): void {
    this.primengConfig.setTranslation({
      dayNames: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ],
      dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      monthNames: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      monthNamesShort: [
        'janv.',
        'févr.',
        'mars',
        'avr.',
        'mai',
        'juin',
        'juil.',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.',
      ],
      today: "Aujourd'hui",
      clear: 'Effacer',
    });
    this.paysList = this.paysServices.getAllCountries();
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    this.getListModules();
    this.getCandidateById();
    this.getSkills();
    this.getYearsList();
    this.getListSkills();
    this.phoneForm = new FormGroup({
      phone: new FormControl('', [Validators.required]),
    });
    this.CreateCandidate = new CCandidate();
    this.filteredLanguages$ = this.searchLang$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.searchLanguages(term))
    );
    this.experienceText = this.getExperienceText();
    const experienceStored = this.localStorageService.getData('experience');
    console.log('experience stored', experienceStored);
    this.experience = experienceStored ? JSON.parse(experienceStored) : false;
    console.log('experience after stored', this.experience);
  }
  /**
   *  @param {number} type - The type of CV to export (e.g., 1 for standard, 2 for anonymous)
   */
  verifyReviewCompany(type: number) {
    if (this.selectedCandidate?.Evaluated == 'true') {      
      this.exportCVCandidate(type, null);
    } else this.verifyReviewCompanyPopup();
  }
  verifyReviewCompanyPopup() {
    Swal.fire({
      title: 'Avis requis',
      text: 'Vous ne pouvez télécharger votre dossier de compétence que si vous avez mis un avis pour une entreprise. Veuillez mettre un avis pour pouvoir télécharger votre dossier.',
      icon: 'info',
      confirmButtonText: 'Donner un avis',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/wegestu/avis/donnees-avis');
      }
    });
  }

  getTodayDatelast(): string {
    return this.datePipe.transform(new Date(), 'dd/MM/yy')!;
  }
  searchLanguages(term: string): Observable<any[]> {
    return new Observable((observer) => {
      if (term) {
        const filtered = this.languages.filter((lang) =>
          lang.name.toLowerCase().includes(term.toLowerCase())
        );
        const sorted = filtered.sort((a, b) => {
          const aStartsWith = a.name
            .toLowerCase()
            .startsWith(term.toLowerCase());
          const bStartsWith = b.name
            .toLowerCase()
            .startsWith(term.toLowerCase());

          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        });
        observer.next(sorted);
      } else {
        observer.next(this.languages);
      }
      observer.complete();
    });
  }
  onSearch(term: any): void {
    console.log('term  : ', term.term);

    this.searchTerm = term.term;
  }
  getYearsList() {
    const currentYear = new Date().getFullYear();
    const startYear = 1990;
    for (let year = currentYear; year >= startYear; year--) {
      this.yearsList.push(year);
    }
  }

  edit(skill: addSkill, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(skill);
      return;
    }

    const index = this.addSkills.indexOf(skill);
    if (index >= 0) {
      this.addSkills[index].name = value;
    }
  }


  editCertifSkills(skill: certifSkill, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(skill);
      return;
    }

    const index = this.certifSkills.indexOf(skill);
    if (index >= 0) {
      this.certifSkills[index].name = value;
    }
  }

  remove(skill: addSkill): void {
    const index = this.addSkills.indexOf(skill);

    if (index >= 0) {
      this.addSkills.splice(index, 1);

      this.updateSkillsText();

      this.formData.delete('skills_text[]');

      this.addSkills.forEach((skill) => {
        this.formData.append('skills_text[]', skill.name);
      });

      if (this.addSkills.length === 0) {
        this.formData.append('skills_text[]', '');
      }

      this.announcer.announce(`Removed ${skill.name}`);
    }
  }

  removeCertifSkills(skill: certifSkill): void {
    const index = this.certifSkills.indexOf(skill);

    if (index >= 0) {
      this.certifSkills.splice(index, 1);

      this.updateSkillsText();

      this.formData.delete('skills_text[]');

      this.certifSkills.forEach((skill) => {
        this.formData.append('skills_text[]', skill.name);
      });

      if (this.certifSkills.length === 0) {
        this.formData.append('skills_text[]', '');
      }

      this.announcer.announce(`Removed ${skill.name}`);
    }
  }

  updateSkillsText(): void {
    const skillsText = this.addSkills.map((skill) => skill.name);
    this.candidateForm.get('skills_text')?.setValue(skillsText);
    
    this.formData.delete('skills_text[]');
    skillsText.forEach((text) => {
      this.formData.append('skills_text[]', text);
    });

    if (skillsText.length === 0) {
      this.formData.append('skills_text[]', '');
    }
    console.log('ytgygygyuugyuyg',skillsText)
  }

  years_of_experience: any;
  isexp: boolean = true;
  /* get candidate by id */
  selectedCompanies: any[] = [];
  getCandidateById() {
    this.spinner.show();
    this.candidatService
      .getCandidate(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];

          if (this.selectedCandidate.ESN_name) {
            const esnCompany = {
              name: this.selectedCandidate.ESN_name,
              id: this.selectedCandidate.ESN_id,
            };
            this.companyOptions.push(esnCompany);
            this.selectedCompanyIds = this.companyOptions.map(option => option.id);
            this.candidateForm.get('selectedCompanyIds')?.setValue(this.selectedCompanyIds);
          }
          if (this.selectedCandidate.client_name) {
            const clientCompany = {
              name: this.selectedCandidate.client_name,
              id: this.selectedCandidate.client_id,
            };
            this.companyOptions.push(clientCompany);
            this.selectedCompanyIds = this.companyOptions.map(option => option.id);
            this.candidateForm.get('selectedCompanyIds')?.setValue(this.selectedCompanyIds);
          }

          // console.log("date first exp",this.candidateForm.get('date_first_experience')?.value)
          if (this.selectedCandidate.date_first_experience) {
            this.experienceText = this.selectedCandidate.years_of_experience;
            if (this.experienceText === '0 ans') this.experienceText = '---';
            this.isexp = true;
          } else {
            this.isexp = false;
          }
          const experiencesArray = this.candidateForm.get(
            'prof_experience'
          ) as FormArray;
          experiencesArray.controls.forEach((experience) => {
            const endDate = this.selectedCandidate.prof_experience[0]?.end_date;
            const todayDate = new Date().toISOString().split('T')[0];
            console.log('1', todayDate);
            console.log('2', endDate);
            if (endDate === todayDate) {
              this.checkeddatefin.push(true);
            } else {
              this.checkeddatefin.push(false);
            }
          });
          this.years_of_experience = this.selectedCandidate.years_of_experience;
          this.spinner.hide();
          this.CheckedMission = res.data[0].open_for_mission;
          this.candidateForm
            .get('open_for_mission')
            ?.setValue(this.CheckedMission);
          console.log(
            "open_for_mission: ['']",
            this.candidateForm.get('open_for_mission')?.value
          );

          if (typeof res.data.certificat == 'string')
            this.parseData('certificat');
          if (typeof res.data.langue_user == 'string')
            this.parseData('langue_user');
          if (typeof res.data.desired_contract == 'string')
            this.parseData('desired_contract');
          if (typeof res.data.diplome == 'string') this.parseData('diplome');
          if (typeof res.data.mobility == 'string') this.parseData('mobility');
          if (typeof res.data.destination_country == 'string')
            this.parseData('destination_country');
          // if (typeof res.data.softskills == 'string')
          //   this.parseData('softskills');
          if (typeof res.data.prof_experience == 'string')
            this.parseData('prof_experience');
          if (typeof res.data.skills_text == 'string')
            this.parseData('skills_text');
          let skillsTextArray = this.selectedCandidate?.skills_text;
          for (let i = 0; i < skillsTextArray?.length; i++) {
            let newSkill: addSkill = { name: skillsTextArray[i] };
            this.addSkills.push(newSkill);
          }
          this.candidateForm = this.createCandidateForm(this.selectedCandidate);
        
          this.onCountryChange('add');

          this.getDesirectContract();
          this.selectedAvailability = this.selectedCandidate.availability;
          this.phoneForm
            .get('phone')
            ?.setValue(this.candidateForm.get('phone_number')?.value);
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  selectedCompanyIds: number[] = [];
  onCompanyChange(event: any) {
    console.log('Change Event:', event);
    this.selectedCompanyIds = event.map((company: any) => company.id);
    console.log('Selected Company IDs:', this.selectedCompanyIds);
  }
  onClear() {
    this.selectedCompanyIds = [];
  }
  showDisassociateConsultantConfirmation() {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir vous dissocier de l'entreprise ?<br>La dissociation entraîne le verrouillage des CRA pour le mois en cours.",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.disassociateConsultant();
      }
    });
  }
  dataDissocier: any
  onRemove(event: any) {
    console.log('Removed company:', event);
    this.selectedCompanyIds = this.selectedCompanyIds.filter(id => id !== event);
    this.selectedCompanyIds = [...this.selectedCompanyIds];
  }
  showSelect = true;

  disassociateConsultant() {
    let data = {
      user_id: this.selectedCandidate?.id,
      company_ids: this.selectedCompanyIds,
    };

    this.dataDissocier = data;
    this.companyOptions = [];
    this.selectedCompanies = [];
    this.selectedCompanyIds = [];
    this.showSelect = false;
  }

  dissocier() {
    this.candidatService
      .disassociateConsultant(this.dataDissocier)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.toastrService.success('Consultant dissocié avec succès');
          this.getCandidateById()
        },
        error: () => {
          this.toastrService.error('Erreur du serveur');
        },
      });
  }
  parseData(name: string) {
    if (
      typeof this.selectedCandidate[name] == 'string' &&
      this.selectedCandidate[name] == 'null'
    ) {
      this.selectedCandidate[name] = [];
    } else {
      this.selectedCandidate[name] = JSON.parse(this.selectedCandidate[name]);
    }
  }

  onCurrentPositionChange(index: number, event: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const formGroup = this.experiencesControls.at(index) as FormGroup;
    if (isChecked) {
      formGroup.get('end_date')?.setValue('');
      this.checkeddatefin[index] = true;
    } else {
      formGroup.get('end_date')?.setValue(null);
      this.checkeddatefin[index] = false;
    }
    this.calculatePeriod(index, this.checkeddatefin[index]);
  }

  getTodayDate() {
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  }

  getDesirectContract() {
    if (this.selectedCandidate.desired_contract.length) {
      for (const iterator of this.selectedCandidate.desired_contract) {
        this.listCantrat.forEach((option) => {
          if (option.id == iterator) option.checked = true;
        });
      }
    }
  }
  updateAvailability(value: string) {
    this.candidateForm.get('availability')?.setValue(value);
  }
  updateOpenForMission(value: number) {
    const currentValue = this.candidateForm.get('open_for_mission')?.value;

    if (currentValue === value) {
      this.candidateForm.get('open_for_mission')?.setValue(null); // ou toute autre valeur par défaut
    } else {
      this.candidateForm.get('open_for_mission')?.setValue(value);
    }

    console.log(
      "open_for_mission: ['']",
      this.candidateForm.get('open_for_mission')?.value
    );
  }

  /* if value equal to null we should not display null in the input fields they should be empty */
  setNullData(name: string, data?: any) {
    console.log('data', data);
    if (data) {
      if (!data[name] || data[name] == 'null') {
        return '';
      } else {
        return data[name];
      }
    }
  }

  addDiplome() {
    const diplome = this.candidateForm.get('diplome') as FormArray;
    diplome.push(this.createFormDiplome());

    this.afficherBloc = true;

    console.log('affichierBolc', this.afficherBloc);
    this.submittedDeplomes.push(false);
    this.submittedDeplomes = this.submittedDeplomes.map(() => false);
  }
  checkInputDiplome(event: any) {
    if (event) {
      this.afficherBloc = true;
    } else {
      this.afficherBloc = false;
    }
  }
  addLangues() {
    const langue_user = this.candidateForm.get('langue_user') as FormArray;
    if (langue_user.length >= 6) {
      this.toastrService.error('Vous ne pouvez pas ajouter plus de 6 langues.');
      return;
    }
    langue_user.push(this.createFormLangues());
    this.afficherBlocLangue = true;
    this.submittedLanguages.push(false);

    this.submittedLanguages = this.submittedLanguages.map(() => false);
  }
  checkInputLangues() {
    this.afficherBlocLangue = true;
  }
  addCertificats() {
    const certificat = this.candidateForm.get('certificat') as FormArray;
    certificat.push(this.createFormCertificate());
    this.afficherBlocCertificat = true;
    this.submittedCertificats.push(false);
    this.submittedCertificats = this.submittedCertificats.map(() => false);
  }
  checkInputCertificats() {
    this.afficherBlocCertificat = true;
  }
  addExperiences() {
    const prof_experience = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    this.checkeddatefin.push(true);

    prof_experience.push(this.createFormExperience());
    this.afficherBlocExperience = true;
  }
  checkInputExperiences() {
    this.afficherBlocExperience = true;
  }
  get diplomaControls(): AbstractControl<any, any>[] {
    return (this.candidateForm.get('diplome') as FormArray)?.controls || [];
  }

  get certificatsControls(): AbstractControl<any, any>[] {
    return (this.candidateForm.get('certificat') as FormArray)?.controls || [];
  }
  get experiencesControls(): AbstractControl<any, any>[] {
    return (
      (this.candidateForm.get('prof_experience') as FormArray)?.controls || []
    );
  }

  removeDiploma(index: number) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ce diplôme ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        const diplome = this.candidateForm.get('diplome') as FormArray;
        diplome.removeAt(index);
        this.isfuturforma.splice(index, 1);
        this.isChosenDateValid1forma.splice(index, 1);

        this.invalid = false;
        this.candidateForm.get('diplome')?.updateValueAndValidity();

        this.updateSubmitButtonState1();
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        ///this.handleCancel1();
      }
    });
  }
  isButtonDisabled = false;
  removeCertificats(index: number) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ce Certificat ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        const certificat = this.candidateForm.get('certificat') as FormArray;
        certificat.removeAt(index);
        this.invalid = false; // Réinitialiser l'état d'invalidité

        // Recalculez l'état des erreurs des dates
        this.candidateForm.get('certificat')?.updateValueAndValidity();
        const certificatControls = this.candidateForm.get(
          'certificat'
        ) as FormArray;

        let hasInvalidDate = false;
        certificatControls.controls.forEach((control) => {
          const dateFormControl = control.get('date') as FormControl;
          if (dateFormControl?.hasError('invalidDate')) {
            hasInvalidDate = true;
          }
        });

        // Si aucune date n'est invalide, activez le bouton
        this.isButtonDisabled = hasInvalidDate || this.candidateForm.invalid;

        // Vous pouvez commenter ou enlever la ligne ci-dessous
        // this.toastrService.error('Veuillez saisir une date à partir de 1900.');

        this.updateSubmitButtonState(); // Mettre à jour l'état du bouton de soumission
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        ///this.handleCancel1();
      }
    });
  }
  updateSubmitButtonState() {
    const hasInvalidDate = this.candidateForm
      .get('certificat')
      ?.hasError('invalidDate');
    this.isButtonDisabled = hasInvalidDate || this.candidateForm.invalid;
  }
  updateSubmitButtonState1() {
    const diplomeArray = this.candidateForm.get('diplome') as FormArray;
    let hasInvalidDate = false;

    diplomeArray.controls.forEach((control, index) => {
      const dateFormControl = control.get('date') as FormControl;
      if (
        this.isfuturforma[index] ||
        dateFormControl?.hasError('invalidDate')
      ) {
        hasInvalidDate = true;
      }
    });

    this.isButtonDisabled = hasInvalidDate || this.candidateForm.invalid;
  }
  removeExperiences(index: number) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer cette expérience ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.checkeddatefin = this.checkeddatefin.splice(index, 1);
        const prof_experience = this.candidateForm.get(
          'prof_experience'
        ) as FormArray;
        prof_experience.removeAt(index);
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    });
  }
  removeLangue(index: number, langue: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ce Langue ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('before remove ', this.languages);

        if (
          this.languages.filter(
            (item: any) => item != undefined && item.name === langue.value.name
          ).length > 0
        )
          this.languages.filter(
            (item: any) => item != undefined && item.name === langue.value.name
          )[0].isFiltered = false;

        const langue_user = this.candidateForm.get('langue_user') as FormArray;
        langue_user.removeAt(index);
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    });
  }

  set afficherBloc(value: boolean) {
    this._afficherBloc = value;
    const diplomeArray = this.candidateForm.get('diplome') as FormArray;
    diplomeArray.controls.forEach((control) => {
      control.get('name')?.setValidators(value ? Validators.required : null);
      control.get('name')?.updateValueAndValidity();
    });
  }
  get afficherBloc(): boolean {
    return this._afficherBloc;
  }
  createFormDiplome(data?: any) {
    const form = this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      date: [
        data ? new Date(data.date).getFullYear().toString() : null,
      ],
      ecole: [data ? data.ecole : ''],
      mention: [data ? data.mention : null],
      user_id: this.id,
    });
    this.diplomeForm = form;
    return form;
  }

  maxWordsValidator(maxWords: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value || [];
      if (Array.isArray(value) && value.length > maxWords) {
        return { maxWords: { valid: false, actualLength: value.length, maxWords } };
      }
      return null; 
    };
  }

  dateValidatorstar(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date = new Date(control.value);
      if (date.getFullYear() < 2000) {
        return { invalidDatesart: true };
      }
      return null;
    };
  }
  set afficherBlocLangue(value: boolean) {
    this._afficherBloc = value;
    const langueArray = this.candidateForm.get('langue_user') as FormArray;
    langueArray.controls.forEach((control) => {
      control.get('name')?.setValidators(value ? Validators.required : null);
      control.get('name')?.updateValueAndValidity();
      control.get('stars')?.setValidators(value ? Validators.required : null);
      control.get('stars')?.updateValueAndValidity();
    });
  }

  get afficherBlocLangue(): boolean {
    return this._afficherBlocLangue;
  }

  createFormLangues(data?: any) {
    const form = this.formBuilder.group({
      name: [
        data ? data.name : null,
        this.afficherBlocLangue ? Validators.required : null,
      ],
      stars: [
        data ? data.stars : '',
        this.afficherBlocLangue ? Validators.required : null,
      ],
      user_id: this.id,
    });
    this.langueForm = form;
    return form;
  }

  set afficherBlocCertificat(value: boolean) {
    this._afficherBlocCertificat = value;
    const certificatArray = this.candidateForm.get('certificat') as FormArray;
    certificatArray.controls.forEach((control) => {
      control.get('name')?.setValidators(value ? Validators.required : null);
      control.get('name')?.updateValueAndValidity();
    });
  }
  get afficherBlocCertificat(): boolean {
    return this._afficherBlocCertificat;
  }

  createFormCertificate(data?: any) {
    const form = this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      date: [
        data ? new Date(data.date).getFullYear().toString() : null,
      ],
      skills: [data ? data.skills : []],
      end_date: [
        data ? new Date(data.end_date).getFullYear().toString() : null,
        //[Validators.required],
      ],
      user_id: this.id,
    });
    this.certificatForm = form;
    return form;
  }
  set afficherBlocExperience(value: boolean) {
    this._afficherBlocExperience = value;
    const certificatArray = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    certificatArray.controls.forEach((control) => {
      control.get('poste')?.setValidators(value ? Validators.required : null);
      control.get('poste')?.updateValueAndValidity();
    });
  }
  get afficherBlocExperience(): boolean {
    return this._afficherBlocExperience;
  }
  formatDate(date: any): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits
    const day = ('0' + date.getDate()).slice(-2); // Ensure two digits
    return `${year}-${month}-${day}`;
  }
  createFormExperience(data?: any) {
    const form = this.formBuilder.group({
      id: data && data.id ? data.id : null,
      poste: [
        data ? data.poste : '',
        this.afficherBlocExperience ? Validators.required : null,
      ],
      start_date: [
        data ? new Date(data.start_date) : null,
        [Validators.required],
      ],
      end_date: [
        data
          ? data.current_position === '0'
            ? new Date(data.end_date)
            : ''
          : null,
      ],
      client: [data ? data.client : ''],
      period: [
        data
          ? data.current_position === '1'
            ? this.currentPeriod(new Date(data.start_date), this.today)
            : data.period
          : `0 ans, 0 mois`,
      ],
      project: [data ? data.project : ''],
      description: [data ? data.description : ''],
      method: [data ? data.method : ''],
      technologies: [data ? data.technologies : []],
      tasks: [data ? data.tasks : ''],
      current_position: [
        data ? (data.current_position === '0' ? false : true) : false,
      ],
      user_id: this.id,
    });
    console.log(' data.start_date ', data);
    data
      ? data.start_date
        ? form.get('current_position')?.enable()
        : form.get('current_position')?.disable()
      : form.get('current_position')?.enable();
    this.ExperienceForm = form;
    return form;
  }

  currentPeriod(startDate: any, endDate: any) {
    const daysDifference = this.calculateDateDifference(startDate, endDate);
    const periodObject = this.calculateYearsMonthsDays(daysDifference - 1);
    const periodString = `${periodObject.years} ans, ${periodObject.months} mois, ${periodObject.days} jours`;
    console.log('periodString : ', periodString);
    return periodString;
  }

  createFormSkills(data?: any) {
    return this.formBuilder.group({
      sub_category_id: [data ? data.sub_category_id : null],
      stars: [data ? data.stars : ''],
      user_id: [data ? data.user_id : this.id],
    });
  }

  /* get form array diploma */
  get diplome() {
    return this.candidateForm.get('diplome') as FormArray;
  }
  /* get form array certificats */
  get certificats() {
    return this.candidateForm.get('certificat') as FormArray;
  }

  /* get form array experience */
  get prof_experience() {
    return this.candidateForm.get('prof_experience') as FormArray;
  }

  /* get form array langues */
  get langue_user() {
    return this.candidateForm.get('langue_user') as FormArray;
  }

  /* get form array skills */
  get skills() {
    return this.candidateForm.get('skills') as FormArray;
  }

  cv_file!: string;
  fileExtention!: string;
  fileError!: string;
  /* upload CV */
  uploadCV(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const maxSize = 2 * 1024 * 1024;
    if (fileInput.files && fileInput.files[0]) {
      const allowedExtensions = ['.pdf', '.docx', '.doc'];
      const file = fileInput.files[0];
      const fileExtension = file.name
        .toLowerCase()
        .substr(file.name.lastIndexOf('.'));
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
      } else if (file.size > maxSize) {
        this.fileError = maxSizeFile;
      } else {
        this.fileError = '';
        this.fileExtention = '';
        this.candidateForm.get('cv')?.setValue(file);
      }
    }
  }

  imageChanged: boolean = false;
  isImageError: boolean = false;

  uploadImage(event: any) {
    this.file = event.target.files[0];
    if (!this.isImage()) {
      this.isImageError = true;
      return;
    }
    this.fileName = this.file.name;
    if (this.file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(this.file);
      this.candidateForm.get('image_url')?.setValue(this.file);
      this.candidateForm.get('image_url')?.markAsTouched();
      this.candidateForm.get('image_url')?.markAsDirty();
      this.imageChanged = true;
      this.isImageError = false; // Reset the error flag
    }
  }

  isImage(): boolean {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    return allowedExtensions.test(this.file.name);
  }

  /* change checkbox contrat */
  handleContractsCheckbox(event: any, optionValue: string) {
    // Recherche de l'élément correspondant à l'optionValue
    const item = this.listCantrat.find(
      (contract) => contract.value === optionValue
    );

    // Si l'élément est trouvé, mettez à jour sa propriété checked directement
    if (item) {
      item.checked = event.target.checked;
    }

    // Mettez à jour la valeur du formulaire en fonction des éléments cochés
    this.candidateForm
      .get('desired_contract')
      ?.setValue(this.getCheckedItems());
  }
  getCheckedItems() {
    const checkedItems = this.listCantrat?.filter((option) => option.checked);
    const checkedValues = checkedItems.map((item) => item.id);
    return checkedValues;
  }
  /* change english level */
  changeEnglishLevel(value: string) {
    this.candidateForm.get('English_level')?.setValue(value);
  }
  /* change frensh level */
  changeFrenshLevel(value: string) {
    this.candidateForm.get('Frensh_level')?.setValue(value);
  }

  scrollToError() {
    const errorMessageElement =
      this.el.nativeElement.querySelector('#error-message');
    if (errorMessageElement) {
      errorMessageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
  editVisualisationCv() { 
    const storedFirstName = localStorage.getItem('firstName');
    this.firstName = storedFirstName ? storedFirstName : this.selectedCandidate?.first_name;
    const storedlastName = localStorage.getItem('lastName');
    this.lastName = storedlastName ? storedlastName : this.selectedCandidate?.last_name;
    const storedPhone = localStorage.getItem('phone');
    this.phoneNumber = storedPhone? storedPhone : this.selectedCandidate?.phone_number;
    const storedDesiredWorkstation = localStorage.getItem('desiredWorkstation');
    if (storedDesiredWorkstation !== null && storedDesiredWorkstation !== '') {
      this.desiredWorkstation = storedDesiredWorkstation;
      console.log('Clé existante avec une valeur :', storedDesiredWorkstation);
    } else if (storedDesiredWorkstation === '') {
     this.desiredWorkstation = '';
    } else if(this.selectedCandidate && this.selectedCandidate.desired_workstation) {
     this.desiredWorkstation= this.selectedCandidate.desired_workstation
    }
    const storedEmail = localStorage.getItem('email');
    this.email = storedEmail ? storedEmail : this.selectedCandidate?.email;
    const storedSavoirFaire = localStorage.getItem('savoirFaire');
    if (storedSavoirFaire !== null && storedSavoirFaire !== '') {
      this.savoirFaire = storedSavoirFaire;
      console.log('Clé existante avec une valeur :', storedSavoirFaire);
    } else if (storedSavoirFaire === '') {
      this.savoirFaire = '';
    } else if(this.selectedCandidate && this.selectedCandidate.savoir_faire) {
      this.savoirFaire= this.selectedCandidate.savoir_faire
    }
    const storedSoftSkills = localStorage.getItem('softSkills');
    
     this.softSkills = storedSoftSkills ? JSON.parse(storedSoftSkills) : this.selectedCandidate?.skills_text;
    console.log('softSkills dddddddd',this.softSkills)
    const storedcurrentCountry = localStorage.getItem('currentCountry');
      this.currentCountry = storedcurrentCountry 
    ? storedcurrentCountry 
    : (this.selectedCandidate?.current_country === "null" || this.selectedCandidate?.current_country === null  ? [] : this.selectedCandidate?.current_country);
    
    if(storedSoftSkills && storedSoftSkills !==null && storedSoftSkills!=="null") {
      this.softSkills= JSON.parse(storedSoftSkills)
    }
    else {
      this.softSkills=this.selectedCandidate?.softskills || []
    }
    const storedCertificates = localStorage.getItem('certificats');

   
    if (storedCertificates) {
      this.certifications = JSON.parse(storedCertificates);
    } else {
      this.certifications = this.selectedCandidate?.certificat || [];
    }
    const storedDiplome = localStorage.getItem('diplome');
    if (storedDiplome) {
      this.graduation = JSON.parse(storedDiplome);
    } else {
      this.graduation = this.selectedCandidate?.diplome || [];
    }
    const storeProfExperience = localStorage.getItem('prof_experience');
    this.experienceProf = storeProfExperience ? JSON.parse(storeProfExperience) : [];

    if (storeProfExperience) {
      this.experienceProf = JSON.parse(storeProfExperience);
    } else {
      this.experienceProf = this.selectedCandidate?.prof_experience || [];
    }
    const storedLangueUser = localStorage.getItem('langue_user');

    if (storedLangueUser) {
      this.langueUser = JSON.parse(storedLangueUser);
    } else {
      this.langueUser = this.selectedCandidate?.langue_user || [];
    }
    
    const storedAddSkills = localStorage.getItem('addSkills');
    this.addSkills = storedAddSkills ? JSON.parse(storedAddSkills) : []; 
    const storedLinkedinLink = localStorage.getItem('linkedinLink');

    if (storedLinkedinLink !== null && storedLinkedinLink !== '') {
      this.linkedinLink = storedLinkedinLink;
      console.log('Clé existante avec une valeur :', storedLinkedinLink);
    } else if (storedLinkedinLink === '') {
     this.linkedinLink = '';
    } else if(this.selectedCandidate && this.selectedCandidate.linkedin_link) {
     this.linkedinLink= this.selectedCandidate.linkedin_link
    }
}

  createCandidateForm(data?: CCandidate) {
    this.visual = this.visual;
    localStorage.setItem('visual', JSON.stringify(this.visual));

    this.editVisualisationCv()

    return this.formBuilder.group({
      first_name: [
        this.editcv ? (data ? data.first_name : this.firstName) : this.firstName,
        [Validators.required, Validators.pattern(patternInfoUser)],
      ],
      last_name: [
        this.editcv ? (data ? data.last_name : this.lastName) : this.lastName,
        [Validators.required, Validators.pattern(patternInfoUser)],
      ],
      email: [
        this.editcv ? (data ? data.email : this.email) : this.email,
        [Validators.email],
      ],
      emailProESN: [
        data ? data.emailProESN : '',
        [Validators.pattern(patternEmail)],
      ],
      emailProClient: [
        data ? data.emailProClient : '',
        [Validators.pattern(patternEmail)],
      ],
      phone_number: [this.editcv ? (data ? data.phone_number : this.phoneNumber) : this.phoneNumber],
      destination_country: [
        data && data.destination_country != 'null' && data.destination_country != null
          ? data.destination_country
          : [],
      ],
      mobility: [data && data.mobility != 'null' && data.mobility!=null ? data.mobility : []],

      skills_text: [
        this.editcv
          ? (data && data.skills_text !== 'null' ? data.skills_text : this.addSkills)
          : this.addSkills
      ],
      availability: [data ? data.availability : null],
      date_first_experience: [data ? data.date_first_experience : ''],
      current_country: [
        this.editcv ? (data && data.current_country != 'null' && data.current_country != null ? data.current_country : this.currentCountry) : this.currentCountry,
      ],
      desired_contract: [data ? data.desired_contract : null],
      tjm: [data && data.tjm != 'null' && data.tjm != '0' ? data.tjm : ''],
      desired_salary: [
        data && data.desired_salary != 'null' && data.desired_salary != '0'
          ? data.desired_salary
          : '',
      ],
     
      linkedin_link: [
        this.editcv ? (data ? data.linkedin_link : this.linkedinLink) 
        : this.linkedinLink,
      ],


      // last_name: [
      //   this.editcv ? (data ? data.last_name : this.lastName) : this.lastName,
      //   [Validators.required, Validators.pattern(patternInfoUser)],
      // ],
      open_for_mission: [data ? data.open_for_mission : 0],
      cv: [data ? data.cv : null],
      image_url: [data ? data.image_url : ''],
      sub_category_id: [this.arrSkilles],
      skills: this.formBuilder.array(
        Array.isArray(data?.skills) && data?.skills.length
          ? data.skills.map((item: any) => this.createFormSkills(item))
          : []
      ),
      diplome: this.formBuilder.array(
        this.editcv && Array.isArray(data?.diplome) && data?.diplome.length
          ? data.diplome.map((item: any) => {
            this.submittedDeplomes.push(false);

            return this.createFormDiplome(item);
          })
          : this.graduation.map((item: any) => {
            return this.createFormDiplome(item);
          })
      ),
      certificat: this.formBuilder.array(
        this.editcv && Array.isArray(data?.certificat) && data?.certificat.length
          ? data.certificat.map((item: any) => {
            this.submittedCertificats.push(false);
            return this.createFormCertificate(item);
          })
          : this.certifications.map((item: any) => {
            return this.createFormCertificate(item);
          })
      ),
      prof_experience: this.formBuilder.array(
        this.editcv && Array.isArray(data?.prof_experience) && data?.prof_experience.length
          ? data.prof_experience.map((item: any) =>
            this.createFormExperience(item)
          )
          : this.experienceProf.map((item: any) => {
            return this.createFormExperience(item);
          })
      ),
      langue_user: this.formBuilder.array(
        this.editcv && Array.isArray(data?.langue_user) && data?.langue_user.length
          ? data.langue_user.map((item: any) => {
            this.submittedLanguages.push(false);

            return this.createFormLangues(item);
          })
          :  this.langueUser.map((item: any) => {
            return this.createFormLangues(item);
          })
      ),

      

      // langue_user: this.formBuilder.array(
      //   Array.isArray(this.langueUser) && this.langueUser.length
      //     ? this.langueUser.map((item: any) => {
      //         this.submittedLanguages.push(false);
      //         return this.createFormLangues(item); // Transforme chaque langue en FormGroup
      //       })
      //     : [] // Fallback si le tableau est vide
      // ),
  


      // langue_user: this.formBuilder.array(
      //   this.editcv 
      //     ? (Array.isArray(this.langueUser) && this.langueUser.length
      //         ? this.langueUser.map((item: any) => {
      //             this.submittedLanguages.push(false);
      //             return this.createFormLangues(item);
      //           })
      //         : []
      //       ) 
      //     : []  
      // ),
      desired_workstation: [
        this.editcv ?(data ? data.desired_workstation : this.desiredWorkstation):this.desiredWorkstation,
        [Validators.maxLength(100)],

      ],
      savoir_faire: [
        this.editcv ? (data ? data.savoir_faire : this.savoirFaire) : this.savoirFaire,
        [Validators.maxLength(500)],
      ],
     
      
      softSkills: [this.editcv 
        ? (data && data.softSkills 
            ? data.softSkills.filter((item: any) => item !== null) 
            : this.softSkills) 
            : this.softSkills,  [Validators.maxLength(500), this.maxWordsValidator(6)]],
            selectedCompanyIds: [this.selectedCompanyIds]
      });
  }
  
  ratings: { [key: string]: number } = {};
  onItemClear(event: any) {
    console.log(' cleared All is :', this.candidateForm?.get('skills')?.value);
    this.deleteSkillsCandidat();
  }

  restSkills() {
    this.addSkills = [];
    this.skillsTextArraytext = [];
  }
  removeSkills: any[] = [];
  items: any[] = [];
  updateSkills: any[] = [];
  skillsTextArraytext: any[] = [];
  subremove: any;

  getItemSkill(event: any) {
    this.items = this.candidateForm.get('sub_category_id')?.value;
    let id = this.items.at(-1);
    this.subremove = id;
    this.skillsAdd.push({ sub_category_id: id, stars: null, user_id: this.id });
    // console.log("skills of ",this.skillsAdd )
    this.skillsList.forEach((item: any) => {
     // console.log('item of skillsList ', item.category_id);

      if (item.id === id) {
        if (item) {
          this.moduleService.getCatId(item.category_id).subscribe((cat) => {
            if (
              this.skillsCandidat.filter((s: any) => s.id === item.category_id)
                .length > 0
            ) {
              item['skills'] = [
                {
                  sub_category_id: id,
                  stars: null,
                  user_id: this.id,
                  checked: '1',
                },
              ];
              this.skillsCandidat
                .filter((s: any) => s.id === item.category_id)[0]
                .sub_category.find((sub: any) => sub.id === item.id) ===
                undefined &&
                this.skillsCandidat
                  .filter((s: any) => s.id === item.category_id)[0]
                  .sub_category.push(item);
            } else {
              cat.data.sub_category = cat.data.sub_category
                .filter(
                  (sub: any, index: number, self: any[]) =>
                    index === self.findIndex((t: any) => t.id === sub.id)
                )
                .filter((sub: any) => sub.id === id)
                .map((item: any) => {
                  item['skills'] = [
                    {
                      sub_category_id: id,
                      stars: null,
                      user_id: this.id,
                      checked: '1',
                    },
                  ];
                  return item;
                });
              console.log('item of sub_category_id ', cat.data);
              this.skillsCandidat.push(cat.data);
            }

            console.log('skillsCandidat of ', this.skillsCandidat);
          });
        }
      }
    });

    if (id != undefined) {
      this.removeSkills.push(id);
    }
  }
  clearAllSkills() {
    const subs: any[] = this.candidateForm.get('sub_category_id')?.value;
    const skills: any[] = this.candidateForm
      .get('skills')
      ?.value.map((item: any) => {
        return item.sub_category_id;
      });

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ces compétences ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('subs is  ', subs);
        console.log('skills is ', skills);
        const difference: any[] = subs.filter((x) => !skills.includes(x));
        console.log('difference is ', difference);

        console.log('skillsCandidat is ', this.skillsCandidat);

        this.skillsCandidat.forEach((item0: any) => {
          item0.sub_category = item0.sub_category.filter(
            (item: any) => !difference.includes(item.id)
          );

          return item0;
        });

        let lastskills: any[] = [];
        this.skillsCandidat.forEach((item0: any) => {
          if (item0.sub_category.length > 0) {
            lastskills.push(item0);
          }
        });

        this.skillsCandidat = lastskills;

        console.log('skillsCandidat is ', this.skillsCandidat);

        this.candidateForm
          .get('sub_category_id')
          ?.setValue(subs.filter((value) => skills.includes(value)));

        let names = this.updateSkills.filter((item: any) => item != '');

        console.log('updateSkills is ', this.updateSkills);

        this.addSkills = this.addSkills.filter(
          (item) => !names.includes(item.name)
        );

        console.log(' After AddSkills ? is : ', this.addSkills);

        this.removeSkills.length = 0;
        this.updateSkills.length = 0;
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        this.handleCancel1();
      }
    });
  }

  deleteSkillsCandidat() {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir réinitialiser ces compétences ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.candidatService.deleteAllSkills(this.id).subscribe((res: any) => {
          console.log('response', res);
          this.toastrService.success(
            'Compétences supprimées avec succès',
            'Succès',
            {
              closeButton: true,
              progressBar: true,
              timeOut: 5000,
              extendedTimeOut: 2000,
            }
          );
          this.deletedSkills = [
            ...this.deletedSkills,
            ...Object.keys(this.ratings),
          ];
          const subCategoryIdControl =
            this.candidateForm.get('sub_category_id');
          if (subCategoryIdControl) {
            subCategoryIdControl.setValue(null);
          }
          this.skillsList = this.skillsList.filter(
            (item: any) => item !== this.idSubCategory
          );
          if (subCategoryIdControl) {
            subCategoryIdControl.setValue(this.arrSkilles);
          }
          this.restSkills();
          this.candidateForm.get('sub_category_id')?.reset();
          this.candidateForm.get('skills')?.reset();
          this.skillsCandidat.length = 0;
          this.removeSkills.length = 0;
          this.updateSkills.length = 0;
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('skillsCandidat of handleCancel : ', this.skillsCandidat);
        this.handleCancel1();
      }
    });
  }
  handleCancel1() {
    this.isAnnuler = false;
    const subCategoryIdControl: any[] =
      this.candidateForm.get('sub_category_id')?.value;
    const skills: any[] = this.candidateForm.get('skills')?.value;

    if (subCategoryIdControl.length === 0) {
      this.candidateForm.get('sub_category_id')?.setValue(
        skills
          .filter((item) => item.sub_category_id != null)
          .map((item) => item.sub_category_id)
          .concat(this.removeSkills)
      );
    } else {
      this.candidateForm
        .get('sub_category_id')
        ?.setValue(
          skills
            .filter((item) => item.sub_category_id != this.idSubCategory)
            .map((item) => item.sub_category_id)
        );
    }
  }
  idSkillsDelete: any;

  selectItem(event: any) {
    console.log('value of blur : ', event);
  }
  onKeyDown(event?: KeyboardEvent) {
    console.log('onKeyDown =======> ', event != undefined);
    if (event != undefined)
      if (event?.key === 'Backspace' || event?.key === 'Delete') {
        // event?.preventDefault();
        this.onRemoveSkill(this.idSkillsDelete);
      }
  }
  onRemoveSkill(event: any) {
    if (event.value) {
      if (event != undefined) {
        this.idSubCategory = event.value.id;
        this.deleteOnSkillCandidat(event.value.id);
      }
    } else if (event != undefined) {
      this.idSubCategory = event.id;

      this.deleteOnSkillCandidat(event.id);
    }
  }
  deleteOnSkillCandidat(subCategoryId: any) {
    let skills = this.candidateForm.get('skills')?.value;

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer cette compétence ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      console.log('result.isConfirmed', result.isConfirmed);
      if (result.isConfirmed) {
        console.log('skills of isConfirmed  ', skills);
        console.log('skills of subCategoryId  ', subCategoryId);
        console.log(
          'length of skills : ',
          skills.filter((item: any) => item.sub_category_id === subCategoryId)
            .length
        );

        console.log('skills of skillsCandidat  ', this.skillsCandidat);

        if (
          skills.filter((item: any) => item.sub_category_id === subCategoryId)
            .length === 0
        ) {
          /* this.skillsCandidat = this.skillsCandidat.filter(
            (item: any) => item.sub_category_id === subCategoryId
          );*/
          this.toastrService.success(
            'Compétence supprimée avec succès',
            'Succès',
            {
              closeButton: true,
              progressBar: true,
              timeOut: 5000,
              extendedTimeOut: 2000,
            }
          );

          this.idSubCategory = subCategoryId;
          console.log('before removeSkills remove :  ', this.removeSkills);

          this.removeSkills = this.removeSkills.filter(
            (item) => item != this.idSubCategory
          );
          console.log('after removeSkills remove :  ', this.removeSkills);
          this.deletedSkills.push(subCategoryId);

          this.skillsCandidat = this.skillsCandidat.filter((skill: any) => {
            skill.sub_category = skill.sub_category.filter(
              (item: any) => item.id != subCategoryId
            );
            return skill;
          });
          this.skillsCandidat = this.skillsCandidat.filter((item: any) => {
            if (item.sub_category.length > 0) return item;
          });
          console.log('skills of skillsCandidat after  ', this.skillsCandidat);

          /* this.skillsList = this.skillsList.filter(
            (skill) => skill.id !== subCategoryId
          );*/
          const subCategoryIdControl =
            this.candidateForm.get('sub_category_id');
          if (subCategoryIdControl) {
            const selectedSkills = subCategoryIdControl.value;
            const updatedSelectedSkills = selectedSkills?.filter(
              (id: any) => id !== subCategoryId
            );
            console.log('updatedSelectedSkills : ', updatedSelectedSkills);
            subCategoryIdControl.setValue(updatedSelectedSkills);
          }
          this.isAnnuler = true;
        } else
          this.candidatService
            .deleteOnSkill(this.id, subCategoryId)
            .subscribe((res: any) => {
              console.log('response', res);
              this.toastrService.success(
                'Compétence supprimée avec succès',
                'Succès',
                {
                  closeButton: true,
                  progressBar: true,
                  timeOut: 5000,
                  extendedTimeOut: 2000,
                }
              );
              this.idSubCategory = subCategoryId;
              this.deletedSkills.push(subCategoryId);
              this.skillsCandidat = this.skillsCandidat.filter(
                (skill: any) => skill.id !== subCategoryId
              );

              /* this.skillsList = this.skillsList.filter(
                (skill) => skill.id !== subCategoryId
              );*/
              const subCategoryIdControl =
                this.candidateForm.get('sub_category_id');
              if (subCategoryIdControl) {
                const selectedSkills = subCategoryIdControl.value;
                const updatedSelectedSkills = selectedSkills?.filter(
                  (id: any) => id !== subCategoryId
                );
                console.log('updatedSelectedSkills : ', updatedSelectedSkills);
                subCategoryIdControl.setValue(updatedSelectedSkills);
              }
              this.isAnnuler = true;
              this.getSkills();
              this.getListSkills();
            });
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        this.handleCancel(subCategoryId);
      }
    });
  }

  handleCancel(subCategoryId: any) {
    console.log('subCategoryId is ', subCategoryId);

    this.isAnnuler = false;
    const subCategoryIdControl: any[] =
      this.candidateForm.get('sub_category_id')?.value;
    subCategoryIdControl.push(subCategoryId);
    this.candidateForm
      .get('sub_category_id')
      ?.setValue(this.removeDuplicates(subCategoryIdControl));
    console.log(
      'subCategoryIdControl of value ',
      this.candidateForm.get('sub_category_id')?.value
    );
  }
  removeDuplicates(array: any) {
    return [...new Set(array)];
  }
  removeDuplicateObjects(array: any, key: any) {
    const uniqueKeys = new Set();
    return array.filter((item: any) => {
      const keyValue = item[key];
      if (uniqueKeys.has(keyValue)) {
        return false;
      } else {
        uniqueKeys.add(keyValue);
        return true;
      }
    });
  }

  hasDateError(index: number) {
    const dateField = this.candidateForm.get('diplome.' + index + '.date');
    return (
      dateField?.hasError('invalidDate') &&
      dateField?.touched &&
      dateField?.hasError('required')
    );
  }
  hasDateError1(index: number) {
    const dateField = this.candidateForm.get('certificat.' + index + '.date');
    return dateField?.hasError('invalidDate') && dateField?.touched;
  }

  hasStartDateError(index: number) {
    const startDateControl = this.candidateForm.get(
      `prof_experience.${index}.start_date`
    );
    startDateControl?.hasError('required') && startDateControl.touched;
  }

  add(event: MatChipInputEvent): void {
    const value: any = (event.value || '').trim();
    console.log('event value', value);
    console.log('event value', this.addSkills);
    if (this.addSkills?.length >= 6) {
      this.toastrService.error('Vous ne pouvez pas ajouter plus de 6 soft skills.');
      return;
    }
   
    // this.listSkills?.push(value)
    // Add our skill
    if (this.isFormatCompetence(value)) {
      if (!this.addSkills?.some((item) => item.name === value)) {
        this.updateSkills.push(value); 
        this.addSkills?.push({ name: value }); 
        localStorage.setItem('addSkills', JSON.stringify(this.addSkills)); 
        this.isFormatCompetenceAdmin = false;  
      }
    } else {
      this.isFormatCompetenceAdmin = true; 
    }
    
    // Clear the input value
    event.chipInput!.clear();
  }
  isFormatCompetence(competence: string): boolean {
    // Vérifie que la compétence commence par une lettre ou un chiffre, ne contient que des lettres, des chiffres, des espaces, des points, des hashtags ou des plus, et n'est pas composée uniquement de chiffres
    const pattern = /^(?!^\d+$)[a-zA-Z0-9][a-zA-Z0-9#.\s]*\+*$/;
    return pattern.test(competence);
  }

  calculatePeriod(index: number, current_position: boolean) {
    const experiences = this.candidateForm.get('prof_experience') as FormArray;
    const experience = experiences.at(index) as FormGroup;

    const startDateStr = experience.get('start_date')?.value;
    const endDateStr = current_position ? this.today : experience.get('end_date')?.value;

    const startDate = new Date(startDateStr);
    let endDate = new Date(endDateStr);

    if (this.isInvalidDate(startDate, experience, 'start_date') || this.isInvalidDate(endDate, experience, 'end_date')) return;

    if (startDate > endDate) {
        experience.setErrors({ invalidDateRange: true });
        return;
    } else {
        experience.setErrors(null);
    }

    if (current_position) {
        endDate.setDate(endDate.getDate() - 1);
    }

    const periodObject = this.calculateExactYearsMonthsDays(startDate, endDate);
    const periodString = `${periodObject.years} ans, ${periodObject.months} mois`;
    experience.get('period')?.setValue(periodString);
}

// Precise calculation of years, months, and days between two dates
private calculateExactYearsMonthsDays(startDate: Date, endDate: Date) {
    let years = endDate.getFullYear() - startDate.getFullYear();
    let months = endDate.getMonth() - startDate.getMonth();
    let days = endDate.getDate() - startDate.getDate();

    if (days < 0) {
        months -= 1;
        days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
    }
    if (months < 0) {
        years -= 1;
        months += 12;
    }

    return { years, months, days };
}

// Helper method to validate date
private isInvalidDate(date: Date, experience: FormGroup, field: string): boolean {
    if (isNaN(date.getTime()) || date.getFullYear() < 2000) {
        experience.get(field)?.setErrors({ invalidDate: true });
        return true;
    } else {
        experience.get(field)?.setErrors(null);
        return false;
    }
}


  calculateYearsMonthsDays(daysDifference: number): {
    years: number;
    months: number;
    days: number;
  } {
    const years = Math.floor(daysDifference / 365);
    const remainingDays = daysDifference % 365;
    const months = Math.floor(remainingDays / 30);
    const days = remainingDays % 30;
    return { years, months, days };
  }

  calculateDateDifference(start: string, end: string): number {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const timeDifference = endDate.getTime() - startDate.getTime();
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }
    return 0;
  }

  areAllFieldsTouched(formGroup: FormGroup): boolean {
    return Object.values(formGroup.controls).every((control) => {
      if (control instanceof FormGroup) {
        return this.areAllFieldsTouched(control);
      } else {
        return control.touched;
      }
    });
  }
  addStarsToSkills(skillsCandidatAfterReview: any) {
    const skillsData = JSON.stringify(skillsCandidatAfterReview);
    this.candidatService
      .addStarsForSkillCandidate(skillsCandidatAfterReview)
      .subscribe({
        next: (res) => {
          console.log('Skills updated successfully', res);
          // this.toastrService.success('Skills updated successfully');
        },
        error: (err) => {
          console.error('Error updating skills', err);
          // this.toastrService.error('Error updating skills');
        },
      });
  }
  onCountryChanged() {
    this.phoneForm.get('phone')?.setValue('');
  }
  invalid!: boolean;
  invaliddate!: boolean;
  skillls: any[] = [];

  onSubmit() {
    // this.editcv = true;
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('desiredWorkstation');
    localStorage.removeItem('diplome');
    localStorage.removeItem('email');
    localStorage.removeItem('linkedinLink');
    localStorage.removeItem('phone');
    localStorage.removeItem('softSkills');
    localStorage.removeItem('savoirFaire');
    localStorage.removeItem('prof_experience');
    localStorage.removeItem('currentCountry');
    
    this.candidateForm
      .get('phone_number')
      ?.setValue(this.candidateForm.get('phone_number')?.value ?? '');
    this.submittedCertificats = this.submittedCertificats.map(() => true);
    this.submittedDeplomes = this.submittedDeplomes.map(() => true);
    this.submittedLanguages = this.submittedLanguages.map(() => true);

    const formArrayCertificat = this.candidateForm.get(
      'certificat'
    ) as FormArray;

    this.candidateForm.markAllAsTouched();
    this.candidateForm.markAsDirty();
    const profExperienceArray = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    if (profExperienceArray.length > 0) {
      const startDateValue = this.ExperienceForm.get('start_date')?.value;
      const endDateValue = this.ExperienceForm.get('end_date')?.value;
        if (this.isChosenDateValid) {
          return this.toastrService.error(invalidSelectedDate);
        }
      const posteValue = this.ExperienceForm.get('poste')?.value;
      if (posteValue === null || (posteValue === '' && startDateValue !== '')) {
          return this.toastrService.error(
          'Ajouter le titre de poste de votre experience'
               );
      }
    }

    // add deplome
    var tab_diplomaControls: any[] = [];
    this.diplomaControls.filter((control: AbstractControl) => {
      console.log('control of deplome ', control.value);
      if (control.value.name != '') {
        tab_diplomaControls.push(control.value);
      }
    });

    const formArrayDiplome = this.candidateForm.get('diplome') as FormArray;
    formArrayDiplome.clear();
    tab_diplomaControls.forEach((item) => {
      formArrayDiplome.push(
        new FormGroup({
          date: new FormControl(item.date),
          name: new FormControl(item.name, Validators.required),
          ecole: new FormControl(item.ecole),
          mention: new FormControl(item.mention),
          user_id: new FormControl(item.user_id),
        })
      );
    });
    console.log('after tab_diplomaControls ', tab_diplomaControls);
    this.candidateForm.get('diplome')?.setValue(tab_diplomaControls);
    if (!formArrayDiplome.valid) {
      console.log('formArray Diplome', formArrayDiplome.valid);
      return;
    }

    // add Certificat
    var tab_certificatsControls: any[] = [];
    this.certificatsControls.filter((control: AbstractControl) => {
      if (control.value.name != '') {
        tab_certificatsControls.push(control.value);
      }
    });
    formArrayCertificat.clear();
    tab_certificatsControls.forEach((item: any) => {
      formArrayCertificat.push(
        new FormGroup({
          name: new FormControl(item.name, Validators.required),
          date: new FormControl(item.date),
          skills: new FormControl(item.skills),
          end_date: new FormControl(
            item.end_date ? new Date(item.end_date).getFullYear().toString() : ''
          ),
          user_id: new FormControl(item.user_id),
        })
      );
    });

    //console.log('tab_certificatsControls ', tab_certificatsControls);
    this.candidateForm.get('certificat')?.setValue(tab_certificatsControls);

    if (!formArrayCertificat.valid) {
      console.log('formArrayCertificat', formArrayCertificat.valid);
      return;
    }

    var tab_languesControls: any[] = [];
    this.languesControls.filter((control: AbstractControl) => {
      if (control.value.name != null) {
        tab_languesControls.push(control.value);
      }
    });

    const formArrayLangue_user = this.candidateForm.get(
      'langue_user'
    ) as FormArray;
    formArrayLangue_user.clear();
    // Add the filtered controls back into the FormArray
    tab_languesControls.forEach((item: any) => {
      formArrayLangue_user.push(
        new FormGroup({
          name: new FormControl(item.name, Validators.required),
          stars: new FormControl(item.stars),
          user_id: new FormControl(item.user_id),
        })
      );
    });
    console.log('tab_languesControls ', tab_languesControls);

    this.candidateForm.get('langue_user')?.setValue(tab_languesControls);

    if (!formArrayLangue_user.valid) {
      console.log('formArrayCertificat', formArrayLangue_user.valid);
      return;
    }

    const invalidExperience = profExperienceArray.controls.some(
      (experience) => {
        const currentPosition = experience.get('current_position')?.value;
        const endDate = experience.get('end_date')?.value;
        return !currentPosition && !endDate;
      }
    );

    if (invalidExperience) {
      this.toastrService.error(
        'Veuillez cocher "poste actuel" ou saisir une date de fin pour chaque expérience.'
      );
      return;
    }
    const invalidExperience1 = profExperienceArray.controls.some(
      (experience) => {
        const start_date = experience.get('start_date')?.value;
        return !start_date;
      }
    );

    if (invalidExperience1) {
      this.toastrService.error(
        'Veuillez saisir une date de début pour chaque expérience.'
      );
      return;
    }

    var tab_experiencesControls: any[] = [];
    this.experiencesControls.filter((control: AbstractControl) => {
      if (control.value.poste != '') {
        tab_experiencesControls.push(control.value);
      }
    });

    const formArrayExperience = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    formArrayExperience.clear();
    tab_experiencesControls.forEach((item: any) => {
      console.log('currectposint is : ', item.current_position);

      formArrayExperience.push(
        new FormGroup({
          id: new FormControl(item.id),
          poste: new FormControl(item.poste),
          start_date: new FormControl(item.start_date),
          end_date: new FormControl(item.end_date),
          client: new FormControl(item.client),
          period: new FormControl(item.period),
          project: new FormControl(item.project),
          description: new FormControl(item.description),
          method: new FormControl(item.method),
          technologies: new FormControl(item.technologies),
          tasks: new FormControl(item.tasks),
          user_id: new FormControl(item.user_id),
          current_position: new FormControl(item.current_position),
        })
      );
    });
   
    this.candidateForm
      .get('prof_experience')
      ?.setValue(tab_experiencesControls);

    if (this.candidateForm.get('savoir_faire')?.hasError('maxlength')) {
      return this.toastrService.error(
        'Le champ Savoir-faire / Savoir-être ne peut pas dépasser 500 caractères.'
      );
    }
    if (this.candidateForm.get('softSkills')?.hasError('maxWords')) {
      const error = this.candidateForm.get('softSkills')?.getError('maxWords');
      return this.toastrService.error(
        `Vous ne pouvez pas ajouter plus de ${error.maxWords} soft skills. Vous en avez actuellement ${error.actualLength}.`
      );
    }
    if (this.candidateForm.get('desired_workstation')?.hasError('maxlength')) {
      return this.toastrService.error(
        'Le champ titre ne peut pas dépasser 100 caractères.'
      );
    }

    const isImageChanged = this.candidateForm.get('image_url')?.dirty;
    if (
      (this.candidateForm.touched && this.candidateForm.dirty) ||
      (this.phoneForm.touched && this.phoneForm.dirty) ||
      !this.file ||
      isImageChanged
    ) {
      this.candidateForm.markAllAsTouched();
      this.candidateForm.markAsDirty();
      const phone = this.phoneForm.get('phone')?.value;
      this.candidateForm
        .get('phone_number')
        ?.setValue(phone?.internationalNumber ?? '');

      const invalidPhone = this.phoneForm.controls['phone'].invalid;

      if (this.fileExtention) {
        return this.toastrService.error(fileTypeExtention);
      }
      if (this.isImageError) {
        return this.toastrService.error(fileTypeExtention);
      }
      if (this.candidateForm.get('first_name')?.hasError('pattern')) {
        return this.toastrService.error('Le champ Nom est trop court.');
      }
      if (this.candidateForm.get('last_name')?.hasError('pattern')) {
        return this.toastrService.error('Le champ Prénom est trop court.');
      }
      const languesArray = this.candidateForm.get('langue_user') as FormArray;
      const languages = languesArray.value.map((langue: any) => {
        if (!langue.isFiltered) return langue.name;
      });
      const invalidStarsLanguage = languages.find(
        (lang: any) => lang && (lang.stars === '' || lang.stars === null)
      );
      const duplicateLanguage = languages.find(
        (value: any, index: any, array: any) => array.indexOf(value) !== index
      );
      if (invalidStarsLanguage) {
        return this.toastrService.error(
          'Le niveau de chaque langue doit être sélectionné.'
        );
      }
      if (duplicateLanguage) {
        return this.toastrService.error(
          'Vous ne pouvez pas ajouter deux langues avec le même nom.'
        );
      }

      if (this.isChosenDateValid) {
        return this.toastrService.error(invalidSelectedDate);
      }
      if (this.candidateForm.valid) {
        console.log(this.skillsCandidat.map((item: any) => item.sub_category));

        let listeCombinee: any = [];
        let skillsCandidatAfterReview: any = [];

        skillsCandidatAfterReview = this.skillsCandidat;
        skillsCandidatAfterReview
          .map((item: any) => item.sub_category)
          .forEach((liste: any) => {
            listeCombinee = listeCombinee.concat(liste);
          });

        skillsCandidatAfterReview = listeCombinee.map((item: any) => {
          return {
            sub_category_id: item.skills[0].sub_category_id,
            stars: item.skills[0].stars,
            user_id: this.id,
          };
        });
        const skills = this.candidateForm.get('skills')?.value;
        this.skillsCandidat.forEach((item0: any) => {
          item0.sub_category.forEach((item1: any) => {
            item1.skills.forEach((item2: any) => {
              this.skillls.push(item2);
            });
          });
        });

        this.formData.append('skills', JSON.stringify(this.skillls));

        const diplome = this.candidateForm.get('diplome')?.value;
        const shouldSendEmptyArray =
          !diplome ||
          diplome.some(
            (diploma: any) => diploma.name === null || diploma.name === ''
          );
        this.formData.append(
          'diplome',
          shouldSendEmptyArray ? '[]' : JSON.stringify(diplome)
        );
        const certificat = this.candidateForm.get('certificat')?.value;
        const formattedCertificat = certificat.map((certificats: any) => {
          const skills = certificats.skills;
          return {
            ...certificats,
            skills: Array.isArray(certificats.skills)
            ? skills.filter((skill: string) => skill.trim() !== '') 
            : (typeof skills === 'string' ? skills.split(',').map((skill: string) => skill.trim()).filter(skill => skill) : []),
          };
        });
        const isEmptyCertificat = formattedCertificat.every(
          (certificats: any) =>
            certificats.name === null || certificats.name === ''
        );
        const certificatToSend = isEmptyCertificat ? [] : formattedCertificat;
        this.formData.append('certificat', JSON.stringify(certificatToSend));
        const prof_experience =
          this.candidateForm.get('prof_experience')?.value;
        const formattedExperiences = prof_experience.map((experience: any) => {
          return {
            ...experience,
            technologies: Array.isArray(experience.technologies)
              ? experience.technologies
              : [experience.technologies],
          };
        });

        const isEmptyProfExperience = formattedExperiences.every(
          (experience: any) => experience.poste === ''
        );

        var profExperienceToSend = isEmptyProfExperience
          ? []
          : formattedExperiences;
        profExperienceToSend = profExperienceToSend.map((item: any) => {
          item.current_position = item.current_position ? '1' : '0';
          return item;
        });
        this.formData.append(
          'prof_experience',
          JSON.stringify(profExperienceToSend)
        );
        const langue_user = this.candidateForm.get('langue_user')?.value;
        const shouldSendEmptyArrayLangues =
          !langue_user ||
          langue_user.some((langue: any) => langue.name === null);
        this.formData.append(
          'langue_user',
          shouldSendEmptyArrayLangues ? '[]' : JSON.stringify(langue_user)
        );
        const checkedItems = this.getCheckedItems();
        this.candidateForm.get('desired_contract')?.setValue(checkedItems);
        const desired_contract =
          this.candidateForm.get('desired_contract')?.value;
        if (Array.isArray(desired_contract) && desired_contract.length > 0) {
          for (const contrat of desired_contract) {
            this.formData.append('desired_contract[]', contrat);
          }
        } else {
          this.formData.append('desired_contract', JSON.stringify([]));
        }
        let mobility = this.candidateForm.get('mobility')?.value;

        if (Array.isArray(mobility) && mobility.length > 0) {
          for (const mob of mobility) {
            this.formData.append('mobility[]', mob);
          }
        } 
        if (mobility === null || mobility === 'null') {
         
          this.formData.append('mobility[]', '');
        }
        
        let destCountry = this.candidateForm.get('destination_country')?.value;
        if (Array.isArray(destCountry) && destCountry.length > 0) {
          for (const mob of destCountry) {
            this.formData.append('destination_country[]', mob);
          }
        } else {
          this.formData.append('destination_country[]', '');
        }
        if (destCountry === null || destCountry === 'null') {
          this.formData.append('destination_country[]', '');
        }

        const skillsText = this.addSkills.map((skill) => skill.name);
        this.candidateForm.get('skills_text')?.setValue(skillsText);
        this.formData.delete('skills_text[]');
        skillsText.forEach((text) => {
          this.formData.append('skills_text[]', text);
        });

        // Ajouter un payload vide si aucune compétence n'est présente
        if (skillsText.length === 0) {
          this.formData.append('skills_text[]', '');
        }
        let skills_text = this.candidateForm.get('skills_text')?.value || [];

        // Réinitialiser FormData pour s'assurer que toutes les compétences sont bien mises à jour
        this.formData.delete('skills_text[]');
        if (Array.isArray(skills_text)) {
          skills_text.forEach((text) => {
            this.formData.append('skills_text[]', text);
          });
        } else {
          console.error('Skills_text is not an array:', skills_text);
        }
        if (skills_text.length === 0) {
          this.formData.append('skills_text[]', '');
        }

        const softSkills = this.candidateForm.get('softSkills')?.value;
       // softSkills = (softSkills !== "null" && softSkills !== null) ? softSkills : [];
        // Réinitialiser FormData pour mettre à jour avec les compétences actuelles
        this.formData.delete('softSkills[]');
        const currentSoftSkills = this.editcv 
          ? (softSkills && softSkills !== "null" && softSkills !== null) ? softSkills : []  
          : this.softSkills; 
          if (Array.isArray(currentSoftSkills)) {
            currentSoftSkills.forEach((skill) => {
              if (skill !== null && skill !== "null") {
                this.formData.append('softSkills[]', skill.trim());
              }
            });
          
            if (currentSoftSkills.length === 0  ) {
              this.formData.append('softSkills[]', '');
            }
          } else {
            console.error('Soft skills is not an array:', currentSoftSkills);
          }

        // Réinitialiser FormData pour s'assurer que toutes les compétences sont bien mises à jour
        this.formData.delete('softSkills[]');
        if (Array.isArray(softSkills)) {
        
          softSkills.forEach((text) => {
            if (text !== null && text !== "null"){
              this.formData.append('softSkills[]', text);
            }
          });
        } else {
          console.error('Skills_text is not an array:', softSkills);
        }
        if (softSkills.length === 0) {
          this.formData.append('softSkills[]', '');
        }

        this.candidateForm
          .get('desired_contract')
          ?.setValue(this.getCheckedItems());
          this.formData.append(
            'first_name',
           // this.editcv 
            this.candidateForm.get('first_name')?.value
            ? this.candidateForm.get('first_name')?.value.trim()
            : '' 
           // : this.firstName.trim()
          );
        this.formData.append(
          'last_name',
          // this.editcv 
           this.candidateForm.get('last_name')?.value
            ? this.candidateForm.get('last_name')?.value.trim()
            : ''
          //  this.lastName.trim()
        );
        if (this.candidateForm.get('cv')?.value == 'null') {
          this.formData.append('cv', '');
        } else {
          this.formData.append('cv', this.candidateForm.get('cv')?.value);
        }
        this.formData.append(
          'current_country',
           this.candidateForm.get('current_country')?.value && this.candidateForm.get('current_country')?.value !== null && this.candidateForm.get('current_country')?.value !=="null"
           ? this.candidateForm.get('current_country')?.value
            : ''
        );
        this.formData.append(
          'open_for_mission',
          this.candidateForm.get('open_for_mission')?.value
        );
        this.formData.append(
          'availability',
          this.candidateForm.get('availability')?.value
            ? this.candidateForm.get('availability')?.value
            : []
        );
        this.formData.append(
          'desired_workstation', 
          this.candidateForm.get('desired_workstation')?.value
            ? this.candidateForm.get('desired_workstation')?.value
            : ''
        );
        this.formData.append(
          'savoir_faire',
            this.candidateForm.get('savoir_faire')?.value
            ? this.candidateForm.get('savoir_faire')?.value.trim()
            : ''
        );
        this.formData.append(
          'desired_salary',
          this.candidateForm.get('desired_salary')?.value
          ? this.candidateForm.get('desired_salary')?.value
          : ''
        );
        this.formData.append(
          'date_first_experience',
          this.candidateForm.get('date_first_experience')?.value
            ? this.candidateForm.get('date_first_experience')?.value
            : ''
        );
        this.formData.append(
          'tjm',
          this.candidateForm.get('tjm')?.value
            ? this.candidateForm.get('tjm')?.value
            : ''
        );
        this.formData.append(
          'linkedin_link',
           this.candidateForm.get('linkedin_link')?.value
           ? this.candidateForm.get('linkedin_link')?.value
          : ''
        );
        this.formData.append(
          'phone_number',
          this.candidateForm.get('phone_number')?.value
          ? this.candidateForm.get('phone_number')?.value
          : ''
        );
        // this.formData.append('email', this.candidateForm.get('email')?.value);

        this.formData.append(
          'email',
           this.candidateForm.get('email')?.value
           ? this.candidateForm.get('email')?.value
          : '' 
        );


        const emailProESNValue = this.candidateForm.get('emailProESN')?.value;
        const emailProClientValue =
          this.candidateForm.get('emailProClient')?.value;

        if (emailProESNValue !== null && emailProESNValue !== undefined) {
          this.formData.append('emailProESN', emailProESNValue);
        }

        if (emailProClientValue !== null && emailProClientValue !== undefined) {
          this.formData.append('emailProClient', emailProClientValue);
        }

        if (this.imageChanged) {
          this.formData.append('image_url', this.file);
        }
        if (this.dataDissocier) {
          this.dissocier()
        }
        
        this.spinner.show();
        this.candidatService
          .editCandidate(this.id, this.formData)
          .pipe()
          .subscribe({
            next: (res) => {
              this.spinner.hide();
              this.formData = new FormData();
              if (res.status == 200) {
                // this.addSkillsCandidat();
                this.toastrService.success(successEditProfile);
                // Delay the second toast message by 5 seconds (3000 milliseconds)
                if (this.selectedCandidate.first_connection === '1')
                  setTimeout(() => {
                    this.toastrService.info(gotoTest, '', {
                      timeOut: 20000,
                    });
                  }, 5000); // Adjust the delay as needed
                this.fileName = '';
                this.getCandidateById();
                this.getSkills();
                this.navigateToProfilCandidat();
              }
              this.showSkills = 'skills';
            },
            error: (err) => {
              this.spinner.hide();
              this.formData = new FormData();
              if (err?.error) {
                if (err.error?.message['email'])
                  this.toastrService.error(emailExist);
                else if (
                  err.error?.message?.phone_number?.[0] ===
                  'The phone number has already been taken.'
                )
                  this.toastrService.error(phoneNumberExist);
                else if (
                  err.error?.message?.phone_number?.[0] ===
                  "Le numéro du téléphone n'est pas valide."
                )
                  this.toastrService.error('Numéro de téléphone est invalide');
                else if (err.error?.message['linkedin_link'])
                  this.toastrService.error(linkdinExist);
                else {
                  if (err.error?.message['emailProESN']) {
                    this.toastrService.error('Email professionnel ESN existe');
                  }

                  if (err.error?.message['emailProClient']) {
                    this.toastrService.error(
                      'Email professionnel client final existe'
                    );
                  }
                }
                if (
                  err.error?.message ===
                  'The name of diploma has already been taken.'
                ) {
                  this.toastrService.error(
                    'Attention: Vous avez saisi deux fois la même diplome'
                  );
                } else if (
                  err.error?.message ===
                  'The name of certificat has already been taken.'
                ) {
                  this.toastrService.error(
                    'Attention: Vous avez saisi deux fois la même certification'
                  );
                }
              }
            },
          });
      } else {
        this.scrollToError();
        this.toastrService.error(checkRequiredInputs);
      }
    } else {
      this.toastrService.info(noThingChanged);
    }
  }
 checkInput(): void {
    const inputValue = this.candidateForm.get('linkedin_link')?.value;
    this.showMessage = inputValue && inputValue.length > 0;
  }
  setStars(langue: FormGroup, stars: number) {
    langue?.get('stars')?.setValue(stars);
  }

  clearLangues(selectComponent: any) {
    const selectedValue = selectComponent.selectedItems[0]?.value;
    /* this.languages = this.languages.filter((item: any) =>
      Object.keys(selectedValue).every((key) => item[key] != selectedValue[key])
    );*/
  }

  validateLanguages(event: any) {
    this.languages = this.languages.filter((item: any) => {
      if (item.id == event.id) {
        item.isFiltered = true;
      }
      return item;
    });

    const languesArray = this.candidateForm.get('langue_user') as FormArray;
    const languages = languesArray.value
      .filter((langue: any) => {
        if (!langue.isFiltered) return langue.name;
      })

      .map((langue: any) => langue.name);

    const duplicateLanguage = languages.find(
      (value: any, index: any, array: any) => array.indexOf(value) !== index
    );

    if (duplicateLanguage) {
      languesArray.setErrors({ duplicateLanguage: true });
    } else {
      languesArray.setErrors(null);
    }
  }
  get languesControls(): FormGroup<any>[] {
    return (
      (this.candidateForm.get('langue_user') as FormArray)?.controls.map(
        (control) => control as FormGroup
      ) || []
    );
  }
  onNgSelectChange(event: any) {
    console.log(
      'Ng-Select Value:',
      this.candidateForm.get('sub_category_id')?.value
    );
  }
  /* Custom validator function : requirement CV or Linkedin  */
  setValidatorRequirements() {
    const cvControl = this.candidateForm.get('cv');
    const linkedinControl = this.candidateForm.get('linkedin_link');

    if (!cvControl!.value && !linkedinControl!.value) {
      cvControl!.setValidators([Validators.required]);
      linkedinControl!.setValidators([
        Validators.required,
        Validators.pattern(linkedInRegExp),
      ]);
    } else if (cvControl!.value) {
      linkedinControl!.setValidators([Validators.pattern(linkedInRegExp)]);
    } else if (linkedinControl!.value) {
      cvControl!.clearValidators();
      linkedinControl!.setValidators([
        Validators.required,
        Validators.pattern(linkedInRegExp),
      ]);
    }

    cvControl!.updateValueAndValidity();
    linkedinControl!.updateValueAndValidity();
  }
  /* get phone number */
  changePhoneNumber(event: any) {
    if (event.phoneNumber)
      this.candidateForm.get('phone_number')?.setValue(event.phoneNumber);
  }
  onSelectChange(event: any, controlName: string) {
    this.candidateForm.get(controlName)?.setValue(event.target.value);
    const selectedMonth = this.candidateForm.get('monthControl')?.value;
    const selectedYear = this.candidateForm.get('yearControl')?.value;
    if (selectedMonth !== null && selectedYear !== null) {
    }
  }
  navigateToUpdateCandidat(id: any) {
    this.visual = !this.visual;
    localStorage.setItem('visual', JSON.stringify(this.visual));
    const firstName=this.candidateForm.get('first_name')?.value.trim();
    const lastName = this.candidateForm.get('last_name')?.value.trim();
    const email = this.candidateForm.get('email')?.value.trim();
    const currentCountry = this.candidateForm.get('current_country')?.value;
    console.log('currentCountrrrrrrrrrrry',currentCountry)
    const desiredWorkstation = this.candidateForm.get('desired_workstation')?.value;
    const linkedinLink = this.candidateForm.get('linkedin_link')?.value;
    const savoirFaire = this.candidateForm.get('savoir_faire')?.value;
    const softSkills = this.candidateForm.get('softSkills')?.value || [];
    const certificats = this.candidateForm.get('certificat')?.value;
    const diplome = this.candidateForm.get('diplome')?.value;
    const prof_experience =this.candidateForm.get('prof_experience')?.value || [];
    const competance =this.candidateForm.get('sub_category_id')?.value;
    const phone = this.phoneForm.get('phone')?.value;
    localStorage.setItem('competance', competance);
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('lastName', lastName);
    localStorage.setItem('email', email);
    if (phone?.internationalNumber) {
      localStorage.setItem('phone', phone?.internationalNumber);
    }
    
    localStorage.setItem('currentCountry',currentCountry);
    if (currentCountry !== null && currentCountry !== 'null') {
      localStorage.setItem('currentCountry', currentCountry);
    }else {
      localStorage.setItem('currentCountry', '');
    }
    if (desiredWorkstation !== null && desiredWorkstation !== 'null') {
      localStorage.setItem('desiredWorkstation', desiredWorkstation);
    }else {
      localStorage.setItem('desiredWorkstation', '');
    }

    if (linkedinLink !== null && linkedinLink !== 'null') {
      localStorage.setItem('linkedinLink', linkedinLink);
    }else {
      localStorage.setItem('linkedinLink', '');
    }
    if (savoirFaire !== null && savoirFaire !== 'null') {
      localStorage.setItem('savoirFaire', savoirFaire);
    }else {
      localStorage.setItem('savoirFaire', '');
    }
    localStorage.setItem('certificats', JSON.stringify(certificats));
    localStorage.setItem('Candidatskills', JSON.stringify(this.skillsCandidat));
    localStorage.setItem('diplome', JSON.stringify(diplome));
    localStorage.setItem('prof_experience', JSON.stringify(prof_experience));
    if(softSkills.length>6) {
      this.toastrService.error('Vous ne pouvez pas ajouter plus de 6 soft skills.')
      //return
    }
    else {
      localStorage.setItem('softSkills', JSON.stringify(softSkills));
      console.log('gggggg',JSON.stringify(softSkills))
    }
    const langue_user = this.candidateForm.get('langue_user')?.value || [];
    if(langue_user.length>6) {
      this.toastrService.error('Vous ne pouvez pas ajouter plus de 6 soft skills.')
      //return
    }
    else {
      localStorage.setItem('langue_user', JSON.stringify(langue_user));
    }
    this.router.navigate(['/wegestu/profil-candidat/cv-candidat', { idCandidat: id }]);
  }
  navigateToCvCandidat() {
    this.router.navigate(['/wegestu/profil-candidat/cv-candidat']);
  }
  navigateToProfilCandidat() {
    this.editcv = !this.editcv;
    localStorage.setItem('editcv', JSON.stringify(this.editcv));
    this.router.navigate(['/wegestu/profil-candidat']);
  }
  /* calculate experience */
  show_experience: boolean = true;
  show_error: boolean = false;
  experienceText: any;
  calculateExperience() {
    const dateInput = this.candidateForm.get('date_first_experience')?.value;
    if (!dateInput) {
      this.experienceText = ''; // Réinitialiser le texte de l'expérience
      this.isexp = false; // Masquer l'élément si nécessaire
      return;
    } else {
      this.isexp = true;
    }
    // Current date
    let currentDate: any = new Date();
    // Start date
    let startDateObj: any = new Date(
      this.candidateForm.value.date_first_experience
    );
    // Calculate the difference in milliseconds
    let timeDifference = currentDate - startDateObj;
    if (timeDifference > 0) this.show_experience = true;
    else this.show_experience = false;
    // Convert the difference to years and months
    let years = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
    let months = Math.floor(
      (timeDifference % (365.25 * 24 * 60 * 60 * 1000)) /
      (30.44 * 24 * 60 * 60 * 1000)
    );
    this.experienceText =
      years > 0 && months > 0
        ? `${years} ans et ${months} mois`
        : years == 0
          ? `${months} mois`
          : months == 0
            ? `${years} ans`
            : '';
    this.updateExperienceText();
  }

  isChosenDateValid!: boolean;
  changeExperience(event: any) {
    this.experience = event.target.checked ? true : false;
    localStorage.setItem('experience', JSON.stringify(this.experience));
    this.show_experience = !this.experience;

    if (this.experience) {
      this.candidateForm.get('date_first_experience')?.setValue(null);
      localStorage.removeItem('experienceText');
      this.experienceText = '---';

      this.selectedDate = this.candidateForm.value.date_first_experience;
      this.candidateForm.patchValue({ date_first_experience: '' });
      this.isChosenDateValid = false;
      localStorage.setItem('date_first_experience', '');
      if (this.selectedDate) {
        const formattedDate = this.formatDateForInput(this.selectedDate);
        this.candidateForm.patchValue({ date_first_experience: formattedDate });
        const chosen = new Date(formattedDate);
        const today = new Date();
        this.isChosenDateValid = chosen > today;
        localStorage.setItem('date_first_experience', formattedDate);
      } else {
        const chosenDateString = this.candidateForm.value.date_first_experience;
        const chosen = new Date(chosenDateString);
        const today = new Date();
        this.isChosenDateValid = chosen > today;
        localStorage.setItem('date_first_experience', chosenDateString);
      }
    }
  }

  updateExperienceText() {
    localStorage.setItem('experienceText', this.experienceText); // Mettre à jour le localStorage
  }
  getExperienceText(): string | null {
    return this.localStorageService.getData('experienceText');
  }
  selectDate() {
    const today = new Date();
    const chosenDateString = this.candidateForm.value.date_first_experience;
    const chosen = new Date(chosenDateString);

    if (isNaN(chosen.getTime())) {
      // Handle invalid date scenario
      this.isChosenDateValid = false;
      console.error('Invalid date chosen.');
    } else {
      this.isChosenDateValid = chosen > today;
    }
  }
  selectedDate: string | null = null;

  formatDateForInput(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  /* *********************************** mes competences ******************** */

  /* handle check candidates from selected list  after pagination */
  handleCheck() {
    for (const iterator of this.listCategories) {
      for (const sub of iterator.sub_category) {
        for (const candidat of this.checkedSubCat) {
          if (sub.id == candidat.id) {
            candidat.selected = sub.selected;
          }
        }
      }
    }
  }
  isfutur: any = [];
  isChosenDateValid1: any = [];
  selectDate1(i: any) {
    const today = new Date();
    const chosen = new Date(this.certificatForm.value.date);
    this.currentindex = i;
    this.isChosenDateValid1[i] = chosen > today;
    if (this.isChosenDateValid1[i]) {
      this.isfutur[i] = true;
    } else {
      this.isfutur[i] = false;
    }

    console.log('isfutur', this.isfutur[i]);
  }
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    // Retirer tout caractère qui n'est pas un chiffre, un slash ou un espace
    input.value = input.value.replace(/[^0-9\/]/g, '');
  }
  selectDate3(i: any) {
    const experiencesArray = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    const today = new Date();
    const chosen = new Date(experiencesArray.value[i].start_date);
    this.currentindex = i;
    this.isChosenDateValid1[i] = chosen > today;
    if (this.isChosenDateValid1[i]) {
      this.isfutur[i] = true;
    } else {
      this.isfutur[i] = false;
    }
    if (!experiencesArray.value[i].current_position)
      this.checkeddatefin[i] = false;
  }
  isfutur2: any = [];
  isChosenDateValid2: any = [];
  isfutur3: any = [];
  isChosenDateValid3: any = [];
  currentindex: any;
  selectDate2(index: number): void {
    const date = new Date(
      this.certificatsControls.at(index)?.get('date')?.value
    );
    const end_date = new Date(
      this.certificatsControls.at(index)?.get('end_date')?.value
    );

    if (end_date > new Date()) {
      this.isChosenDateValid3[index] = false;
      this.isfutur3[index] = true;
    } else {
      this.isChosenDateValid3[index] = false;
      this.isfutur3[index] = false;
    }

    if (this.certificatsControls.at(index)?.get('end_date')?.value) {
      if (date > end_date) {
        this.isChosenDateValid2[index] = true;
        this.isfutur2[index] = true;
      } else {
        this.isChosenDateValid2[index] = false;
        this.isfutur2[index] = false;
      }

      if (end_date > new Date()) {
        this.isChosenDateValid3[index] = false;
        this.isfutur3[index] = true;
      } else {
        this.isChosenDateValid3[index] = false;
        this.isfutur3[index] = false;
      }
    } else {
      this.isChosenDateValid2[index] = false;
      this.isfutur2[index] = false;
    }

    if (this.certificatsControls.at(index)?.get('date')?.value) {
      if (date > end_date) {
        this.isChosenDateValid2[index] = true;
        this.isfutur2[index] = true;
      } else {
        this.isChosenDateValid2[index] = false;
        this.isfutur2[index] = false;
      }

      if (date > new Date()) {
        this.isChosenDateValid2[index] = false;
        this.isfutur2[index] = true;
      }
    } else {
      this.isChosenDateValid2[index] = false;
      this.isfutur2[index] = false;
    }
  }

  isfuturforma: any = [];
  isChosenDateValid1forma: any = [];
  selectDate1forma(i: any) {
    const today = new Date();
    const chosen = new Date(this.diplomeForm.value.date);
    this.currentindex = i;
    this.isChosenDateValid1forma[i] = chosen > today;
    if (this.isChosenDateValid1forma[i]) {
      this.isfuturforma[i] = true;
    } else {
      this.isfuturforma[i] = false;
    }
    console.log('isfuturforma', this.isfuturforma[i]);
  }

  allDatesValid(): boolean {
    return this.isfuturforma.every((dateValid: any) => !dateValid);
  }
  onClose() {
    console.log('Dropdown closed');
    alert('close all ');
  }

  clearSelection(event: Event) {
    event.stopPropagation(); // Prevent dropdown from closing
    this.skillsList = [];
  }

  getListModules() {
    this.moduleService
      .getListCategoriesCnadidat()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.categories = res.data.data;
          this.categories.map((el: any) => {
            el.sub_category.map((subCat: any) => {
              subCat.selected = false;
            });
          });
          this.listCategories = res.data.data.slice(
            this.startIndex,
            this.endIndex
          );
          this.totalItems = res.data.total;
          this.getSkillsCandidate();
        },
        error: () => { },
      });
  }

  getSkills() {
    this.candidatService
      .getSkillsCandidate(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsCandidat = res.data.categoriesWithSubCategoriesAndSkills;
          console.log(
            'categoriesWithSubCategoriesAndSkills ',
            this.skillsCandidat
          );
          this.totalItems = res.data.count;
          if (!this.isAnnuler)
            this.skillsCandidat.forEach((item: any) => {
              if (item.sub_category.length > 0)
                item.sub_category.forEach((element: any) => {
                  if (!this.arrSkilles.includes(element.id))
                    this.arrSkilles.push(element.id);
                  const subCategoryIdControl =
                    this.candidateForm.get('sub_category_id');
                  if (this.isAnnuler)
                    this.arrSkilles = this.arrSkilles.filter(
                      (item: any) => item != this.idSubCategory
                    );
                  if (subCategoryIdControl) {
                    subCategoryIdControl.setValue(this.arrSkilles);
                  }
                });
            });
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }

  downloadFile(typeFile?: string) {
    if (typeFile && typeFile != '')
      this.selectedCandidate.file_test =
        this.selectedCandidate.file_test.split('/')[1];
    this.spinner.show();
    const fileUrl = !typeFile
      ? `${this.url}/User/file/gallery_cvs/${this.selectedCandidate.cv}`
      : `${this.url}/ResponseCandidate/GetFile/${this.selectedCandidate.file_test}`;
    this.candidatService.downloadPDF(fileUrl).subscribe((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = !typeFile
        ? `${this.selectedCandidate.ID_user}_${this.selectedCandidate.first_name
        }_${this.selectedCandidate.last_name
        }_CV.${this.selectedCandidate!.cv.split('.').pop()}`
        : `${this.selectedCandidate.ID_user}_${this.selectedCandidate.first_name
        }_${this.selectedCandidate.last_name
        }_QCM.${this.selectedCandidate!.file_test.split('.').pop()}`;
      document.body.appendChild(a);
      a.click();
      this.spinner.hide();
    });
  }
  getListSkills() {
    this.candidatService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => { },
      });
  }
  getSkillsCandidate() {
    this.candidatService
      .getSkillsCandidate(this.id)
      .pipe()
      .subscribe({
        next: (res) => {
          this.skillsCandidate = res.data.categoriesWithSubCategoriesAndSkills;
          const subCategoryIds = this.skillsCandidate.flatMap((item: any) =>
            item.sub_category.map((sub: any) => {
              return sub.id;
            })
          );
          this.selectedSubcategoryIds = subCategoryIds;
          this.categories?.forEach((category: any) => {
            category.sub_category.forEach((subcategory: any) => {
              if (subCategoryIds.includes(subcategory.id)) {
                subcategory.selected = true;
              }
              // else {
              //   subcategory.selected = false;
              // }
            });
          });
          /* this.listCategories */
          this.getSubCategoryNamesByIds(subCategoryIds, this.categories);
        },
        error: (err) => { },
      });
  }
  onCheckboxChange(category: any, subcategory: any, event: any) {
    console.log('subcategory', subcategory);

    subcategory.selected = event.target.checked;
    // this.categories.map((el:any)=>{
    //   el.sub_category.map((subCat:any)=>{
    //     if(subCat.id==subcategory.id){

    //     }
    //   })
    // })
    console.log('categories', this.categories);

    this.updateSubSkillSlection();
  }
  checkedSubCat: any = [];

  updateSubSkillSlection() {
    let selectedSubcategoryNames = [];
    this.selectedSubcategoryIds = [];
    for (const iterator of this.categories) {
      for (const cat of iterator.sub_category) {
        if (cat.selected) {
          this.checkedSubCat.push(cat);
          selectedSubcategoryNames.push(cat.name_FR);

          if (!this.selectedSubcategoryIds.includes(cat.id)) {
            this.selectedSubcategoryIds.push(cat.id);
          }
        }
      }
    }
    this.selectedSubcategories = [...selectedSubcategoryNames];
  }

  getSubCategoryNamesByIds(ids: number[], data: any[]) {
    const subCategoryNames: string[] = [];

    console.log('cats of ', data);
    if (Array.isArray(data)) {
      for (let category of data) {
        for (let subCategory of category.sub_category) {
          if (ids.includes(subCategory.id)) {
            subCategoryNames.push(subCategory.name_FR);
          }
        }
      }
    }
    this.selectedSubcategories = [...subCategoryNames];
    this.handleCheck();
  }

  onInputChange(data: any) {
    this.candidateForm.get('phone_number')?.setValue(data.phoneNumber);
  }
  onSelectChangeSkills(event: any) {
    this.selectedSubcategoryIds = [];
    for (const iterator of this.categories) {
      for (const subCat of iterator.sub_category) {
        if (event.includes(subCat.name_FR)) {
          this.selectedSubcategoryIds.push(subCat.id);
          subCat.selected = true;
        } else {
          subCat.selected = false;
        }
      }
    }
  }

  /* get Ids From Names */
  getIdsFromNames(names: string[], data: any[]): number[] {
    const itemIds: number[] = [];
    for (const category of data) {
      for (const subCategory of category.sub_category) {
        if (names.includes(subCategory.name_FR)) {
          itemIds.push(subCategory.id);
        }
      }
    }
    return itemIds;
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }

  deletedSkills: string[] = [];

  setRating(i: any, j: any, stars: any) {
    console.log('skill_Id : ', i, 'sub_category_id : ', j, 'stars : ', stars);
    console.log('skillsCandidat1', this.skillsCandidat[i]);
    this.skillsCandidat[i].sub_category[j].skills[0].stars = stars;
    console.log('skillsCandidat2', this.skillsCandidat[i]);
  }
  setChecked(i: any, j: any, checked: any) {
    console.log(
      'skill_Id : ',
      i,
      'sub_category_id : ',
      j,
      'checked : ',
      checked,

      this.skillsCandidat[i].sub_category[j]
    );

    this.skillsCandidat[i].sub_category[j].skills[0].checked =
      checked === '0' ? '1' : '0';
  }

  isStarGold(i: number, j: number, k: number): boolean {
    const flattenedIndex = i * this.maxSubSkills + j;
    return this.flattenedStars[flattenedIndex] >= k;
  }

  getSkillName(index: number): string {
    const skill = this.skillsCandidat[index];
    return skill ? skill.name_FR : '';
  }

  getSubSkills(index: number): any[] {
    const skill = this.skillsCandidat[index];
    return skill ? skill.sub_category : [];
  }

  resetCV() {
    const inputFile = document.getElementById('cv') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.fileExtention = '';
    this.fileError = '';
    this.candidateForm.get('cv')?.setValue('');
    console.log('clear cv', this.candidateForm.get('cv')?.value);
    this.fileName = '';
    this.existCv = true;
  }
  onCountryChange(mode: string): void {
    this.candidateForm.get('mobility')?.enable();
    this.countries = this.candidateForm.get('destination_country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.candidateForm.get('mobility')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0)
        /// if(this.mobilities.length==0)
        after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.candidateForm.get('mobility')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.candidateForm.get('mobility')?.disable();
        this.candidateForm.get('mobility')?.setValue('');
      }
    }
  }

  onMobilityChange(mode: string) {
    this.mobilities = this.candidateForm.get('mobility')?.value;
    let paysLists: any[] = [];
    this.countries = this.candidateForm.get('destination_country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    console.log(
      'stateList in change mobilities mobilities : ',
      this.mobilities
    );
    console.log(
      'stateList in change mobilities stateListAfter  : ',
      stateListAfter
    );
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.candidateForm.get('mobility')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }

  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }
    return duplicates;
  }

  // @TOFIX: duplicated
/**
 * Exports the CV of a candidate based on the specified type and company ID.
 *
 * @param {number} type - The type of CV to export (e.g., 1 for standard, 2 for anonymous)
 * @param {number | null} idCompany - The ID of the company for which the CV is being exported.
 *                          Use `null` if no specific company is associated.
 */
exportCVCandidate(type: number, idCompany : number | null) { 
  this.spinner.show()
  const candidate = {
    ids: [this.id],
    last_name: this.selectedCandidate?.last_name,
    first_name: this.selectedCandidate?.first_name
  } 

    const exportMethod = idCompany !== null
    ? this.exportService.exportCVEsnCandidate(candidate, type, idCompany)
    : this.exportService.exportCVWegestuCandidate(candidate, type);

  exportMethod.subscribe({
    next: () => {
      this.spinner.hide();
    },
    error: () => {
      this.spinner.hide();
    },
  });
}


  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
interface Langue {
  name: string;
  stars: number;
}

interface Certificat {
  name: string;
  date: Date;
}
interface Diplome {
  name: string;
  date:Date;
  ecole: string;
}

interface SubCategory {
  id: number;
  name_FR: string;
  name_EN: string | null;
  icon: string;
  nb_question: number;
  archived: string;
}

interface SkillsCandidat {
  name_FR: string;
  sub_category: SubCategory[];
}
interface Experience {
  poste:string;
  start_date: Date;
  end_date: Date;
  period: string;
  current_position : boolean;
  client: string;
  project: string;
  description: string;
  method: string;
  tasks: string;
  technologies: string; 
}