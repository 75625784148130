<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
  <!--begin::Toolbar container-->
  <div
    id="kt_app_toolbar_container"
    class="app-container container-fluid d-flex flex-stack"
  >
    <!--begin::Page title-->
    <div
      class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
    >
      <!--begin::Title-->
      <div class="d-flex align-items-center">
        <h1 class="page-heading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
          >
            <g filter="url(#filter0_d_2093_798)">
              <path
                d="M32.8286 12.3011C32.8286 12.0526 32.7289 11.8142 32.5514 11.6385C32.374 11.4627 32.1334 11.364 31.8824 11.364H22.0522C21.8013 11.364 21.5607 11.4627 21.3832 11.6385C21.2058 11.8142 21.1061 12.0526 21.1061 12.3011V26.7139H19.4062V28.5881H34.2761V26.7139H32.8286V12.3011ZM22.9984 13.2382H30.9363V26.7139H22.9984V13.2382Z"
                fill="#265D91"
              />
              <path
                d="M25.4293 14.191H23.5371V17.0211H25.4293V14.191Z"
                fill="#265D91"
              />
              <path
                d="M27.9508 14.191H26.0586V17.0211H27.9508V14.191Z"
                fill="#265D91"
              />
              <path
                d="M30.4684 14.191H28.5762V17.0211H30.4684V14.191Z"
                fill="#265D91"
              />
              <path
                d="M25.4293 18.5611H23.5371V21.3912H25.4293V18.5611Z"
                fill="#265D91"
              />
              <path
                d="M27.9508 18.5611H26.0586V21.3912H27.9508V18.5611Z"
                fill="#265D91"
              />
              <path
                d="M30.4684 18.5611H28.5762V21.3912H30.4684V18.5611Z"
                fill="#265D91"
              />
              <path
                d="M25.4293 22.928H23.5371V25.7581H25.4293V22.928Z"
                fill="#265D91"
              />
              <path
                d="M27.9508 22.928H26.0586V25.7581H27.9508V22.928Z"
                fill="#265D91"
              />
              <path
                d="M30.4684 22.928H28.5762V25.7581H30.4684V22.928Z"
                fill="#265D91"
              />
              <path
                d="M17.4223 2.3491C17.4223 2.10056 17.3226 1.8622 17.1452 1.68646C16.9678 1.51072 16.7271 1.41199 16.4762 1.41199H6.64599C6.39506 1.41199 6.15441 1.51072 5.97698 1.68646C5.79954 1.8622 5.69986 2.10056 5.69986 2.3491V16.7619H4V18.6361H18.8699V16.7619H17.4223V2.3491ZM7.59211 3.28621H15.5301V16.7619H7.59211V3.28621Z"
                fill="#265D91"
              />
              <path
                d="M10.0231 4.24194H8.13086V7.07202H10.0231V4.24194Z"
                fill="#265D91"
              />
              <path
                d="M12.5465 4.24194H10.6543V7.07202H12.5465V4.24194Z"
                fill="#265D91"
              />
              <path
                d="M15.0661 4.24194H13.1738V7.07202H15.0661V4.24194Z"
                fill="#265D91"
              />
              <path
                d="M10.0231 8.60901H8.13086V11.4391H10.0231V8.60901Z"
                fill="#265D91"
              />
              <path
                d="M12.5465 8.60901H10.6543V11.4391H12.5465V8.60901Z"
                fill="#265D91"
              />
              <path
                d="M15.0661 8.60901H13.1738V11.4391H15.0661V8.60901Z"
                fill="#265D91"
              />
              <path
                d="M10.0231 12.9758H8.13086V15.8059H10.0231V12.9758Z"
                fill="#265D91"
              />
              <path
                d="M12.5465 12.9758H10.6543V15.8059H12.5465V12.9758Z"
                fill="#265D91"
              />
              <path
                d="M15.0661 12.9758H13.1738V15.8059H15.0661V12.9758Z"
                fill="#265D91"
              />
              <path
                d="M30.3343 8.19659H32.2266V2.93003H25.9727V0L20.3086 3.82341L25.9853 7.61871V4.80738H30.3469L30.3343 8.19659ZM23.6768 3.82341L24.0742 3.55477V4.08892L23.6768 3.82341Z"
                fill="#265D91"
              />
              <path
                d="M7.94107 21.8035H6.04883V27.07H12.3027V30.0001L17.9668 26.1766L12.2901 22.3814V25.1927H7.92845L7.94107 21.8035ZM14.5986 26.1766L14.2012 26.4453V25.9174L14.5986 26.1766Z"
                fill="#265D91"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2093_798"
                x="0"
                y="0"
                width="38.2754"
                height="38.0001"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2093_798"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2093_798"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          Entreprises
        </h1>
        <!--begin::Menu-->
      </div>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul
        class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
      >
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a
            [routerLink]="['/wegestu/profil-candidat']"
            class="text-muted"
            routerLinkActive="router-link-active"
            >Accueil</a
          >
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">Liste des entreprises</li>
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
  </div>
  <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
  <!--begin::Content container-->
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->
      <div class="card-body tools">
        <div class="title mb-10">
          <h4>Recherche</h4>
        </div>
        <div [formGroup]="searchForm">
          <div class="card-title">
            <div class="row">
              <div class="col-lg-6">
                <div class="input-group search-libre">
                  <span class="input-group-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                        fill="#7E7C7C"
                      />
                    </svg>
                  </span>

                  <input
                    type="text"
                    data-kt-customer-table-filter="search"
                    class="form-control form-control-solid ps-5"
                    placeholder="Rechercher"
                    formControlName="search"
                    (keyup.enter)="searchCompnies()"
                  />
                  <div class="tooltip-container tooltip-info">
                    <div class="tooltip-trigger">
                      <i
                        class="fa-solid fa-circle-info"
                        style="font-size: 20px"
                      ></i>
                    </div>
                    <div class="tooltip-content">
                      <div class="content">
                        <h4>Opérateur AND :</h4>
                        <p>
                          Utilisez l'opérateur AND pour spécifier que les
                          résultats doivent inclure plusieurs termes.
                        </p>
                        <p>
                          Exemple : software AND engineer renverra des profils
                          qui mentionnent à la fois "software" et "engineer".
                        </p>
                        <h4>Opérateur OR :</h4>
                        <p>
                          Utilisez l'opérateur OR pour spécifier que les
                          résultats peuvent inclure l'un des termes.
                        </p>
                        <p>
                          Exemple : sales OR marketing renverra des profils qui
                          mentionnent soit "sales" soit "marketing".
                        </p>
                        <h4>Opérateur NOT :</h4>
                        <p>
                          Utilisez l'opérateur NOT pour exclure des termes
                          spécifiques des résultats.
                        </p>
                        <p>
                          Exemple : developer NOT manager renverra des profils
                          qui mentionnent "developer" mais pas "manager".
                        </p>
                        <h4>Guillemets pour une expression exacte :</h4>
                        <p>
                          Utilisez des guillemets pour rechercher une expression
                          exacte.
                        </p>
                        <p>
                          Exemple : "product manager" renverra des profils qui
                          mentionnent exactement "product manager".
                        </p>
                        <h4>Parenthèses pour grouper des opérations :</h4>
                        <p>
                          Utilisez des parenthèses pour définir l'ordre
                          d'évaluation des opérations.
                        </p>
                        <p>
                          Exemple : (sales OR marketing) AND manager renverra
                          des profils qui mentionnent "sales" ou "marketing" et
                          "manager".
                        </p>
                        <h4>Recherche standard :</h4>
                        <p>
                          Si aucun opérateur n'est spécifié dans la recherche,
                          le texte sera interprété comme s'il était entre
                          guillemets.
                        </p>
                        <p>
                          Exemple : La recherche product manager sera traitée
                          comme "product manager", suivant la règle 4.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="text-danger mt-2"
                *ngIf="searchForm.get('search')?.hasError('pattern') && searchForm.get('search')?.touched && isSimpleWord(searchForm.get('search')?.value)">
                Le modèle de recherche est invalide. Utilisez le format correct (par exemple "hello" OR "hello").
              </div> -->
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-3 mb-3">
              <div class="input-shadow">
                <input
                  type="text"
                  formControlName="ID_company"
                  (change)="ispagination = false"
                  class="form-control form-control-solid ps-5 mb-3"
                  name="Nom"
                  placeholder="ID"
                />
              </div>
            </div>
            <div class="col-lg-3 mb-3">
              <div class="input-shadow">
                <input
                  type="text"
                  formControlName="name"
                  (change)="ispagination = false"
                  class="form-control form-control-solid ps-5 mb-3"
                  name="Nom"
                  placeholder="Nom Entreprise"
                />
              </div>
            </div>
            <div class="col-lg-3 mb-3">
              <ng-select
                (change)="ispagination = false"
                [items]="typeEntrepriseFiltered"
                bindLabel="name"
                bindValue="id"
                id="typeEntreprise"
                placeholder="Type d’entreprise"
                [notFoundText]="'Aucun élément trouvé'"
                formControlName="typeComp"
              >
              </ng-select>
            </div>
            <div class="col-lg-3 mb-3">
              <ng-select
                (change)="ispagination = false"
                formControlName="country"
                [items]="paysList"
                bindLabel="name"
                bindValue="name"
                [placeholder]="'Pays'"
                [multiple]="true"
                [notFoundText]="'Aucun élément trouvé'"
                autoComplete
              >
              </ng-select>
            </div>
          </div>
          <div class="row">
            <!--  <div class="col-lg-3  mb-3">
                        <ng-select   [items]="Stateesn" bindLabel="name" bindValue="id" 
                        id="typeEntreprise" placeholder="statut" [notFoundText]="'Aucun élément trouvé'" formControlName="blocked">
                    </ng-select>
                    </div> -->

            <div class="col-lg-3">
              <div class="me-md-2 mb-5 mt-1">
                <label for="">Avis</label>
                <div class="stars">
                  <rating
                    formControlName="note"
                    [titles]="[' ', ' ', ' ', ' ', ' ']"
                    [max]="5"
                    class="rating-stars"
                    (click)="handleRatingClick($event)"
                  >
                  </rating>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3 rechercher">
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3"></div>

            <div class="col-lg-3">
              <div class="mb-3 search-btn d-flex justify-content-end">
                <div class="btn_search">
                  <a type="button" (click)="searchCompnies()" class="search">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                        fill="white"
                      />
                    </svg>
                    Rechercher
                  </a>
                </div>
                &nbsp;&nbsp;
                <div class="resett-btn">
                  <div class="reset-icon cursor-pointer">
                    <a (click)="reset()">
                      <img
                        src="/assets/media/icons/icon-reset.png"
                        alt=""
                        width="30px"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Content container-->
</div>

<div *ngIf="!isLoadSpinner" id="kt_app_content" class="app-content flex-column-fluid mb-4">
  <!--begin::Content container-->
  <div
    id="kt_app_content_container"
    class="app-container container-fluid for-web"
  >
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->
      <div class="d-flex flex-wrap my-1 header-grid align-items-center">
        <div class="section_title">
          <h1 class="section-heading my-0">Liste des entreprises</h1>
        </div>
        <!--begin::Tab nav-->
        <ul class="nav nav-pills me-5">
          <li class="nav-item m-0">
            <a
              class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3"
              data-bs-toggle="tab"
              href="#kt_project_targets_card_pane"
            >
              <i class="ki-duotone ki-element-plus fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </i>
            </a>
          </li>
          <li class="nav-item m-0">
            <a
              class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active"
              data-bs-toggle="tab"
              href="#kt_project_targets_table_pane"
            >
              <i class="ki-duotone ki-row-horizontal fs-2">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
        </ul>
        <!--end::Tab nav-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <div class="tab-content">
          <!--begin::Tab pane-->
          <div id="kt_project_targets_card_pane" class="tab-pane fade">
            <!--begin::Row-->
            <div class="row g-6 g-xl-9 justify-content-center">
              <!--begin::Col-->
              <div
                class="col-md-6 col-xxl-4"
                *ngFor="let company of listCompany"
              >
                <!--begin::Card-->
                <div class="card pt-5 p-5">
                  <div class="tools">
                    <div class="poste">
                      <span
                        *ngIf="company?.company?.type !== '1'"
                        [ngClass]="
                          company?.company?.type == '2'
                            ? 'esn-type'
                            : company?.company?.type == '3'
                            ? 'client-type'
                            : 'centre-type'
                        "
                        class="type-esn text-uppercase wrapping fw-bold"
                      >
                        {{
                          company?.company?.type == "1"
                            ? "Entreprise"
                            : company?.company?.type == "2"
                            ? "ESN"
                            : company?.company?.type == "3"
                            ? "Client Final"
                            : "Cabinet de Recrutement"
                        }}</span
                      >
                      <span *ngIf="company?.company?.type == '1'">---</span>
                    </div>
                    <div class="actions">
                      <div class="tools-links">
                        <div class="dropdown">
                          <button
                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              width="19"
                              height="5"
                              viewBox="0 0 19 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="2.44739"
                                cy="2.44739"
                                r="2.44739"
                                transform="matrix(1 0 0 -1 0 4.89478)"
                                fill="#265D91"
                              />
                              <circle
                                cx="2.44739"
                                cy="2.44739"
                                r="2.44739"
                                transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                fill="#265D91"
                              />
                              <circle
                                cx="2.44739"
                                cy="2.44739"
                                r="2.44739"
                                transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                fill="#265D91"
                              />
                            </svg>
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item btn-details"
                              (click)="
                                profilCompanyNavigation(
                                  company.company.id,
                                  company?.company?.user?.role_user_id[0]
                                )
                              "
                              >Voir Détails
                            </a>
                            <a
                              *ngIf="company.company?.employee?.length"
                              class="dropdown-item btn-details"
                              (click)="
                                contactResponsableNavigation(company.company.id)
                              "
                              >Contacter le responsable
                            </a>
                            <a
                              class="dropdown-item btn-details"
                              (click)="
                                reviewCompanyNavigation(
                                  company.company.id,
                                  'detail_company'
                                )
                              "
                              >Evaluer
                            </a>
                            <a
                              *ngIf="!company.blocked"
                              class="dropdown-item btn-details"
                              (click)="bloquer(company.company.id)"
                              >Bloquer
                            </a>
                            <a
                              *ngIf="company.blocked"
                              class="dropdown-item btn-details"
                              (click)="debloquer(company.company.id)"
                              >Débloquer
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-center flex-column">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-65px symbol-circle mb-5">
                      <img
                        *ngIf="company.company?.logo"
                        class="table-img logo-img"
                        src="{{
                          url +
                            '/Company/file/gallery_company/' +
                            company.company?.logo
                        }}"
                        alt=""
                      />
                      <img
                        *ngIf="!company.company?.logo"
                        class="table-img logo-img"
                        src="/assets/media/img/modules/avatar.jpg"
                        alt=""
                      />

                      <div
                        class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-45 ms-n3 mt-n3"
                      ></div>
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Name-->
                    <a class="esn-title"  [routerLink]="[
                    '/wegestu/entreprises/details',
                    {
                      id: company.company?.id,
                      idRole: company?.company?.user?.role_user_id[0]
                    }
                  ]"
                  routerLinkActive="router-link-active">
                      {{
                        company.company?.name && company.company?.name!=="null" && company.company?.name!='0' ? company.company?.name : "---"
                      }}
                    </a>
                    <!--end::Name-->

                    <!--begin::Info-->
                    <!-- <div class="d-flex flex-center flex-wrap">
                                            <div class=" rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">
                                                    {{!company?.user?.created_at?'---':company?.user?.created_at| date:
                                                    'yyyy' }}</div>
                                                <div class="details">Année création</div>
                                            </div>
                                            <div class=" rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">{{
                                                    company.company.employee.length > 0 ?
                                                    company.company.employee.length : '---' }}</div>
                                                <div class="details">Nombre salarié</div>
                                            </div>
                                            <div class=" rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">
                                                    {{company?.note?company?.note:'---'}} {{company?.note?'%':''}}</div>
                                                <div class="details">Note</div>
                                            </div>
                                        </div> -->

                    <!--end::Info-->
                  </div>
                  <div class="foot p-5">
                    <div class="ratings">
                      <rating
                        [(ngModel)]="company.average_note"
                        (ngModelChange)="onRatingChange($event)"
                        [max]="5"
                        [readonly]="true"
                      >
                      </rating>
                    </div>
                  </div>
                  <div class="sep"></div>
                  <div class="foot-card" [ngClass]="{'justify-content-center': !company.company?.website_link && company.company?.website_link!=='null'}">
                    <div class="siteweb" *ngIf="company.company?.website_link && company.company?.website_link!=='null'">
                        <a target="_blank"
                        [attr.href]="getWebsiteLink(company.company?.website_link)"
                        [attr.title]="getWebsiteLink(company.company?.website_link)">
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="25" height="25" rx="3" fill="#265D91" />
                          <path
                            d="M12.0833 20.0001C7.47222 20.0001 3.75 16.6501 3.75 12.5001C3.75 8.35012 7.47222 5.00012 12.0833 5.00012C16.6944 5.00012 20.4167 8.35012 20.4167 12.5001C20.4167 16.6501 16.6944 20.0001 12.0833 20.0001ZM12.0833 6.00012C8.08333 6.00012 4.86111 8.90012 4.86111 12.5001C4.86111 16.1001 8.08333 19.0001 12.0833 19.0001C16.0833 19.0001 19.3056 16.1001 19.3056 12.5001C19.3056 8.90012 16.0833 6.00012 12.0833 6.00012Z"
                            fill="white"
                          />
                          <path
                            d="M16.4145 14.3501C16.5256 13.7501 16.5256 13.1501 16.5256 12.5001C16.5256 11.8501 16.4701 11.2501 16.4145 10.6501C16.3034 9.85012 16.0812 9.10012 15.8589 8.40012C15.0256 6.35012 13.6923 5.00012 12.0812 5.00012C10.1923 5.00012 8.63672 6.85012 7.97005 9.55012C7.9145 9.80012 7.85894 10.0001 7.80339 10.2501C7.69227 10.9501 7.63672 11.7001 7.63672 12.5001C7.63672 13.1501 7.69227 13.7501 7.74783 14.3501C7.85894 15.1501 8.08116 15.9001 8.30339 16.6001C9.08116 18.6501 10.4701 20.0001 12.0256 20.0001C13.5812 20.0001 14.9701 18.6501 15.7478 16.6001C15.3589 16.7001 14.9145 16.7501 14.4701 16.8501C13.9145 18.2001 12.9701 19.0001 12.0812 19.0001C11.1923 19.0001 10.2478 18.2001 9.63672 16.8501C9.35894 16.2501 9.13672 15.5001 8.97005 14.7001C8.80339 14.0001 8.74783 13.3001 8.74783 12.5001C8.74783 11.7001 8.80339 10.9501 8.97005 10.3001C9.02561 10.0501 9.08116 9.85012 9.13672 9.60012V9.55012C9.19227 9.30012 9.24783 9.10012 9.35894 8.90012C9.4145 8.75012 9.47005 8.60012 9.52561 8.50012C9.52561 8.50012 9.52561 8.50012 9.52561 8.45012L9.63672 8.25012C9.63672 8.25012 9.63672 8.20012 9.69227 8.15012C10.2478 6.80012 11.1923 6.00012 12.0812 6.00012C12.9701 6.00012 13.9145 6.80012 14.5256 8.15012C14.8034 8.75012 15.0256 9.50012 15.1923 10.3001C15.3589 11.0001 15.4145 11.7001 15.4145 12.5001C15.4145 13.3001 15.3589 14.0001 15.2478 14.7001C15.6367 14.6001 16.0256 14.5001 16.4145 14.3501Z"
                            fill="white"
                          />
                          <path
                            d="M16.6389 9.15049C15.9167 8.90049 15.0278 8.75049 14.1389 8.65049C13.4722 8.55049 12.8056 8.50049 12.0833 8.50049C11.3611 8.50049 10.6944 8.55049 10.0278 8.60049C9.91667 8.90049 9.75 9.25049 9.63889 9.65049C10.4167 9.55049 11.1944 9.50049 12.0833 9.50049C12.9722 9.50049 13.75 9.55049 14.5278 9.65049C15.4167 9.80049 16.25 10.0005 16.9167 10.2505C18.4167 10.8505 19.3056 11.6505 19.3056 12.4505C19.3056 13.2505 18.4167 14.1005 16.9167 14.6505C16.25 14.9005 15.4167 15.1005 14.5278 15.2505C13.75 15.4505 12.9722 15.5005 12.0833 15.5005C11.1944 15.5005 10.4167 15.4505 9.63889 15.3005C8.75 15.2005 7.91667 15.0005 7.25 14.7005C5.75 14.1505 4.86111 13.3005 4.86111 12.5005C4.86111 11.7005 5.75 10.8505 7.25 10.3005C7.30556 9.90049 7.41667 9.50049 7.52778 9.15049C5.25 9.85049 3.75 11.0505 3.75 12.5005C3.75 13.9505 5.25 15.1505 7.52778 15.8505C8.25 16.1005 9.13889 16.2505 10.0278 16.3505C10.6944 16.4505 11.3611 16.4505 12.0833 16.4505C12.8056 16.4505 13.4722 16.4005 14.1389 16.3505C15.0278 16.2505 15.9167 16.0505 16.6389 15.8505C18.9167 15.1505 20.4167 13.9005 20.4167 12.5005C20.4167 11.1005 18.9167 9.85049 16.6389 9.15049Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </div>
                    <div class="voirplus mt-3">
                      <a
                        class="btn-details"
                        (click)="
                          profilCompanyNavigation(
                            company.company.id,
                            company?.company?.user?.role_user_id[0]
                          )
                        "
                        >Voir plus</a
                      >
                    </div>
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->

              <div
                class="alert alert-warning text-center mt-5"
                role="alert"
                *ngIf="listCompany && !listCompany?.length"
              >
                <img
                  src="/assets/media/logos/logo-no-data.png"
                  alt=""
                  class="mb-5"
                />
                <span class="empty-list"> La liste est vide </span>
              </div>
            </div>
            <!--end::Row-->
          </div>
          <!--end::Tab pane-->
          <!--begin::Tab pane-->
          <div
            id="kt_project_targets_table_pane"
            class="tab-pane fade active show"
          >
            <div class="content">
              <div class="card-body pt-3">
                <!--begin::Table-->
                <div class="table-responsive" id="kt_customers_table">
                  <p-table [value]="listCompany" [scrollable]="true" scrollHeight="700px">
                    <ng-template pTemplate="header">
                      <tr>
                        <ng-container
                        *ngFor="let item of listNames; let i = index"
                      >
                        <th
                          class="min-w-175px"
                          *ngIf="!['Dernière connexion'].includes(item)"
                          [ngClass]="{ 'th-fixed': item === 'Entreprise','th-fixed-2': item === 'Type' }"
                        >
                          <span> {{ item }}</span>
                          <i
                            *ngIf="
                              !['CV', 'Logo', 'CV WegestU', 'Type'].includes(
                                item
                              )
                            "
                            (click)="sortData(item)"
                            class="fa-solid fa-sort cursor-pointer"
                          ></i>
                        </th>
                      </ng-container>
                      <th class="min-w-90px stiky-column-action"><span>Actions</span></th>
                      <th class="stiky-column">
                        <div class="dropdown" id="table-filter">
                          <button
                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d_444_2002)">
                                <rect
                                  x="4"
                                  width="20"
                                  height="20"
                                  rx="4"
                                  fill="#63C3E8"
                                  fill-opacity="0.12"
                                  shape-rendering="crispEdges"
                                />
                              </g>
                              <path
                                d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                fill="#265D91"
                                stroke="#265D91"
                                stroke-width="0.2"
                              />
                              <defs>
                                <filter
                                  id="filter0_d_444_2002"
                                  x="0"
                                  y="0"
                                  width="28"
                                  height="28"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="4" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite
                                    in2="hardAlpha"
                                    operator="out"
                                  />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_444_2002"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_444_2002"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          </button>
                          <div class="list-actions">
                            <div
                              class="list-actions-content dropdown-menu eliminate"
                              aria-labelledby="dropdownMenuButton"
                              data-bs-popper="static">
                              <div
                                class="d-flex checkbox mb-2 menu"
                                *ngFor="let item of dataHeader; let i = index"
                              >
                                <div
                                  class="form-check form-check-sm form-check-custom form-check-solid"
                                  *ngIf="item.name != 'checkbox'"
                                >
                                  <input
                                    class="form-check-input checkbox-border"
                                    [checked]="
                                      (item.checked == 'oui' &&
                                        !item.checked_user) ||
                                      (item.checked_user == 'oui' &&
                                        (item.checked == 'non' ||
                                          item.checked == 'oui'))
                                    "
                                    (change)="
                                      changeSelection($event, item, i)
                                    "
                                    type="checkbox"
                                    value="1"
                                    *ngIf="
                                      !['Dernière connexion'].includes(
                                        item.name
                                      )
                                    "
                                    [disabled]="
                                      item.name === 'Entreprise' ||
                                      item.name === 'Logo'
                                    "
                                  />
                                  <a
                                    *ngIf="
                                      !['Dernière connexion'].includes(
                                        item.name
                                      )
                                    "
                                    class="dropdown-item"
                                    >{{ item.name }}</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body"  let-company>
                      <tr>
                        <td *ngIf="listNames?.includes('ID')">
                          <a
                            class="first-td"
                            [routerLink]="[
                              '/wegestu/entreprises/details',
                              {
                                id: company.company?.id,
                                idRole: company?.company?.user?.role_user_id[0]
                              }
                            ]"
                          >
                            {{
                              company?.company?.ID_company
                                ? company?.company?.ID_company
                                : "---"
                            }}</a
                          >
                        </td>
                        <td *ngIf="listNames?.includes('Logo')">
                          <img
                            *ngIf="company.company.logo"
                            class="table-img logo-img"
                            src="{{
                              url +
                                '/Company/file/gallery_company/' +
                                company.company.logo
                            }}"
                            alt=""
                          />
                          <img
                            *ngIf="
                              !company.company.logo ||
                              company.company.logo === null
                            "
                            class="table-img logo-img"
                            src="/assets/media/img/modules/avatar.jpg"
                            alt=""
                          />
                        </td>
                        <td
                          *ngIf="listNames?.includes('Entreprise')"
                          class="wrapping td-fixed"
                        >
                          <a
                            class="first-td"
                            [routerLink]="[
                              '/wegestu/entreprises/details',
                              {
                                id: company.company?.id,
                                idRole: company?.company?.user?.role_user_id[0]
                              }
                            ]"
                            routerLinkActive="router-link-active"
                          >
                            {{
                              company.company?.name && company.company?.name!=="null" && company.company?.name!='0'
                                ? company.company?.name
                                : "---"
                            }}</a>
                        </td>
                        <td *ngIf="listNames?.includes('Type')" class="wrapping poste-wrapper td-fixed-2">
                          <span
                            class="wrapping"
                            
                            [ngClass]="
                            company.company?.type == '1'
                            ?'entreprise'
                             : company.company?.type == '2'
                                ? 'esn-type'
                                : company.company?.type == '3'
                                ? 'client-type'
                                : 'centre-type'
                            "
                          >
                            {{
                               company.company?.type == '1'
                            ?'entreprise'
                              :company.company?.type == "2" ||
                              company.company?.type == "5"
                                ? "ESN"
                                : company.company?.type == "3"
                                ? "Client Final"
                                : company.company?.type == "4"
                                ? "Cabinet de Recrutement"
                                : "Particulier"
                            }}</span
                          >
                        </td>
                        <td *ngIf="listNames?.includes('Avis')">
                          <div class="stars">
                            <rating
                              [(ngModel)]="company.average_note"
                              [titles]="[' ', ' ', ' ', ' ', ' ']"
                              [max]="5"
                              [readonly]="true"
                              class="rating-stars disable-hover"
                            >
                            </rating>
                          </div>
                        </td>
                        <td *ngIf="listNames?.includes('Taille')">
                          <span>
                            {{
                              company?.company?.size_company && company?.company?.size_company !== "null" && company?.company?.size_company!='0' 
                                ? company?.company?.size_company
                                : "---"
                            }}
                          </span>
                        </td>
                        <td *ngIf="listNames?.includes('Pays')">
                          <span>
                            {{
                              company?.company?.country && company?.company?.country !== "null" && company?.company?.country != '0'
                                ? company?.company?.country
                                : "---"
                            }}
                          </span>
                        </td>
                        <td
                          *ngIf="listNames?.includes('Ville')"
                          
                        >
                          <span >
                            {{
                              company?.company?.city &&
                              company?.company?.city !== "null" && company?.company?.city != "0"
                                ? company?.company?.city
                                : "---"
                            }}
                          </span>
                        </td>

                        <td *ngIf="listNames?.includes('Email')">
                          <span>
                            {{
                              company?.company?.ContactEmail && company?.company?.ContactEmail !== "null" && company?.company?.ContactEmail != "0" 
                                ? company?.company?.ContactEmail
                                : "---"
                            }}
                          </span>
                        </td>
                        <td *ngIf="listNames?.includes('N°Tel')">
                          <span>
                            {{
                              company?.company?.ContactPhone && company?.company?.ContactPhone !== "null" && company?.company?.ContactPhone != "0"
                                ? company?.company?.ContactPhone
                                : "---"
                            }}
                          </span>
                        </td>
                        <td class="td-fixed-3">
                          <button mat-button [matMenuTriggerFor]="menu">  <svg
                          width="19"
                          height="5"
                          viewBox="0 0 19 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91"
                          />
                        </svg></button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="profilCompanyNavigation(company.company?.id,company?.company?.user?.role_user_id[0])">
                            Voir Détails 
                          </button>
                          <button *ngIf="company.company?.employee.length" mat-menu-item (click)="contactResponsableNavigation(company.company?.id)">
                            Contacter le responsable
                          </button>
                          <button mat-menu-item (click)="reviewCompanyNavigation(company.company?.id,'detail_company')">
                            Evaluer
                          </button>
                          <button  *ngIf="!company.blocked" mat-menu-item (click)="bloquer(company.company?.id)">
                            Bloquer
                          </button>
                          <button *ngIf="company.blocked" mat-menu-item (click)="debloquer(company.company?.id)">
                            Débloquer
                          </button>
                          
                        </mat-menu>
                        </td>
                        <td class="td-fixed-4"></td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
                <!--end::Table-->
                <div
                class="alert alert-warning text-center mt-5"
                role="alert"
                *ngIf="listCompany && !listCompany?.length"
              >
                <img
                  src="/assets/media/logos/logo-no-data.png"
                  alt=""
                  class="mb-5"
                />
                <span class="empty-list"> La liste est vide </span>
              </div>
              </div>

            </div>
          </div>

          <div class="pagination" *ngIf="listCompany && listCompany?.length">
            <app-pagination
              [data]="listCompany"
              [lengthData]="totalItems"
              [currentPageList]="currentPage"
              [endexPageList]="endIndex"
              (setPages)="getItems($event)"
            >
            </app-pagination>
          </div>

         

          <!--end::Tab pane-->

          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
  </div>
  <div
    id="kt_app_content_container"
    class="app-container container-fluid for-mobile"
  >
  <div class="tab-content">
    <!--begin::Tab pane-->
    <div id="kt_project_targets_card_pane" class="tab-pane fade  active show">
  <div class="card">
    <!--begin::Card header-->
    <div class="d-flex flex-wrap my-1 header-grid align-items-center">
      <div class="section_title">
        <h1 class="section-heading my-0">Liste des entreprises</h1>
      </div>
      <!--begin::Tab nav-->
  
      <!--end::Tab nav-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body pt-0">
      <div class="tab-content">
        <!--begin::Tab pane-->
        <div id="kt_project_targets_card_pane" class="tab-pane fade active show">
          <!--begin::Row-->
          <div class="row g-6 g-xl-9 justify-content-center">
            <!--begin::Col-->
            <div class="col-md-6 col-xxl-4" *ngFor="let company of listCompany">
              <!--begin::Card-->
              <div class="card pt-5 p-5">
                <div class="tools">
                  <div class="poste">
                    <span
                      *ngIf="company?.company?.type !== '1'"
                      [ngClass]="
                        company?.company?.type == '2'
                          ? 'esn-type'
                          : company?.company?.type == '3'
                          ? 'client-type'
                          : 'centre-type'
                      "
                      class="type-esn text-uppercase wrapping fw-bold"
                    >
                      {{
                        company?.company?.type == "1"
                          ? "Entreprise"
                          : company?.company?.type == "2"
                          ? "ESN"
                          : company?.company?.type == "3"
                          ? "Client Final"
                          : "Cabinet de Recrutement"
                      }}</span
                    >
                    <span *ngIf="company?.company?.type == '1'">---</span>
                  </div>
                  <div class="actions">
                    <div class="tools-links">
                      <div class="dropdown">
                        <button
                          class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <svg
                            width="19"
                            height="5"
                            viewBox="0 0 19 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="2.44739"
                              cy="2.44739"
                              r="2.44739"
                              transform="matrix(1 0 0 -1 0 4.89478)"
                              fill="#265D91"
                            />
                            <circle
                              cx="2.44739"
                              cy="2.44739"
                              r="2.44739"
                              transform="matrix(1 0 0 -1 6.85254 4.89478)"
                              fill="#265D91"
                            />
                            <circle
                              cx="2.44739"
                              cy="2.44739"
                              r="2.44739"
                              transform="matrix(1 0 0 -1 13.7061 4.89478)"
                              fill="#265D91"
                            />
                          </svg>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            class="dropdown-item btn-details"
                            (click)="
                              profilCompanyNavigation(
                                company.company.id,
                                company?.company?.user?.role_user_id[0]
                              )
                            "
                            >Voir Détails
                          </a>
                          <a
                            *ngIf="company.company?.employee?.length"
                            class="dropdown-item btn-details"
                            (click)="
                              contactResponsableNavigation(company.company.id)
                            "
                            >Contacter le responsable
                          </a>
                          <a
                            class="dropdown-item btn-details"
                            (click)="
                              reviewCompanyNavigation(
                                company.company.id,
                                'detail_company'
                              )
                            "
                            >Evaluer
                          </a>
                          <a
                            *ngIf="!company.blocked"
                            class="dropdown-item btn-details"
                            (click)="bloquer(company.company.id)"
                            >Bloquer
                          </a>
                          <a
                            *ngIf="company.blocked"
                            class="dropdown-item btn-details"
                            (click)="debloquer(company.company.id)"
                            >Débloquer
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--begin::Card body-->
                <div class="card-body d-flex flex-center flex-column">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-65px symbol-circle mb-5">
                    <img
                      *ngIf="company.company?.logo"
                      class="table-img logo-img"
                      src="{{
                        url +
                          '/Company/file/gallery_company/' +
                          company.company?.logo
                      }}"
                      alt=""
                    />
                    <img
                      *ngIf="!company.company?.logo"
                      class="table-img logo-img"
                      src="/assets/media/img/modules/avatar.jpg"
                      alt=""
                    />
  
                    <div
                      class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-45 ms-n3 mt-n3"
                    ></div>
                  </div>
                  <!--end::Avatar-->
                  <!--begin::Name-->
                  <a class="esn-title">
                    {{ company.company?.name ? company.company?.name : "---" }}
                  </a>
                  <!--end::Name-->
  
                  <!--begin::Info-->
                  <!-- <div class="d-flex flex-center flex-wrap">
                                    <div class=" rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                        <div class="fs-6 fw-bold text-gray-700">
                                            {{!company?.user?.created_at?'---':company?.user?.created_at| date:
                                            'yyyy' }}</div>
                                        <div class="details">Année création</div>
                                    </div>
                                    <div class=" rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                        <div class="fs-6 fw-bold text-gray-700">{{
                                            company.company.employee.length > 0 ?
                                            company.company.employee.length : '---' }}</div>
                                        <div class="details">Nombre salarié</div>
                                    </div>
                                    <div class=" rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                        <div class="fs-6 fw-bold text-gray-700">
                                            {{company?.note?company?.note:'---'}} {{company?.note?'%':''}}</div>
                                        <div class="details">Note</div>
                                    </div>
                                </div> -->
  
                  <!--end::Info-->
                </div>
                <div class="foot p-5">
                  <div class="ratings">
                    <rating
                      [(ngModel)]="company.average_note"
                      (ngModelChange)="onRatingChange($event)"
                      [max]="5"
                      [readonly]="true"
                    >
                    </rating>
                  </div>
                </div>
                <div class="sep"></div>
                <div class="foot-card">
                  <div class="siteweb">
                    <a [href]="company.company?.website_link" target="_blank">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="25" height="25" rx="3" fill="#265D91" />
                        <path
                          d="M12.0833 20.0001C7.47222 20.0001 3.75 16.6501 3.75 12.5001C3.75 8.35012 7.47222 5.00012 12.0833 5.00012C16.6944 5.00012 20.4167 8.35012 20.4167 12.5001C20.4167 16.6501 16.6944 20.0001 12.0833 20.0001ZM12.0833 6.00012C8.08333 6.00012 4.86111 8.90012 4.86111 12.5001C4.86111 16.1001 8.08333 19.0001 12.0833 19.0001C16.0833 19.0001 19.3056 16.1001 19.3056 12.5001C19.3056 8.90012 16.0833 6.00012 12.0833 6.00012Z"
                          fill="white"
                        />
                        <path
                          d="M16.4145 14.3501C16.5256 13.7501 16.5256 13.1501 16.5256 12.5001C16.5256 11.8501 16.4701 11.2501 16.4145 10.6501C16.3034 9.85012 16.0812 9.10012 15.8589 8.40012C15.0256 6.35012 13.6923 5.00012 12.0812 5.00012C10.1923 5.00012 8.63672 6.85012 7.97005 9.55012C7.9145 9.80012 7.85894 10.0001 7.80339 10.2501C7.69227 10.9501 7.63672 11.7001 7.63672 12.5001C7.63672 13.1501 7.69227 13.7501 7.74783 14.3501C7.85894 15.1501 8.08116 15.9001 8.30339 16.6001C9.08116 18.6501 10.4701 20.0001 12.0256 20.0001C13.5812 20.0001 14.9701 18.6501 15.7478 16.6001C15.3589 16.7001 14.9145 16.7501 14.4701 16.8501C13.9145 18.2001 12.9701 19.0001 12.0812 19.0001C11.1923 19.0001 10.2478 18.2001 9.63672 16.8501C9.35894 16.2501 9.13672 15.5001 8.97005 14.7001C8.80339 14.0001 8.74783 13.3001 8.74783 12.5001C8.74783 11.7001 8.80339 10.9501 8.97005 10.3001C9.02561 10.0501 9.08116 9.85012 9.13672 9.60012V9.55012C9.19227 9.30012 9.24783 9.10012 9.35894 8.90012C9.4145 8.75012 9.47005 8.60012 9.52561 8.50012C9.52561 8.50012 9.52561 8.50012 9.52561 8.45012L9.63672 8.25012C9.63672 8.25012 9.63672 8.20012 9.69227 8.15012C10.2478 6.80012 11.1923 6.00012 12.0812 6.00012C12.9701 6.00012 13.9145 6.80012 14.5256 8.15012C14.8034 8.75012 15.0256 9.50012 15.1923 10.3001C15.3589 11.0001 15.4145 11.7001 15.4145 12.5001C15.4145 13.3001 15.3589 14.0001 15.2478 14.7001C15.6367 14.6001 16.0256 14.5001 16.4145 14.3501Z"
                          fill="white"
                        />
                        <path
                          d="M16.6389 9.15049C15.9167 8.90049 15.0278 8.75049 14.1389 8.65049C13.4722 8.55049 12.8056 8.50049 12.0833 8.50049C11.3611 8.50049 10.6944 8.55049 10.0278 8.60049C9.91667 8.90049 9.75 9.25049 9.63889 9.65049C10.4167 9.55049 11.1944 9.50049 12.0833 9.50049C12.9722 9.50049 13.75 9.55049 14.5278 9.65049C15.4167 9.80049 16.25 10.0005 16.9167 10.2505C18.4167 10.8505 19.3056 11.6505 19.3056 12.4505C19.3056 13.2505 18.4167 14.1005 16.9167 14.6505C16.25 14.9005 15.4167 15.1005 14.5278 15.2505C13.75 15.4505 12.9722 15.5005 12.0833 15.5005C11.1944 15.5005 10.4167 15.4505 9.63889 15.3005C8.75 15.2005 7.91667 15.0005 7.25 14.7005C5.75 14.1505 4.86111 13.3005 4.86111 12.5005C4.86111 11.7005 5.75 10.8505 7.25 10.3005C7.30556 9.90049 7.41667 9.50049 7.52778 9.15049C5.25 9.85049 3.75 11.0505 3.75 12.5005C3.75 13.9505 5.25 15.1505 7.52778 15.8505C8.25 16.1005 9.13889 16.2505 10.0278 16.3505C10.6944 16.4505 11.3611 16.4505 12.0833 16.4505C12.8056 16.4505 13.4722 16.4005 14.1389 16.3505C15.0278 16.2505 15.9167 16.0505 16.6389 15.8505C18.9167 15.1505 20.4167 13.9005 20.4167 12.5005C20.4167 11.1005 18.9167 9.85049 16.6389 9.15049Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                  <div class="voirplus mt-3">
                    <a
                      class="btn-details"
                      (click)="
                        profilCompanyNavigation(
                          company.company.id,
                          company?.company?.user?.role_user_id[0]
                        )
                      "
                      >Voir plus</a
                    >
                  </div>
                </div>
                <!--end::Card body-->
              </div>
              <!--end::Card-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Row-->
        </div>
        <!--end::Tab pane-->
        <!--begin::Tab pane-->
  
        <div class="pagination" *ngIf="listCompany && listCompany?.length">
          <app-pagination
            [data]="listCompany"
            [lengthData]="totalItems"
            [currentPageList]="currentPage"
            (setPages)="getItems($event)"
          >
          </app-pagination>
        </div>
  
        <div
          class="alert alert-warning text-center mt-5"
          role="alert"
          *ngIf="listCompany && !listCompany?.length"
        >
          <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5" />
          <span class="empty-list"> La liste est vide </span>
        </div>
  
        <!--end::Tab pane-->
      </div>
  
      <!--end::Table-->
    </div>
    <!--end::Card body-->
  </div>
      </div>

      </div>

</div>
  <!--end::Content container-->
</div>


<div class="modal fade" id="details" tabindex="-1" aria-hidden="true">
  <!--begin::Modal dialog-->
  <div class="modal-dialog modal-dialog-centered mw-650px">
    <!--begin::Modal content-->
    <div class="modal-content pt-5 pb-15">
      <!--begin::Modal header-->
      <div class="modal-header">
        <!--begin::Modal title-->
        <h2 class="fw-bold">Compte rendu</h2>
        <!--end::Modal title-->
        <!--begin::Close-->
        <div
          id="kt_customers_export_close"
          class="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i class="ki-duotone ki-cross fs-1" data-bs-dismiss="modal">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
        <!--end::Close-->
      </div>

      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body mx-5 mx-xl-15 my-7">
        <h4 class="mb-5">Votre compte rendu</h4>
        <textarea
          name="compte"
          id="rendu"
          style="height: 150px"
          class="form-control compte-rendu"
        ></textarea>
      </div>
      <div class="card-footer mx-5 mx-xl-15 my-7">
        <div class="actions">
          <a data-bs-dismiss="modal" class="annuler">Annuler</a>
          <a class="confirmer">Confirmer</a>
        </div>
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal content-->
  </div>
  <!--end::Modal dialog-->
</div>
