import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CandidateService } from '../../../../main/dossier/services/candidate.service';
import {
  maxCompetence,
  minCompetence,
  serverError,
  successCreateTest,
  successTestRequest,
} from '../../../../shared/shared-models/message';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { TestService } from '../../services/test.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-qcm',
  templateUrl: './qcm.component.html',
  styleUrls: ['./qcm.component.css'],
})
export class QcmComponent {
  competences = [
    { id: 1, name: 'Python' },
    { id: 2, name: 'laravel' },
    { id: 3, name: 'angular' },
    { id: 4, name: 'javascript' },
    { id: 5, name: 'java' },
  ];
  colors = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];
  listCategories: any;
  categories: any;
  selectedSubcategories: any[] = [];

  loadSpinner: boolean = false;
  loadSpinnerTable: boolean = false;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  /* pagination */
  totalItems: number = 10;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  selectedSubcategoryIds: any[] = [];
  SubcategoryIdsCandidate: any[] = [];
  skillsCandidate: any[] = [];
  skillsCandidates: any[] = [];

  currentUser: any;
  id: any;
  skillsList: any[] = [];
  score: any;
  show_score: boolean = false;
  stateTest: any;
  is_test_in_progress: any;
  roundedPercentageScore: any;
  complet_test:any
  state_passed_test:any
  requestTest: boolean = false;
  Candidate_info: any;

  show_request_btn: boolean = false;
  show_request_sent: boolean = false;
  pagination : boolean =false;

  request_response_test: any;
  number_test: any;

  constructor(
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private candidateService: CandidateService,
    private testService: TestService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadSpinnerTable = true;
    this.getUserInfo();
    localStorage.removeItem('remainingTime');
    localStorage.removeItem('currentQuestionIndex');
  }
  getTestStatus(): string {
    return this.complet_test === 0 ? 'Test non terminé' : 'Test terminé';
  }
  getUserInfo() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    this.getProfileCandidate(this.id);
    this.spinner.show();
    this.loadSpinner = true;
  }

  showContactWegestUForNewTest() {
    Swal.fire({
      title: 'Demande nouveau test',
      text: "Contacter l'équipe de support de Wegestu pour planifier un nouveau test",
      icon: 'info',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/wegestu/contact/wegestu');
      }
    });
  }

  /* list of id skills  candidate*/
  getListIdsSubcategoriesCandidate() {
    this.skillsList.forEach((category: any) => {
      if (category.sub_category) {
        category.sub_category.forEach((subCategory: any) => {
          this.SubcategoryIdsCandidate.push(subCategory.id);
        });
      }
    });
  }

  /* select skills*/
  onCheckboxChange(category: any, subcategory: any, event: any) {
    console.log('onCheckboxChange');
    const isChecked = event.target.checked;
    subcategory.selected = isChecked;
    if (this.selectedSubcategoryIds.includes(subcategory.id)) {
      console.log('ID exists in the array.');
      this.selectedSubcategoryIds = this.selectedSubcategoryIds.filter(
        (id: any) => id !== subcategory.id
      );
      ///  this.selectedSubcategories = this.selectedSubcategories.filter((name_FR:any) => name_FR !== subcategory.name_FR);
    } else {
      ///  this.selectedSubcategories.push(subcategory.name_FR);

      this.selectedSubcategoryIds.push(subcategory.id);

      console.log('ID does not exist in the array.');
    }
    this.skillsCandidate = this.skillsCandidate.filter((item) =>
      item.sub_category
        .filter((item: any) => item.id === subcategory.id)
        .map((item: any) => {
          return {
            archived: item.archived,
            category_id: item.category_id,
            created_at: item.created_at,
            icon: item.icon,
            id: item.id,
            name_EN: item.name_EN,
            name_FR: item.name_FR,
            nb_question: item.nb_question,
            question: item.question,
            skills: item.skills,
            selected: true,
          };
        })
    );

    this.updateSelectedSubcategories();
  }

  /* update subcategorie*/
  updateSelectedSubcategories() {
    const selectedSubcategoryNames: string[] = [];
    // Iterate categories: to find selected subcategories
    this.skillsCandidate.forEach((category: any) => {
      category.sub_category.forEach((subcategory: any) => {
        if (this.selectedSubcategoryIds.includes(subcategory.id)) {
          selectedSubcategoryNames.push(subcategory.name_FR);
          // Check if the subcategory id already exists in the array before pushing it.
          if (!this.selectedSubcategoryIds.includes(subcategory.id)) {
            this.selectedSubcategoryIds.push(subcategory.id);
          }
        } else {
          // If the subcategory is deselected, remove its id from the array if it exists.
          const index = this.selectedSubcategoryIds.indexOf(subcategory.id);
          if (index !== -1) {
            this.selectedSubcategoryIds.splice(index, 1);
          }
        }
      });
    });

    this.selectedSubcategories = selectedSubcategoryNames;
    this.competences = this.selectedSubcategories.map(
      (name: any, index: any) => ({ id: index, name })
    );
    console.log(
      '%cqcm.component.ts line:172 selectedSubcategories',
      'color: #007acc;',
      this.selectedSubcategories
    );
  }

  showGlobalSpinner() {
    this.loadSpinner = true;
    this.spinner.show();
  }

  hideGlobalSpinner() {
    this.loadSpinner = false;
    this.spinner.hide();
  }

  /* get pagination items */
  
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
       if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getSkillsCandidate();
        }
    }
  }

  getSubCategoryNamesByIds(ids: number[], data: any[]) {
    const subCategoryNames: string[] = [];
    if (Array.isArray(data)) {
      for (let category of data) {
        for (let subCategory of category.sub_category) {
          if (ids.includes(subCategory.id)) {
            subCategoryNames.push(subCategory.name_FR);
          }
        }
      }
    }
    this.selectedSubcategories = subCategoryNames;
  }

  /* get Ids From Names subcategorie */
  getIdsFromNames(names: any[], data: any[]): any[] {
    const itemIds: number[] = [];
    console.log('%cqcm.component.ts line:184 data+++', 'color: #007acc;', data);
    for (const category of data) {
      for (const subCategory of category.sub_category) {
        if (names.includes(subCategory.name_FR)) {
          itemIds.push(subCategory.id);
        }
      }
    }
    return itemIds;
  }

  onSelectedSubcategoriesChange() {
    this.selectedSubcategoryIds = this.getIdsFromNames(
      this.selectedSubcategories,
      this.categories
    );

    // this.categories?.forEach((category: any) => {
    //   category.sub_category.forEach((subcategory: any) => {

    //     if (this.selectedSubcategoryIds.includes(subcategory.id)) {
    //       console.log('%cqcm.component.ts line:232 existt ', 'color: #007acc;', );
    //       subcategory.selected = true;
    //     } else {
    //       console.log('%cqcm.component.ts line:232 NOOOOO ', 'color: #007acc;', );

    //       subcategory.selected = false;
    //     }
    //   });
    // });
  }

  verifyTestInProgree() {
    Swal.fire({
      title: 'Test en cours',
      text: 'Le test est déjà en cours ou ouvert dans un autre contexte.',
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }
  /* selected modules */
  sendSelectedModules() {
    if (this.is_test_in_progress == 1) {
      return this.verifyTestInProgree();
    }
    console.log('selectedSubcategoryIds', this.selectedSubcategoryIds);
    // Check if sub_category_ids array is empty
    if (this.selectedSubcategoryIds.length === 0) {
      this.toastrService.error(minCompetence);
      return;
    } else if (this.selectedSubcategoryIds.length > 5) {
      // If more than 5 items are selected show error message
      this.toastrService.error(maxCompetence);
      return;
    } else {
      this.createTest(this.selectedSubcategoryIds);
    }
  }
  /* create test*/
  createTest(data: number[]) {
    this.testService
      .CreateTest({ sub_category_ids: data })
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.testService.setTestResponse(res);
            localStorage.setItem('testResponse', JSON.stringify(res));
            this.toastrService.success(successCreateTest);
            const queryParams = { skills_ids: data };
            const urlTree = this.router.createUrlTree(['/test/welcome-test'], { queryParams });
            const url = this.router.serializeUrl(urlTree);
            window.open(url, '_blank');      
            window.location.reload()
          }
        },
        error: (err: any) => {
          console.log('error', err);
          if (err?.error) {
            if (err.error?.message) {
              if (
                err.error.message ===
                'The number of questions for the chosen skill was insufficient to complete the test.'
              ) {
                this.toastrService.error(
                  'Le nombre de questions pour la compétence choisie était insuffisant pour passer le test.'
                );
              } 
              if (err.error.message === 'Issues with the following subcategories:') {
                let subcategories = err.error.answeredAll.join(', ');
                this.toastrService.error(
                  `Vous avez déjà répondu à toutes les questions dans les sous-catégories : ${subcategories}.`
                );
              }
              else this.toastrService.error(serverError);
            }
          }
        },
      });
  }
  fixRequestNewTest() {
    console.log("testttt")
    if (this.number_test > 4) {
      this.showContactWegestUForNewTest();
    } else {
      this.requestNewTest();
    }
  }
  /* request new test*/
  requestNewTest() {
    console.log("testttt2");
  
    // Déclenchement de setTimeout avant l'envoi de la requête au backend
    setTimeout(() => {
      this.loadSpinner = true;
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        this.loadSpinner = false;
        this.toastrService.success(successTestRequest);
        this.request_response_test = '1';
      }, 3000); // Délai de 3 secondes avant l'affichage du message de succès
      // Envoi de la requête au backend
      this.testService
        .requestNewTest(this.id)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            console.log("res", res);
             // Affichage du message de succès après un délai
            if (res.status === 200) {
              this.getProfileCandidate(this.id);
              this.show_request_btn = false;
              this.show_request_sent = true;
            }
          },
          error: (err: any) => {
            this.spinner.hide();
            this.loadSpinner = false;
            console.log('error', err);
            this.toastrService.error(serverError);
          }
        });
    }, 0); // Démarrage de setTimeout immédiatement
  }
  /*get profile candidat by id */
  getProfileCandidate(id: any) {
    this.spinner.show();
    this.loadSpinner = true;

    this.candidateService
      .getCandidate(id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log(
            '%cqcm.component.ts line:307 Candidate_info',
            'color: #007acc;',
            res.data
          );
          this.Candidate_info = res.data[0];
          this.stateTest = this.Candidate_info.stateTest;
          this.is_test_in_progress = this.Candidate_info.is_test_in_progress;
          this.number_test = this.Candidate_info.number_test;

          this.request_response_test =
            this.Candidate_info.request_response_test;
          console.log("request_response_testtttt",this.Candidate_info.request_response_test)
          this.roundedPercentageScore = this.Candidate_info.score;
          this.complet_test = this.Candidate_info.complet_test;
          this.state_passed_test = this.Candidate_info.state_passed_test


          if (this.stateTest === '1') {
            this.score = JSON.parse(this.currentUser).score;

            this.show_score = true;

            // const percentage = this.score
            // this.roundedPercentageScore = percentage.toFixed(2)

            /* format value score*/
            this.roundedPercentageScore = this.Candidate_info.score;

            //  const hasDecimalValues = originalValue % 1 !== 0;

            //  this.roundedPercentageScore= hasDecimalValues ? originalValue.toFixed(2) : originalValue.toString();

            console.log(this.roundedPercentageScore);
            this.show_request_btn = true;
            this.show_request_sent = false;
          } else {
            console.log(
              '%cqcm.component.ts line:82 test not passed yet ',
              'color: #007acc;'
            );
          }
         this.getSkillsCandidate();
        },
        error: (err) => {
          console.log('error', err);
        },
      });
  }
  /* skills by id candidate*/
  getSkillsCandidate() {
    // if (this.stateTest == '1') {
    //   this.candidateService
    //   .getSkillsbyIdCandidateAfterTest(this.id)
    //   .pipe()
    //   .subscribe({
    //     next: (res) => {
    //       console.log('is stateTest ===> 1 ');

    //       this.skillsCandidate =
    //         res.data.categoriesWithSubCategoriesAndSkills.slice(
    //           this.startIndex,
    //           this.endIndex
    //         );
    //         console.log(' after skillsCandidate : ', this.skillsCandidate);
    //       this.totalItems = res.data.count;
    //       this.skillsList = res.data.categoriesWithSubCategoriesAndSkills;
    //       this.spinner.hide();
    //       this.loadSpinner = false;
    //       this.loadSpinnerTable = false

    //       },
    //       error: (err) => {
    //         console.log('error', err);

    //         this.spinner.hide();
    //         this.loadSpinner = false;
    //         this.loadSpinnerTable = false

    //       },
    //     });
    // } else
    this.candidateService
    .getSkillsbyIdCandidate(this.id)
    .pipe()
    .subscribe({
      next: (res) => {
        console.log('is stateTest ===> 0 ');
        this.skillsCandidate =
          res.data.categoriesWithSubCategoriesAndSkills.slice(
            this.startIndex,
            this.endIndex
          );

            console.log(' after skillsCandidate : ', this.skillsCandidate);
            this.totalItems = res.data.count;
            this.skillsList = res.data.categoriesWithSubCategoriesAndSkills;
            this.spinner.hide();
            this.loadSpinner = false;
          },
          error: (err) => {
            console.log('error', err);

            this.spinner.hide();
            this.loadSpinner = false;
            this.loadSpinnerTable = false;
          },
        });
  }
  
  startTestQcm() {
    this.testService.startTest().subscribe({
      next: (response: any) => {
        console.log('Réponse du serveur:', response);
      },
      error: (error: any) => {
        console.error('Erreur lors de la complétion du test:', error);
      }
    });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
