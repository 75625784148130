<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <ngx-spinner class="custom-spinner"
    bdColor="rgba(255,255,255,1)"
      size="medium"
      color="#fff"
      type="ball-atom"
      [fullScreen]="true"
    >
    <span class="loader">
      <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
    </span>
    </ngx-spinner>

    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="36" height="38" viewBox="0 0 36 38" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_373_934)">
                            <path
                                d="M4.60489 6.04201H8.77521C9.1081 6.04201 9.38048 5.76959 9.38048 5.43673V1.26638C9.38048 1.02433 9.23522 0.800305 9.00522 0.709573C8.78127 0.612708 8.521 0.667162 8.34549 0.836657L4.18119 5.00701C4.00564 5.18249 3.95118 5.44279 4.04801 5.66674C4.13882 5.89675 4.36276 6.04201 4.60489 6.04201Z"
                                fill="#265D91" />
                            <path
                                d="M12.2618 21.9129V18.9894C12.2618 17.9881 13.0763 17.1736 14.0776 17.1736H14.8236L14.2934 16.6434C13.9505 16.3017 13.7614 15.846 13.7614 15.3607C13.7614 14.8754 13.9505 14.4197 14.2946 14.0769L16.3634 12.008C16.6719 11.68 17.1472 11.4778 17.6431 11.4778C18.1301 11.4778 18.587 11.6664 18.9287 12.008L19.4589 12.5383V11.7923C19.4589 10.791 20.2734 9.97647 21.2747 9.97647H23.3385V1.26677C23.3385 0.933843 23.0722 0.661499 22.7332 0.661499H10.486C10.5522 0.851977 10.5914 1.05502 10.5914 1.26677V5.43691C10.5914 6.43821 9.77686 7.25273 8.77556 7.25273H4.60542C4.39378 7.25273 4.19081 7.21343 4 7.14648V25.6473C4 25.9802 4.27234 26.2526 4.60528 26.2526H13.9076C13.8127 26.0304 13.7614 25.7902 13.7614 25.5416C13.7614 25.0563 13.9505 24.6006 14.2946 24.2578L14.823 23.7287H14.0776C13.0763 23.7287 12.2618 22.9142 12.2618 21.9129ZM10.6599 21.5943H7.63357C7.29902 21.5943 7.0283 21.3236 7.0283 20.989C7.0283 20.6545 7.29902 20.3838 7.63357 20.3838H10.6599C10.9945 20.3838 11.2652 20.6545 11.2652 20.989C11.2652 21.3236 10.9945 21.5943 10.6599 21.5943ZM10.6599 17.9627H7.63357C7.29902 17.9627 7.0283 17.692 7.0283 17.3574C7.0283 17.0228 7.29902 16.7521 7.63357 16.7521H10.6599C10.9945 16.7521 11.2652 17.0228 11.2652 17.3574C11.2652 17.692 10.9945 17.9627 10.6599 17.9627ZM10.6599 14.331H7.63357C7.29902 14.331 7.0283 14.0603 7.0283 13.7257C7.0283 13.3912 7.29902 13.1205 7.63357 13.1205H10.6599C10.9945 13.1205 11.2652 13.3912 11.2652 13.7257C11.2652 14.0603 10.9945 14.331 10.6599 14.331ZM10.6599 10.6994H7.63357C7.29902 10.6994 7.0283 10.4287 7.0283 10.0941C7.0283 9.75954 7.29902 9.48882 7.63357 9.48882H10.6599C10.9945 9.48882 11.2652 9.75954 11.2652 10.0941C11.2652 10.4287 10.9945 10.6994 10.6599 10.6994Z"
                                fill="#265D91" />
                            <path
                                d="M31.3948 18.384H29.0706C28.9617 18.0572 28.8285 17.7364 28.6772 17.4337L30.3175 15.7874C30.5596 15.5514 30.5596 15.17 30.3175 14.934L28.2535 12.8639C28.0174 12.6279 27.6361 12.6279 27.4 12.8639L25.7537 14.5103C25.445 14.3529 25.1303 14.2258 24.8034 14.1168V11.7926C24.8034 11.4537 24.531 11.1873 24.1982 11.1873H21.2747C20.9418 11.1873 20.6694 11.4537 20.6694 11.7926V14.1168C20.3426 14.2258 20.0278 14.3529 19.7191 14.5103L18.0728 12.8639C17.9578 12.7489 17.8064 12.6884 17.643 12.6884C17.4856 12.6884 17.3283 12.7489 17.2193 12.8639L15.1493 14.934C14.9132 15.17 14.9132 15.5514 15.1493 15.7874L16.7956 17.4337C16.6382 17.7364 16.5111 18.0572 16.4022 18.384H14.0779C13.739 18.384 13.4727 18.6564 13.4727 18.9893V21.9128C13.4727 22.2457 13.739 22.5181 14.0779 22.5181H16.4022C16.5111 22.8449 16.6382 23.1597 16.7956 23.4684L15.1493 25.1147C14.9132 25.3508 14.9132 25.7321 15.1493 25.9682L17.2193 28.0321C17.4554 28.2742 17.8367 28.2742 18.0728 28.0321L19.7191 26.3918C20.0278 26.5431 20.3426 26.6763 20.6694 26.7853V29.1095C20.6694 29.4424 20.9418 29.7148 21.2747 29.7148H24.1982C24.531 29.7148 24.8034 29.4424 24.8034 29.1095V26.7853C25.1303 26.6763 25.445 26.5431 25.7537 26.3918L27.4 28.0382C27.509 28.1471 27.6664 28.2137 27.8237 28.2137C27.9871 28.2137 28.1385 28.1471 28.2535 28.0382L30.3175 25.9682C30.5596 25.7321 30.5596 25.3508 30.3175 25.1147L28.6772 23.4684C28.8285 23.1597 28.9617 22.8449 29.0706 22.5181H31.3948C31.7278 22.5181 32.0001 22.2457 32.0001 21.9128V18.9893C32.0001 18.6564 31.7278 18.384 31.3948 18.384ZM22.7334 24.3097C20.6088 24.3097 18.8778 22.5786 18.8778 20.448C18.8778 18.3235 20.6088 16.5925 22.7334 16.5925C24.8639 16.5925 26.595 18.3235 26.595 20.448C26.595 22.5786 24.8639 24.3097 22.7334 24.3097Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_373_934" x="0" y="0.661499" width="36" height="37.0533"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_373_934" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_373_934"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                  
                    CV {{selectedCandidate?.first_name}} {{selectedCandidate?.last_name}}
                </h1>

                <!--begin::Menu-->
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a class="text-muted text-hover-primary" [routerLink]="['/wegestu/profil-candidat']">Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted"><a class="text-muted" routerLink="/wegestu/profil-candidat/modifier">Dossier de Compétences</a> </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Cv candidat</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

    <div class="actions-profil">
        <a (click)="navigateToProfilCandidat()" class="modifier" ><i class="fa fa-refresh updateIcon" aria-hidden="true"></i>
            Modifier</a>
        <a (click)="navigateToCVCandidat()" class="annuler">
                <i class="fa-solid fa-xmark iconeXmark" style="color: #fc0909;"></i>
              Annuler</a>

    </div>
    <div id="kt_app_content" class="app-content flex-column-fluid pt-10">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container container-fluid">
            <!--begin::Layout-->
            <body>

                <!--begin::Layout-->
                <div  class="generate-pdf d-flex flex-column gap-4">
                    <div class="row">
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo">
                    </div>
                    <section class="main-section pt-6 ralative">
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-left" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-left">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <div class="img-user d-flex justify-content-center">
                                    <img *ngIf="selectedCandidate?.image_url&&selectedCandidate?.image_url!='avatar.png'&&selectedCandidate?.image_url!='undefined'"
                                    src="{{ url+'/api/User/file/gallery_users/' + selectedCandidate?.image_url }} "
                                    alt="image user" class="user-img">
                                <img *ngIf="!selectedCandidate?.image_url||selectedCandidate?.image_url&&(selectedCandidate?.image_url=='avatar.png'||selectedCandidate?.image_url=='undefined')"
                                    class="table-img" src="/assets/media/logos/logo-avatar.jpg" alt="image user" class="user-img">
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="details-user">
                                    <h1 *ngIf="!visual">{{selectedCandidate?.first_name}} {{selectedCandidate?.last_name}}</h1>
                                    <h1 *ngIf="visual">{{ firstName }} {{lastName}}</h1>
                                    
                                    <h3 class="mb-4" *ngIf="!visual && selectedCandidate?.desired_workstation?.length">{{selectedCandidate?.desired_workstation  && selectedCandidate?.desired_workstation!='null' ?  selectedCandidate.desired_workstation:'---'}}</h3>
                                    <h3 class="mb-4" *ngIf="visual && desiredWorkstation?.length">{{desiredWorkstation}}</h3>
                                    <div class="mb-4 contact-info d-flex align-items-center gap-6 ">
                                        <div class="d-flex align-items-center gap-2" *ngIf="!visual && selectedCandidate?.email">
                                            <span class="img-icon">
                                                <img [src]="emailImage" alt="email" width="24" height="24">
                                            </span>
                                            <span>{{selectedCandidate?.email?selectedCandidate.email:'---'}}</span>
                                        </div>

                                        <div class="d-flex align-items-center gap-2" *ngIf="visual && email">
                                            <span class="img-icon">
                                                <img [src]="emailImage" alt="email" width="24" height="24">
                                            </span>
                                            <span>{{email}}</span>
                                        </div>
                                        <div class="d-flex align-items-center gap-2" *ngIf="!visual &&  selectedCandidate?.phone_number">
                                            <span class="img-icon">
                                                <img [src]="telephoneImage" alt="telephone" width="24" height="24">
                                            </span>
                                            <span>{{selectedCandidate?.phone_number  ? selectedCandidate?.phone_number:'---'}}</span>
                                        </div>

                                        <div class="d-flex align-items-center gap-2" *ngIf="visual && phone">
                                            <div>
                                                <span class="img-icon">
                                                    <img [src]="telephoneImage" alt="telephone" width="24" height="24">
                                                </span>
                                                <span>{{phone}}</span>
                                            </div>
                                            
                                        </div>  
                                    </div>
                                    
                                    <div class="mb-4 social-item d-flex align-items-center gap-2 pl-1" *ngIf="!visual && selectedCandidate?.linkedin_link?.length">
                                        <span class="img-icon">
                                            <img [src]="linkedinImage" alt="linkedin" width="24" height="24">
                                        </span>
                                        <span><a [href]="linkedinUrl" target="_blank">{{selectedCandidate?.linkedin_link?selectedCandidate.linkedin_link:'---'}}</a></span>
                                    </div>

                                    <div class="mb-4 social-item d-flex align-items-center gap-2 pl-1" *ngIf="visual && linkedinLink?.length">
                                        <span class="img-icon">
                                            <img [src]="linkedinImage" alt="linkedin" width="24" height="24">
                                        </span>
                                        <span><a [href]="linkedinUrl" target="_blank">{{linkedinLink}}</a></span>
                                    </div>
                                    
                                    <div class="mb-4 d-flex align-items-center gap-2" *ngIf="!visual && selectedCandidate?.mobility?.length">
                                        <span class="img-icon">
                                            <img [src]="locationImage" alt="location" width="24" height="24">
                                        </span>
                                        <ng-container *ngFor="let item of selectedCandidate?.mobility; let last = last">
                                            <span class="mobilite">{{ item }}</span>
                                            <span *ngIf="!last">, </span>
                                        </ng-container>
                                    </div>

                                    <div class="mb-4 d-flex align-items-center gap-2" *ngIf="visual && currentCountry !== 'null' && currentCountry!=='' ">
                                        <span class="img-icon">
                                            <img [src]="locationImage" alt="location" width="24" height="24">
                                        </span>
                                        <ng-container>
                                            <span class="mobilite">{{ currentCountry }}</span>
                                            <!--<span *ngIf="!last">, </span> --> 
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-right" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-right">
                    
                    </section>
                    <section *ngIf="selectedCandidate?.savoir_faire && savoirFaire">
                        <div class="d-flex align-items-center gap-3 pb-2 px-2" style="border-bottom: 3px double #265d91;">
                        <span><img [src]="aboutImage" alt="about" width="20" height="20"></span>
                        <span class="section-title">A propos</span>
                        </div>
            
                        <p class="p-5 m-0" *ngIf="!visual" style="line-height: 27px;">{{ removeHtmlTags(selectedCandidate?.savoir_faire) }}</p>
                        <p class="p-5 m-0" *ngIf="visual" style="line-height: 27px;">{{ removeHtmlTags(savoirFaire || '') }}</p>
                    </section>
                    <section class="skills-section relative">
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-left" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-left">
                        <div *ngIf="skillsCandidat?.length || selectedCandidate?.skills_text?.length" class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="skillsImage" alt="skills" width="23" height="23">
                            </span>
                            <span class="section-title">COMPÉTENCES</span>
                        </div>
                    
                        <div *ngIf="skillsCandidat?.length || selectedCandidate?.skills_text?.length" class="skills-content p-5 pb-2">
                            <div *ngIf="!visual">
                                <div class="row">
                                    <div *ngFor="let skill of skillsCandidat" [ngClass]="{'col-xxl-4 col-xl-6 col-sm-12 mb-3':skill.sub_category.length<3, 'col-12 col-sm-12 mb-3':skill.sub_category.length>3 }"
                                        [style.order]="skill.sub_category.length<3 ? 1 : 2">
                                            <div class="name-skills">{{skill?.name_FR}}</div>
                                            <!--<span> ({{ skill?.sub_category ? skill.sub_category.length : 0 }} sub-categories)</span>-->
                                            <div class="row">
                                                <div *ngFor="let subSkill of skill.sub_category" [ngClass]="{'col-xxl-4 col-xl-6 col-sm-12':skill.sub_category.length>2, 'col-lg-12':skill.sub_category.length<2}">
                                                        <div class="d-flex px-5 py-1 gap-7" >
                                                            <div class="sub-name-skills">
                                                                <span>{{subSkill?.name_FR}}</span>
                                                            </div>
                                                            <div class="rating">
                                                                <div class="stars">
                                                                    <rating [titles]="[' ',' ',' ',' ',' ']" [(ngModel)]="subSkill.skills[0].stars" [readonly]="true"
                                                                        class="rating-stars"></rating>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>  
                                    </div>    
                                </div>

                            </div>
                                <div *ngIf="visual">
                                    <div class="row">
                                        <div [ngClass]="{'col-xxl-4 col-xl-6 col-sm-12 mb-3':skill.sub_category.length<3, 'col-12 col-sm-12 mb-3':skill.sub_category.length>3 }" *ngFor="let skill of candidatSkills"  [style.order]="skill.sub_category.length<3 ? 1 : 2">
                                            <div class="name-skills">{{skill?.name_FR}}</div>
                                            <div class="row">
                                                <div *ngFor="let subSkill of skill.sub_category" [ngClass]="{'col-xxl-4 col-xl-6 col-sm-12':skill.sub_category.length>2, 'col-lg-12':skill.sub_category.length<2}">
                                                    <div class="d-flex px-5 py-1 gap-7">
                                                        <div class="sub-name-skills">
                                                            <span>{{subSkill?.name_FR}}</span>
                                                        </div>
                                                        <div *ngFor="let subS of subSkill.skills">
                                                            <div class="rating">
                                                                <div class="stars">
                                                                    <rating [titles]="[' ', ' ', ' ', ' ', ' ']" [(ngModel)]="subS.stars" [readonly]="true" class="rating-stars"></rating>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="other-skills font-bold" *ngIf="!visual && selectedCandidate?.skills_text?.length">
                                        <span>Autres Compétences : <span class="blue-color font-bold">{{selectedCandidate.skills_text}}</span> </span>
                                    </div>
                                <div  *ngIf="visual && addSkills.length>0" >
                                    <span class="other-skills font-bold">Autres Compétences :</span>
                                        <span  *ngFor="let item of addSkills" class="blue-color font-bold px-1">{{item?.name }}</span>
                                </div>
                            
                        </div>
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-center" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-center">
                    </section>

                    <section class="Professional-experiences relative" *ngIf="prof_experience.length>0 || selectedCandidate?.prof_experience?.length>0">
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-left" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-left">
                        <div  class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon img-icon-skills">
                                <img [src]="experienceImage" alt="about" width="23" height="23">
                            </span>
                            <span class="section-title">EXPÉRIENCES PROFESSIONNELLES</span>
                        </div>
                        <div *ngIf="!visual && selectedCandidate?.prof_experience?.length>0" class="experiences-content p-5 pb-2">
                            <div  *ngFor="let item of selectedCandidate?.prof_experience">
                                <div class="name-position">{{capitalizeFirstLetter(item?.poste)}}</div>
                                <table class="m-4 mb-0">  
                                    <tbody>
                                        <tr  *ngIf="item?.start_date">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Période</span></td>
                                            <td class="experience-details pb-3 text-left">
                                                <span>
                                                du {{item?.start_date | date:'MM/yyyy'}}
                                                    <span *ngIf="item.current_position =='0'"> au {{ item?.end_date | date : "MM/yyyy" }}</span>
                                                </span>
                                                <span *ngIf="item.current_position =='1'">
                                                Jusqu'à présent
                                                </span>
                                                <!-- <span> ({{item.period}})</span> -->
                                                <span>({{ formatPeriod(item.period) }})</span>

                                            </td>
                                        </tr>

                                        <tr *ngIf="item?.client">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Client</span></td>
                                            <td class="experience-details pb-3 text-left"><span >{{item?.client}}</span></td>
                                        </tr>

                                        <tr  *ngIf="item?.project">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Projet</span></td>
                                            <td class="experience-details pb-3 text-left"><span>{{item?.project ? item?.project:'---' }}</span></td>
                                        </tr>
                                        <tr *ngIf="item?.description">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Descriptif</span></td>
                                            <td class="experience-details pb-3 text-left" > <span [innerHTML]="item?.description"></span></td>
                                        </tr>
                                        <tr *ngIf="item?.method">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Méthode</span></td>
                                            <td class="experience-details pb-3 text-left"><span>{{item?.method}}</span></td>
                                        </tr>
                                        <tr *ngIf="item?.tasks">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Tâches</span></td>
                                            <td class="experience-details pb-3 text-left">
                                                <span [innerHTML]="item?.tasks"></span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="item?.technologies?.length">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Outils & technologies</span></td>
                                            <td class="experience-details pb-3 text-left">
                                                <span>{{item?.technologies[0]}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="visual && prof_experience?.length" class="experiences-content p-5 pb-2">
                            <div  *ngFor="let item of prof_experience">
                                <div class="name-position">{{item?.poste}}</div>
                                <table class="m-4 mb-0">
                                    
                                    <tbody>
                                        <tr  *ngIf="item?.start_date">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Période</span></td>
                                            <td class="experience-details pb-3 text-left">
                                                <span>
                                                du {{item?.start_date | date:'MM/yyyy'}}
                                                    <span *ngIf="item.current_position ==false"> au {{ item?.end_date | date : "MM/yyyy" }}</span>
                                                </span>
                                            <span *ngIf="item.current_position ==true">
                                                Jusqu'à présent
                                                </span>
                                                <span>({{ formatPeriod(item.period) }})</span>
                                            </td>
                                        </tr>

                                        <tr *ngIf="item?.client">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Client</span></td>
                                            <td class="experience-details pb-3 text-left"><span >{{item?.client}}</span></td>
                                        </tr>

                                        <tr  *ngIf="item?.project">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Projet</span></td>
                                            <td class="experience-details pb-3 text-left"><span>{{item?.project ? item?.project:'---' }}</span></td>
                                        </tr>
                                        <tr *ngIf="item?.description">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Descriptif</span></td>
                                            <td class="experience-details pb-3 text-left"><span>{{removeHtmlTags(item?.description)}}</span></td>
                                        </tr>
                                        <tr *ngIf="item?.method">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Méthode</span></td>
                                            <td class="experience-details pb-3 text-left"><span>{{item?.method}}</span></td>
                                        </tr>
                                    <tr *ngIf="item?.tasks">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Tâches</span></td>
                                            <td class="experience-details pb-3 text-left">
                                                <span [innerHTML]="item?.tasks"></span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="item?.technologies">
                                            <td class="experience-title pb-3 text-left"><span class="attribut">Outils & technologies</span></td>
                                            <td class="experience-details pb-3 text-left">
                                                <span>{{item?.technologies}}</span>
                                            </td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-right" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-right">
                    </section>

                    <section *ngIf="certificats.length>0 || selectedCandidate?.certificat?.length>0" class="certif-section relative">
                        <div *ngIf="certificats.length>0 || selectedCandidate?.certificat?.length>0" class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="certificateImage" alt="certification" width="23" height="23">
                            </span>
                            <span class="section-title">CERTIFICATIONS</span>
                        </div>
                    
                        <div *ngIf="!visual && selectedCandidate.certificat.length>0 || certificats.length>0" class="skills-content p-5 pb-2">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of selectedCandidate.certificat">
                                    <div class="d-flex gap-5">
                                        <span class="blue-color">{{item?.date | date:'yyyy'}}</span>
                                        <span>
                                            {{item?.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="visual && certificats.length>0" class="skills-content p-5 pb-2">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of certificats">
                                    <div class="d-flex gap-5">
                                        <span class="blue-color">{{item?.date | date:'yyyy'}}</span>
                                        <span>
                                            {{item?.name}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-center" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-center">
                    </section>
                    <section class="certif-section relative">
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-left" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-left">
                        <div *ngIf="!visual && selectedCandidate?.diplome.length>0" class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="graduationImage" alt="graduation" width="23" height="23">
                            </span>
                            <span class="section-title">DIPLÔMES</span>
                        </div>
                    
                        <div *ngIf="!visual" class="diplome-content p-5 pb-2">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of selectedCandidate?.diplome">
                                    <div class="d-flex gap-5">
                                        <span class="blue-color">{{item?.date | date:'yyyy'}}</span>
                                        <span>
                                            {{item?.name}}
                                        </span>
                                        <span>{{item?.ecole}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="visual && diplome.length>0" class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="graduationImage" alt="graduation" width="23" height="23">
                            </span>
                            <span class="section-title">DIPLÔMES</span>
                        </div>
                        <div *ngIf="visual && diplome.length>0" class="diplome-content p-5 pb-2">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of diplome">
                                    <div class="d-flex gap-5">
                                        <span class="blue-color">{{item?.date | date:'yyyy'}}</span>
                                        <span>
                                            {{item?.name}}
                                        </span>
                                        <span>{{item?.ecole}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-right" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-right">
                    </section>

                    <section *ngIf="!visual && selectedCandidate?.softSkills?.length>0" class="softskills-section">
                        <div class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="softSkillsImage" alt="skills" width="23" height="23">
                            </span>
                            <span class="section-title">Soft Skills</span>
                        </div>
                    
                        <div class="skills-content p-5">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12"  *ngFor="let item of selectedCandidate?.softSkills">
                                    <div class="pb-2" *ngIf="item">{{ item }}</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section *ngIf="visual && softSkills.length>0" class="softskills-section">
                        <div class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="softSkillsImage" alt="skills" width="23" height="23">
                            </span>
                            <span class="section-title">Soft Skills</span>
                        </div>
                    
                        <div class="skills-content p-5">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12"  *ngFor="let item of softSkills">
                                    <div class="pb-2" *ngIf="item">{{ item }}</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    

                    <section *ngIf="!visual && selectedCandidate?.langue_user?.length" class="langue-section relative">
                        <div *ngIf="selectedCandidate?.langue_user?.length" class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                            <span class="img-icon ">
                            <img [src]="languageImage" alt="certification" width="23" height="23">
                            </span>
                            <span class="section-title">Langues</span>
                        </div>
                    
                        <div class="langue-content p-5 pb-2">
                            <div class="row" >
                                <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of selectedCandidate?.langue_user">
                                    <div class="d-flex gap-5">
                                        <div >{{item?.name}}</div>
                                        <div class="value">
                                            <div class="stars">
                                                <rating [titles]="[' ',' ',' ',' ',' ']" [(ngModel)]="item.stars" [readonly]="true"
                                                    class="rating-stars"></rating>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img *ngIf="!selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" alt="Logo" class="logo-center" [src]="imageLogo">
                        <img *ngIf="selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo" 
                        src="{{baseUrl_Company + selectedCandidate?.consultant_e_s_n[0]?.e_s_n?.logo }}" alt="Logo" class="logo-center">
                    </section>


                    <section *ngIf="visual && langue_user.length > 0" class="langue-section relative">
                        <div *ngIf="langue_user.length > 0" class="d-flex align-items-center gap-3 px-2 pb-2" style="border-bottom: 3px double #265d91;">
                        <span class="img-icon">
                            <img [src]="languageImage" alt="certification" width="23" height="23">
                        </span>
                        <span class="section-title">Langues</span>
                        </div>
                    
                        <div class="langue-content p-5 pb-2">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of langue_user">
                            <div class="d-flex gap-5">
                                <div>{{ item.name }}</div>
                                <div class="value">
                                <div class="stars">
                                    <rating [titles]="[' ', ' ', ' ', ' ', ' ']" [(ngModel)]="item.stars" [readonly]="true" class="rating-stars"></rating>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                <div>
                </div> 
                </div>
            </body>
            <!--end::Content container-->
        </div>
    </div>
