import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntrepriseRoutingModule } from './entreprise-routing.module';
import { EntreprisesComponent } from './components/entreprises/entreprises.component';
import { DetailsCompanyComponent } from './components/details-company/details-company.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModuleModule } from '../../shared/pipes/pipes-module.module';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StarRatingComponent } from '../../shared/rate/star-rating/star-rating.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { CalendarModule } from 'primeng/calendar';
 import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
 import { TableModule } from 'primeng/table';
 import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [
    EntreprisesComponent,
    DetailsCompanyComponent,
    StarRatingComponent,
  ],
  imports: [
    CommonModule,
    EntrepriseRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModuleModule,
    PaginationModule,
    NgxSpinnerModule,
    RatingModule.forRoot(),
    CalendarModule,
    NgbTooltipModule,
    TableModule,
    MenuModule,
    DropdownModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
    ]
})
export class EntrepriseModule {}
