import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stripe } from '@stripe/stripe-js';
import { CrudService } from '../crud-service/crud.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environnements/environment';
import { ApiResponse } from '../../shared-models/responseApi';

@Injectable({
  providedIn: 'root',
})
export class StripeService extends CrudService<
  ApiResponse<any>,
  number
> {
  stripePromise!: Promise<Stripe>;

  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  getActiveSubscriptionPlan(): Observable<any> {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Offer/active-plan`);
  }
}
