import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class JobsService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  //jobs
  getAllJobs(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
      candidate_id?: any;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/User/getListJob?per_page=${params?.per_page}&page=${params?.page}${sort}&candidate_id=${params?.candidate_id}`
    );
  }

  applyCandidateJobOffer(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/CandidateJobOffer/create`,
      data
    );
  }

  applyCandidateJobRequest(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/CandidateJobRequest/create`,
      data
    );
  }
  searchJobs(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/SearchInListJob`,
      data
    );
  }

  getJobOfferbyId(id: string) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/candidate/JobOffer/getJobOfferDetailsForCandidate/${id}`
    );
  }

  getskills(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SkillsJob/getByJobOffer?job_offer_id=${id}`
    );
  }

  getJobRequestbyId(id: string) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/JobRequest/getById/${id}`
    );
  }

  archivedJobOffer(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/candidate/JobOffer/archivedJobOffer`,
      data
    );
  }

  archivedJobRequest(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/JobRequest/archivedJobRequest`,
      data
    );
  }

  blockedJobOffer(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/candidate/JobOffer/blockedJobOffer`,
      data
    );
  }

  blockedJobRequest(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/JobRequest/blockedJobRequest`,
      data
    );
  }

  //Candidat
  getAllCandidature(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
      user_id?: any;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/User/getListCandidatures/${params?.user_id}?per_page=${params?.per_page}&page=${params?.page}${sort}`
    );
  }

  searchCandidat(id: any, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/searchInCandidatures/${id}`,
      data
    );
  }

  deleteCandidatJobOffer(jobRequestId: any, userId: any) {
    return this._http.delete(this._base + `/CandidateJobOffer/delete`, {
      params: {
        job_offer_id: jobRequestId.toString(),
        user_id: userId.toString(),
      },
    });
  }

  deleteCandidatJobRequest(jobRequestId: number, userId: number) {
    return this._http.delete(this._base + `/CandidateJobRequest/delete`, {
      params: {
        job_request_id: jobRequestId?.toString(),
        user_id: userId?.toString(),
      },
    });
  }

  getListOfSkills() {
    return this._http.get<ApiResponse<any>>(this._base + `/SubCategory/getAll`);
  }
  getSkills() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SubCategory/getListSubCateg`
    );
  }

  //ArchivedJobs
  getAllArchivedJob(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
      user_id?: any;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/candidate/JobOffer/getListJobOfferArchivedForCandidate?per_page=${params?.per_page}&page=${params?.page}${sort}&id=${params?.user_id}`
    );
  }

  searchArchivedJob(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/candidate/JobOffer/SearchInListJobArchived`,
      data
    );
  }

  restoredJobOffer(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/candidate/JobOffer/restoredJobOffer`,
      data
    );
  }

  restoredJobRequest(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/JobRequest/restoredJobRequest`,
      data
    );
  }
  getJobOfferList(
    id: number,
    data?: {
      per_page?: number;
      page?: number;
      sort?: number;
      type?: number;
      sortAttribute?: string;
      candidate_id?: any;
    }
  ) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data?.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/candidate/JobOffer/getByEsn?esn_id=${id}&candidate_id=${data?.candidate_id}&per_page=${data?.per_page}&page=${data?.page}${sort}`
    );
  }

  /* get elment of header table list jobs */
  getHeaderElements(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/getPagesWithSubPagesForUser?role_id=3&interface=3&id=${id}`
    );
  }
  changeDelectedElementHeader(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/addRemoveSubPageToUser?role_id=3&interface=3`,
      data
    );
  }
  sortArray(
    array: any[],
    column: string | undefined,
    direction: number
  ): any[] {
    const compareFn = (a: any, b: any): any => {
      if (column) {
        const aValue = this.getValue(a, column);
        const bValue = this.getValue(b, column);

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return direction * (aValue - bValue);
        } else {
          const aString = String(aValue).toLowerCase();
          const bString = String(bValue).toLowerCase();
          return direction * aString.localeCompare(bString);
        }
      }
      return 0;
    };

    return array.sort(compareFn);
  }

  getValue(obj: any, column: string): any {
    return column.includes('.')
      ? column.split('.').reduce((o, i) => o[i], obj)
      : obj[column];
  }
  createTestEsn(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/CandidateJobOffer/TestJobOffer`,
      data
    );
  }

  getAllDefaultSuggestions(params: { per_page?: number; page?: number, sort?: number,sortAttribute?:string} = {}){
     let sort=''
    if (params?.sort) sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`
    return this._http.get<ApiResponse<any>>(this._base + `/User/getSuggestionForCandidate?per_page=${params?.per_page}&page=${params?.page}${sort}`);

  }
  searchSuggestion(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/seachInSuggestionForCandidate`,
      data
    );
  }
  searchJobOffer(data: any) {
    let tabs = this.findDoubleQuotes(data.search);

    if (tabs.length <= 2) data.search = '"' + data.search + '"';
    console.log(
      'data of searchCandidat',
      'count = ' + tabs.length,
      data.search
    );
    return this._http.post<ApiResponse<any>>(
      this._base + `/candidate/JobOffer/search`,
      data
    );
  }
  findDoubleQuotes(str: string): number[] {
    const indices: number[] = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '"') {
        indices.push(i);
      }
    }
    return indices;
  }
}
