import { Component, ElementRef, OnInit } from '@angular/core';
import { JobsService } from '../../services/jobs.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environnements/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  restoredSuccess,
  serverError,
} from '../../../../shared/shared-models/message';
import { ICountry, State } from 'country-state-city';
import { PaysService } from '../../../../shared/services/pays.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { headerColumns4 } from '../../models/jobOffer';
@Component({
  selector: 'app-jobs-archived',
  templateUrl: './jobs-archived.component.html',
  styleUrls: ['./jobs-archived.component.css'],
})
export class JobsArchivedComponent implements OnInit {
  isLoadSpinner: boolean = true;
  listNames: string[] = [];
  dataHeader: any = [];
  isCollapsed = true; // Initial state
  currentUser: any;
  id: any;
  archivedJobs: any;
  totalJobArchived!: number;
  url: string = environment.baseUrl + '/api';
  showStartDate: boolean = false;
  showDate: boolean = false;

  showEndDate: boolean = false;
  pagination: boolean = false;

  totalJobs: any
  skillsList: { name_FR: string }[] = [];
  currentSortColumn: string = 'esn_name';
  /* formGroup */
  searchForm: FormGroup = this.createSearchForm();
  translations: { [key: string]: string } = {
    Afghanistan: 'Afghanistan',
    'South Africa': 'Afrique du Sud',
    Albania: 'Albanie',
    Algeria: 'Algérie',
    Germany: 'Allemagne',
    Andorra: 'Andorre',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    'Saudi Arabia': 'Arabie saoudite',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    Belarus: 'Biélorussie',
    Bolivia: 'Bolivie',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    Brazil: 'Brésil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    Chile: 'Chili',
    China: 'Chine',
    Cyprus: 'Chypre',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    'Congo - Brazzaville': 'Congo-Brazzaville',
    'Congo - Kinshasa': 'Congo-Kinshasa',
    'North Korea': 'Corée du Nord',
    'South Korea': 'Corée du Sud',
    'Costa Rica': 'Costa Rica',
    'Ivory Coast': 'Côte d’Ivoire',
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Denmark: 'Danemark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'United Arab Emirates': 'Émirats arabes unis',
    Eritrea: 'Érythrée',
    Spain: 'Espagne',
    Eswatini: 'Eswatini',
    Estonia: 'Estonie',
    'United States': 'États-Unis',
    Ethiopia: 'Éthiopie',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Grenada: 'Grenade',
    Greenland: 'Groenland',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    'Equatorial Guinea': 'Guinée équatoriale',
    Guyana: 'Guyana',
    'French Guiana': 'Guyane française',
    Haiti: 'Haïti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    Iraq: 'Irak',
    Iran: 'Iran',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    Laos: 'Laos',
    Latvia: 'Lettonie',
    Lebanon: 'Liban',
    Lesotho: 'Lesotho',
    Liberia: 'Libéria',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'North Macedonia': 'Macédoine du Nord',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    Micronesia: 'Micronésie',
    Moldova: 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibie',
    Nauru: 'Nauru',
    Nepal: 'Népal',
    Netherlands: 'Pays-Bas',
    'New Caledonia': 'Nouvelle-Calédonie',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    Palestine: 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Romania: 'Roumanie',
    Russia: 'Russie',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint-Barthélemy',
    'Saint Helena': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
    'Saint Lucia': 'Sainte-Lucie',
    'Saint Martin': 'Saint-Martin',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten': 'Saint-Martin',
    Slovakia: 'Slovaquie',
    Slovenia: 'Slovénie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Georgia and the South Sandwich Islands':
      'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Swaziland: 'Eswatini',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    Syria: 'Syrie',
    Taiwan: 'Taïwan',
    Tajikistan: 'Tadjikistan',
    Tanzania: 'Tanzanie',
    Thailand: 'Thaïlande',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Kingdom': 'Royaume-Uni',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Viêt Nam',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Zimbabwe: 'Zimbabwe',
  };
  paysList: ICountry[] = [];
  stateList: any[] = [];
  mobilities: any[] = [];
  countries: any[] = [];
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;

  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  type = [
    { id: 1, name: 'Alternance' },
    { id: 2, name: 'Autres type de contrat' },
    { id: 3, name: 'CDD' },
    { id: 4, name: 'CDI' },
    { id: 5, name: 'Freelance' },
    { id: 6, name: 'Portage salarial' },
    { id: 7, name: 'Stage' },
  ];

  poste = [
    { id: 1, name: 'à distance' },
    { id: 2, name: 'Hybrid' },
    { id: 3, name: 'sur site' },
  ];
  comp = [
    { id: 1, name: 'JAVA' },
    { id: 2, name: 'LAravel' },
    { id: 3, name: 'Symfony' },
    { id: 4, name: 'Angular' },
    { id: 5, name: 'Kotlin' },
    { id: 5, name: 'React' },
  ];
  state = [
    { id: 0, name: 'Actif' },
    { id: 1, name: 'Inactif' },
  ];
  profile_abroad = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  passeport_talent = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  typeEntreprise = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de recrutement' },
    // { id: 3, name: 'Client final' },
  ];

  pays = [
    { id: 1, name: 'France' },
    { id: 2, name: 'Tunisie' },
    { id: 3, name: 'Turquie' },
    { id: 4, name: 'Allemagne' },
  ];

  entreprise = [
    { id: 1, name: 'Entreprise' },
    { id: 2, name: 'ESN' },
    { id: 3, name: 'Client Final' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ];

  villes = [
    { id: 1, name: 'Paris' },
    { id: 2, name: 'lyon' },
    { id: 3, name: 'Marseille' },
    { id: 4, name: 'Nice' },
    { id: 5, name: 'Strasbourg' },
  ];
  date = [
    { id: 1, name: 'Hier' },
    { id: 2, name: 'Dernier 7 jours' },
    { id: 3, name: 'Dernier mois' },
    { id: 4, name: 'Dernier 2 mois' },
  ];
  contratTypeOptions: { [key: string]: string } = {
    '1': 'CDD',
    '2': 'CDI',
    '3': 'Portage salarial',
    '4': 'Freelance',
    '5': 'Stage',
    '6': 'Alternance',
    '7': 'Autres type de contrat',
  };
  checkboxState: {
    [key: string]: { displayName: string; isChecked: boolean };
  } = {
      id: { displayName: 'id', isChecked: true },
      logo: { displayName: 'Logo', isChecked: true },
      societe: { displayName: 'Société', isChecked: true },
      type: { displayName: 'Type', isChecked: true },
      poste: { displayName: 'poste', isChecked: true },
      dateOfCandidate: { displayName: 'date de candidature', isChecked: true },
      dateOfPublication: { displayName: 'DATE DE PUBLICATION', isChecked: true },
      experience: { displayName: 'ANNÉE EXPERIENCE', isChecked: true },
      typeOfContract: { displayName: 'TYPE DE CONTRACT', isChecked: true },
      salary: { displayName: 'SALAIRE ', isChecked: true },
      tjm: { displayName: 'TJM ', isChecked: true },
      typeOfPost: { displayName: 'TYPE DE POSTE', isChecked: true },
      skills: { displayName: 'COMPÉTENCES ', isChecked: true },
      availability: { displayName: 'DISPONIBILITÉ ', isChecked: true },
      scoreJobOffer: { displayName: 'SCORE ', isChecked: true },
      city: { displayName: 'PAYS ', isChecked: true },
      country: { displayName: 'VILLE ', isChecked: true },
      state: { displayName: 'STATUT ', isChecked: true },
    };
  constructor(
    private jobService: JobsService,
    private localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private paysServices: PaysService,
    private elRef: ElementRef,
    private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig

  ) { }

  ngOnInit(): void {
    this.paysList = this.paysServices.getAllCountries();
   this.getAllDefaultJobArchived();
    this.getAllSkills();
    this.searchForm.get('city')?.disable();
    this.getListElementHeader();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });

    
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    // if (dropdown) {
    //   dropdown.classList.remove('show');
    // }
  }
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  filterColumns(columnName: string): boolean {
    return this.checkboxState[columnName]?.isChecked || false;
  }

  updateCheckboxState(columnName: string): void {
    const checkbox = this.checkboxState[columnName];
    if (checkbox) {
      checkbox.isChecked = !checkbox.isChecked;
    }
  }
  translateCountryName(name: string): string {
    return this.translations[name] || name;
  }
  /* change country select and delete the string Governorate from state name*/
  onCountryChange(mode: string): void {
    this.searchForm.get('city')?.enable();
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.searchForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.searchForm.get('city')?.disable();
        this.searchForm.get('city')?.setValue(null);
      }
    }
  }
  updateInputType(type: 'text' | 'date', nameControl: string) {
    const inputElement =
      nameControl == 'end_date'
        ? (document.getElementById('dateInputEnd') as HTMLInputElement)
        : (document.getElementById('dateInput') as HTMLInputElement);
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchForm.value[nameControl] &&
        this.searchForm.value[nameControl] != ''
      )
        this.searchForm
          .get(nameControl)
          ?.setValue(
            moment(this.searchForm.value[nameControl]).format('DD/MM/yyyy')
          );
    }
  }
  oncityChange(mode: string) {
    this.mobilities = this.searchForm.get('city')?.value;
    let paysLists: any[] = [];
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    this.mobilities = this.searchForm.get('city')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }
  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }

    return duplicates;
  }
  getCheckboxStateEntries(): [
    string,
    { displayName: string; isChecked: boolean }
  ][] {
    return Object.entries(this.checkboxState);
  }
  getAllDefaultJobArchived() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    // let dataPayload =!sort? {per_page: this.itemsPerPage, page: this.currentPage,user_id:this.id}:{ ...{per_page: this.itemsPerPage, page: this.currentPage,user_id:this.id}, ...sort }
    let data =
      this.sortAttr == ''
        ? {
          user_id: this.id,
          per_page: this.itemsPerPage,
          page: this.currentPage,
        }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            user_id: this.id,
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
    this.spinner.show();

    this.jobService.getAllArchivedJob(data).subscribe(
      (res: any) => {
        this.archivedJobs = res.data.data
        this.totalJobArchived = res.data.total;
        if (this.currentPage > 1 && !res.data.data.length) {
          this.currentPage = 1;
        //  this.getAllDefaultJobArchived();
        }
        this.spinner.hide();
      },
      (error) => {
      }
    );
  }

  getSkillsArray(candidate: any): string[] {
    if (candidate && candidate.skills) {
      return candidate.skills.split(',').map((s: string) => s.trim());
    } else {
      return [];
    }
  }
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_jobOffer: [data ? data.ID_jobOffer : ''],
      company_name: [data ? data.ens.name : null],
      name: [data ? data?.name : ''],
      skillsAnd: [data ? data.skillsAnd : ''],
      skillsOr: [data ? data.skillsOr : ''],
      years_of_experience: [data ? data.years_of_experience : ''],
      post_type: [data ? data.post_type : ''],
      contract_type: [data ? data?.contract_type : null],
      country: [data ? data?.country : ''],
      city: [data ? data?.city : ''],
      salary: [data ? data?.salary : ''],
      salary_range: [data ? data?.salary_range : ''],
      tjm: [data ? data?.tjm : ''],
      tjm_range: [data ? data?.tjm_range : ''],
      date_range: data ? data?.date_range : '',
      availability: [data ? data?.availability : ''],
      typeEntreprise: [data ? data?.typeEntreprise : ''],
      start_date: [data ? data.start_date : ''],
      end_date: [data ? data.end_date : ''],
      profile_abroad: [data ? data.profile_abroad : ''],
      passeport_talent: [data ? data.passeport_talent : ''],
      state: [data ? data.state : ''],
      date_candidature: [data ? data.date_candidature : ''],
      search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
    });
  }
  onSalaryRangeInput(event: any) {
    this.preventNegativeInput(event)
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.searchForm.controls['salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }


  headerColumns: { checked: boolean; name: string; code: string }[] =
    headerColumns4;
  getSortName(columnName: string): string {
    switch (columnName) {
      case 'ID':
        return 'ID_jobOffer';
      case 'Nom entreprise':
        return 'esn_name';
      case 'Type entreprise':
        return 'type';
      case 'Poste':
        return 'name';
        case 'Date de publication':
          return 'start_date';
      case 'Date de candidature':
        return 'candidate_job_offer_creation_date';
      case 'Années d’expérience':
        return 'years_of_experience';
      case 'Type de contrat':
        return 'contract_type';
      case 'Type de poste':
        return 'post_type';
      case 'Disponibilité':
        return 'availability';
      case 'scoreJobOffer':
        return 'Score';
      case 'TJM':
        return 'tjm';
      case 'Salaire':
        return 'salary';
      case 'Compétence':
        return 'skills';
      case 'Pays':
        return 'country';
      case 'Ville':
        return 'city';
      case 'Statut':
        return 'state';

      default:
        return columnName;
    }
  }

  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.jobService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => {},
        error: () => {},
      });
  }


  getListElementHeader() {
    this.jobService
      .getHeaderElements(26)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isLoadSpinner = false;
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
            console.log('listNamescondu', this.listNames);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => {},
      });
  }


  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      return parseFloat(value.slice(0, -1)) * 1000;
    } else if (!isNaN(value)) {
      return parseFloat(value) * 1000;
    }
    return parseFloat(value);
  }
  transformSearch(search: string): string {
    search = search.replace(/\|\|/g, 'OR');
    search = search.replace(/&&/g, 'AND');
    search = search.replace(/!/g, 'NOT');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        // If the term is inside double quotes, keep it as is
        return `"${p1}"`;
      } else {
        // If the term is not inside double quotes, add double quotes around it
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `"${escapedTerm}"`;
      }
    });

    // Ensure that even number of quotes are present
    /*  const countQuotes = (search.match(/"/g) || []).length;
    if (countQuotes % 2 !== 0) {
      // If the number of quotes is odd, add a closing quote at the end
      search += '"';
    }
  */
    // Additional adjustment to remove extra backslashes before escaped double quotes
    // search = search.replace(/\\"/g, '"');

    return search;
  }

  isSimpleWord(value: string): boolean {
    // Utilisez une expression régulière pour vérifier si la valeur est un mot simple ou deux mots avec espace
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchJobArchived() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    let formattedEndDate = this.datePipe.transform(this.searchForm.value.end_date, 'yyyy-MM-dd');
    let formattedStartDate = this.datePipe.transform(this.searchForm.value.start_date, 'yyyy-MM-dd');
    let formattedDateCandidature = this.datePipe.transform(this.searchForm.value.date_candidature, 'yyyy-MM-dd');
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      id: this.id,
      name: this.searchForm.value.name,
      years_of_experience: this.searchForm.value.years_of_experience,
      company_name: this.searchForm.value.company_name,
      skillsAnd: this.searchForm.value.skillsAnd,
      skillsOr: this.searchForm.value.skillsOr,
      availability: this.searchForm.value.availability,
      contract_type: this.searchForm.value.contract_type,
      country: this.searchForm.value.country,
      city: this.searchForm.value.city,
      post_type: this.searchForm.value.post_type,
      tjm: this.searchForm.value.tjm,
      tjm_range: this.searchForm.value.tjm_range,
      salary: this.searchForm.value.salary,
      salary_range: this.convertKToNumber(
        this.searchForm.value.salary_range?.toString()
      ),
      date_range: this.searchForm.value.date_range,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      date_candidature: formattedDateCandidature,
      profile_abroad: this.searchForm.value.profile_abroad,
      passeport_talent: this.searchForm.value.passeport_talent,
      state: this.searchForm.value.state,
      ID_jobOffer: this.searchForm.value.ID_jobOffer,
      typeEntreprise: this.searchForm.value.typeEntreprise,
      search: this.transformSearch(this.searchForm.value.search),
    };

    this.spinner.show();
    this.jobService
      .searchArchivedJob(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (res.status == 200) {
            if (this.currentPage > 1) {

              this.currentPage = 1;
              this.endIndex = 5;
              this.searchJobArchived();
            } else {
              this.spinner.hide();
              this.archivedJobs = res.data.data
              this.totalJobArchived = res.data.total;
              // this.getItems()
            }
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  isRecherche: boolean = false;
  reset() {
    this.showStartDate = false;
    this.showEndDate = false;
    this.showDate = false;

    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
      this.currentPage = 1;
    } else {
      this.spinner.hide();
      this.currentPage = 1;
      this.getAllDefaultJobArchived();
    }
    return (this.isRecherche = false);
  }

  getAllSkills() {
    this.jobService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
        },
      });
  }
   /* get pagination items */
   getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchJobArchived();
      } 
      else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getAllDefaultJobArchived();
      }
    }
  }
  detailsJobOfferNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }

  detailsJobRequestNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }

  restoredJob(idJobs: any, type: any) {
    if (type === '2') {
      const data = { jobOffer_id: idJobs };
      this.jobService.restoredJobOffer(data).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.toastr.success(restoredSuccess);
          this.searchJobArchived();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    } else {
      const data = { job_request_id: idJobs, user_id: this.id };
      this.jobService.restoredJobRequest(data).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.toastr.success(restoredSuccess);
          this.searchJobArchived();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    }
  }
  // sortData(columnName: string) {
  //   let sort={sort:this.currentSortDirection,sortAttribute:this.currentSortColumn}
  //   if (this.currentSortColumn === columnName) {
  //     this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1; // Toggle sort direction
  //   } else {
  //     this.currentSortColumn = columnName; // Change the sort column
  //     this.currentSortDirection = 1; // Reset sort direction to ascending
  //   }
  //     this.getAllDefaultJobArchived(sort)
  // }
  sortAttr: string = '';
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute;
    this.currentPage = 1;
    this.endIndex = 5;
    this.archivedJobs = this.jobService.sortArray(
      this.archivedJobs,
      sortAttribute,
      this.currentSortDirection
    );
    this.getAllDefaultJobArchived();
  }
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  onDateInputStartDateChange(e: any) {
    this.showStartDate = true;
  }
  onDateInputDateChange(e: any) {
    this.showDate = true;
  }
  onDateInputEndDateChange(e: any) {
    this.showEndDate = true;
  }
  
// @TOFIX: duplicated  
/**
 * Prevents negative numbers or invalid characters in input fields.
 *
 * @param event - The input event triggered by the user.
 */
preventNegativeInput(event: Event): void {
  const inputElement = event.target as HTMLInputElement;
  if (inputElement.type === 'number') {
    const value = inputElement.value;
      inputElement.value = value.replace(/-/g, '');
  }
  if (inputElement.type === 'text') {
    console.log('text ',inputElement.value);
    
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
  }
}

}
