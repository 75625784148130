import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { PusherService } from '../../services/pusher.service';
import * as moment from 'moment';
import 'moment-duration-format';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import {
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../../environnements/environment';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { PaginatorCustomPIntl } from 'src/app/shared/_helpers/paginationCustomIntel';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorCustomPIntl}],

})
export class ChatComponent {
  @ViewChildren('userLink') userLinks!: QueryList<ElementRef>;
  messages: any;
  messagesReciveds: any;
  newMessage: string = '';
  totalUsers: any;
  dataEmployeeWithCompany: any;
  ListMessages: any;
  currentConversationUserId: number | null = null;
  currentUser: any;
  url: string = environment.baseUrl;
  messagesSubscription!: Subscription;
  selectedUser: any;
  headerClass: any;
  userId:any;
  name:any;
  searchSubjectMessage : Subject<string> = new Subject<string>();
  searchSubjectUser: Subject<string> = new Subject<string>();

  private unsubscribeAll: Subject<void> = new Subject();

  pageSizeOptions = [5, 10, 20];

  pageSizeUser = 5;
  pageIndexUser = 0;
  totalPagesUser = 0
  searchUserText = ''

  pageSizeMessage = 5;
  pageIndexMessage = 0;
  totalPagesMessage = 0
  searchMessageText = ''

  constructor(
    private pusherService: PusherService,
    private chatService: ChatService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
  ) {}

  ngOnInit() {
    this.changeDetectorRef.detectChanges(); 

    this.getUrlParms()

    this.messagesSubscription = this.pusherService.messages$.subscribe(
      (message) => {
        console.log('message', message);
        this.messages = this.messages.concat(message);
        console.log('message2', this.messages);
        this.changeDetectorRef.detectChanges();
      }
    );

    this.currentUser = JSON.parse(this.localStorageService.getData('UserInfo')?? '');

    this.initUserSearchListener();
    this.searchUsers(this.searchUserText);

    this.initMessageSearchListener();
    this.searchMessage(this.searchMessageText);

  }
  goToUsersTab() {
    const usersTab = document.querySelector('a[href="#Utilisateurs"]');
    if (usersTab) {
      (usersTab as HTMLElement).click();
    }
  }

  clickUserLink(userId: string) {
    const userLink = this.userLinks.find(link => link.nativeElement.getAttribute('href') === `#userLink_${userId}`);
    if (userLink) {
      userLink.nativeElement.click();
    }
  }

  loadMessagesEmitter(userId?: any) {
    this.spinner.show();
    this.chatService.getMessagesEmitter(userId).subscribe((messages) => {
      this.messages = messages?.data.reverse().map((message: any) => {
        message.elapsedTime = this.formatElapsedTime(message.created_at);
        return message;
      });
      this.spinner.hide();
    });
  }

  getUrlParms() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {

        this.userId = params.params['user'];
        this.name = params.params['name'];
        if (this.name && this.userId) {
          this.searchUserText = this.name;
          this.goToUsersTab()
        } 
      },
    });
  }
  sendMessage() {
    if (this.newMessage.trim() !== '') {
      this.chatService
        .sendMessage(this.currentConversationUserId, this.newMessage)
        .subscribe(() => {
          this.newMessage = '';
          this.loadMessagesEmitter(this.currentConversationUserId)
          this.searchMessage(this.searchMessageText)
        });
    }
    console.log('chanel', this.pusherService.channel);
  }
  formatElapsedTime(created_at: string): string {
    const currentTime = moment();
    const messageTime = moment(created_at);
    const duration = moment.duration(currentTime.diff(messageTime));

    if (duration.asMinutes() <= 59) {
      const minutes = Math.floor(duration.asMinutes());
      if (minutes < 1) {
        return "à l'instant";
      } else if (minutes === 1) {
        return '1 minute';
      } else {
        return `${minutes} minutes`;
      }
    } else if (duration.asHours() <= 23) {
      const hours = Math.floor(duration.asHours());
      if (hours === 1) {
        return '1 heure';
      } else {
        return `${hours} heures`;
      }
    } else if (duration.asDays() <= 6) {
      const days = Math.floor(duration.asDays());
      if (days === 1) {
        return '1 jour';
      } else {
        return `${days} jours`;
      }
    } else {
      const weeks = Math.floor(duration.asDays() / 7);
      if (weeks === 1) {
        return '1 semaine';
      } else {
        return `${weeks} semaines`;
      }
    }
  }

  
  userSelected(user: any) {
    this.selectedUser = user;
    this.startConversation(user?.id);
  }
  newUser: any;
  startConversation(userId: any) {
    this.currentConversationUserId = userId;
    this.loadMessagesEmitter(userId);
  }

  
  deleteConversation(receiverId: any): void {

    Swal.fire({
          title: 'Êtes-vous sûr(e) ?',
          text: 'Êtes-vous sûr de vouloir supprimer cette Conversation ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler',
          reverseButtons : true
        }).then((result) => {
          if (result.isConfirmed) {
            this.chatService.deleteConversation(receiverId).subscribe(
              (response) => {
                this.toastrService.success('La conversation a été supprimée avec succès');
                this.messages = this.messages.filter(
                  (message: any) => message.id === receiverId
                );
              },
              (error) => {
                this.toastrService.success('Erreur de suppression de conversation');
              }
            )
          }
        });
  }
  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }
  ngOnDestroy() {
    this.pusherService.disconnect();
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.messagesSubscription.unsubscribe();
  }

   /**
   * Initialize the search listener to debounce user input.
   */
   private initUserSearchListener(): void {
    this.searchSubjectUser
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchTerm) => {
        this.searchUserText = searchTerm
        this.pageIndexUser = 0
        this.searchUsers(this.searchUserText);
      });
  }

  /**
   * Call the service to search users based on the search term.
   * @param searchTerm The search term entered by the user.
   */
    searchUsers(searchTerm: string): void {
      this.spinner.show();
      const requestData = { search: searchTerm };
  
      this.chatService.searchListUser(requestData, this.pageSizeUser, this.pageIndexUser).subscribe({
        next: (result) => {
          this.spinner.hide();
          this.dataEmployeeWithCompany = result?.employeeWithCompany?.data || [];
          this.totalPagesUser = result.employeeWithCompany?.total

          if(this.name && this.userId && this.name === this.searchUserText){
            this.dataEmployeeWithCompany = this.dataEmployeeWithCompany.filter((user: any) => user.id == this.userId);
            this.userSelected(this.dataEmployeeWithCompany[0])
            this.totalPagesUser = 1
          }
        },
        error: (err) => {
          this.spinner.hide(); // Hide spinner in case of an error
          this.dataEmployeeWithCompany = [];
          this.pageIndexUser = 0;
          this.totalPagesUser = 0
        }
      });
    }

     /**
   * Trigger search whenever the user types in the search input.
   * @param event The keyboard event from the input field.
   */
  onSearchInputUser(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchSubjectUser.next(inputValue); // Emit the input value to the search subject
  }

  /**
   * Initialize the search listener to debounce user input.
   */
  private initMessageSearchListener(): void {
    this.searchSubjectMessage
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchTerm) => {
        this.searchMessageText = searchTerm
        this.pageIndexMessage = 0
        this.searchMessage(this.searchMessageText);
      });
  }

  /**
   * Call the service to search messages based on the search term.
   * @param searchTerm The search term entered by the user.
   */
    searchMessage(searchTerm: string): void {
      this.spinner.show();
      const requestData = { search: searchTerm };
  
      this.chatService.searchListMessage(requestData, this.pageSizeMessage, this.pageIndexMessage).subscribe({
        next: (result) => {
          this.spinner.hide();
          this.ListMessages = result.data || [];
          this.totalPagesMessage = result?.totalElements
        },
        error: (err) => {
          this.spinner.hide();
          this.ListMessages = [];
          this.pageIndexUser = 0;
          this.totalPagesUser = 0
        }
      });
    }

     /**
   * Trigger search whenever the user types in the search input.
   * @param event The keyboard event from the input field.
   */
  onSearchInputMessage(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchSubjectMessage.next(inputValue);
  }
  

   /**
   * Handle page change event from the paginator.
   * @param event The page event containing the new page index and page size.
   */
   onPageChange(e: PageEvent, type :string): void {   
    if(type === 'U'){
      this.pageSizeUser = e.pageSize;
      this.pageIndexUser = e.pageIndex;
      this.searchUsers(this.searchUserText);
    } else if(type === 'M'){
      this.pageSizeMessage = e.pageSize;
      this.pageIndexMessage = e.pageIndex;
      this.searchMessage(this.searchMessageText);
    }
  }

  getCompanyName(user: any ): string {
    if (user?.company_name_user) {
      return user.company_name_user;
    }
    if (user?.company_name_employ) {
      return user.company_name_employ;
    }
    return '---';
  }

  getUserImage(user: any ): string {
    
    const  urlUser = `${this.url}/api/User/file/gallery_users/`
    const  urlCompany = `${this.url}/api/Company/file/gallery_company/`

    if(user?.company_name_user){
        return urlCompany.concat(user?.image_url)
    } else {
      return urlUser.concat(user?.image_url)
    }
  }

  getListMessageImage(user: any ): string {
    const  urlUser = `${this.url}/api/User/file/gallery_users/`
    const  urlCompany = `${this.url}/api/Company/file/gallery_company/`

    if(user?.company_name_user){
        return urlCompany.concat(user?.image_url)
    } else {
      return urlUser.concat(user?.image_url)
    }
  }

  getSenderImage(message: any ): string {
    const  urlUser = `${this.url}/api/User/file/gallery_users/`
    const  urlCompany = `${this.url}/api/Company/file/gallery_company/`
    const hasRoleAdmin = message?.emitter?.role_user_id.includes("7") || message?.emitter?.role_user_id.includes("8");

    if(hasRoleAdmin){
      return urlCompany.concat(message?.emi_image_url)
    } else {
      return urlUser.concat(message?.emi_image_url)
    }
  }

}
