import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceCraService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  getListOfCra(company_name: any) :Observable<any>{
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/SpecificTypeOfCra/getListWithoutPaginate?company_name=${company_name}`
    );
  }

  getHistoryCompany() {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ConsultantHistory/companyHistory`
    );
  }

  getClientsByCandidate(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Cra/getClientsByCandidate?id=${id}`
    );
  }
  getDetailsCra(idCra: any, company_name: any, consultant_id:any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Cra/getDetails?country=fr&id=${idCra}&company_name=${company_name}&consultant_id=${consultant_id}`
    );
  }
  getAllListOfMonthsOfYear(params: { year?: number; user_id?: number } = {}) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Cra/getListOfMonthsForYear?year=${params.year}&user_id=${params.user_id}`
    );
  }
  searshCra(data: any) {
    return this._http.post<ApiResponse<any>>(this._base + `/Cra/search`, data);
  }

  getAllListOfCraForMonth(
    params: { year?: number; user_id?: number; month?: string } = {}
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Cra/getListOfCrasForMonth?year=${params.year}&user_id=${params.user_id}&month=${params.month}`
    );
  }

  searchCraForMonth(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Cra/searchinListOfCrasForMonth`,
      data
    );
  }

  addListCra(data: any) {
    return this._http.post<ApiResponse<any>>(this._base + `/Cra/add`, data);
  }

  checkAndCreateCRAs() {
    return this._http.post<ApiResponse<any>>(this._base + `/Cra/checkAndCreateCRAs`, null);
  }

  cancelAndValidateCra(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Cra/sendRejectCraByCansultant`,
      data
    );
  }

 validateCra(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Cra/validate-cra`,
      data
    );
  }
  searchCra(idCra:any, searchTerm: any, company_name: any, userId:any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/Cra/searchInCraDetails?country=fr&id=${idCra}&company_name=${company_name}&consultant_id=${userId}&searchTerm=${searchTerm}`,{}
    );
  }

/**
 * Export a CRA as a file for the specified CRA ID and country.
 * The response is returned as a binary file (array buffer) suitable for downloading.
 *
 * @param idCra - The unique identifier of the CRA to be exported.
 * @param country - The country code for which the CRA is being exported (e.g., "FR").
 * @returns An Observable that emits the binary file data as an `ArrayBuffer`.
 */
  exportCra(idCra: number, country: string) {
    let url = `${this._base}/Cra/downloadCra?id=${idCra}&country=${country}`
    return this._http.get(url, {responseType: 'arraybuffer',});
  }
}
