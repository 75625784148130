import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { serverError } from '../../../../shared/shared-models/message';
import { environment } from '../../../../../environnements/environment';
import { CandidateService } from '../../services/candidate.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import Swal from 'sweetalert2';
import { CvType } from 'src/app/core/enums/cv-type.enum';
import { ExportService } from 'src/app/shared/services/export.service';
@Component({
  selector: 'app-cv-candidat',
  templateUrl: './cv-candidat.component.html',
  styleUrls: ['./cv-candidat.component.css'],
})

export class CvCandidatComponent {
  visual :boolean =false;
  cvType: string | undefined;
  cvTypeEnum = CvType
  emailImage = 'assets/image_cv/email-svg.svg';
  linkedinImage = 'assets/image_cv/linkedin.svg';
  locationImage = 'assets/image_cv/location.svg';
  telephoneImage = 'assets/image_cv/telephone.svg';
  aboutImage = 'assets/image_cv/about-icon.svg';
  skillsImage = 'assets/image_cv/skills-image.png';
  softSkillsImage = 'assets/image_cv/softSkills.png';
  starImage='assets/image_cv/start.svg';
  starinactiveImage='assets/image_cv/star-inactive.svg';
  imageLogo='assets/image_cv/wegstu-pdf.png'; 
  experienceImage='assets/image_cv/experience-image.svg';
  graduationImage='assets/image_cv/graduation-svg.svg';
  certificateImage='assets/image_cv/certificate.svg';
  languageImage='assets/image_cv/language.svg';
  linkedinUrl = '';
  workExperience: any[] =[];
 
  removeHtmlTags(html: string | undefined): string {
    if (!html) {
      return '';
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html; 
    return tempDiv.textContent || ''; 
  }
  isParagraphValid(): boolean {
    const text = this.removeHtmlTags(this.selectedCandidate?.savoir_faire || '');
    const wordCount = text.split(/\s+/).filter(word => word.trim().length > 0).length;
    return wordCount >= 2;
  }

  constructor(
    private spinner: NgxSpinnerService,
    private candidateService: CandidateService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private router: Router,
     private exportService: ExportService,
  ) {}
  
  firstName: string | null= '';
  lastName: string | null= '';
  desiredWorkstation: string | null = '';
  email: string = ''; 
  phone: string | null = '';
  linkedinLink: string | null = ''; 
  phoneNumber: Phone[] = [];
  savoirFaire: string | null = ''; 
  softSkills: string[] = [];
  addSkills: AddSkill[] = [];
  langue_user: Langue[] = [];
  certificats: Certificat[] = [];
  candidatSkills: SkillsCandidat[] = [];
  diplome: Diplome[] = [];
  prof_experience : Experience[] = [];
  emailProESN: string = ''; 
  emailProClient: string = '';
  editcv: boolean = false;
  currentCountry: string | null = '';
  destinationCountry: string = '';
  mobility: string = '';
  endIndex: number = 5;
  idCandidat: any;
  idCompany: string | null = null;
  selectedCandidate: any;
  totalItems: any;
  skillsCandidat: any;
  private unsubscribeAll: Subject<void> = new Subject();
 baseUrl_Company = environment.baseUrlGaleryCompany
 first_name: string = '';
 last_name: string = '';
  currentUser:any
  id:any
 /* string */
 url:string=environment.baseUrl

  ngOnInit(): void {
   this.currentUser = this.localStorageService.getData('UserInfo');
   this.id = JSON.parse(this.currentUser).id;
   this.getRoutingParams()
   this.getSkills()
   this.visualisation()
   if (this.selectedCandidate?.prof_experience) {
    this.selectedCandidate.prof_experience.sort((a: Experience, b: Experience) => 
      new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
    );
  } 
  }
  visualisation() {
    const storedVisual = localStorage.getItem('visual');
    this.visual = storedVisual ? JSON.parse(storedVisual) : false; 
    const storedFirstName = localStorage.getItem('firstName');
    this.firstName = storedFirstName;
    const storedLastName = localStorage.getItem('lastName');
    this.lastName = storedLastName;
    const storedDesiredWorkstation = localStorage.getItem('desiredWorkstation');
    this.desiredWorkstation = storedDesiredWorkstation ;
    const storedEmail = localStorage.getItem('email');
    this.email = storedEmail ? storedEmail : '*****';
    const storedLinkedinLink  = localStorage.getItem('linkedinLink');
    this.linkedinLink = storedLinkedinLink ;
    const storedcurrentCountry = localStorage.getItem('currentCountry');
    this.currentCountry = storedcurrentCountry
    const storedSavoirFaire = localStorage.getItem('savoirFaire') ;
    this.savoirFaire = storedSavoirFaire 
    const storedSoftSkills = localStorage.getItem('softSkills');
    this.softSkills = storedSoftSkills ? JSON.parse(storedSoftSkills) : []; 
    const storedLangueUser = localStorage.getItem('langue_user');
    this.langue_user = storedLangueUser ? JSON.parse(storedLangueUser) : [];
    this.langue_user = this.langue_user.map(item => ({
      ...item,
      stars: +item.stars
    })); 
    const storedCertificates = localStorage.getItem('certificats');
    this.certificats = storedCertificates ? JSON.parse(storedCertificates) : []; 
    const storedDiplome = localStorage.getItem('diplome');
    this.diplome = storedDiplome ? JSON.parse(storedDiplome) : []; 
    const storedAddSkills = localStorage.getItem('addSkills');
    this.addSkills = storedAddSkills ? JSON.parse(storedAddSkills) : []; 
    const storedProfExperience = localStorage.getItem('prof_experience');
    this.prof_experience = storedProfExperience ? JSON.parse(storedProfExperience) : []; 
    this.phone = localStorage.getItem('phone');
    const storedCondidatSkills = localStorage.getItem('Candidatskills');
    this.candidatSkills = storedCondidatSkills ? JSON.parse(storedCondidatSkills) : []; 
  }
  navigateToProfilCandidat() {
    this.editcv = this.editcv; 
    localStorage.setItem('editcv', JSON.stringify(this.editcv));
    const storedFirstName = localStorage.getItem('firstName');
    if (storedFirstName) {
      this.firstName = storedFirstName;
    } else if (this.selectedCandidate && this.selectedCandidate.first_name) {
        this.firstName = this.selectedCandidate.first_name;
        localStorage.setItem('firstName', this.selectedCandidate.first_name);
    } else {
        this.firstName = '*****'; 
    }
  const storedLastName =localStorage.getItem('lastName');

    if (storedLastName) {
      this.lastName = storedLastName;
    } else if (this.selectedCandidate && this.selectedCandidate.last_name) {
        this.firstName = this.selectedCandidate.last_name;
        localStorage.setItem('lastName', this.selectedCandidate.last_name);
    } else {
        this.lastName = '*****';
    }

    const storedPhone = localStorage.getItem('phone');
    if (storedPhone) {
      this.phone = storedPhone;
    } else if (this.selectedCandidate && this.selectedCandidate.phone_number) {
      this.phone = this.selectedCandidate.phone_number;
        localStorage.setItem('phone', this.selectedCandidate.phone_number);
    } else {
        this.phone = '*****';
    }
    const storedDesiredWorkstation = localStorage.getItem('desiredWorkstation');


    if (storedDesiredWorkstation !== null && storedDesiredWorkstation !== '') {
      this.desiredWorkstation = storedDesiredWorkstation;
      console.log('desiredWorkstation desiredWorkstationClé existante avec une valeur :', storedDesiredWorkstation);
    } else if (storedDesiredWorkstation === '') {

      localStorage.setItem('desiredWorkstation','')
      this.desiredWorkstation = '';
      console.log('desiredWorkstation desiredWorkstationClé existante mais vide.');
    } else if(this.selectedCandidate && this.selectedCandidate.desired_workstation) {
      localStorage.setItem('desiredWorkstation', this.selectedCandidate.desired_workstation);
      this.desiredWorkstation= this.selectedCandidate.desired_workstation
    }
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      this.email = storedEmail;
    } else if (this.email && this.selectedCandidate.email) {
        this.email = this.selectedCandidate.email;
        localStorage.setItem('email', this.selectedCandidate.email);
    } else {
        this.email = '*****';
    }

    const storedcurrentCountry = localStorage.getItem('currentCountry');
    if (storedcurrentCountry) {
      this.currentCountry = storedcurrentCountry;
      } else if (this.selectedCandidate && this.selectedCandidate.current_country) {
          this.currentCountry = this.selectedCandidate.current_country;
          localStorage.setItem('currentCountry', this.selectedCandidate.current_country);
      } else {
          this.currentCountry = '*****'; 
      }

     const storedSavoirFaire = localStorage.getItem('savoirFaire');
      if (storedSavoirFaire !== null && storedSavoirFaire !== '') {
        this.savoirFaire = storedSavoirFaire;
      } else if (storedSavoirFaire === '') {
        localStorage.setItem('savoirFaire','')
        this.savoirFaire = '';
      } else if(this.selectedCandidate && this.selectedCandidate.savoir_faire) {
        localStorage.setItem('savoirFaire', this.selectedCandidate.savoir_faire);
        this.savoirFaire= this.selectedCandidate.savoir_faire
      }
      
    const storedSoftSkills = localStorage.getItem('softSkills');
    if (storedSoftSkills) {
      this.softSkills = JSON.parse(storedSoftSkills) as string[];
    } else if (this.selectedCandidate?.softSkills?.length) {
      this.softSkills = this.selectedCandidate.softSkills;
      localStorage.setItem('softSkills', JSON.stringify(this.selectedCandidate.softSkills));
    } else {
      this.softSkills = [];
    }
    const storedCertificats = localStorage.getItem('certificats');
    if (storedCertificats) {
      this.certificats = JSON.parse(storedCertificats).map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
    } else if (this.selectedCandidate?.certificats?.length) {
      this.certificats = this.selectedCandidate.certificats.map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
      localStorage.setItem('certificats', JSON.stringify(this.certificats));
    } else {
      this.certificats = [];
    }
    const storedLangueUser = localStorage.getItem('langue_user');
    if (storedLangueUser) {
      this.langue_user = JSON.parse(storedLangueUser).map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
    } else if (this.selectedCandidate?.langue_user?.length) {
      this.langue_user = this.selectedCandidate.langue_user.map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
      localStorage.setItem('langue_user', JSON.stringify(this.langue_user));
    } else {
      this.langue_user = [];
    }

    const storedDiplome = localStorage.getItem('diplome');
    if (storedDiplome) {
      this.diplome = JSON.parse(storedDiplome).map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
    } else if (this.selectedCandidate?.diplome?.length) {
      this.diplome = this.selectedCandidate.diplome.map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
      localStorage.setItem('diplome', JSON.stringify(this.diplome));
    } else {
      this.diplome = [];
    }

    const storedProfExperience = localStorage.getItem('prof_experience');
    if (storedProfExperience) {
      this.prof_experience = JSON.parse(storedProfExperience).map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));

    } else if (this.selectedCandidate?.diplome?.length) {
      this.prof_experience = this.selectedCandidate.diplome.map((item: any) => ({
        ...item,
        stars: +item.stars,
      }));
      localStorage.setItem('diplome', JSON.stringify(this.diplome));
    } else {
      this.prof_experience = [];
    }

    const storedLinkedinLink = localStorage.getItem('linkedinLink'); 
    if (storedLinkedinLink !== null && storedLinkedinLink !== '') {
      this.linkedinLink = storedLinkedinLink;
      console.log('linkedinLink Clé existante avec une valeur :', storedLinkedinLink);
    } else if (storedLinkedinLink === '') {

      localStorage.setItem('linkedinLink','')
      this.linkedinLink = '';
      console.log('linkedinLink Clé existante mais vide.');
    } else if(this.selectedCandidate && this.selectedCandidate.linkedin_link) {
      localStorage.setItem('linkedinLink', this.selectedCandidate.linkedin_link);
      this.linkedinLink= this.selectedCandidate.linkedin_link
    }
    this.router.navigate(['/wegestu/profil-candidat/modifier']);
}

navigateToCVCandidat() {
  this.editcv = !this.editcv;
  localStorage.setItem('editcv', JSON.stringify(this.editcv));
  console.log('Modification annulée, mode lecture:', 'editcv:', this.editcv);
  this.router.navigate(['/wegestu/profil-candidat/modifier']);
}
  chunkArray(array: any[], chunkSize: number): any[][] {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  }

  getRoutingParams() {
    this.activatedRoute.params.subscribe(params => {
      this.idCandidat = params['idCandidat'];
      this.idCompany = params['idCompany']
      this.getCandidateById()
    });

    if (history.state && history.state.type) {
      const type = history.state.type
      if (type === this.cvTypeEnum.WEGESTU || type === this.cvTypeEnum.ESN) {
        this.cvType = type;
      }
    }    
  }

  verifyReviewCompany() {
    if (this.selectedCandidate.Evaluated == 'true') {
      this.cvType === this.cvTypeEnum.WEGESTU ? this.exportCVCandidate(1, null) : this.exportCVCandidate(1, Number(this.idCompany))
    } else this.verifyReviewCompanyPopup();
  }
  verifyReviewCompanyPopup() {
    Swal.fire({
      title: 'Avis requis',
      text: 'Vous ne pouvez télécharger votre dossier de compétence que si vous avez mis un avis pour une entreprise. Veuillez mettre un avis pour pouvoir télécharger votre dossier.',
      icon: 'info',
      confirmButtonText: 'Donner un avis',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/wegestu/avis/donnees-avis');
      }
    });
  }
  // navigateToProfilCandidat() {
  //   this.router.navigate([
  //     '/wegestu/profil-candidat'
  //   ]);
  // }
  formatPeriod(period: string): string {
    if (!period) return '';
  
    const matches = period.match(/(\d+) ans, (\d+) mois, (\d+) jours/);
    if (!matches) return period;
  
    const years = parseInt(matches[1], 10);
    const months = parseInt(matches[2], 10);
  
    if (years === 0 && months === 0) {
      return `moins d'un mois`;
    } else {
      let result = '';
  
      if (years > 0) {
        result += `${years} An${years > 1 ? 's' : ''}`;
      }
      if (months > 0) {
        if (result.length > 0) result += ', ';
        result += `${months} Mois`;
      }
  
      return result;
    }
  }
  capitalizeFirstLetter(string:any) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  } 
 
  getCandidateById() {
    this.spinner.show()
    this.candidateService.getConsultantById(this.idCandidat).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res: any) => {
        this.selectedCandidate = res.data[0]
        const storedVisual = localStorage.getItem('visual');
        this.visual = storedVisual ? JSON.parse(storedVisual) : false; 
        console.log("selectedCandidate offffffffff ",this.selectedCandidate);
        console.log("selectedCandidate offffffffff ",this.visual);
        this.selectedCandidate.softSkills= this.selectedCandidate.softSkills.filter((skill:any) => skill !== null);
        this.first_name = this.selectedCandidate.first_name || '';
        this.last_name = this.selectedCandidate.last_name || '';
        this.spinner.hide()
        console.log('selectedCandidate:', this.selectedCandidate);
       // this.cdr.detectChanges();
      },
      error: () => {
        this.spinner.hide()
      }
    });
  }

  getSkills() {
    this.candidateService
      .getSkillsCandidate(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsCandidat = res.data.categoriesWithSubCategoriesAndSkills
            .map((category:any) => ({
              ...category,
              sub_category: category.sub_category
                .map((subCategory:any) => ({
                  ...subCategory,
                  skills: subCategory.skills.filter((skill:any) => skill.checked === '1')
                }))
                .filter((subCategory:any) => subCategory.skills.length > 0)
            }))
            .filter((category:any) => category.sub_category.length > 0);

          this.totalItems = res.data.count;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }

  navigateToUpdateCandidat() {
    this.router.navigate(['/wegestu/profil-candidat/modifier']);
  }
  exportToPDF() {
    const element = document.getElementById('kt_app_content');

    if (element) {
      this.spinner.show();
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('export_Cv.pdf');
        this.spinner.hide();
      });
    } else {
      console.error('Element not found!');
    }
  }

// @TOFIX: duplicated
/**
 * Exports the CV of a candidate based on the specified type and company ID.
 *
 * @param {number} type - The type of CV to export (e.g., 1 for standard, 2 for anonymous)
 * @param {number | null} idCompany - The ID of the company for which the CV is being exported.
 *                          Use `null` if no specific company is associated.
 */
exportCVCandidate(type: number, idCompany : number | null) { 

  this.spinner.show()
  const candidate = {
    ids: [this.id],
    last_name: this.last_name,
    first_name: this.first_name
  } 

    const exportMethod = idCompany !== null
    ? this.exportService.exportCVEsnCandidate(candidate, type, idCompany)
    : this.exportService.exportCVWegestuCandidate(candidate, type);

      exportMethod.subscribe({
        next: () => {
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
}

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
interface Langue {
  name: string;
  stars: number;
}
interface Certificat {
  name: string;
  date: Date;
}

interface Diplome {
  name: string;
  date:Date;
  ecole: string;
}
interface AddSkill {
  name: string;
}
interface Phone {
  number: number;
}
interface Experience {
  poste:string;
  start_date: Date;
  end_date: Date;
  period: string;
  current_position : boolean;
  client: string;
  project: string;
  description: string;
  method: string;
  tasks: string;
  technologies: string; 
}

interface SubCategory {
  id: number;
  name_FR: string;
  name_EN: string | null;
  icon: string;
  nb_question: number;
  archived: string;
  skills: Skills[];
}
interface Skills {
  stars: number;
}
interface SkillsCandidat {
  name_FR: string;
  sub_category: SubCategory[];
}