import { Injectable } from '@angular/core';
import { LocalStorageService } from '../shared-services/local-storage.service';

@Injectable({
  providedIn: 'root', // Singleton service
})
export class SubscriptionService {
  private activeSubscription: any;

  constructor(private localStorageService: LocalStorageService) {
    this.loadSubscription();
  }

  private loadSubscription(): void {
    const data = this.localStorageService.getData('subscription');
    this.activeSubscription = data ? JSON.parse(data) : '';
  }

  subscriptionCanAccess(featureName: string): boolean {    
    return (
      this.activeSubscription &&
      this.activeSubscription.features &&
      this.activeSubscription.features.some(
        (feature: any) => feature.name === featureName
      )
    );
  }

  unSubscribedEsn(): boolean {
    return typeof(this.activeSubscription) === 'string' && this.activeSubscription?.length < 1
  }

}
