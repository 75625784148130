export const headerColumns=[
    { checked: true, name: 'ID', code: 'ID_company' },
    { checked: true, name: 'Logo', code: 'image_url' },
    { checked: true, name: 'Entreprise', code: 'name' },
    { checked: true, name: 'Type', code: 'type' },
    { checked: true, name: 'Avis', code: 'average_note' },
    { checked: true, name: 'Taille', code: 'size_company' },
    { checked: true, name: 'Pays', code: 'country' },
    { checked: true, name: 'Ville', code: 'city' },
    { checked: true, name: 'N°Tel', code: 'ContactPhone' },
    { checked: true, name: 'Email', code: 'ContactEmail' },
   
  ];

  export const headerColumns3=[
    { checked: true, name: 'Prénom', code: 'first_name' },
    { checked: true, name: 'Poste', code: 'desired_workstation' },
    { checked: true, name: 'TJM', code: 'tjm' },
    { checked: true, name: 'Salaire', code: 'desired_salary' },
    { checked: true, name: 'Compétence', code: 'sub_categories_names' },
    { checked: true, name: 'Pays', code: 'current_country'},
    { checked: true, name: 'Dernière connexion', code: 'last_connection' },  
  ];

  export const headerColumns4=[
    { checked: true, name: 'Logo', code: 'logo' },
    { checked: true, name: 'Société', code: 'societe' },
    { checked: true, name: 'Type', code: 'type' },
    { checked: true, name: 'Site Web', code: 'siteWeb' },
    { checked: true, name: 'Avis', code: 'avis' },  
  ];

  export const headerColumns17=[
    { checked: true, name: 'ID', code: 'ID_company' },
    { checked: true, name: 'Logo', code: 'image_url' },
    { checked: true, name: 'Entreprise', code: 'name' },
    { checked: true, name: 'Type', code: 'type' },
    { checked: true, name: 'Avis', code: 'average_note' },
    { checked: true, name: 'Taille', code: 'size_company' },
    { checked: true, name: 'Pays', code: 'country' },
    { checked: true, name: 'Ville', code: 'city' },
    { checked: true, name: 'N°Tel', code: 'ContactPhone' },
    { checked: true, name: 'Email', code: 'ContactEmail' },
   
  ];