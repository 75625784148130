import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactEsnComponent } from './components/contact-esn/contact-esn.component';
import { ContactWegestuComponent } from './components/contact-wegestu/contact-wegestu.component';
import { ContactResponsableComponent } from './components/contact-responsable/contact-responsable.component';
import { SubscribedEsnGuard } from 'src/app/guards/main-guards/subscribed.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'esn',
    pathMatch: 'full',
  },
  {
    path: 'esn',
    component: ContactEsnComponent,
    canActivate: [SubscribedEsnGuard],
  },
  {
    path: 'wegestu',
    component: ContactWegestuComponent
  },
  {
    path: 'responsable',
    component: ContactResponsableComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
