import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { NotificationsComponent } from './main/notifications/notifications.component';
import { PageNotfoundComponent } from './core/page-notfound/page-notfound.component';
import { authenticationGuard } from './guards/auth-guard/auth.guard';
import { mainGuard } from './guards/main-guards/main.guard';
import { NetworkStatusComponent } from './main/network-status/network-status.component';
import { SubscriptionHasFeatureGuard } from './guards/subscription-has-feature.guard';
import { MyReviewsComponent } from './main/evaluation/components/my-reviews/my-reviews.component';
import { SocietyEvaluationComponent } from './main/evaluation/components/society-evaluation/society-evaluation.component';
import { ReviewComponent } from './main/evaluation/components/society-evaluation/components/review/review.component';

const routes: Routes = [

  { path: 'reconnect', component: NetworkStatusComponent },

  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth', loadChildren: () => import('./authentification/authentification.module').then((m) => m.AuthentificationModule),
    canActivate: [authenticationGuard.canActivate],
  },

  {
    path: 'wegestu', component: LayoutComponent,
    canActivate: [mainGuard.canActivate],
    children: [

      { path: '', redirectTo: 'profil-candidat', pathMatch: 'full' },
      {
        path: 'profil-candidat', loadChildren: () => import('./main/dossier/dossier.module').then((m) => m.DossierModule),
      },
      {
        path: 'qcm', loadChildren: () => import('./main/qcms/qcms.module').then((m) => m.QcmsModule),
      },
      {
        path: 'chat', loadChildren: () => import('./main/chats/chats.module').then((m) => m.ChatsModule),
      },
      {
        path: 'jobs', loadChildren: () => import('./main/jobs/jobs.module').then((m) => m.JobsModule),
      },
      {
        path: 'consultant', loadChildren: () => import('./main/consultant/consultant.module').then((m) => m.ConsultantModule),
      },
      {
        path: 'client-final', loadChildren: () => import('./main/client-final/client-final.module').then((m) => m.ClientFinalModule),
      },
      {
        path: 'evaluations', 
        loadChildren: () => import('./main/evaluation/evaluation.module').then((m) => m.EvaluationModule),
        canActivate: [SubscriptionHasFeatureGuard],
        data: { featureName: 'Créer évaluations consultants' },
      },
      {
        path: 'pointage', 
        loadChildren: () => import('./main/cra/cra.module').then((m) => m.CraModule),
        canActivate: [SubscriptionHasFeatureGuard],
        data: { featureName: 'CRA' },
      },
      {
        path: 'contact', loadChildren: () => import('./main/contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'notifications', component: NotificationsComponent
      },
      {
        path: 'avis/mes-avis', component: MyReviewsComponent
      },
      {
        path: 'avis/donnees-avis', component: SocietyEvaluationComponent
      },
      {
        path: 'avis/review-company', component: ReviewComponent
      },
      {
        path: 'suggestions', loadChildren: () => import('./main/suggestions/suggestions.module').then((m) => m.SuggestionsModule),
      },
      {
        path: 'faq', loadChildren: () => import('./main/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'tuto', loadChildren: () => import('./main/tuto/tuto.module').then((m) => m.TutoModule),
      },
      {
        path: 'entreprises', loadChildren: () => import('./main/entreprise/entreprise.module').then((m) => m.EntrepriseModule),
      },
      {
        path: 'evaluer-wegestu', loadChildren: () => import('./main/rate-wegestu/rate-wegestu.module').then((m) => m.RateWegestuModule),
      },
      {
        path: 'reviews', loadChildren: () => import('./main/reviews/reviews.module').then((m) => m.ReviewsModule),
      },
     
    ],
  },
  {
    path: 'test', loadChildren: () => import('./main/test/test.module').then((m) => m.TestModule),
  },

  { path: '', redirectTo: 'profil-candidat', pathMatch: 'full' },
  { path: '**', component: PageNotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
