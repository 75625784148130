import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../services/company.service';
import { environment } from '../../../../../environnements/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { serverError } from '../../../../shared/shared-models/message';

@Component({
  selector: 'app-details-company',
  templateUrl: './details-company.component.html',
  styleUrls: ['./details-company.component.css'],
})
export class DetailsCompanyComponent {
  /* object */
  esnDetails: any;
  reviewCompany: any;
  reviewCandidat: any;
  companyDetailsForm: any;
  /* string */
  id!: string;
  /* formGroup */
  // companyDetailsForm: FormGroup = this.createCompanyDetailsForm()
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  /* short url */
  siteweb_link: any;
  shortenedUrl = '';
  modalRef?: BsModalRef;
  url: string = environment.baseUrl + '/api';
  listReviewCompany: any;
  listDefaultReview: any;
  listCandidatReviewDetails: any;
  linkedinPrefix = 'https://www.linkedin.com/company/';
  /* number */
  totalItems!: number;
  itemsPerPage: number = 20;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  total_avg_stars: any;
  showBoxResponseCompany = false;
  showBoxDefaultResponse = true;
  pagination : boolean =false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit() {
    window.scrollTo(0, document.body.scrollHeight);
    this.getId();
    this.getAllDefaultReview(this.itemsPerPage, this.currentPage);
    window.scrollTo(0, 0);
  }

  /* get id esn from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.id = params.params['id'];
        this.getCompanyById();
        this.getReviewCompany();
        this.getReviewCandidat();
        this.getCandidatReviewDetails();
      },
    });
  }
  navigatToDetailsCandidat(id: any) {
    this.router.navigate(['/wegestu/consultant/detailsConsultant', { id: id }]);
  }
  contactResponsableNavigation(id: any, type:any) {
    this.router.navigate([
      '/wegestu/contact/responsable',
      { idResponsable: id, Type:type },
    ]);
  }
  voirOffreemploi(id: any) {
    this.router.navigate([
      '/wegestu/jobs/list-jobs-entreprise',
      { idResponsable: id },
    ]);
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
       if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getAllDefaultReview(this.itemsPerPage, this.currentPage);
        }
    }
  }
  redirectTo(url: string, isBlank: boolean): void {
    if (!url) {
      return;
    }

    if (isBlank) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  /* get company details */
  getCompanyById() {
    this.spinner.show();
    this.companyService
      .getCompanybyId(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.companyDetailsForm = res.data[0];
          console.log(
            '%cdetails-company.component.ts line:87 companyDetailsForm',
            'color: #007acc;',
            res.data
          );
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  getYearFromDate(date: string | Date): string {
    if (!date) return '---';
    const year = new Date(date).getFullYear();
    return isNaN(year) ? '---' : year.toString();
  }
  /* get All Default review */
  getAllDefaultReview(per_page: number, page: number) {
    this.companyService
      .getAllDefaultReview(per_page)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listDefaultReview = res.data.items?.slice(0, this.endIndex);
          this.totalItems = res.data.items?.total;
          this.listDefaultReview = res.data.items;
        },
        error: (err: any) => {
          console.log('error', err);
        },
      });
  }
  /* get all reviews company */
  getReviewCompany() {
    this.spinner.show();
    this.companyService
      .getReviewCompany(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.reviewCompany = res;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  openModalDetails(template: TemplateRef<any>, idDonneur: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    console.log('idDonneur', idDonneur);
    this.getCandidatReviewDetails(idDonneur);
  }

  closeModalDetails() {
    this.modalRef!.hide();
  }

  getCandidatReviewDetails(idDonneur?: any) {
    this.spinner.show();
    this.companyService
      .getCandidatReviewById(idDonneur, this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.spinner.hide();
            this.listCandidatReviewDetails = res;
            console.log(
              'listCandidatReviewDetails',
              this.listCandidatReviewDetails
            );
          }
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }
  /*get  candidat */
  getReviewCandidat() {
    this.spinner.show();
    this.companyService
      .getReviewCandidat(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.reviewCandidat = res.data.data;
          this.reviewCandidat = this.reviewCandidat;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
  reviewCompanyNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/list-jobs-entreprise', { idResponsable: id }])
  }
  reviewCompanyNavigation1(id: any, redirection:any) {
    this.router.navigate(['/wegestu/avis/review-company/', { idCompany: id , redirection:redirection}])
  }
}
