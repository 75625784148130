import { Component,ViewChild , TemplateRef , ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { ICountry, State } from 'country-state-city';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { JobsService } from '../../../../main/jobs/services/jobs.service';
import { PaysService } from '../../../../shared/services/pays.service';
import { environment } from '../../../../../environnements/environment';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { headerColumns3 } from '../../../../main/jobs/models/jobOffer';
import { MenuItem } from 'primeng/api';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  applySuccess,
  serverError,
} from '../../../../shared/shared-models/message';
@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.css'],
})
export class SuggestionsComponent {
  @ViewChild('send', { static: true }) send!: TemplateRef<any>;
  loadSpinner: boolean = false;
  idRequestJob!: any;
  safeDescription!: SafeHtml;
  mode: string = ''
  idJobs: any;
  jobRequest!: any;
  hasPassedTestEsn : boolean = false
  //state: any;
  postuled: any;
  currentUser: any;
  stateTest: any;
  id: any;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  totalJobs: any;
  ListSuggestions: any;
  isLoadSpinner: boolean = true;
  listNames: string[] = [];
  dataHeader: any = [];
  skillsList: { name_FR: string }[] = [];
  url: string = environment.baseUrl + '/api';
  stateList: any[] = [];
  currentSortColumn: string = 'esn_name';
  currentSortDirection: number = 2;
  pagination: boolean = false;
  applyForm: FormGroup = this.createForm()
  mobilities: any[] = [];
  countries: any[] = [];
  /* modal */
  modalRef?: BsModalRef;
  /* formGroup */
  searchForm: FormGroup = this.createSearchForm();
  translations: { [key: string]: string } = {
    Afghanistan: 'Afghanistan',
    'South Africa': 'Afrique du Sud',
    Albania: 'Albanie',
    Algeria: 'Algérie',
    Germany: 'Allemagne',
    Andorra: 'Andorre',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    'Saudi Arabia': 'Arabie saoudite',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    Belarus: 'Biélorussie',
    Bolivia: 'Bolivie',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    Brazil: 'Brésil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    Chile: 'Chili',
    China: 'Chine',
    Cyprus: 'Chypre',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    'Congo - Brazzaville': 'Congo-Brazzaville',
    'Congo - Kinshasa': 'Congo-Kinshasa',
    'North Korea': 'Corée du Nord',
    'South Korea': 'Corée du Sud',
    'Costa Rica': 'Costa Rica',
    'Ivory Coast': 'Côte d’Ivoire',
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Denmark: 'Danemark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'United Arab Emirates': 'Émirats arabes unis',
    Eritrea: 'Érythrée',
    Spain: 'Espagne',
    Eswatini: 'Eswatini',
    Estonia: 'Estonie',
    'United States': 'États-Unis',
    Ethiopia: 'Éthiopie',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Grenada: 'Grenade',
    Greenland: 'Groenland',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    'Equatorial Guinea': 'Guinée équatoriale',
    Guyana: 'Guyana',
    'French Guiana': 'Guyane française',
    Haiti: 'Haïti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    Iraq: 'Irak',
    Iran: 'Iran',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    Laos: 'Laos',
    Latvia: 'Lettonie',
    Lebanon: 'Liban',
    Lesotho: 'Lesotho',
    Liberia: 'Libéria',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'North Macedonia': 'Macédoine du Nord',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    Micronesia: 'Micronésie',
    Moldova: 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibie',
    Nauru: 'Nauru',
    Nepal: 'Népal',
    Netherlands: 'Pays-Bas',
    'New Caledonia': 'Nouvelle-Calédonie',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    Palestine: 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Romania: 'Roumanie',
    Russia: 'Russie',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint-Barthélemy',
    'Saint Helena': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
    'Saint Lucia': 'Sainte-Lucie',
    'Saint Martin': 'Saint-Martin',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten': 'Saint-Martin',
    Slovakia: 'Slovaquie',
    Slovenia: 'Slovénie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Georgia and the South Sandwich Islands':
      'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Swaziland: 'Eswatini',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    Syria: 'Syrie',
    Taiwan: 'Taïwan',
    Tajikistan: 'Tadjikistan',
    Tanzania: 'Tanzanie',
    Thailand: 'Thaïlande',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Kingdom': 'Royaume-Uni',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Viêt Nam',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Zimbabwe: 'Zimbabwe',
  };
  paysList: ICountry[] = [];
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  isCollapsed = true;
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  state = [
    { id: 0, name: 'Actif' },
    { id: 1, name: 'Inactif' },
  ];
  profile_abroad = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  passeport_talent = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  typeEntreprise = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de recrutement' },
    // { id: 3, name: 'Client final' },
  ];
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  type = [
    { id: 1, name: 'Alternance' },
    { id: 2, name: 'Autres type de contrat' },
    { id: 3, name: 'CDD' },
    { id: 4, name: 'CDI' },
    { id: 5, name: 'Freelance' },
    { id: 6, name: 'Portage salarial' },
    { id: 7, name: 'Stage' },
  ];
  poste = [
    { id: 1, name: 'à distance' },
    { id: 2, name: 'Hybrid' },
    { id: 3, name: 'sur site' },
  ];
  checkboxState: {
    [key: string]: { displayName: string; isChecked: boolean };
  } = {
      id: { displayName: 'id', isChecked: true },
      logo: { displayName: 'Logo', isChecked: true },
      societe: { displayName: 'Société', isChecked: true },
      type: { displayName: 'Type', isChecked: true },
      poste: { displayName: 'poste', isChecked: true },
      dateOfPublication: { displayName: 'DATE DE PUBLICATION', isChecked: true },
      experience: { displayName: 'ANNÉE EXPERIENCE', isChecked: true },
      typeOfContract: { displayName: 'TYPE DE CONTRACT', isChecked: true },
      salary: { displayName: 'SALAIRE ', isChecked: true },
      tjm: { displayName: 'TJM ', isChecked: true },
      typeOfPost: { displayName: 'TYPE DE POSTE', isChecked: true },
      skills: { displayName: 'COMPÉTENCES ', isChecked: true },
      scoreJobOffer: { displayName: 'SCORE ', isChecked: true },
      city: { displayName: 'PAYS ', isChecked: true },
      country: { displayName: 'VILLE ', isChecked: true },
      state: { displayName: 'STATUT ', isChecked: true },
    };
  sortAttr: string = '';
  constructor(private activatedRoute: ActivatedRoute,
    private jobService: JobsService,
    private jobOffreService: JobsService,
    private router: Router,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private paysServices: PaysService,
    private sortDataService: JobsService,
    private elRef: ElementRef,
    private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig,
    private sanitizer: DomSanitizer) 
   {

  }

  ngOnInit(): void {
   
    this.paysList = this.paysServices.getAllCountries();
    this.getListSuggestions();
   
    this.getAllSkills();
    this.searchForm.get('city')?.disable();
    this.getListElementHeader();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
  }
 
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    // if (dropdown) {
    //   dropdown.classList.remove('show');
    // }
  }
   
  
  createForm(data?: any) {
    return this.formBuilder.group({
      letter: [data ? data.letter : ''],
      salary: [data ? data.salary : ''],
      tjm: [data ? data.tjm : ''],
    });
  }
  getUser() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.stateTest = JSON.parse(this.currentUser).stateTest;
  }
   /* Open and close modal */
    openModal(
      template: TemplateRef<any>,
      idJobs: any,
      state: any,
      postuled: any
    ) {
      this.modalRef = this.modalService.show(template, { backdrop: 'static' });
      this.idJobs = idJobs;
      this.state = state;
      this.postuled = postuled;
      this.getIdRequestJob(this.idJobs);
      if (this.mode == 'apply') {
        this.applyCandidateJob(this.idJobs, this.state, this.postuled, this.mode);
      }
    }

      applyCandidateJob(idJobs: any, state: any, postuled: any, mode: string) {
        this.currentUser = this.localStorageService.getData('UserInfo');
        this.id = JSON.parse(this.currentUser).id;
        const data = { job_offer_id: idJobs, user_id: this.id };
        if (state === 0) {
          if (postuled === 0) {
            this.jobService
              .applyCandidateJobOffer({ ...data, ...this.applyForm.value })
              .subscribe({
                next: (res: any) => {
                  this.spinner.hide();
                  this.toastr.success(applySuccess);
                  this.getRequestJob();
                  
                  const index = this.ListSuggestions.findIndex((s: { id: any }) => s.id === idJobs);
                  
                  if (index !== -1) {
                    this.ListSuggestions = [
                      ...this.ListSuggestions.slice(0, index),
                      ...this.ListSuggestions.slice(index + 1),
                    ];
                  } 
                      
                  this.closeModal();
                  setTimeout(() => {
                    this.createTest();
                  }, 3000);
                },
                error: () => {
                  this.spinner.hide();
                  this.toastr.error(serverError);
                },
              });
          } else {
            this.toastr.error("Vous avez déjà postulé à cette offre d'emploi.");
          }
        } else {
          this.toastr.error("Cette offre d'emploi est actuellement désactivée.");
        }
      }
    closeModal() {
      this.modalRef!.hide();
      this.applyForm.reset();
    }
/* get request job by id */
getRequestJob() {
  this.spinner.show();
  this.jobOffreService.getJobOfferbyId(this.idRequestJob).subscribe({
    next: (res: any) => {
      this.spinner.hide()
      this.jobRequest = res.data[0]
      this.updateDescription(this.jobRequest.description);
    },
    error: () => {
      this.spinner.hide();
      this.toastrService.error(serverError);
    },
  });
}
updateDescription(description: string) {
  this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(description);
} 
deleteCandidatJobRequest(idJob: any, idUser: any): void {
  this.currentUser = this.localStorageService.getData('UserInfo');
  idUser = JSON.parse(this.currentUser).id;
  this.jobOffreService.deleteCandidatJobOffer(idJob, idUser).subscribe(
    (response: any) => {
      this.router.navigate(['/wegestu/jobs/candidatures']);
    },
    (error) => {
    }
  );
}
showDeleteCandidatJobConfirmation(idJob: any, idUser: any) {
    const title = this.jobRequest?.passed_test_esn ? 
    'Êtes-vous sûr de vouloir retirer cette candidature ? Vous n’êtes pas autorisé à repasser le test.' 
    : 'Êtes-vous sûr de vouloir retirer cette candidature ?'
    Swal.fire({
      title: title,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteCandidatJobRequest(idJob, idUser);
      }
    });
  }
     showTestConfirmation() {
        Swal.fire({
          title: 'Pourriez-vous passer un test pour cette offre d’emploi?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate([
              '/test/welcome-test',
              { idOffre: this.idRequestJob },
            ]);
          } else {
            
          }
        });
      }
      createTest() {
        const data = { job_offer_id: this.idRequestJob };
        this.jobOffreService.createTestEsn(data).subscribe({
          next: (res: any) => {
            this.spinner.hide();
            this.showTestConfirmation();
          },
          error: () => {
            this.spinner.hide();
          },
        });
      }
  getListSuggestions(sort?: any) {
    let dataPyload = this.sortAttr == '' ? { per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }
    this.spinner.show();
    this.jobService.getAllDefaultSuggestions(dataPyload).subscribe(
      (res: any) => {
        if (res?.data?.data instanceof Array) {
          this.ListSuggestions = res.data.data;
          this.ListSuggestions.sort((a: any, b: any) => {
            return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
          });
          this.ListSuggestions.forEach((suggestion: any) => {
          });
          this.totalJobs = res.data.total;

          this.ListSuggestions.sort((a: any, b: any) => {
            return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
          });

        } else {
          this.ListSuggestions = Object.values(res.data.data)
          this.totalJobs = res.data.total;
        }
        if (this.currentPage > 1 && this.ListSuggestions.length === 0) {
          this.currentPage = 1;
          this.getListSuggestions();
        }
        this.spinner.hide();
      },
      (error) => {
      }
    );
  }
  
  getIdRequestJob(idJobs?: any) {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idRequestJob = idJobs;
        this.getRequestJob();
      },
    });
  }
  translateCountryName(name: string): string {
    return this.translations[name] || name;
  }

  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchJobSuggestions();
      }
      else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListSuggestions();
      }
    }
  }
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_jobOffer: [data ? data.ID_jobOffer : ''],
      company_name: [data ? data.ens.name : null],
      name: [data ? data?.name : ''],
      skillsAnd: [data ? data.skillsAnd : ''],
      skillsOr: [data ? data.skillsOr : ''],
      years_of_experience: [data ? data.years_of_experience : ''],
      post_type: [data ? data.post_type : ''],
      contract_type: [data ? data?.contract_type : null],
      country: [data ? data?.country : ''],
      city: [data ? data?.city : ''],
      salary: [data ? data?.salary : ''],
      salary_range: [data ? data?.salary_range : ''],
      tjm: [data ? data?.tjm : ''],
      tjm_range: [data ? data?.tjm_range : ''],
      experience_range: data ? data?.experience_range : '',
      availability: [data ? data?.availability : ''],
      typeEntreprise: [data ? data?.typeEntreprise : ''],
      start_date: [data ? data.start_date : ''],
      end_date: [data ? data.end_date : ''],
      profile_abroad: [data ? data.profile_abroad : ''],
      passeport_talent: [data ? data.passeport_talent : ''],
      state: [data ? data.state : ''],
      date_candidature: [data ? data.date_candidature : ''],
      search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
    });
  }
  onSalaryRangeInput(event: any) {
    this.preventNegativeInput(event)
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.searchForm.controls['salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }

  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      return parseFloat(value.slice(0, -1)) * 1000;
    } else if (!isNaN(value)) {
      return parseFloat(value) * 1000;
    }
    return parseFloat(value);
  }
  transformSearch(search: string): string {
    search = search.replace(/\|\|/g, 'OR');
    search = search.replace(/&&/g, 'AND');
    search = search.replace(/!/g, 'NOT');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `"${escapedTerm}"`;
      }
    });
    return search;
  }
  isSimpleWord(value: string): boolean {
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchJobSuggestions() {
    let formattedEndDate = this.datePipe.transform(this.searchForm.value.end_date, 'yyyy-MM-dd');
    let formattedStartDate = this.datePipe.transform(this.searchForm.value.start_date, 'yyyy-MM-dd');
    let formattedDateCandidature = this.datePipe.transform(this.searchForm.value.date_candidature, 'yyyy-MM-dd');
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      name: this.searchForm.value.name,
      ID_jobOffer: this.searchForm.value.ID_jobOffer,
      years_of_experience: this.searchForm.value.years_of_experience,
      typeEntreprise: this.searchForm.value.typeEntreprise,
      company_name: this.searchForm.value.company_name,
      skillsAnd: this.searchForm.value.skillsAnd,
      skillsOr: this.searchForm.value.skillsOr,
      availability: this.searchForm.value.availability,
      contract_type: this.searchForm.value.contract_type,
      country: this.searchForm.value.country,
      city: this.searchForm.value.city,
      post_type: this.searchForm.value.post_type,
      tjm: this.searchForm.value.tjm,
      tjm_range: this.searchForm.value.tjm_range,
      salary: this.searchForm.value.salary,
      salary_range: this.convertKToNumber(
        this.searchForm.value.salary_range?.toString()
      ),
      experience_range: this.searchForm.value.experience_range,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      date_candidature: formattedDateCandidature,
      profile_abroad: this.searchForm.value.profile_abroad,
      passeport_talent: this.searchForm.value.passeport_talent,
      state: this.searchForm.value.state,
      search: this.transformSearch(this.searchForm.value.search),
    };

    this.spinner.show();

    this.jobService
      .searchSuggestion(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.isRecherche = true;
          if (res?.data?.data instanceof Array) {
            this.ListSuggestions = res.data.data.slice(0, this.endIndex);
          } else {
            this.ListSuggestions = Object.values(res.data.data).slice(
              0,
              this.endIndex
            );
          }
          this.spinner.hide();
        },
        error: (error: any) => {
          this.spinner.hide();
        },
      });
  }
  isRecherche: boolean = false;
  showStartDate: boolean = false;
  showEndDate: boolean = false;

  onDateInputStartDateChange(e: any) {
    this.showStartDate = true;
  }
  onDateInputEndDateChange(e: any) {
    this.showEndDate = true;
  }
 
  getMenuItems(suggestion: any): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        label: 'Voir Détails',
        command: () =>
          this.detailsJobOfferNavigation(suggestion?.id),
      }
    ];
    if (suggestion?.postuled === 0 && suggestion?.state === 0) {
      menuItems.push({
        label: 'Postuler',
        command: () =>
          this.applyOrNavigate( this.stateTest,suggestion,suggestion.state,suggestion.postuled,this.send)
      });
    }
    return menuItems;
  }

  navigateToTest() {
    this.router.navigate(['/wegestu/qcm']);
    this.toastr.info('Vous devez passer le test avant de postuler');
  }
  applyOrNavigate(stateTest: number, suggestion: any,state:any,postuled:any, template: TemplateRef<any>) {
    if (+stateTest === 0) {
      this.navigateToTest();
    } else {
      this.openModal(template, suggestion.id,suggestion.state,suggestion.postuled);
    }
  }
  reset() {
    this.showStartDate = false;
    this.showEndDate = false;
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListSuggestions();
    }
    return (this.isRecherche = false);
  }
  getListSuggestionsreset(sort?: any) {
    this.jobService.getAllDefaultSuggestions().subscribe(
      (res: any) => {
        if (res?.data?.data instanceof Array) {
          this.ListSuggestions = res.data.data.slice(0, this.endIndex);
          this.totalJobs = res.data.total;
        } else {
          this.ListSuggestions = Object.values(res.data.data).slice(
            0,
            this.endIndex
          );
          this.totalJobs = res.data.total;
        }
        if (this.currentPage > 1 && this.ListSuggestions.length === 0) {
          this.currentPage = 1;
          this.getListSuggestions();
        }
      },
      (error) => {
      }
    );
  }

  /* change country select and delete the string Governorate from state name*/
  onCountryChange(mode: string): void {
    this.searchForm.get('city')?.enable();
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.searchForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.searchForm.get('city')?.disable();
        this.searchForm.get('city')?.setValue(null);
      }
    }
  }

  oncityChange(mode: string) {
    this.mobilities = this.searchForm.get('city')?.value;
    let paysLists: any[] = [];
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    this.mobilities = this.searchForm.get('city')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }
  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }

    return duplicates;
  }


  headerColumns: { checked: boolean; name: string; code: string }[] =
  headerColumns3;
getSortName(columnName: string): string {
  switch (columnName) {
    case 'ID':
      return 'ID_jobOffer';
    case 'Nom entreprise':
      return 'esn_name';
    case 'Type entreprise':
      return 'type';
    case 'Poste':
      return 'name';
    case 'Date de publication':
      return 'start_date';
    case 'Date de candidature':
      return 'candidate_job_offer_creation_date';  
    case 'Années d’expériences':
      return 'years_of_experience';
    case 'Type de contrat':
      return 'contract_type';
    case 'Type de poste':
      return 'post_type';
    case 'Disponibilité':
      return 'availability';
    case 'scoreJobOffer':
      return 'Score';
    case 'TJM':
      return 'tjm';
    case 'Salaire':
      return 'salary';
    case 'Compétence':
      return 'skills';
    case 'Pays':
      return 'country';
    case 'Ville':
      return 'city';
    case 'Statut':
      return 'state';

    default:
      return columnName;
  }
}

changeSelection(event: any, data: any, i: number) {
  this.listNames = [];
  data.checkAdmin = event.target.checked;
  for (const iterator of this.dataHeader) {
    if (iterator.checkAdmin) this.listNames.push(iterator.name);
  }
  this.changeSelectionHeadrElements(data);
}
changeSelectionHeadrElements(data: any) {
  let payload = {
    sub_page_id: data?.id,
  };
  this.jobService
    .changeDelectedElementHeader(payload)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: () => {},
      error: () => {},
    });
}


getListElementHeader() {
  this.jobService
    .getHeaderElements(27)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: (res) => {
        this.isLoadSpinner = false;
        this.dataHeader = res.data;
        for (const iterator of this.dataHeader) {
          if (
            (iterator.checked == 'oui' && !iterator.checked_user) ||
            (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
            (iterator.checked == 'oui' && iterator.checked_user == 'oui')
          )
            this.listNames.push(iterator.name);
        }
        this.dataHeader.map((el: any) => {
          if (
            (el.checked == 'oui' && !el.checked_user) ||
            (el.checked_user == 'oui' &&
              (el.checked == 'non' || el.checked == 'oui'))
          ) {
            return (el.checkAdmin = true);
          } else {
            return (el.checkAdmin = false);
          }
        });
      },
      error: () => {},
    });
}

  getAllSkills() {
    this.jobService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.skillsList = res.data;
        },
        error: () => {
        },
      });
  }
  filterColumns(columnName: string): boolean {
    return this.checkboxState[columnName]?.isChecked || false;
  }
  getSkillsArray(suggestion: any): string[] {
    if (suggestion && suggestion.skills) {
      return suggestion.skills.split(',').map((s: string) => s.trim());
    } else {
      return [];
    }
  }
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute
    this.currentPage = 1;
    this.endIndex = 5;
    if (this.searchForm.dirty) this.ListSuggestions = this.sortDataService.sortArray(this.ListSuggestions, sortAttribute, this.currentSortDirection)
    else this.getListSuggestions()
  }
  detailsJobOfferNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }

  detailsJobRequestNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }
  getCheckboxStateEntries(): [
    string,
    { displayName: string; isChecked: boolean }
  ][] {
    return Object.entries(this.checkboxState);
  }
  updateCheckboxState(columnName: string): void {
    const checkbox = this.checkboxState[columnName];
    if (checkbox) {
      checkbox.isChecked = !checkbox.isChecked;
    }
  }
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));

  }

  // @TOFIX: duplicated  
/**
 * Prevents negative numbers or invalid characters in input fields.
 *
 * @param event - The input event triggered by the user.
 */
  preventNegativeInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.type === 'number') {
      const value = inputElement.value;
        inputElement.value = value.replace(/-/g, '');
    }
    if (inputElement.type === 'text') {
      inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
    }
  }
}

