import { Component, DebugElement } from '@angular/core';
import { CandidateService } from '../../main/dossier/services/candidate.service';
import { ServiceCraService } from '../../main/cra/services/service-cra.service';
import { LocalStorageService } from '../../shared/shared-services/local-storage.service';
import { environment } from '../../../environnements/environment';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  listHistorty: any;
  currentUser: any;
  first_name: any;
  last_name: any;
  image_profile: any;
  picture: any;
  roleUser: any;
  user: any;
  id: any;
  base_url_img = environment.baseUrlGalery;
  isDisabled = true;
  AllPermissions: any
  isMenuJobOfferOpen: boolean = false;
  isMenuActivityOpen: boolean = false;
  isMenuEvaluationOpen: boolean = false;
  isMenuReviewOpen: boolean = false;
  isMenuWegestUOpen: boolean = false;

  constructor(
    private candidateService: CandidateService,
    private serviceCra: ServiceCraService,
    private localStorageService: LocalStorageService,
    private subscriptionService: SubscriptionService,
  ) {
    this.currentUser = this.localStorageService.getData('UserInfo');

    if (!this.currentUser) {
      console.error('User information is not available.');
      return;
    }
    try {
      const userData = JSON.parse(this.currentUser);
      this.id = userData.id;
      this.first_name = userData.first_name;
      this.last_name = userData.last_name;
      this.picture = userData.image_url;
      this.roleUser = userData.role_user_id;
      this.image_profile = this.base_url_img + userData.image_url;
    } catch (error) {
      console.error('Error parsing user information:', error);
    }
  }

  ngOnInit() {
    this.get_profile_user();
    this.getListPermissions();
     this.getlistHistory();
  }

  toggleMenuJobOffer() {
    this.isMenuJobOfferOpen = !this.isMenuJobOfferOpen;
  }
  toggleMenuActivity() {
    this.isMenuActivityOpen = !this.isMenuActivityOpen;
  }
  toggleMenuEvaluation() {
    this.isMenuEvaluationOpen = !this.isMenuEvaluationOpen;
  }
  toggleMenuReview() {
    this.isMenuReviewOpen = !this.isMenuReviewOpen;
  }
  toggleMenuWegestU() {
    this.isMenuWegestUOpen = !this.isMenuWegestUOpen;
  }
 
  getListPermissions() {
    this.candidateService
      .getAllPermissionsByUser()
      .subscribe({
        next: (res: any) => {
          console.log('get All Permissions By User', res);
          this.AllPermissions = res.data
        },
        error: () => {
        },
      });
  }
  hasPermission(permissionName: string): boolean {
    return this.AllPermissions?.some((perm: any) => perm.name === permissionName);
  }

  get_profile_user() {
    this.candidateService.getCandidateProfil(this.id).subscribe({
      next: (res) => {
        this.user = res.data;
        localStorage.setItem("user",JSON.stringify(this.user))
        this.image_profile = this.base_url_img + this.user.image_url;
      },
      error: (e: any) => {
        console.log('get user by id error ===== ', e);
      },
      complete: () => {
        console.info('complete');
      },
    });
  }
  isConsultant(): boolean {
    return (
      this.user?.role_user_id?.includes('3') &&
      this.user?.role_user_id?.includes('4')
    );
  }
  isExternalConsultant(): boolean {
    return (
      this.user?.role_user_id?.includes('3') &&
      this.user?.role_user_id?.includes('5')
    );
  }
  isEmployee(): boolean {
    return (
      this.user?.role_user_id?.includes('3') &&
      this.user?.role_user_id?.includes('6')
    );
  }
  isCandidat(): boolean {
    return this.user?.role_user_id?.includes('3');
  }

  subscriptionCanAccess(featureName: string): boolean {
    return this.subscriptionService.subscriptionCanAccess(featureName);
  }
  
  unSubscribedEsn(): boolean {
    return this.subscriptionService.unSubscribedEsn();
  }

 getlistHistory() {
    this.serviceCra.getHistoryCompany().subscribe({
      next: (res) => {
        this.listHistorty= res?.data.data || [];
      },
      error: (err) => {
        console.error('Error fetching history company:', err);
      }
    });
  }
}
