import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/shared-services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SubscribedEsnGuard implements CanActivate {
  constructor( 
    private router: Router,
    private _localStorageService : LocalStorageService
  ) {}

  canActivate(): boolean {

    const data = this._localStorageService.getData('subscription');
    const activeSubscription = data ? JSON.parse(data) : null;
        if (activeSubscription) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
  }
}
