<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="34" height="28" viewBox="0 0 34 28" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_390_1260)">
                            <path
                                d="M19.101 12.5953C18.4572 13.0232 17.7094 13.2494 16.9384 13.2494C16.1674 13.2494 15.4196 13.0232 14.7758 12.5953L4.1723 5.54654C4.11347 5.50742 4.05614 5.46665 4 5.42473V16.9749C4 18.2991 5.07778 19.3501 6.38213 19.3501H27.4946C28.8227 19.3501 29.8767 18.2755 29.8767 16.9749V5.42468C29.8204 5.4667 29.763 5.50758 29.704 5.54672L19.101 12.5953Z"
                                fill="#265D91" />
                            <path
                                d="M5.01334 4.28863L15.6169 11.3374C16.0183 11.6043 16.4783 11.7377 16.9383 11.7377C17.3984 11.7377 17.8585 11.6042 18.2598 11.3374L28.8634 4.28863C29.4979 3.86707 29.8767 3.16157 29.8767 2.4001C29.8767 1.09081 28.8085 0.0256958 27.4954 0.0256958H6.38132C5.06828 0.0257471 4 1.09086 4 2.40136C4 3.16157 4.37886 3.86707 5.01334 4.28863Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_390_1260" x="0" y="0.0256958" width="33.877" height="27.3244"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_1260" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_1260"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>

                    Messages
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted"
                        routerLinkActive="router-link-active">Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Messages</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Layout-->
        <div class="d-flex flex-column flex-lg-row bg-chat">
            <!--begin::Sidebar-->
            <div class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                <!--begin::Contacts-->
                <div class="card left-side card-flush">
                    <div class="card-header pt-7" id="kt_chat_contacts_header">
                        <ul class="nav nav-tabs nav-line-tabs  fs-6">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#Messages" (contextmenu)="onRightClick($event)">Messages</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#Utilisateurs" (contextmenu)="onRightClick($event)">Utilisateurs</a>
                            </li>
                        </ul>
                    </div>

                    <!--begin::Card body-->
                    <div class="card-body pt-5" id="kt_chat_contacts_body">
                        <!--begin::List-->
                        <div class="me-n5 pe-5  h-lg-auto ">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade  active show" id="Messages" role="tabpanel">
                                    <!--begin::Form-->
                                    <form class="w-100 position-relative  mb-5" autocomplete="off">
                                        <!--begin::Icon-->
                                        <i
                                            class="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                        <!--end::Icon-->
                                        <!--begin::Input-->
                                        <input 
                                            type="text" 
                                            class="form-control form-control-solid px-13" 
                                            name="search"
                                            placeholder="Rechercher dans les messages"
                                            (input)="onSearchInputMessage($event)" 
                                        />
                                        <!--end::Input-->
                                    </form>
                                    <!--end::Form-->
                                    <div class="scroll-y me-n5 pe-5  h-lg-auto chat_contacts" 
                                    *ngIf="ListMessages?.length > 0; else noMessages"
                                    >
                                        <div class="list_users py-4" *ngFor="let conversation of ListMessages">
                                            <!--begin::Details-->
                                            <div class="d-flex flex-stack startConvertion"
                                                (click)="userSelected(conversation?.user)">
                                                <div class="d-flex align-items-center">
                                                    <!--begin::Avatar-->
                                                    <div class="symbol symbol-45px symbol-circle">
                                                        <img *ngIf="conversation?.user?.image_url; else noImageSender"
                                                                src="{{ getListMessageImage(conversation?.user) }}" alt="" 
                                                        />
                                                        <ng-template #noImageSender>
                                                          <span
                                                            class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                            {{conversation?.user?.first_name?.charAt(0) | uppercase}} {{conversation?.user?.last_name?.charAt(0) | uppercase}}
                                                          </span>
                                                        </ng-template>
                                                        
                                                    </div>
                                                    <!--end::Avatar-->
                                                    <!--begin::Details-->
                                                    <div class="ms-5">
                                                        <a (click)="userSelected(conversation?.user)"
                                                            class="list-name  mb-2">{{conversation?.user?.first_name}} {{conversation?.user?.last_name}} 
                                                            ({{ getCompanyName(conversation?.user) }})
                                                        </a>
                                                        <div class="list-mail">{{conversation?.chats[0]?.message}}</div>
                                                    </div>
                                                </div>
                                                <div class="role-user" >
                                                    <p style="margin: 2px;">{{conversation?.user?.Role ?
                                                        conversation?.user?.Role : '---'}}</p>
                                                </div>
                                                <!--end::Details-->
                                            </div>
                                            <!--end::Details-->
                                        </div>
                                        <mat-paginator
                                            [length]="totalPagesMessage"
                                            [pageSize]="pageSizeMessage"
                                            [showFirstLastButtons]="true"
                                            [pageSizeOptions]="pageSizeOptions"
                                            [pageIndex]="pageIndexMessage"
                                            (page)="onPageChange($event, 'M')"
                                            aria-label="Select page">
                                        </mat-paginator>
                                    </div>
                                    <ng-template #noMessages>
                                        <div class="alert alert-warning text-center mt-5" role="alert">
                                        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                        <span class="empty-list">
                                            Créez un espace pour discuter et collaborer
                                        </span>
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="tab-pane fade" id="Utilisateurs" role="tabpanel">
                                    <!--begin::Form-->
                                    <form class="w-100 position-relative  mb-5" autocomplete="off">
                                        <!--begin::Icon-->
                                        <i
                                            class="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                                            <span class="path1"></span>
                                            <span class="path2"></span>
                                        </i>
                                        <!--end::Icon-->
                                        <!--begin::Input-->
                                        <input 
                                            type="text" 
                                            class="form-control form-control-solid px-13" 
                                            name="search"
                                            placeholder="Rechercher dans les messages"
                                            (input)="onSearchInputUser($event)" 
                                            [(ngModel)]="searchUserText"
                                        />
                                        <!--end::Input-->
                                    </form>
                                    <!--end::Form-->
                                    <div class="scroll-y me-n5 pe-5  h-lg-auto chat_contacts">
                                        <div *ngIf="dataEmployeeWithCompany?.length > 0; else noUsers">
                                            <div class="list_users py-4" *ngFor="let user of dataEmployeeWithCompany">
                                                <!--begin::Details-->
                                                <div class="d-flex flex-stack startConvertion"
                                                    (click)="userSelected(user)">
                                                    <div class="d-flex align-items-center">
                                                    <!--begin::Avatar-->
                                                        <div class="symbol symbol-45px symbol-circle">
                                                            <img *ngIf="user?.image_url || user?.employee?.user?.image_url; else noImageUser"
                                                                src="{{ getUserImage(user) }}" alt="" 
                                                            />
                                                            <ng-template #noImageUser>
                                                                <span class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                    {{user?.first_name?.charAt(0) | uppercase}} {{user?.last_name?.charAt(0) | uppercase}}
                                                                </span>
                                                            </ng-template>
                                                                
                                                        </div>
                                                        <!--end::Avatar-->
                                                        <!--begin::Details-->
                                                        <div class="ms-5">
                                                            <a class="list-name  mb-2">{{user?.first_name}} {{user?.last_name}}
                                                                ({{ getCompanyName(user) }})
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="role-user">
                                                        <p style="margin: 2px;">{{user?.Role ? user?.Role:'---'}}</p>
                                                    </div>
                                                    <!--end::Details-->
                                                </div>
                                                <!--end::Details-->
                                            </div>
                                        </div>
                                       <div>
                                        <mat-paginator
                                            [length]="totalPagesUser"
                                            [pageSize]="pageSizeUser"
                                            [showFirstLastButtons]="true"
                                            [pageSizeOptions]="pageSizeOptions"
                                            [pageIndex]="pageIndexUser"
                                            (page)="onPageChange($event, 'U')"
                                            aria-label="Select page">
                                        </mat-paginator>
                                       </div>
                                    </div>

                                    <ng-template #noUsers>
                                        <div class="alert alert-warning text-center mt-5" role="alert">
                                        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                        <span class="empty-list">
                                            Prenez contact dès maintenant et démarrez la conversation !
                                        </span>
                                    </div>
                                    </ng-template>
                                </div>

                            </div>
                        </div>
                        <!--end::List-->
                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Contacts-->
            </div>
            <!--end::Sidebar-->
            <!--begin::Content-->
            <div class="flex-lg-row-fluid w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                <!--begin::Messenger-->
                <div class="alert alert-warning mt-5" role="alert"
                    *ngIf="currentConversationUserId === null || !selectedUser">
                    <span class="empty-list">
                        Sélectionner une discussion
                    </span>
                </div>
                <div class="card" id="kt_chat_messenger" *ngIf="currentConversationUserId !== null && selectedUser">
                    <!--begin::Card header-->

                    <div class="card-header" id="kt_chat_messenger_header">
                        <!--begin::Title-->
                        <div class="card-title">
                            <!--begin::User-->
                            <!-- <div class="d-flex justify-content-center flex-column me-3">
                                <a class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">Brian Cox</a>
                                <div class="mb-0 lh-1">
                                    <span class="badge badge-success badge-circle w-10px h-10px me-1"></span>
                                    <span class="fs-7 fw-semibold text-muted">Active</span>
                                </div>
                            </div> -->
                            <!--end::User-->



                            <div class="d-flex flex-stack py-4">
                                <!--begin::Details-->
                                <div class="d-flex align-items-center">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-45px symbol-circle">
                                        <img *ngIf="selectedUser?.image_url || selectedUser?.employee?.user?.image_url; else noImage"
                                            src="{{ getUserImage(selectedUser) }}" alt="" 
                                        >
                                        <ng-template #noImage>
                                            <div class="symbol symbol-45px symbol-circle">
                                                <span class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                    {{selectedUser?.first_name?.charAt(0) | uppercase}} {{selectedUser?.last_name?.charAt(0) | uppercase}} 
                                                </span>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Details-->
                                    <div class="ms-5">
                                        <a class="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                            {{ selectedUser?.first_name }} {{ selectedUser?.last_name }}  
                                            ({{ getCompanyName(selectedUser) }})
                                        </a>
                                    </div>
                                    <!--end::Details-->
                                </div>
                                <!--end::Details-->
                            </div>
                        </div>
                        <!--end::Title-->
                        <div class="delete_icon">
                            <a class="btn-trash" (click)="deleteConversation(currentConversationUserId)">
                                <i class="fa fa-trash delete_btn" aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body height-msg" id="kt_chat_messenger_body">
                        <!--begin::Messages-->
                        <div class="scroll">
                            <div class=" pe-5 " *ngFor="let message of messages">
                                <!--begin::Message(out)-->
                                <div class="d-flex justify-content-end mb-5">
                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-column align-items-end"
                                        *ngIf="currentConversationUserId !== null && message?.user_id === currentConversationUserId ">
                                        <!--begin::User-->
                                        <div class="d-flex align-items-center mb-2">
                                            <!--begin::Details-->
                                            <div class="me-3">
                                                <span class="time-sent mb-1">{{ message?.elapsedTime }}</span>


                                                <a href="#" class="send-name ms-1">{{ message?.emitter?.first_name}} {{ message?.emitter?.last_name}}</a>
                                            </div>
                                            <!--end::Details-->
                                            <!--begin::Avatar-->
                                            <div class="symbol symbol-35px symbol-circle">
                                                <img *ngIf="message?.emi_image_url; else noImageEmitter"
                                                    src="{{ getSenderImage(message) }}"
                                                    alt="" 
                                                />
                                                <ng-template #noImageEmitter>
                                                    <div class="symbol symbol-45px symbol-circle">
                                                        <span class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">+++
                                                            {{message?.emitter?.first_name?.charAt(0) | uppercase}} {{message?.emitter?.last_name?.charAt(0) | uppercase }}
                                                        </span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                            <!--end::Avatar-->
                                        </div>
                                        <!--end::User-->
                                        <!--begin::Text-->
                                        <div class="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end"
                                            data-kt-element="message-text">{{message?.message}}</div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>
                                <!--end::Message(out)-->
                                <!--begin::Message(in)-->
                                <div class="d-flex justify-content-start mb-5">
                                    <!--begin::Wrapper-->
                                    <!-- && messageRecived?.user_id === currentConversationUserId" -->
                                    <div class="d-flex flex-column align-items-start"
                                        *ngIf="currentConversationUserId !== null && message?.user_id === currentUser?.id">
                                        <!--begin::User-->
                                        <div class="d-flex align-items-center mb-2">
                                            <!--begin::Avatar-->
                                            <div class="symbol symbol-35px symbol-circle">
                                                <img *ngIf="message?.emi_image_url; else noImage"
                                                    src="{{ getSenderImage(message) }}"
                                                    alt="" 
                                                />

                                                <ng-template #noImage>
                                                    <div class="symbol symbol-45px symbol-circle">
                                                        <span class="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                            {{message?.emitter?.first_name?.charAt(0) | uppercase}} {{message?.emitter?.last_name?.charAt(0) | uppercase }}
                                                        </span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                            <!--end::Avatar-->
                                            <!--begin::Details-->
                                            <div class="ms-3">
                                                <a href="#" class="send-name  me-1">{{message?.emitter?.first_name}}
                                                    {{message?.emitter?.last_name}}</a>
                                                <span class="time-sent  mb-1">{{message?.elapsedTime}}</span>
                                            </div>
                                            <!--end::Details-->
                                        </div>
                                        <!--end::User-->
                                        <!--begin::Text-->
                                        <div class="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                                            data-kt-element="message-text">{{message?.message}}</div>
                                        <!--end::Text-->
                                    </div>
                                    <!--end::Wrapper-->
                                </div>
                                <!--end::Message(in)-->
                            </div>
                        </div>

                        <!--end::Messages-->
                    </div>
                    <!--end::Card body-->
                    <!--begin::Card footer-->
                    <div class="card-footer pt-4" id="kt_chat_messenger_footer">
                        <!--begin::Input-->
                        <div class="send_msg">
                            <textarea class="form-control form-control-flush mb-3" [(ngModel)]="newMessage"
                                placeholder="Entrez votre message"></textarea>
                            <!--end::Input-->
                            <!--begin:Toolbar-->
                            <div class="d-flex flex-stack">
                                <!--begin::Actions-->
                                <div class="d-flex align-items-center me-2">

                                </div>
                                <!--end::Actions-->
                                <!--begin::Send-->
                                <button class="btn btn-primary send_btn" type="button"
                                    (click)="sendMessage()">Envoyer</button>
                                <!--end::Send-->
                            </div>
                        </div>
                        <!--end::Toolbar-->
                    </div>
                    <!--end::Card footer-->
                </div>
                <!--end::Messenger-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Layout-->
    </div>
    <!--end::Content container-->
</div>
<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>