<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="41" height="38" viewBox="0 0 41 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_458_5200)">
                            <path
                                dRecherche="M16.9689 5.94653V7.75614H16.7748C16.2766 7.75614 15.8711 7.35065 15.8711 6.85242C15.8711 6.35418 16.2766 5.94869 16.7748 5.94869L16.9689 5.94653Z"
                                fill="#265D91" />
                            <path
                                d="M24.6348 4.13905V5.46552C24.5054 5.4267 24.3674 5.40729 24.225 5.40729H24.0309V4.99748C24.0309 4.93062 24.0072 4.86591 23.9619 4.81631L22.6333 3.33239C22.5837 3.27631 22.5147 3.24395 22.4392 3.2418C22.3658 3.23964 22.2925 3.26768 22.2407 3.3216C20.9099 4.65239 17.9982 4.72788 17.3684 4.72788C17.2929 4.72788 17.2498 4.72788 17.2454 4.72572C17.1721 4.72356 17.1009 4.7516 17.0492 4.80121C16.9974 4.85297 16.9672 4.92199 16.9672 4.99533V5.40513H16.7731C16.6307 5.40513 16.4927 5.4267 16.3633 5.46337V4.1714C16.3633 3.05631 16.8033 2.00591 17.5992 1.21003C18.3864 0.427091 19.4196 -0.00428168 20.5131 3.20428e-05C22.7864 0.0108164 24.6348 1.86572 24.6348 4.13905Z"
                                fill="#265D91" />
                            <path
                                d="M24.2254 5.94649C24.7236 5.94649 25.1291 6.35198 25.1291 6.85022C25.1291 7.34845 24.7236 7.75394 24.2254 7.75394H24.0312V5.94434L24.2254 5.94649Z"
                                fill="#265D91" />
                            <path
                                d="M23.491 5.67687V7.95021C23.491 9.60021 22.1494 10.9418 20.4994 10.9418C18.8494 10.9418 17.5078 9.60021 17.5078 7.95021V5.67687V5.26923C17.8637 5.26491 18.5215 5.23903 19.2786 5.1204C20.6525 4.90472 21.7051 4.49491 22.4125 3.89746L23.491 5.10099V5.67687Z"
                                fill="#265D91" />
                            <path
                                d="M20.1567 14.4726H13.4531C13.8112 14.1599 14.2318 13.9248 14.6933 13.7824L18.539 12.5961L20.1567 14.4726Z"
                                fill="#265D91" />
                            <path
                                d="M22.1012 11.097V12.1884L20.5008 14.0433L18.9004 12.1884V11.097C19.3814 11.3429 19.9249 11.481 20.5008 11.481C21.0767 11.481 21.6202 11.3429 22.1012 11.097Z"
                                fill="#265D91" />
                            <path
                                d="M27.5473 14.4726H20.8438L22.4636 12.5961L26.3071 13.7846C26.7665 13.9248 27.1871 14.1599 27.5473 14.4726Z"
                                fill="#265D91" />
                            <path
                                d="M30.3398 15.152C30.4239 15.2404 30.4627 15.3569 30.4541 15.4799L29.6776 25.8565C29.6604 26.083 29.4706 26.2577 29.2441 26.2577H11.7541C11.5276 26.2577 11.3378 26.0808 11.3206 25.8565L10.5441 15.4799C10.5355 15.3569 10.5765 15.2426 10.6584 15.152C10.7425 15.0614 10.8547 15.014 10.9776 15.014H12.8153H28.1808H30.0184C30.1435 15.014 30.2557 15.0636 30.3398 15.152ZM20.7698 21.1848C21.7059 21.0532 22.4284 20.2465 22.4284 19.2738C22.4284 18.2104 21.5635 17.3455 20.5002 17.3455C19.4368 17.3455 18.5719 18.2104 18.5719 19.2738C18.5719 19.4226 18.6927 19.5434 18.8416 19.5434C18.9904 19.5434 19.1112 19.4226 19.1112 19.2738C19.1112 18.5081 19.7345 17.8848 20.5002 17.8848C21.2659 17.8848 21.8892 18.5081 21.8892 19.2738C21.8892 20.0395 21.2659 20.6628 20.5002 20.6628C20.3514 20.6628 20.2306 20.7836 20.2306 20.9324V22.4897C20.2306 22.6385 20.3514 22.7593 20.5002 22.7593C20.649 22.7593 20.7698 22.6385 20.7698 22.4897V21.1848ZM20.869 23.6673C20.869 23.4775 20.7159 23.3244 20.5261 23.3244C20.3363 23.3244 20.1831 23.4775 20.1831 23.6673C20.1831 23.8571 20.3363 24.0103 20.5261 24.0103C20.7159 24.0103 20.869 23.855 20.869 23.6673Z"
                                fill="#265D91" />
                            <path
                                d="M37 29.4693V29.9611H4V29.4693C4 28.0026 5.19275 26.8099 6.65941 26.8099H34.3406C35.8073 26.8077 37 28.0026 37 29.4693Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_458_5200" x="0" y="0" width="41" height="37.9611"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_458_5200" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_458_5200"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>


                    Consultants
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Liste des consultants</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
            <div class="card-body tools">
                <div class="title mb-10">
                    <h4>Recherche</h4>
                </div>
                <div [formGroup]="searchForm">
                    <div class="row">
                        <div class="col-lg-6 mb-5">
                         
                          <div class="input-group search-libre">
                  
                            <span class="input-group-text">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z" fill="#7E7C7C" />
                              </svg>
                            </span>
                            <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid ps-5"
                            placeholder="Rechercher" formControlName="search"  (keyup.enter)="searchConsultant()"
                            />
                         
                       
                            <div class="tooltip-container tooltip-info">
                              <div class="tooltip-trigger"><i class="fa-solid fa-circle-info" style="font-size: 20px;"></i></div>
                              <div class="tooltip-content">
                                <div class="content">
                                  <h4>Opérateur AND :</h4>
                                  <p>Utilisez l'opérateur AND pour spécifier que les résultats doivent inclure plusieurs termes.
                                  </p>
                                  <p>Exemple : software AND engineer renverra des profils qui mentionnent à la fois "software" et "engineer".
                                  </p>
                                  <h4>Opérateur OR :</h4>
                                  <p>Utilisez l'opérateur OR pour spécifier que les résultats peuvent inclure l'un des termes.
                                  </p>
                                  <p>Exemple : sales OR marketing renverra des profils qui mentionnent soit "sales" soit "marketing".
                                  </p>
                                  <h4>Opérateur NOT :</h4>
                                  <p>Utilisez l'opérateur NOT pour exclure des termes spécifiques des résultats.
                                  </p>
                                  <p>Exemple : developer NOT manager renverra des profils qui mentionnent "developer" mais pas "manager".
                                  </p>
                                  <h4>Guillemets pour une expression exacte :</h4>
                                  <p>Utilisez des guillemets pour rechercher une expression exacte.
                                  </p>
                                  <p>Exemple : "product manager" renverra des profils qui mentionnent exactement "product manager".
                                  </p>
                                  <h4>Parenthèses pour grouper des opérations :</h4>
                                  <p>Utilisez des parenthèses pour définir l'ordre d'évaluation des opérations.
                                  </p>
                                  <p>Exemple : (sales OR marketing) AND manager renverra des profils qui mentionnent "sales" ou "marketing" et "manager".
                                  </p>
                                  <h4>Recherche standard :</h4>
                                  <p>Si aucun opérateur n'est spécifié dans la recherche, le texte sera interprété comme s'il était entre guillemets.
                                  </p>
                                  <p>Exemple : La recherche product manager sera traitée comme "product manager", suivant la règle 4.
                                  </p>
                                </div>
                              </div>
                            </div>
                         
                          </div>
                          <!-- <div class="text-danger mt-2"
                          *ngIf="searchForm.get('search')?.hasError('pattern') && searchForm.get('search')?.touched && isSimpleWord(searchForm.get('search')?.value)">
                          Le modèle de recherche est invalide. Utilisez le format correct (par exemple "hello" OR "hello").
                        </div> -->
                        </div>
                       
                      </div>
                    <div class="row mb-3">
                        <div class="col-lg-3">
                                <input type="text" name="desired_workstation"  class="form-control form-control-solid mb-3"
                                placeholder="Poste" id="desired_workstation" formControlName="desired_workstation">
                        </div>
                      
                        <div class="col-lg-3 mb-3">
                            <ng-select [items]="skillsList" bindLabel="name_FR" bindValue="name_FR" [multiple]="true" id="skillsList"
                            placeholder="Comptétences (ou)" formControlName="skillsOr" [notFoundText]="'Aucun élément trouvé'">
                           </ng-select>
                        </div>
                        <div class="col-lg-3 mb-3">
                            <ng-select [items]="skillsList" bindLabel="name_FR" bindValue="name_FR" [multiple]="true" id="skillsList"
                            placeholder="Comptétences (et)" formControlName="skillsAnd" [notFoundText]="'Aucun élément trouvé'">
                        </ng-select>
                        </div>
                        <div class="col-lg-3">
                            <input type="number" name="min_experience_years" formControlName="min_experience_years" id="min_experience_years"
                                class="form-control form-control-solid mb-3" placeholder="Expérience min" min="0" max="50"
                                oninput="validity.valid||(value='');">
                    </div>
                   
                    </div>
                    <div class="row mb-3" *ngIf="!isCollapsed">
                        <div class="col-lg-3">
                            <input type="number" name="max_experience_years" formControlName="max_experience_years" id="max_experience_years"
                                class="form-control form-control-solid mb-3" placeholder="Expérience max" min="0" max="50"
                                oninput="validity.valid||(value='');">
                        </div>
                        <div class="col-lg-3">
                            <div class="combined-input mb-3">
                                <input type="number" name="number1" class="nb1" placeholder="TJM" id="tjm" formControlName="tjm">
                                <div class="euro">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z" fill="#4B4B4B"/>
                                        </svg>
                                        
                                </div>
                                <input type="number" name="number2" class="nb2" value="1" id="marge_tjm" formControlName="tjm_range">
                               <div class="icon">
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z" fill="#4B4B4B"/>
                                    </svg>
                                    
                               </div>
                               <div class="plusetmois">
                                <i class="fa-solid fa-plus-minus"></i>
                               </div>
                                    
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="combined-input  mb-3">
                                <input type="number" name="number1" class="nb1" placeholder="Salaire" id="Salaire" formControlName="desired_salary">
                                <div class="euro">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z" fill="#4B4B4B"/>
                                        </svg>
                                        
                                </div>
                                <input type="number" name="number2" class="nb2" value="1" id="salary_range" formControlName="desired_salary_range" (input)="onSalaryRangeInput($event)">
                               <div class="icon">
                                <svg width="20" height="15" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.37 10L1.352 5.548V10H0.0780001V0.242H1.352V4.764L5.384 0.242H6.994L2.57 5.128L7.036 10H5.37Z" fill="#4B4B4B"/>
                                    <path d="M13.889 8.739C14.435 8.739 14.9117 8.62633 15.319 8.401C15.735 8.167 16.06 7.82467 16.294 7.374H18.075C17.737 8.24067 17.1953 8.908 16.45 9.376C15.7047 9.844 14.851 10.078 13.889 10.078C12.875 10.078 11.9737 9.805 11.185 9.259C10.3963 8.70433 9.846 7.96767 9.534 7.049H8.338V6.035H9.3C9.28267 5.79233 9.274 5.60167 9.274 5.463C9.274 5.32433 9.28267 5.13367 9.3 4.891H8.338V3.877H9.534C9.846 2.95833 10.3963 2.226 11.185 1.68C11.9737 1.12533 12.875 0.847999 13.889 0.847999C14.8423 0.847999 15.6917 1.08633 16.437 1.563C17.191 2.031 17.737 2.69833 18.075 3.565H16.294C16.06 3.11433 15.735 2.77633 15.319 2.551C14.9117 2.317 14.435 2.2 13.889 2.2C13.2563 2.2 12.6973 2.34733 12.212 2.642C11.7353 2.93667 11.3713 3.34833 11.12 3.877H13.889V4.891H10.834C10.808 5.073 10.795 5.26367 10.795 5.463C10.795 5.66233 10.808 5.853 10.834 6.035H13.889V7.049H11.12C11.3713 7.58633 11.7353 8.00233 12.212 8.297C12.6973 8.59167 13.2563 8.739 13.889 8.739Z" fill="#4B4B4B"/>
                                    </svg>
                               </div>
                               <div class="plusetmois">
                                <i class="fa-solid fa-plus-minus"></i>
                               </div>
                                    
                            </div>
                        </div>
                        <div class="col-lg-3 mb-3" >
                            <ng-select  [items]="desired_contract" bindLabel="name" bindValue="id" [multiple]="true" id="desired_contract"
                             placeholder="Contrat souhaité" formControlName="desired_contract" [notFoundText]="'Aucun élément trouvé'">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="!isCollapsed">
                        <div class="col-lg-3  mb-3">
                            <ng-select (change)="onCountryChange('add')" [items]="paysList" [multiple]="true"
                            id="pays_destinataire" bindLabel="name" bindValue="name" [placeholder]="'Pays destinataire'"
                            autoComplete formControlName="destination_country"  [notFoundText]="'Aucun élément trouvé'"  autoComplete>
                            <ng-template ng-optgroup-tmp let-item="item">
                              {{ item.name || "Unnamed group" }}
                            </ng-template>
                          </ng-select>
            
                          </div>
                          <div class="col-lg-3  mb-3">
                            <ng-select  [items]="stateList" [multiple]="true"
                              (change)="onMobilityChange('add')" id="mobilite" bindLabel="name"
                              formControlName="mobility" 
                              [notFoundText]="'Aucun élément trouvé'" bindValue="name" [placeholder]="'Mobilité'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                            </ng-select>
                          </div>
                    </div>
                    <div class="row mb-3 mb-0-sm">
                        <div class="col-lg-3 mb-3"></div>
                        <div class="col-lg-3 mb-3"></div>
                        <div class="col-lg-3 mb-3"></div>
                        <div class="col-lg-3 mb-3">
                           <div class="see-more">
                            <a [ngClass]="{'show-more-button': !isCollapsed, 'emphasized-button': isCollapsed}"
                            (click)="toggleCollapsible()" class="show-more-button">
                            {{ isCollapsed ? 'Voir plus de critères de recherche' : 'Voir moins de critères de recherche' }}
                        </a>
                           </div>
                        </div>
                    </div>
                    <div class="row mb-3 rechercher" >
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3">
                            <div class="mb-3 search-btn d-flex justify-content-end">
                                <div class="btn_search">
                                    <a type="button" (click)="searchConsultant()" class="search"> <svg width="20" height="20"
                                            viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                                fill="white" />
                                        </svg>
                                        Rechercher
                                    </a>
                                </div> &nbsp;&nbsp;
                                <div class="resett-btn">
                       
                                    <div class="reset-icon cursor-pointer"  >
                                     <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                                     </div>
                                  </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Content container-->
</div>

<div *ngIf="!isLoadSpinner" id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid for-web">
        <!--begin::Card-->
        <div class="card">

            <!--begin::Card header-->
            <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                <div class="section_title">
                    <h1 class="section-heading my-0">Liste des consultants</h1>
                </div>
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3"
                            data-bs-toggle="tab" href="#kt_project_targets_card_pane">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active"
                            data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->
                <!--begin::Wrapper-->

                <!--end::Wrapper-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <div class="tab-content">
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_card_pane" class="tab-pane fade ">
                        <!--begin::Row-->
                        <div class="row g-6 g-xl-9 justify-content-center">
                       
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4" *ngFor="let consultant of listConsultant">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                       <!--<div class="symbol text-center symbol-65px symbol-circle mb-5">
                                            <img *ngIf="!consultant.image_url || consultant.image_url==='undefined'" src="assets/media/img/modules/avatar.jpg" alt="image" />
                                            <img *ngIf="consultant.image_url && consultant.image_url!=='undefined'" src="{{url+'/User/file/gallery_users/'+consultant.image_url}}" alt="logo">
                                        </div>--> 
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img
                                              *ngIf="consultant && consultant.image_url"
                                              [src]="
                                                url + '/Company/file/gallery_company/' + consultant.image_url
                                              "
                                              alt="logo"
                                            />
                                            <img
                                              *ngIf="!consultant || !consultant?.image_url"
                                              src="/assets/media/img/modules/avatar.jpg"
                                              alt="image"
                                            />
                      
                                            <div
                                              class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-45 ms-n3 mt-n3"
                                            ></div>
                                          </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="job-title"
                                        [routerLink]="['/wegestu/consultant/detailsConsultant', {id: consultant.id }]">
                                        {{ consultant.first_name && consultant.first_name!=="null" && consultant.first_name !='0' ? consultant.first_name : "#---" }}</a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <div class="poste-consult mb-3">
                                            <span class="mb-3">  {{consultant.desired_workstation && consultant.desired_workstation !=="null" && consultant.desired_workstation!='0' ?consultant.desired_workstation:'---'}} </span>
                                        </div>

                                        <div class="d-flex flex-center flex-wrap mt-5 mb-5">
                                            <div class="skills">
                                                <div class="skill" *ngFor="let skill of getSkillsArray(consultant)?.slice(0,3); let i = index">
                                                    <span [ngClass]="'badge style' + (i % 3 + 1)">{{ skill }}</span>
                                                </div>
                                                <span *ngIf="getSkillsArray(consultant) && getSkillsArray(consultant).length > 3">+</span>
                                              </div>
                                              <div class="skill"><span *ngIf="consultant.skills">---</span>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <div class="type mb-3">
                                                <span>Salaire :</span>
                                                {{consultant.desired_salary && consultant.desired_salary !=0 && consultant.desired_salary!=="null" ? consultant.desired_salary:'---'}}
                                            </div>
                                            <div class="type mb-3">
                                                <span>TJM :</span>
                                                {{consultant?.tjm &&  consultant?.tjm !== 'null' && consultant?.tjm != 0 && consultant?.tjm!=='nil' ? consultant?.tjm +'€':'---'}}
                                            </div>
                                        </div> 
                                        <div class="experience">
                                            <div class="exper mb-3">
                                                <span>Expérience :</span>
                                                {{consultant?.years_of_experience && consultant?.years_of_experience>0 && consultant?.years_of_experience !='0' ? consultant?.years_of_experience:'---'}}
                                                <span *ngIf="consultant?.years_of_experience>0"> ans</span>
                                            </div>
                                        </div>
                                        <!--begin::Info-->
                                        <!-- <div class="d-flex flex-center flex-wrap mt-5">
                                            <div class="skills">
                                                <div class="skill" *ngFor="let skill of getSkillsArray(consultant)?.slice(0,3)
                                                ; let i = index">
                                                    <span [ngClass]="'badge style' + (i % 3 + 1)">{{ skill}}</span>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!--end::Info-->
                                    </div>
                                    <div class="sep"></div>
                                    <div class="foot p-5">
                                       
                                        <div class="plus">
                                            <a (click)="profilConsultantNavigation(consultant.id)">Voir Detail</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                    <!--end::Tab pane-->
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_table_pane" class="tab-pane fade  active show">
                        <div class="content">
                            <div class="card-body pt-3">
                                <!--begin::Table-->
                                <div class="table-responsive"  id="kt_customers_table">
                                    <table class="table align-middle fs-6 gy-5"
                                       >
                                        <thead>
                                            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                <ng-container *ngFor="let item of listNames ;let i =index">
                                                    <th class="min-w-175px" [ngClass]="{'th-fixed': item === 'Prénom','th-fixed-2': item === 'Poste'}">
                                                      <span>{{item}}</span>
                                                      <i  *ngIf="!['Compétence'].includes(item)"  (click)="sortData(item)"
                                                        class="fa-solid fa-sort cursor-pointer"></i>
                                                    </th>
                                                  </ng-container>
                                                <th class="min-w-90px stiky-column-action"><span>Actions</span></th>
                                                <th class="stiky-column">
                                                    <div class="dropdown" id="table-filter">
                                                        <button
                                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                            type="button" id="dropdownMenuButton"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_d_444_2002)">
                                                                    <rect x="4" width="20" height="20" rx="4"
                                                                        fill="#63C3E8" fill-opacity="0.12"
                                                                        shape-rendering="crispEdges" />
                                                                </g>
                                                                <path
                                                                    d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                                                    fill="#265D91" stroke="#265D91"
                                                                    stroke-width="0.2" />
                                                                <defs>
                                                                    <filter id="filter0_d_444_2002" x="0" y="0"
                                                                        width="28" height="28"
                                                                        filterUnits="userSpaceOnUse"
                                                                        color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0"
                                                                            result="BackgroundImageFix" />
                                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                            result="hardAlpha" />
                                                                        <feOffset dy="4" />
                                                                        <feGaussianBlur stdDeviation="2" />
                                                                        <feComposite in2="hardAlpha" operator="out" />
                                                                        <feColorMatrix type="matrix"
                                                                            values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                            result="effect1_dropShadow_444_2002" />
                                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                            in2="effect1_dropShadow_444_2002"
                                                                            result="shape" />
                                                                    </filter>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                        
                                                       <div class="list-actions">
                                                        <div class="list-actions-content dropdown-menu eliminate" aria-labelledby="dropdownMenuButton">
                                                            <div class="d-flex checkbox mb-2 menu" *ngFor="let item of dataHeader;let i =index">
                                                              <div class="form-check form-check-sm form-check-custom form-check-solid"
                                                                *ngIf="item.name!='checkbox'">
                        
                                                                <input class="form-check-input checkbox-border"
                                                                  [checked]="(item.checked=='oui'&&!item.checked_user)||item.checked_user=='oui'&&(item.checked=='non'||item.checked=='oui')"
                                                                  (change)="changeSelection($event,item,i)" type="checkbox" value="1" [disabled]="
                                                                  item.name === 'Prénom' ||
                                                                  item.name === 'Poste' 
                                                                "/>
                                                                <a class="dropdown-item">{{item.name}}</a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                       </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-semibold text-gray-600 " >
                                            <tr class="table-row" *ngFor="let consultant of listConsultant ; let i = index" #tableRow>
                                              <td class="td-fixed" *ngIf="listNames?.includes('Prénom')">
                                                <a
                                                  class="first-td "
                                                  [routerLink]="['/wegestu/consultant/detailsConsultant', {id: consultant.id }]"
                                                  >{{
                                                    consultant.first_name && consultant.first_name!=="null" && consultant.first_name !='0' ? consultant.first_name : "#---"
                                                  }}</a>
                                              </td>
                                              <td *ngIf="listNames?.includes('Poste')" class="wrapping poste-wrapper td-fixed-2">
                                                <span class=" poste-wrap">{{ consultant?.desired_workstation && consultant?.desired_workstation!=="null" && consultant?.desired_workstation !='0' ? consultant?.desired_workstation : "---" }}</span>
                                              </td>
                                              <td *ngIf="listNames?.includes('Années d’expérience')">
                                                <span>{{
                                                    consultant.years_of_experience && consultant.years_of_experience !=="null" && consultant.years_of_experience != '0' && consultant.years_of_experience !=="0 ans"
                                                    ? consultant.years_of_experience
                                                    : "---"
                                                }}</span>
                                                <span *ngIf="consultant?.years_of_experience>0"> ans</span>
                                              </td>
                                              <td *ngIf="listNames?.includes('Compétence')">
                                                <div class="skills">
                                                  <div class="skill" *ngFor="let skill of getSkillsArray(consultant)?.slice(0,3); let i = index">
                                                      <span [ngClass]="'badge style' + (i % 3 + 1)">{{ skill }}</span>
                                                  </div>
                                                  <span *ngIf="getSkillsArray(consultant) && getSkillsArray(consultant).length > 3">+</span>
                                                </div>
                                                <div class="skill"><span *ngIf="consultant.sub_categories_names?.length < 1">---</span></div>
                                              </td>
                                              <td *ngIf="listNames?.includes('TJM')" class="wrapping">
                                                <span class="poste-wrap">{{consultant?.tjm &&  consultant?.tjm !== 'null' && consultant?.tjm != 0 && consultant?.tjm!=='nil' ? consultant?.tjm +'€':'---'}}  </span>
                                            </td>
                                            <td *ngIf="listNames?.includes('Salaire')" class="wrapping">
                                                <span class="poste-wrap">{{consultant?.desired_salary && consultant?.desired_salary!=='null' && consultant?.desired_salary!= 0 && consultant?.desired_salary!=='nil' ? consultant?.desired_salary +'€':'---'}}</span>
                                            </td>
                                            <td *ngIf="listNames?.includes('Contrat souhaité')" class="wrapping poste-wrapper">
                                                <span class="first-td poste-wrap">  <ng-container *ngIf="consultant?.desired_contract?.length; else noContract">
                                                    <ng-container *ngFor="let contract of consultant?.desired_contract; let last = last">
                                                        {{ getContractLabel(contract) }}{{ last ? '' : ', ' }}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #noContract>
                                                    ---
                                                </ng-template></span>
                                            </td> 
                                            <td *ngIf="listNames?.includes('Pays de résidence')" class="wrapping">
                                                <span class="poste-wrap">{{consultant?.current_country && consultant?.current_country!=='null' ? consultant.current_country:'---'}}</span>
                                            </td>
                                            <td *ngIf="listNames?.includes('Pays destinataire')" class="wrapping">
                                                <span class="poste-wrap"> {{ consultant?.destination_country && consultant.destination_country.length > 0
                                                     && consultant?.destination_country !=="null" && consultant?.destination_country!='0'
                                                    ? consultant.destination_country : '---' }}</span>
                                            </td>
                                            <td *ngIf="listNames?.includes('Mobilité')" class="wrapping">
                                                <span class="poste-wrap"> {{ consultant?.mobility && consultant.mobility.length > 0 
                                                    && consultant?.mobility !=="null" && consultant?.mobility!='0'
                                                    ? consultant.mobility : '---' }}</span>
                                            </td>
                                            <td *ngIf="listNames?.includes('Dernière connexion')" class="wrapping">
                                                <span *ngIf="consultant.last_connection" class="poste-wrap">{{ cleanDate(consultant.last_connection!) | formatDate }}</span>
                                                <span *ngIf="!consultant?.last_connection">---</span>
                                            </td>


                                            <td class="actions td-fixed-3">
                                                <div class="seemore">
                                                    <a (click)="profilConsultantNavigation(consultant.id)">Voir detail</a>
                                                </div>
                                            </td>
                                              <td class="td-fixed-4"></td>
                                            </tr>
                                          </tbody>
                                    </table>
                                </div>
                                <!--end::Table-->
                                <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listConsultant&&!listConsultant.length">
                                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                    <span class="empty-list">
                                     La liste est vide
                                    </span>
                                 </div>
                    
                            </div>
                        </div>
                    </div>
                    <!-- [endexPageList]="endIndex" -->
                    <div class="pagination" *ngIf="listConsultant && listConsultant.length">
                        <app-pagination [data]="listConsultant" [lengthData]="totalItems" [currentPageList]="currentPage"  [endexPageList]="endIndex" (setPages)="getItems($event)">
                        </app-pagination>
                    </div>
                  
                  
                    <!--end::Tab pane-->
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>

    <div id="kt_app_content_container" class="app-container container-fluid for-mobile">
        <!--begin::Card-->
        <div class="card">

            <!--begin::Card header-->
            <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                <div class="section_title">
                    <h1 class="section-heading my-0">Liste des consultants</h1>
                </div>
                <!--begin::Tab nav-->
              
                <!--end::Tab nav-->
                <!--begin::Wrapper-->

                <!--end::Wrapper-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <div class="tab-content">
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_card_pane" class="tab-pane fade  active show">
                        <!--begin::Row-->
                        <div class="row g-6 g-xl-9">
                       
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4" *ngFor="let consultant of listConsultant">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img *ngIf="!consultant.image_url || consultant.image_url==='undefined'" src="assets/media/img/modules/avatar.jpg" alt="image" />
                                            <img *ngIf="consultant.image_url && consultant.image_url!=='undefined'" src="{{url+'/Company/file/gallery_company/'+consultant.image_url}}" alt="logo">

                                          
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a  class="text-gray-800 fw-bold mb-5">{{consultant.first_name?consultant.first_name:'---'}} </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <div class="poste text-center mb-5">

                                            <span class="text-gray-800 fw-bold">  {{consultant.desired_workstation?consultant.desired_workstation:'---'}} </span>
                                        </div>
                                        <!--begin::Info-->
                                        <!-- <div class="d-flex flex-center flex-wrap mt-5">
                                            <div class="skills">
                                                <div class="skill" *ngFor="let skill of getSkillsArray(consultant)?.slice(0,3)
                                                ; let i = index">
                                                    <span [ngClass]="'badge style' + (i % 3 + 1)">{{ skill}}</span>
                                                </div>
                                            </div>
                                        </div> -->



                                        <!-- <div class="info">
                                            <div class="type mb-3">
                                                <span>Salaire :</span>
                                                {{consultant.desired_salary && consultant.desired_salary !=0 && consultant.desired_salary!='null' ? consultant.desired_salary:'---'}}
                                            </div>
                                            <div class="type mb-3">
                                                <span>TJM :</span>
                                                {{consultant.tjm && consultant.tjm !=0 && consultant.tjm!='null' ? consultant.tjm:'---'}}
                                            </div>
                                           
                                        </div> -->
                                        <div class="experience">
                                            <div class="exper mb-3">
                                                <span>Expérience :</span>
                                                {{consultant?.years_of_experience && consultant?.years_of_experience !='0' ? consultant?.years_of_experience:'---'}} 
                                            </div>
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="sep"></div>
                                    <div class="foot p-5">
                                       
                                        <div class="plus">
                                            <a (click)="profilConsultantNavigation(consultant.id)">Voir Detail</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                        </div>

                        <div class="pagination" *ngIf="listConsultant && listConsultant.length">
                            <app-pagination [data]="listConsultant" [lengthData]="totalItems" [currentPageList]="currentPage"  [endexPageList]="endIndex" (setPages)="getItems($event)">
                            </app-pagination>
                        </div>
                      
                        <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listConsultant&&!listConsultant.length">
                            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                            <span class="empty-list">
                             La liste est vide
                         </span>
                         </div>
                        <!--end::Row-->
                    </div>
                    <!--end::Tab pane-->
                    <!--begin::Tab pane-->
                  
                    <!--end::Tab pane-->
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
  
    <!--end::Content container-->
</div>
