<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1223_3646)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M13.75 3.2834C9.86824 4.28202 7 7.80437 7 11.9996V17.9991C7 18.5519 6.55087 19 6.00104 19C4.8959 19 4 19.8977 4 21C4 22.1046 4.89703 23 6.00495 23H25.9951C27.1024 23 28 22.1023 28 21C28 19.8954 27.0999 19 25.999 19C25.4472 19 25 18.5554 25 17.9991V11.9996C25 7.80603 22.1324 4.28236 18.25 3.28343V2.25043C18.25 1.00462 17.2426 0 16 0C14.7599 0 13.75 1.00755 13.75 2.25043V3.2834ZM12.5 24H19.5C19.5 25.933 17.933 27.5 16 27.5C14.067 27.5 12.5 25.933 12.5 24Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_1223_3646" x="0" y="0" width="32" height="35.5"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1223_3646" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1223_3646"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    Mes avis
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted"
                        routerLinkActive="router-link-active">Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Mes Avis</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <div id="kt_app_content_container" class="app-container container-fluid">
        <div class="card">
            <div class="card-body tools">
                <div class="title mb-10">
                    <h4>Recherche</h4>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="input-group mb-3 input-group search-libre  mb-15">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                        fill="none">
                                        <path
                                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                            fill="#7E7C7C" />
                                    </svg>
                                </span>
                                <input type="text" data-kt-customer-table-filter="search" [(ngModel)]="searchElement"
                                    class="form-control form-control-solid" (keyup.enter)="applyFilter()"
                                    placeholder="Rechercher" />
                                <div class="tooltip-container tooltip-info">
                                    <div class="tooltip-trigger">
                                        <i class="fa-solid fa-circle-info" style="font-size: 20px"></i>
                                    </div>
                                    <div class="tooltip-content">
                                        <div class="content">
                                            <h4>Opérateur AND :</h4>
                                            <p>
                                                Utilisez l'opérateur AND pour spécifier que les
                                                résultats doivent inclure plusieurs termes.
                                            </p>
                                            <p>
                                                Exemple : software AND engineer renverra des profils
                                                qui mentionnent à la fois "software" et "engineer".
                                            </p>
                                            <h4>Opérateur OR :</h4>
                                            <p>
                                                Utilisez l'opérateur OR pour spécifier que les
                                                résultats peuvent inclure l'un des termes.
                                            </p>
                                            <p>
                                                Exemple : sales OR marketing renverra des profils qui
                                                mentionnent soit "sales" soit "marketing".
                                            </p>
                                            <h4>Opérateur NOT :</h4>
                                            <p>
                                                Utilisez l'opérateur NOT pour exclure des termes
                                                spécifiques des résultats.
                                            </p>
                                            <p>
                                                Exemple : developer NOT manager renverra des profils
                                                qui mentionnent "developer" mais pas "manager".
                                            </p>
                                            <h4>Guillemets pour une expression exacte :</h4>
                                            <p>
                                                Utilisez des guillemets pour rechercher une expression
                                                exacte.
                                            </p>
                                            <p>
                                                Exemple : "product manager" renverra des profils qui
                                                mentionnent exactement "product manager".
                                            </p>
                                            <h4>Parenthèses pour grouper des opérations :</h4>
                                            <p>
                                                Utilisez des parenthèses pour définir l'ordre
                                                d'évaluation des opérations.
                                            </p>
                                            <p>
                                                Exemple : (sales OR marketing) AND manager renverra
                                                des profils qui mentionnent "sales" ou "marketing" et
                                                "manager".
                                            </p>
                                            <h4>Recherche standard :</h4>
                                            <p>
                                                Si aucun opérateur n'est spécifié dans la recherche,
                                                le texte sera interprété comme s'il était entre
                                                guillemets.
                                            </p>
                                            <p>
                                                Exemple : La recherche product manager sera traitée
                                                comme "product manager", suivant la règle 4.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                <div class="section_title">
                    <h1>Liste de mes avis entreprises</h1>
                </div>
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3"
                            data-bs-toggle="tab" href="#grid">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active "
                            data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="container-fluid">
                    <div class="content">
                        <div class="tab-content for-web pb-5">

                            <div id="kt_project_targets_table_pane" class="tab-pane fade active show">
                                <div class="content">
                                    <div class="card-body pt-3">
                                        <!--begin::Table-->
                                        <div class="table-responsive" id="kt_customers_table">
                                            <p-table [value]="listCompany" [scrollable]="true" scrollHeight="700px">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <ng-container *ngFor="let item of listNames; let i = index">
                                                            <th class="min-w-175px">
                                                                <span>{{ item }}</span>
                                                                <i (click)="sortData(item)"
                                                                    class="fa-solid fa-sort cursor-pointer"></i>
                                                            </th>
                                                        </ng-container>
                                                        <th class="min-w-125px stiky-column-action"><span>Action</span></th>
                                                        <th class="stiky-column">
                                                            <div class="dropdown" id="table-filter">
                                                                <button
                                                                    class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                    type="button" id="dropdownMenuButton"
                                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <svg width="28" height="28" viewBox="0 0 28 28"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g filter="url(#filter0_d_444_2002)">
                                                                            <rect x="4" width="20" height="20" rx="4"
                                                                                fill="#63C3E8" fill-opacity="0.12"
                                                                                shape-rendering="crispEdges" />
                                                                        </g>
                                                                        <path
                                                                            d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                                                            fill="#265D91" stroke="#265D91"
                                                                            stroke-width="0.2" />
                                                                        <defs>
                                                                            <filter id="filter0_d_444_2002" x="0" y="0"
                                                                                width="28" height="28"
                                                                                filterUnits="userSpaceOnUse"
                                                                                color-interpolation-filters="sRGB">
                                                                                <feFlood flood-opacity="0"
                                                                                    result="BackgroundImageFix" />
                                                                                <feColorMatrix in="SourceAlpha"
                                                                                    type="matrix"
                                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                                    result="hardAlpha" />
                                                                                <feOffset dy="4" />
                                                                                <feGaussianBlur stdDeviation="2" />
                                                                                <feComposite in2="hardAlpha"
                                                                                    operator="out" />
                                                                                <feColorMatrix type="matrix"
                                                                                    values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                                                                <feBlend mode="normal"
                                                                                    in2="BackgroundImageFix"
                                                                                    result="effect1_dropShadow_444_2002" />
                                                                                <feBlend mode="normal" in="SourceGraphic"
                                                                                    in2="effect1_dropShadow_444_2002"
                                                                                    result="shape" />
                                                                            </filter>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                                <div class="list-actions">
                                                                    <div class="list-actions-content dropdown-menu eliminate"
                                                                        aria-labelledby="dropdownMenuButton"
                                                                        data-bs-popper="static">
                                                                        <div class="d-flex checkbox mb-2 menu"
                                                                            *ngFor="let item of dataHeader; let i = index">
                                                                            <div class="form-check form-check-sm form-check-custom form-check-solid"
                                                                                *ngIf="item.name != 'checkbox'">
                                                                                <input
                                                                                    class="form-check-input checkbox-border"
                                                                                    [checked]="
                                                                                    (item.checked == 'oui' &&
                                                                                      !item.checked_user) ||
                                                                                    (item.checked_user == 'oui' &&
                                                                                      (item.checked == 'non' ||
                                                                                        item.checked == 'oui'))
                                                                                  " (change)="
                                                                                    changeSelection($event, item, i)
                                                                                  " type="checkbox" value="1" *ngIf="
                                                                                    !['Dernière connexion'].includes(
                                                                                      item.name
                                                                                    )
                                                                                  " [disabled]="
                                                                                    item.name === 'Nom entreprise' ||
                                                                                    item.name === 'Type entreprise'
                                                                                  " />
                                                                                <a class="dropdown-item">{{ item.name }}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-company>
                                                    <tr>
                                                        <td *ngIf="listNames?.includes('Logo entreprise')">
                                                            <img *ngIf="company.logo" class="table-img logo-img"
                                                                src="{{url+'/Company/file/gallery_company/'+ company.logo}}"
                                                                alt="">
                                                            <img *ngIf="!company.logo" class="table-img logo-img"
                                                                src="/assets/media/img/modules/avatar.jpg" alt="">
                                                        </td>
                                                        <td *ngIf="listNames?.includes('Nom entreprise')">
                                                            <a class="entreprise-name  name mb-1 "
                                                                [routerLink]="['/wegestu/entreprises/details', { id:company.id }]">
                                                                {{company?.name && company?.name!=="null" &&
                                                                company?.name!='0' ?company?.name:'---'}}
                                                            </a>
                                                        </td>
                                                        <td *ngIf="listNames?.includes('Type entreprise')">
                                                            <span class="wrapping" [ngClass]="company?.type=='1'?'entreprise':company?.type=='2'?
                                                            'esn-type':company?.type=='3'?'client-type':'centre-type'">
                                                                {{company?.type=='2'||company?.type=='5'?
                                                                'ESN':company?.type=='3'?'Client
                                                                Final':company?.type=='1'?'Entreprise':company?.type=='4'?'Cabinet
                                                                de
                                                                Recrutement':'Particulier'}}</span>
                                                        </td>
        
                                                        <td *ngIf="listNames?.includes('Site Web')">
                                                            <ng-container
                                                                *ngIf="company?.website_link && company?.website_link !=='null' && company?.website_link!='0'; else noLink">
                                                                <a target="_blank"
                                                                    [attr.href]="getWebsiteLink(company?.website_link)"
                                                                    [attr.title]="getWebsiteLink(company?.website_link)">
        
                                                                    <svg width="25" height="25" viewBox="0 0 25 25"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="25" height="25" rx="3"
                                                                            fill="#265D91" />
                                                                        <path
                                                                            d="M12.0833 20.0001C7.47222 20.0001 3.75 16.6501 3.75 12.5001C3.75 8.35012 7.47222 5.00012 12.0833 5.00012C16.6944 5.00012 20.4167 8.35012 20.4167 12.5001C20.4167 16.6501 16.6944 20.0001 12.0833 20.0001ZM12.0833 6.00012C8.08333 6.00012 4.86111 8.90012 4.86111 12.5001C4.86111 16.1001 8.08333 19.0001 12.0833 19.0001C16.0833 19.0001 19.3056 16.1001 19.3056 12.5001C19.3056 8.90012 16.0833 6.00012 12.0833 6.00012Z"
                                                                            fill="white" />
                                                                        <path
                                                                            d="M16.4145 14.3501C16.5256 13.7501 16.5256 13.1501 16.5256 12.5001C16.5256 11.8501 16.4701 11.2501 16.4145 10.6501C16.3034 9.85012 16.0812 9.10012 15.8589 8.40012C15.0256 6.35012 13.6923 5.00012 12.0812 5.00012C10.1923 5.00012 8.63672 6.85012 7.97005 9.55012C7.9145 9.80012 7.85894 10.0001 7.80339 10.2501C7.69227 10.9501 7.63672 11.7001 7.63672 12.5001C7.63672 13.1501 7.69227 13.7501 7.74783 14.3501C7.85894 15.1501 8.08116 15.9001 8.30339 16.6001C9.08116 18.6501 10.4701 20.0001 12.0256 20.0001C13.5812 20.0001 14.9701 18.6501 15.7478 16.6001C15.3589 16.7001 14.9145 16.7501 14.4701 16.8501C13.9145 18.2001 12.9701 19.0001 12.0812 19.0001C11.1923 19.0001 10.2478 18.2001 9.63672 16.8501C9.35894 16.2501 9.13672 15.5001 8.97005 14.7001C8.80339 14.0001 8.74783 13.3001 8.74783 12.5001C8.74783 11.7001 8.80339 10.9501 8.97005 10.3001C9.02561 10.0501 9.08116 9.85012 9.13672 9.60012V9.55012C9.19227 9.30012 9.24783 9.10012 9.35894 8.90012C9.4145 8.75012 9.47005 8.60012 9.52561 8.50012C9.52561 8.50012 9.52561 8.50012 9.52561 8.45012L9.63672 8.25012C9.63672 8.25012 9.63672 8.20012 9.69227 8.15012C10.2478 6.80012 11.1923 6.00012 12.0812 6.00012C12.9701 6.00012 13.9145 6.80012 14.5256 8.15012C14.8034 8.75012 15.0256 9.50012 15.1923 10.3001C15.3589 11.0001 15.4145 11.7001 15.4145 12.5001C15.4145 13.3001 15.3589 14.0001 15.2478 14.7001C15.6367 14.6001 16.0256 14.5001 16.4145 14.3501Z"
                                                                            fill="white" />
                                                                        <path
                                                                            d="M16.6389 9.15049C15.9167 8.90049 15.0278 8.75049 14.1389 8.65049C13.4722 8.55049 12.8056 8.50049 12.0833 8.50049C11.3611 8.50049 10.6944 8.55049 10.0278 8.60049C9.91667 8.90049 9.75 9.25049 9.63889 9.65049C10.4167 9.55049 11.1944 9.50049 12.0833 9.50049C12.9722 9.50049 13.75 9.55049 14.5278 9.65049C15.4167 9.80049 16.25 10.0005 16.9167 10.2505C18.4167 10.8505 19.3056 11.6505 19.3056 12.4505C19.3056 13.2505 18.4167 14.1005 16.9167 14.6505C16.25 14.9005 15.4167 15.1005 14.5278 15.2505C13.75 15.4505 12.9722 15.5005 12.0833 15.5005C11.1944 15.5005 10.4167 15.4505 9.63889 15.3005C8.75 15.2005 7.91667 15.0005 7.25 14.7005C5.75 14.1505 4.86111 13.3005 4.86111 12.5005C4.86111 11.7005 5.75 10.8505 7.25 10.3005C7.30556 9.90049 7.41667 9.50049 7.52778 9.15049C5.25 9.85049 3.75 11.0505 3.75 12.5005C3.75 13.9505 5.25 15.1505 7.52778 15.8505C8.25 16.1005 9.13889 16.2505 10.0278 16.3505C10.6944 16.4505 11.3611 16.4505 12.0833 16.4505C12.8056 16.4505 13.4722 16.4005 14.1389 16.3505C15.0278 16.2505 15.9167 16.0505 16.6389 15.8505C18.9167 15.1505 20.4167 13.9005 20.4167 12.5005C20.4167 11.1005 18.9167 9.85049 16.6389 9.15049Z"
                                                                            fill="white" />
                                                                    </svg>
                                                                </a>
                                                            </ng-container>
                                                            <ng-template #noLink>
                                                                ---
                                                            </ng-template>
                                                        </td>
                                                        <td *ngIf="listNames?.includes('Avis')">
                                                            <rating [(ngModel)]="company.average_note_candidat"
                                                                [titles]="[' ',' ',' ',' ',' ']" [max]="5" [readonly]="true"
                                                                class="rating-stars disable-hover">
                                                            </rating>
                                                        </td>
                                                        <td class="td-fixed-3">
                                                            <button mat-button [matMenuTriggerFor]="menu"> <svg width="19"
                                                                    height="5" viewBox="0 0 19 5" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg></button>
                                                            <mat-menu #menu="matMenu">
                                                                <button mat-menu-item
                                                                    (click)="reviewCandidateNavigation(company.id)">
                                                                    Mon avis
                                                                </button>
                                                                <button mat-menu-item
                                                                    (click)="companyDetailsNavigation(company.id)">
                                                                    Voir Détails
                                                                </button>
                                                            </mat-menu>
                                                        </td>
                                                        <td class="td-fixed-4"></td>
                                                    </tr>
        
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <!--end::Table-->
                                        <!-- [endexPageList]="endIndex" -->
                                        <div class="pagination" *ngIf="listCompany && listCompany.length">
                                            <app-pagination [data]="listCompany" [lengthData]="totalItems"
                                                [currentPageList]="currentPage" [endexPageList]="endIndex"
                                                (setPages)="getItems($event)">
                                            </app-pagination>
        
                                        </div>
        
                                        <div class="alert alert-warning text-center mt-5" role="alert"
                                            *ngIf="listCompany&&!listCompany.length">
                                            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                            <span class="empty-list">
                                                La liste est vide
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="grid" class="tab-pane fade ">
                                <div class="row g-6 g-xl-9 cards justify-content-center">
                                    <div class="col-sm-6 col-xl-4" *ngFor="let company of listCompany">
                                        <div class="card pt-5 p-5">
                                            <div class="poste">
                                                <span class="wrapping" *ngIf="company?.type!=='1'" [ngClass]="company?.type=='2'?'esn-type':company?.type=='3'?'client-type':'centre-type'">
                                                    {{company?.type=='2'||company?.type=='5'?'ESN':company?.type=='3'?'ClientFinal':company?.type=='4'?
                                                    'Cabinet de  Recrutement':'Particulier'}}</span>
                                                <span *ngIf="company?.type=='1'">---</span>
                                            </div>
                                            <div class="card-toolbar m-0">
                                                <!--begin::Menu-->
                                                <div class="tools">
    
                                                    <div class="actions">
                                                        <div class="tools">
                                                            <div class="dropdown">
                                                                <button
                                                                    class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                    type="button" id="dropdownMenuButton"
                                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <svg width="19" height="5" viewBox="0 0 19 5"
                                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                            transform="matrix(1 0 0 -1 0 4.89478)"
                                                                            fill="#265D91" />
                                                                        <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                            transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                            fill="#265D91" />
                                                                        <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                            transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                            fill="#265D91" />
                                                                    </svg>
                                                                </button>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    
                                                                    <a class="dropdown-item btn-details"
                                                                        (click)="reviewCandidateNavigation(company.id)">Mon avis</a>
                                                                    <a class="dropdown-item btn-details"
                                                                        (click)="companyDetailsNavigation(company.id)">Voir
                                                                        Détails</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--begin::Card header-->
                                            <div class="card-header flex-nowrap border-0 pt-9">
                                                <!--begin::Card title-->
                                                <div class="card-title m-0">
                                                    <!--begin::Icon-->
                                                    <div class="symbol">
                                                        <img *ngIf="company.logo" class="table-img logo-img"
                                                            src="{{url+'/Company/file/gallery_company/'+ company.logo}}"
                                                            alt="">
                                                        <img *ngIf="!company.logo" class="table-img logo-img"
                                                            src="/assets/media/img/modules/avatar.jpg" alt="">
                                                    </div>
                                                    <!--end::Icon-->
                                                    <!--begin::Title-->
                                                    
                                                    <a class="fs-4 fw-semibold text-hover-primary name"
                                                    [routerLink]="['/wegestu/entreprises/details', { id:company.id }]">
                                                                {{company?.name && company?.name!=="null" &&
                                                                company?.name!='0' ?company?.name:'---'}}</a>
                                                    <!--end::Title-->
                                                    <div class="ratings">
                                                        <rating [(ngModel)]="company.average_note_candidat"
                                                                [titles]="[' ',' ',' ',' ',' ']" [max]="5" [readonly]="true"
                                                                class="rating-stars disable-hover">
                                                            </rating>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column pt-6 pb-8">
                                                <div class="footer d-flex fw-semibold"
                                                [ngClass]="{'justify-content-center': (!company?.website_link && company?.website_link === 'null') || (!company?.linkedin_link && company?.linkedin_link === 'null')}">
                                                    <div class="socials">
                                                        <span class="px-3 py-2 me-2"
                                                            *ngIf="company?.linkedin_link && company?.linkedin_link!=='null' && company?.linkedin_link!='0'">
                                                            <a target="_blank" [href]="company?.linkedin_link"
                                                                [attr.title]="company?.linkedin_link">
                                                                <svg width="25" height="25" viewBox="0 0 17 18" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_426_2396)">
                                                                        <path
                                                                            d="M14.2669 17.8389H2.84767C1.3399 17.8389 0.113281 16.5429 0.113281 14.9497V2.88923C0.113281 1.296 1.3399 0 2.84767 0H14.2669C15.7742 0 17.0008 1.296 17.0008 2.88923V14.9502C17.0008 16.5434 15.7742 17.8389 14.2669 17.8389ZM5.81961 14.7475H5.8231V7.05277H3.5621V14.7475H5.81918H5.81961ZM4.69108 6.00185C4.86298 6.00209 5.03325 5.96642 5.19211 5.89687C5.35098 5.82733 5.49532 5.72528 5.61688 5.59657C5.73844 5.46787 5.83482 5.31503 5.9005 5.14682C5.96618 4.97861 5.99987 4.79833 5.99964 4.61631C5.99895 4.24906 5.86086 3.89707 5.61561 3.63739C5.37035 3.37771 5.03792 3.2315 4.69108 3.23077C4.34406 3.23089 4.01129 3.37691 3.76591 3.63672C3.52053 3.89653 3.38263 4.24888 3.38251 4.61631C3.38263 4.98374 3.52053 5.33608 3.76591 5.5959C4.01129 5.85571 4.34406 6.00172 4.69108 6.00185ZM14.2799 14.748V10.5295C14.2799 8.45677 13.8549 6.86215 11.5708 6.86215C10.4728 6.86215 9.73572 7.49908 9.43277 8.10369H9.40226V7.05277H7.23715V14.7475H9.49423V10.9398C9.49423 9.93554 9.67425 8.964 10.8499 8.964C12.0059 8.964 12.0229 10.1123 12.0229 11.0045V14.7475H14.2799V14.748Z"
                                                                            fill="#265D91" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_426_2396">
                                                                            <rect width="17" height="18" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </a>
                                                        </span>
                                                        <span 
                                                            *ngIf="company?.website_link && company?.website_link!=='null' && company?.website_link!='0'">
                                                            <a target="_blank"
                                                                [attr.href]="getWebsiteLink(company?.website_link)"
                                                                [attr.title]="getWebsiteLink(company?.website_link)">
                                                                <svg width="25" height="25" viewBox="0 0 18 18" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="0.0410156" y="0.0407715" width="17.9592"
                                                                        height="17.9592" rx="3" fill="#265D91" />
                                                                    <path
                                                                        d="M8.72077 14.4082C5.4083 14.4082 2.73438 12.0017 2.73438 9.02045C2.73438 6.03922 5.4083 3.63269 8.72077 3.63269C12.0332 3.63269 14.7072 6.03922 14.7072 9.02045C14.7072 12.0017 12.0332 14.4082 8.72077 14.4082ZM8.72077 4.35106C5.8473 4.35106 3.53256 6.43432 3.53256 9.02045C3.53256 11.6066 5.8473 13.6898 8.72077 13.6898C11.5942 13.6898 13.909 11.6066 13.909 9.02045C13.909 6.43432 11.5942 4.35106 8.72077 4.35106Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M11.833 10.3494C11.9128 9.91841 11.9128 9.48738 11.9128 9.02045C11.9128 8.55351 11.8729 8.12249 11.833 7.69147C11.7532 7.11677 11.5936 6.578 11.4339 6.07514C10.8353 4.60249 9.87746 3.63269 8.72009 3.63269C7.36317 3.63269 6.24571 4.96167 5.7668 6.90126C5.72689 7.08085 5.68698 7.22453 5.64707 7.40412C5.56725 7.90698 5.52734 8.44575 5.52734 9.02045C5.52734 9.48738 5.56725 9.91841 5.60716 10.3494C5.68698 10.9241 5.84662 11.4629 6.00626 11.9658C6.56499 13.4384 7.56272 14.4082 8.68018 14.4082C9.79764 14.4082 10.7954 13.4384 11.3541 11.9658C11.0747 12.0376 10.7555 12.0735 10.4362 12.1453C10.0371 13.1151 9.35864 13.6898 8.72009 13.6898C8.08154 13.6898 7.40308 13.1151 6.96408 12.1453C6.76453 11.7143 6.60489 11.1755 6.48517 10.6009C6.36544 10.098 6.32553 9.59514 6.32553 9.02045C6.32553 8.44575 6.36544 7.90698 6.48517 7.44004C6.52508 7.26045 6.56499 7.11677 6.60489 6.93718V6.90126C6.6448 6.72167 6.68471 6.578 6.76453 6.43432C6.80444 6.32657 6.84435 6.21881 6.88426 6.14698C6.88426 6.14698 6.88426 6.14698 6.88426 6.11106L6.96408 5.96738C6.96408 5.96738 6.96408 5.93147 7.00399 5.89555C7.40308 4.92575 8.08154 4.35106 8.72009 4.35106C9.35864 4.35106 10.0371 4.92575 10.4761 5.89555C10.6756 6.32657 10.8353 6.86534 10.955 7.44004C11.0747 7.94289 11.1146 8.44575 11.1146 9.02045C11.1146 9.59514 11.0747 10.098 10.9949 10.6009C11.2743 10.529 11.5536 10.4572 11.833 10.3494Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M11.9933 6.61416C11.4745 6.43456 10.836 6.32681 10.1974 6.25497C9.7185 6.18314 9.23959 6.14722 8.72077 6.14722C8.20195 6.14722 7.72304 6.18314 7.24412 6.21905C7.16431 6.43456 7.04458 6.68599 6.96476 6.97334C7.52349 6.9015 8.08222 6.86558 8.72077 6.86558C9.35932 6.86558 9.91805 6.9015 10.4768 6.97334C11.1153 7.08109 11.714 7.22477 12.1929 7.40436C13.2704 7.83538 13.909 8.41007 13.909 8.98477C13.909 9.55946 13.2704 10.1701 12.1929 10.5652C11.714 10.7448 11.1153 10.8884 10.4768 10.9962C9.91805 11.1399 9.35932 11.1758 8.72077 11.1758C8.08222 11.1758 7.52349 11.1399 6.96476 11.0321C6.32621 10.9603 5.72757 10.8166 5.24866 10.6011C4.17111 10.206 3.53256 9.59538 3.53256 9.02069C3.53256 8.44599 4.17111 7.83538 5.24866 7.44028C5.28857 7.15293 5.36839 6.86558 5.44821 6.61416C3.81193 7.11701 2.73438 7.97905 2.73438 9.02069C2.73438 10.0623 3.81193 10.9244 5.44821 11.4272C5.96703 11.6068 6.60558 11.7146 7.24412 11.7864C7.72304 11.8582 8.20195 11.8582 8.72077 11.8582C9.23959 11.8582 9.7185 11.8223 10.1974 11.7864C10.836 11.7146 11.4745 11.5709 11.9933 11.4272C13.6296 10.9244 14.7072 10.0264 14.7072 9.02069C14.7072 8.01497 13.6296 7.11701 11.9933 6.61416Z"
                                                                        fill="white" />
                                                                </svg>
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div class="details">
                                                        <a (click)="companyDetailsNavigation(company.id)">Voir
                                                            Détails</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pagination" *ngIf="listCompany && listCompany.length">
                                    <app-pagination
                                        [data]="listCompany"
                                        [lengthData]="totalItems"
                                        [currentPageList]="currentPage"
                                        (setPages)="getItems($event)"
                                    >
                                    </app-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>

        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>


<div class="modal fade" id="edit_review" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content pt-5 pb-15">
            <!--begin::Modal header-->
            <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">Modifier avis</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div id="kt_customers_export_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                    <i class="ki-duotone ki-cross fs-1" data-bs-dismiss="modal">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                </div>
                <!--end::Close-->
            </div>
            <hr class="line">
            <!--end::Modal header-->
            <!--begin::Modal body-->
            <div class="modal-body text-center mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <div class="">
                    <div class="card-body">
                        <div class="flex_input">
                            <span class="mb-3">Nom de l'entreprise</span>
                            <input type="text" name="entreprise" id="entreprise"
                                class="form-control form-control-solid mb-3 avis-text" placeholder="entreprise" />
                        </div>
                        <div class="flex_input">
                            <span class="mb-3">Avis</span>
                            <textarea type="text" name="avis" id="avis"
                                class="form-control form-control-solid mb-3 avis-text" rows="3"
                                placeholder="Avis"></textarea>
                        </div>
                    </div>
                </div>
                <!--end::Form-->
            </div>
            <div class="card-footer mx-5 mx-xl-15 my-7">
                <div class="actions">
                    <a data-bs-dismiss="modal" class="annuler">Annuler</a>
                    <a class="confirmer">Confirmer</a>
                </div>
            </div>
            <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
</div>

<ngx-spinner class="custom-spinner" bdColor="rgba(255,255,255,1)" size="medium" color="#fff" type="ball-atom"
    [fullScreen]="true">
    <span class="loader">
        <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
    </span>
</ngx-spinner>