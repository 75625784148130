import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CraRoutingModule } from './cra-routing.module';
import { ListaCraComponent } from './components/lista-cra/lista-cra.component';
import { DetailCraComponent } from './components/detail-cra/detail-cra.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ListCraCompanyComponent } from './components/list-cra-company/list-cra-company.component';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListTimeSheetComponent } from './components/list-time-sheet/list-time-sheet.component';
import { TimeSheetCompanyComponent } from './components/time-sheet-company/time-sheet-company.component';
import { DetailTimeSheetComponent } from './components/detail-time-sheet/detail-time-sheet.component';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PaginationModule } from '../../shared/pagination/pagination.module';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ListaCraComponent,
    DetailCraComponent,
    ListCraCompanyComponent,
    ListTimeSheetComponent,
    TimeSheetCompanyComponent,
    DetailTimeSheetComponent,
  ],
  imports: [
    CommonModule,
    CraRoutingModule,
    FullCalendarModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    TableModule,
    MenuModule,
    CalendarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    PaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class CraModule {}
