import { environment } from './../../../../environnements/environment';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CLogin } from '../../../shared/shared-models/login';
import { AuthentificationService } from '../../services/authentification.service';
import { ToastrService } from 'ngx-toastr';
import { patternEmail } from '../../../shared/shared-models/pattern';
import {
  codeSended,
  invalidMailPassword,
  noAccountCandidateMail,
  noAccountMail,
  notVerifiedEmail,
  serverError,
  welcomeMessage,
} from '../../../shared/shared-models/message';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../shared/shared-services/local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { StripeService } from 'src/app/shared/services/stripeService/stripe-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  /* formGroup */
  generateLoginForm: FormGroup = this.loginForm(new CLogin());
  show_button_resend: boolean = false;
  show_button_login: boolean = true;
  showPassword: string = 'password';
  // rememberMe!: boolean;
  /* variables d'environnement */
  clientId = environment.CLIENT_ID;
  clientSecret = environment.CLIENT_SECRET;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthentificationService,
    private toastrService: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private stripeService : StripeService,
  ) {}
  ngOnInit(): void {
    const email = this.cookieService.get('username');
    const password = this.cookieService.get('password');
    if (email && password) {
      this.generateLoginForm.patchValue({
        username: email,
        password: password,
      });
      // this.rememberMe = true;
      console.log(
        '%clogin.component.ts line:41 generateLoginForm Value',
        'color: #007acc;',
        this.generateLoginForm.value
      );
    }
  }

  /* create login form */
  loginForm(data: CLogin) {
    return this.formBuilder.group({
      username: [
        data.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(patternEmail),
        ],
      ],
      password: ['', [Validators.required]],
      rememberMe: [false],
    });
  }
  /* send login data */
  login() {
    if (this.generateLoginForm.valid) {
      let body = {
        ...this.generateLoginForm.value,
        grant_type: 'password',
        client_id: this.clientId,
        client_secret: this.clientSecret,
      };
      console.log('body', body);
      this.authService
        .login(body)
        .pipe()
        .subscribe({
          next: (res: any) => {
            this.handleLoginResponse(res);
          },
          error: (err: any) => {
            console.log('erro', err);
            if (err?.error) {
              this.handleLoginError(err.error.status);
            }
          },
        });
    }
  }

  private handleLoginResponse(res: any) {
    const validRoles = ['3'];
    const userRoles = Array.isArray(res.data.role_user_id)
      ? res.data.role_user_id
      : [res.data.role_user_id];
    console.log('Role User ID:', res.data.role_user_id);
    console.log('Valid Roles:', validRoles);

    if (
      res.status == 200 &&
      userRoles.some((role: any) => validRoles.includes(role))
    ) {
      this.handleSuccessfulLogin(res);
    } else {
      this.toastrService.error(noAccountCandidateMail);
    }
  }

  private handleSuccessfulLogin(res: any) {
    this.authService.setCookies('token', res.token['access_token']);
    this.authService.setCookies('refresh_token', res.token['refresh_token']);
    this.localStorageService.saveData('UserInfo', JSON.stringify(res.data));

   this.stripeService.getActiveSubscriptionPlan().subscribe(subscription => {
      this.toastrService.success(welcomeMessage);
      const subscriptionData = subscription?.data ? subscription.data : null
      this.localStorageService.saveData('subscription', JSON.stringify(subscriptionData));
      const url = '/wegestu/profil-candidat';
        window.location.href = url;
    })
  }

  private handleLoginError(status: number) {
    let errorMessage: string;

    switch (status) {
      case 404:
        errorMessage = noAccountMail;
        break;
      case 401:
        errorMessage = invalidMailPassword;
        break;
      case 406:
        errorMessage = notVerifiedEmail;
        this.show_button_login = false;
        this.show_button_resend = true;
        break;
      default:
        errorMessage = serverError;
    }

    this.toastrService.error(errorMessage);
  }
  verificationNavigation(email: string) {
    this.router.navigate(['/auth/code-verification', { email: email }]);
  }
  resendCodeVerification() {
    const data = { email: this.generateLoginForm.value.username };
    this.authService
      .resendCodeVerification(data)
      .pipe()
      .subscribe({
        next: (data) => {
          console.log('data', data);
          this.toastrService.success(codeSended);
          this.router.navigate([
            '/auth/code-verification',
            { email: this.generateLoginForm.get('username')?.value },
          ]);
        },
        error: (err) => {
          console.log('error', err);
          if (err?.error) {
            this.toastrService.error(err);
          }
        },
      });
  }
}
