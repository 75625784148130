import { Component, LOCALE_ID } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { ServiceCraService } from '../../services/service-cra.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { CandidateService } from '../../../../main/dossier/services/candidate.service';
import Swal from 'sweetalert2';
import * as saveAs from 'file-saver';
import { SubscriptionService } from '../../../../shared/services/subscription.service';
registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-detail-cra',
  templateUrl: './detail-cra.component.html',
  styleUrls: ['./detail-cra.component.css'],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class DetailCraComponent {
  hasSubscriptionAccessDisplay : boolean = true;
  hasValidStatus : boolean = false;
  listHistorty: any;
  allSelectedValues: any[] = [];
  listOfCra: any;
  idCra: any;
  listDetailsCraForMonths: any;
  totalTypeOfCra: any;
  rowIndex!: number;
  numberOfDays!: number;
  addCraData: any;
  alladdCraData: any[] = [];
  totalClientOfCra: any;
  listClientOfCra: any[] = [];
  durationCra: any;
  typeIds: any = [];
  totalDurations: any[] = [];
  searchElement: string = '';
  totalDurationByfamilyByType: any;
  totalDurationForAllFamilies: any;
  type_id!: number;
  idUser: any;
  unknownFamily: boolean = false;
  unknownType: boolean = false;
  currentUser: any;
  company_name: any;
  company_id: any;
  isInvalidDuration: boolean = false;
  durationMapping: any = { 0: 0, 1: 0.25, 2: 0.5, 3: 0.75, 4: 1 };
  id: any;
  selectedCandidate: any;
  sums: any[] = [];
  craAdded: boolean = false;
  sendCra: boolean = false;
  cancelCra: boolean = false;
  validationCraState: any;
  items_values = [
    { id: 0, name: '0' },
    { id: 1, name: '0.25' },
    { id: 2, name: '0.5' },
    { id: 3, name: '0.75' },
    { id: 4, name: '1' },
  ];

  totalDurationByDay: { day: number; total_duration: number }[] = [];

  testArray: any = [1, 2, 3];
  modelSlect = '1';
  selectedValues: { [type_id: number]: { duration: any }[] } = {};
  previousValues: { [typeId: string]: { [j: number]: any } } = {};
  intermediateValue: any = null;
  unsubscribeAll: Subject<void> = new Subject();
  searchFilter: string = '';
  isEnvoyerDisabled: boolean = false;
  activeRow: { index: number | null; type: string | null } = {
    index: null,
    type: null,
  };
  combinedList: any[] = [];
  show: boolean = false;
  desiredCRA: any;
  clickedIndex: boolean = false;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek',
    },
  };
  constructor(
    private serviceCra: ServiceCraService,
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private candidatService: CandidateService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.activatedRoute.paramMap.subscribe({
      next: (params) => {
        this.idCra = params.get('idCra')!;
      },
    });

    this.getCandidateById();
    this.calculateSums();
    this.checkEnvoyerButton();
    this.getlistClientOfCra();
    setTimeout(() => {
      this.sortedListClientOfCra();
      this.show = true;

    }, 5000);
    this.checkAccess();
   
  }

  calculateSums(): void {
    const daysArray = this.generateDaysArray();
    const numDays = daysArray.length;
    const sums = new Array(numDays).fill(0);
    for (const typeId in this.selectedValues) {
      this.selectedValues[typeId].forEach((day, index) => {
        if (this.isWeekend(index + 1) || this.isHoliday(index + 1)) {
          return;
        }
        const code = day.duration;
        if (code != null && this.durationMapping[code] != null) {
          sums[index] += this.durationMapping[code];
        }
      });
    }

    this.sums = sums;
  }
  checkEnvoyerButton(): void {
    const filteredSums = this.sums.filter((_, index) => {
      const dayIndex = index + 1; // Adjust for 1-based indexing
      return !this.isWeekend(dayIndex) && !this.isHoliday(dayIndex);
    });

    const allFilled = filteredSums.every((sum) => sum === 1);
    this.isEnvoyerDisabled = !allFilled;
  }
  validateTotals(): boolean {
    return this.sums.every((sum) => sum <= 1);
  }

  deleteInput(typeId: number, dayIndex: number): void {
    if (this.selectedValues[typeId] && this.selectedValues[typeId][dayIndex]) {
      this.selectedValues[typeId][dayIndex].duration = null;
      this.calculateSums();
      this.checkEnvoyerButton();
    }
  }

  getCandidateById(): void {
    this.currentUser = JSON.parse(
      this.localStorageService.getData('UserInfo')!
    );
    this.candidatService.getCandidate(this.currentUser.id).subscribe({
      next: (res) => {
        this.company_name = res.data[0]?.ESN_name;
        if (this.company_name) {
          this.company_id = res.data[0]?.id;
          this.getListTypeOfCra();
          this.getDetailsListCra();
        }
        else {
          this.serviceCra.getHistoryCompany().subscribe({
            next: (res) => {
              this.listHistorty= res?.data?.data
               const listCra = this.listHistorty.map((item: any) => item.cra).flat()
               const consultantId = listCra.find((item: any) => item.id == this.idCra).consultant_id;
               const rowIndex = this.listHistorty.findIndex((item: any) => item.id == consultantId)
             if (rowIndex != -1 && this.listHistorty[rowIndex]) {
              this.company_name = this.listHistorty[rowIndex].e_s_n.name;
              this.getListTypeOfCra();
              this.getDetailsListCra();
            }
            },
            error: (err) => {
              console.error('Error fetching history company:', err);
            }
          });
        }
      },
      error: () => {
        this.toastr.error('Failed to load candidate data');
      },
    });
  }

  getListTypeOfCra() {
    this.serviceCra.getListOfCra(this.company_name).subscribe({
      next: (res) => {
        this.listOfCra = res?.data.items.map((item: any) => ({
          ...item,
          checked: false,
        }));
        this.typeIds = this.listOfCra.map((item: any) => item.type_id);
        this.totalTypeOfCra = res.data.count;
      },
      error: () => {
        this.toastr.error('Failed to load CRA types');
      },
    });
  }

  getlistClientOfCra() {
    this.serviceCra.getClientsByCandidate(this.currentUser.id).subscribe({
      next: (res) => {
        this.listClientOfCra = res.data.map((client: any) => ({
          ...client,
          checked: false,
        }));
      },
      error: () => {
        this.toastr.error('Failed to load clients');
      },
    });
  }

  getDetailsListCra(): void {
    this.serviceCra
      .getDetailsCra(this.idCra, this.company_name, this.currentUser.id)
      .subscribe({
        next: (res) => {
          if (!res?.data) {
            this.toastr.error('No data found');
            this.spinner.hide();
            return;
          }

          this.listDetailsCraForMonths = res.data;
          this.desiredCRA = this.listDetailsCraForMonths.desiredCRA;
          if (!this.desiredCRA) {
            this.toastr.error('CRA details missing');
            this.spinner.hide();
            return;
          }
          const checkStatus = res.data?.desiredCRA.status;
          const archivedStatus = res.data?.desiredCRA?.Consultant_archived;
          if ( checkStatus === 3 || checkStatus === 5 || archivedStatus === '2') {
            this.hasValidStatus = !this.hasValidStatus;
          }else {
            this.hasValidStatus = this.hasValidStatus;
          }
          this.totalDurationByDay = this.desiredCRA.total_duration_by_day;
          this.listDetailsCraForMonths.desiredCRA.ligne_cra =
            this.desiredCRA.ligne_cra?.map((item: any) => {
              if (item?.days) {
                item.days = item.days.map((day: any) => ({
                  ...day,
                  values: this.items_values,
                }));
              }
              return item;
            }) || [];
          const numberOfRows = Number(this.numberOfRows);
          const numberOfDays = Number(
            this.listDetailsCraForMonths.numberOfDays
          );
          this.selectedValues = {};
          for (let i = 0; i < numberOfRows; i++) {
            this.selectedValues[i] = Array.from(
              { length: numberOfDays },
              () => ({ duration: null })
            );
          }
          this.desiredCRA.ligne_cra?.forEach((cra: any) => {
            const type_id = cra.type_id;
            cra.days.forEach((day: any) => {
              const dayIndex = day.day - 1;
              const duration = day.duration ? parseInt(day.duration) : null;
              if (!this.selectedValues[type_id]) {
                this.selectedValues[type_id] = Array.from(
                  { length: numberOfDays },
                  () => ({ duration: null })
                );
              }
              this.selectedValues[type_id][dayIndex].duration = duration;
            });
          });

          this.totalDurations = [];
          this.desiredCRA.total_duration_by_day?.forEach(
            (totalDuration: any) => {
              const dayIndex = totalDuration.day - 1;
              this.totalDurations[dayIndex] = totalDuration.total_duration;
            }
          );

          this.calculateSums();
          this.totalDurationByfamilyByType =
            this.desiredCRA.totalDurationByfamilyByType;
          this.totalDurationForAllFamilies =
            this.desiredCRA.totalDurationForAllFamilies;

          //this.spinner.hide();
        },
        error: () => {
          this.toastr.error('Failed to load');
          this.spinner.hide();
        },
      });
  }

  filteredListClientOfCra() {
    const searchTerm = this.searchFilter?.toLowerCase();

    if (!searchTerm) {
      return this.listClientOfCra;
    }

    return this.listClientOfCra.filter((client: any) => {
      const clientName = client?.client_final_e_s_n?.name?.toLowerCase();
      return clientName?.includes(searchTerm);
    });
  }

  remplirLigne(typeId: number, index: number, type: string): void {
    this.activeRow = { index, type };
    this.allSelectedValues.length = 0;
    const isClient = type === 'project';
    this.combinedList[index].checked = true;
    if (!this.combinedList[index].days) {
      this.combinedList[index].days = Array.from(
        { length: this.listDetailsCraForMonths.numberOfDays },
        () => ({ duration: null })
      );
    }
    if (!this.selectedValues[typeId]) {
      this.selectedValues[typeId] = Array.from(
        { length: this.listDetailsCraForMonths.numberOfDays },
        () => ({ duration: null })
      );
    }
    this.combinedList[index].days.forEach((day: any, i: number) => {
      const currentVal = this.selectedValues[typeId][i]?.duration;
      let matchedItem;
      if (currentVal == null || currentVal === 0) {
        const neededValue = 1 - this.sums[i];
        if (neededValue != 0) {
          matchedItem = this.items_values.find(
            (item) => parseFloat(item.name) === neededValue
          );
        }

        if (matchedItem) {
          day.duration = matchedItem.id;
          this.selectedValues[typeId][i].duration = matchedItem.id;
        }
      }
    });
    this.toastr.success('Ligne remplie avec succès.');
    this.calculateSums();
    this.checkEnvoyerButton();
    this.buildCraData(typeId, isClient);
  }

  buildCraData(typeId: number, isClient: boolean) {
    const uniqueSelectedValues = new Set();
    this.selectedValues[typeId].forEach((value: any, day: number) => {
      if (this.isWeekend(day + 1) || this.isHoliday(day + 1)) {
        return;
      }

      const obj = {
        client_id: isClient ? typeId : '',
        type_id: isClient ? '' : typeId,
        duration: value.duration,
        day: day + 1,
      };
      uniqueSelectedValues.add(JSON.stringify(obj));
    });
    const uniqueValuesArray = Array.from(uniqueSelectedValues, (item) =>
      JSON.parse(item as string)
    );
    const data = {
      date: this.listDetailsCraForMonths?.desiredCRA?.date,
      ligneCra: uniqueValuesArray,
    };
    this.addCraData = [];
    this.addCraData = data;
    const dataSet = new Set(
      this.alladdCraData.map((item) => JSON.stringify(item))
    );
    dataSet.add(JSON.stringify(data));
    this.alladdCraData = Array.from(dataSet, (item) =>
      JSON.parse(item as string)
    );
  }

  removeLigne(clientId: number, index: number, type: string): void {
    this.activeRow = { index: null, type: null };
    this.isEnvoyerDisabled = true;
    const isClient = type === 'project';
    const typeId = isClient ? clientId : this.combinedList[index].id;
    this.combinedList[index].checked = false;
    if (!this.combinedList[index].days) {
      this.combinedList[index].days = Array.from({ length: 31 }, () => ({
        duration: null,
      }));
    } else {
      this.combinedList[index].days.forEach(
        (day: any) => (day.duration = null)
      );
    }

    this.selectedValues[typeId] = this.combinedList[index].days.map(() => ({
      duration: null,
    }));
    this.buildCraData(typeId, isClient);
    this.calculateSums();
    this.checkEnvoyerButton();
  }

  onDurationChange(
    event: any,
    typeId: number,
    type: string,
    dayIndex: number,
    day: any
  ): void {
    let value = this.previousValues[typeId][dayIndex] || 0;
    const newTotal =
      this.sums[dayIndex] -
      this.durationMapping[value] +
      this.durationMapping[event.target.value];

    if (newTotal > 1) {
      this.toastr.error('Le total des activités ne peut pas dépasser 1.');
      const selectElement = document.getElementById(
        `dispo-${typeId}-${dayIndex}`
      ) as HTMLSelectElement;
      if (selectElement) {
        selectElement.selectedIndex = -1;
        selectElement.className =
          'form-control custom-ng-select option-valuenull ng-untouched ng-pristine ng-valid ng-star-inserted';
      }
    } else {
      value = event.target.value;
    }

    this.previousValues[typeId][dayIndex] = value;
    this.selectedValues[typeId][dayIndex].duration = value;

    this.calculateSums();
    if (!this.sums.every((sum) => sum != 1)) {
      this.activeRow = { index: null, type: null };
    }
    const isClient = type === 'client';
    const sourceList = isClient ? this.listClientOfCra : this.listOfCra;
    const rowIndex = sourceList.findIndex(
      (item: any) =>
        (isClient && item.client_id === typeId) ||
        (!isClient && item.type_id === typeId)
    );

    if (rowIndex != -1 && sourceList[rowIndex].days) {
      sourceList[rowIndex].days[dayIndex].duration = value;
    }

    this.buildCraData(typeId, isClient);
    this.checkEnvoyerButton();

    if (day?.values) {
      day.values = [...day.values];
    }
  }

  sortedListClientOfCra() {
    if (!this.listClientOfCra || !this.listOfCra) {
      this.combinedList = [];
      return;
    }

    const sortedListClientOfCra = this.listClientOfCra.sort(
      (a: any, b: any) => {
        if (a.assigned && !b.assigned) return -1;
        if (!a.assigned && b.assigned) return 1;
        return 0;
      }
    );
    this.combinedList = [
      ...sortedListClientOfCra.map((item) => ({ ...item, type: 'project' })),
      ...this.listOfCra.map((item: any) => ({ ...item, type: 'timesheet' })),
    ];
    this.desiredCRA.ligne_cra.forEach((element: any, index: any) => {
      const hasNonNullDuration = element.days.some(
        (day: any) => day.duration !== null
      );

      this.combinedList[index].checked = hasNonNullDuration;
    });
        this.spinner.hide();
    return this.combinedList;
  }

  updateSourceList(
    typeId: number,
    type: string,
    dayIndex: number,
    value: string
  ): void {
    const isClient = type === 'client';
    const sourceList = isClient ? this.listClientOfCra : this.listOfCra;

    const rowIndex = sourceList.findIndex(
      (item: any) =>
        (isClient && item.client_id === typeId) ||
        (!isClient && item.type_id === typeId)
    );

    if (rowIndex !== -1 && sourceList[rowIndex].days) {
      sourceList[rowIndex].days[dayIndex].duration = value;
    }
  }

  resetCraSelections(): void {
    this.allSelectedValues = [];
    this.listClientOfCra = this.listClientOfCra.map((item: any) => {
      item['checked'] = false;
      return item;
    });
    this.listOfCra = this.listOfCra.map((item: any) => {
      item['checked'] = false;
      return item;
    });
  }
  storePreviousValue(typeId: string, j: number, oldValue: string) {
    if (!this.previousValues[typeId]) {
      this.previousValues[typeId] = {};
    }
    this.previousValues[typeId][j] = oldValue;
  }

  getTotalDurationByType(familyId: string): any[] {
    const family = this.totalDurationByfamilyByType?.find(
      (fam: any) => fam.family_id === familyId
    );
    return family ? family.types : [];
  }

  numberOfRows(): number[] {
    return Array.from({ length: this.totalTypeOfCra }, (_, index) => index + 1);
  }

  isWeekend(day: number): boolean {
    const weekends = this.listDetailsCraForMonths?.weekends ?? [];
    if (!weekends.length) return false;
    const yearMonth = weekends[0].slice(0, 7);
    const formattedDate = `${yearMonth}-${day.toString().padStart(2, '0')}`;
    return weekends.includes(formattedDate);
  }
  isHoliday(day: number): boolean {
    const holidays = this.listDetailsCraForMonths?.holidays ?? [];
    if (!holidays.length) return false;
    const yearMonth = holidays[0].slice(0, 7);
    const formattedDate = `${yearMonth}-${day.toString().padStart(2, '0')}`;
    return holidays.includes(formattedDate);
  }

  selectedTypeId!: number;

  generateDayNumbersArray(): number[] {
    const numberOfDays = this.listDetailsCraForMonths?.numberOfDays;
    if (!numberOfDays) return [];

    return Array.from({ length: numberOfDays }, (_, index) => index + 1);
  }
  generateDaysArray(): string[] {
    const days: string[] = [];
    const month = this.listDetailsCraForMonths?.desiredCRA?.date;

    if (!month) return days;

    const date = new Date(month);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const numDays = new Date(year, monthIndex + 1, 0).getDate();

    for (let i = 1; i <= numDays; i++) {
      const day = new Date(year, monthIndex, i);
      const formattedDay = day.toLocaleDateString('fr-FR', {
        weekday: 'short',
        day: 'numeric',
      });
      days.push(formattedDay);
    }

    return days;
  }

  exportCra(): void {
    this.spinner.show();
    const EXTENSION = '.pdf';
    const COUNTRY = 'FR';

    this.serviceCra
      .exportCra(this.listDetailsCraForMonths?.desiredCRA?.id, COUNTRY)
      .subscribe({
        next: (res: any) => {
          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          let fileName =
            this.listDetailsCraForMonths?.desiredCRA?.name + EXTENSION;
          saveAs(blob, fileName);
          this.spinner.hide();
          this.toastr.success('CRA téléchargé avec succès');
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error('Erreur serveur');
        },
      });
  }
checkAccess(): void {
  const hasSubscriptionAccess =  this.subscriptionService.subscriptionCanAccess('CRA');
  if (hasSubscriptionAccess !== this.hasSubscriptionAccessDisplay) {
    this.hasSubscriptionAccessDisplay = hasSubscriptionAccess;
  } 
}

  addCra(): void {
    if (this.alladdCraData.length === 0) {
      this.toastr.info("Vous n'avez rien à changer dans le CRA.");
      return;
    }

    this.spinner.show();
    this.serviceCra.addListCra(this.alladdCraData).subscribe({
      next: () => {
        this.isEnvoyerDisabled = false;
        this.toastr.success('CRA ajouté avec succès.');
        this.spinner.hide();
        this.craAdded = true;
        this.resetCraSelections();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message || 'Erreur serveur');
        this.resetCraSelections();
      },
    });
  }

  rejectCra(): void {
    Swal.fire({
      title: "Voulez-vous vraiment être sûr d'annuler votre CRA ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          cra_id: this.idCra,
          state_validation: '0',
          state: '1',
        };
        this.serviceCra.validateCra(data).subscribe({
          next: () => {
            this.cancelCra = true;
            this.toastr.success('Cra a été annulé avec succès.');
            this.getDetailsListCra();
          },
          error: () => {
            this.toastr.error('Erreur serveur');
          },
        });
      }
    });
  }

  validateCra(): void {
    const incompleteDays = this.sums.filter((sum) => sum < 1).length;
    const confirmationMessage = incompleteDays
      ? 'Il y a des jours non remplis. Voulez-vous quand même envoyer le CRA?'
      : 'Voulez-vous vraiment envoyer votre CRA ?';

    Swal.fire({
      title: confirmationMessage,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          cra_id: this.idCra,
          state: '2',
        };
        this.serviceCra.validateCra(data).subscribe({
          next: (res: any) => {
            this.validationCraState = res.state_validation;
            this.sendCra = true;
            this.toastr.success('Cra a été envoyé avec succès.');
            this.getDetailsListCra();
          },
          error: () => {
            this.toastr.error('Erreur serveur');
          },
        });
      }
    });
  }

  getBackgroundColor(input: any): string {
    if (typeof input === 'string') {
      const colorMap: { [key: string]: string } = {
        Production: 'rgb(252 228 236 / 41%)',
        Interne: 'rgb(232 245 233 / 66%)',
        Absence: 'rgb(224 247 250 / 48%)',
      };
      return colorMap[input] || '#ffffff';
    } else if (input?.day) {
      return this.isWeekend(input.day)
        ? '#FDEFEE'
        : this.isHoliday(input.day)
        ? '#f4587a'
        : '';
    }
    return '#ffffff';
  }

  getNgSelectClasses(typeId: any, j: number): any {
    const duration = this.selectedValues[typeId]?.[j]?.duration;
    return {
      'custom-ng-select': true,
      [`option-value${duration}`]: duration !== undefined,
    };
  }

  getVisibility(day: any): string {
    return !this.isWeekend(day.day) && !this.isHoliday(day.day)
      ? 'visible'
      : 'hidden';
  }
  getTotalCellStyles(dayIndex: number, total: number) {
    const day = dayIndex + 1;
    const isWeekendDay = this.isWeekend(day);
    const isHolidayDay = this.isHoliday(day);

    const backgroundColor = isWeekendDay
      ? '#FDEFEE'
      : isHolidayDay
      ? '#f4587a'
      : '';
    const visibility = !(isWeekendDay || isHolidayDay) ? 'visible' : 'hidden';

    return {
      'background-color': backgroundColor,
      color: this.getTotalColor(total),
      visibility: visibility,
    };
  }
  getTotalColor(total: number): string {
    if (total === 1) {
      return 'green';
    } else if (total < 1) {
      return 'red';
    }
    return 'black';
  }

  trackByCra(index: number, cra: any): number {
    return cra.type_id;
  }

  trackByDay(index: number, day: any): any {
    return day.day;
  }
  trackById(index: number, item: any): any {
    return item?.type == 'timesheet' ? item?.id : item?.client_final_e_s_n?.id;
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
