import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { SubscriptionService } from '../shared/services/subscription.service';
import { ServiceCraService } from '../main/cra/services/service-cra.service';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionHasFeatureGuard implements CanActivate {
  listHistorty: any;
  constructor(
    private subscriptionService: SubscriptionService,
    private serviceCra: ServiceCraService,
    private router: Router
  ) { 
  }
  getlistHistory(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.serviceCra.getHistoryCompany().subscribe({
        next: (res) => {
          const listHistory = res?.data?.data || [];
          resolve(listHistory.length > 0);
        },
        error: (err) => {
          console.error('Error fetching history company:', err);
          resolve(false);
        }
      });
    });
  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {    
    const featureName = route.data['featureName'];
    const hasSubscriptionAccess = this.subscriptionService.subscriptionCanAccess(featureName);
    const hasHistory = await this.getlistHistory();
    if (hasHistory || hasSubscriptionAccess) {
      return true;
    } else {
      this.router.navigate(['/404']);
      return false;
    }
  }
}
