import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICountry, State } from 'country-state-city';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environnements/environment';
import { ConsultantService } from '../../services/consultant.service';
import { PaysService } from '../../../../shared/services/pays.service';
import { headerColumns3 } from '../../../../main/entreprise/models/company';

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.css'],
})
export class ConsultantComponent {
  isCollapsed = true; // Initial state
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  listConsultant: any = [];
  typesCompanies = [
    { id: 1, name: 'Entreprise' },
    { id: 2, name: 'ESN' },
    { id: 3, name: 'Client Final' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ];
  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  currentSortColumn: string = 'last_name';
  listNames: string[] = [];
  dataHeader: any = [];
  skillsList: { name_FR: string }[] = [];
  pagination: boolean = false;

  /* string */
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  fileError: string = '';
  fileExtention: string = '';
  fileName: string = '';
  mobilities: any[] = [];
  countries: any[] = [];
  isLoadSpinner: boolean = true;

  note: number = 4;
  stars: number[] = Array(5).fill(0);
  translations: { [key: string]: string } = {
    Afghanistan: 'Afghanistan',
    'South Africa': 'Afrique du Sud',
    Albania: 'Albanie',
    Algeria: 'Algérie',
    Germany: 'Allemagne',
    Andorra: 'Andorre',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    'Saudi Arabia': 'Arabie saoudite',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    Belarus: 'Biélorussie',
    Bolivia: 'Bolivie',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    Brazil: 'Brésil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    Chile: 'Chili',
    China: 'Chine',
    Cyprus: 'Chypre',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    'Congo - Brazzaville': 'Congo-Brazzaville',
    'Congo - Kinshasa': 'Congo-Kinshasa',
    'North Korea': 'Corée du Nord',
    'South Korea': 'Corée du Sud',
    'Costa Rica': 'Costa Rica',
    'Ivory Coast': 'Côte d’Ivoire',
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Denmark: 'Danemark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'United Arab Emirates': 'Émirats arabes unis',
    Eritrea: 'Érythrée',
    Spain: 'Espagne',
    Eswatini: 'Eswatini',
    Estonia: 'Estonie',
    'United States': 'États-Unis',
    Ethiopia: 'Éthiopie',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Grenada: 'Grenade',
    Greenland: 'Groenland',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    'Equatorial Guinea': 'Guinée équatoriale',
    Guyana: 'Guyana',
    'French Guiana': 'Guyane française',
    Haiti: 'Haïti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    Iraq: 'Irak',
    Iran: 'Iran',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    Laos: 'Laos',
    Latvia: 'Lettonie',
    Lebanon: 'Liban',
    Lesotho: 'Lesotho',
    Liberia: 'Libéria',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'North Macedonia': 'Macédoine du Nord',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    Micronesia: 'Micronésie',
    Moldova: 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibie',
    Nauru: 'Nauru',
    Nepal: 'Népal',
    Netherlands: 'Pays-Bas',
    'New Caledonia': 'Nouvelle-Calédonie',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    Palestine: 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Romania: 'Roumanie',
    Russia: 'Russie',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint-Barthélemy',
    'Saint Helena': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
    'Saint Lucia': 'Sainte-Lucie',
    'Saint Martin': 'Saint-Martin',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten': 'Saint-Martin',
    Slovakia: 'Slovaquie',
    Slovenia: 'Slovénie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Georgia and the South Sandwich Islands':
      'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Swaziland: 'Eswatini',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    Syria: 'Syrie',
    Taiwan: 'Taïwan',
    Tajikistan: 'Tadjikistan',
    Tanzania: 'Tanzanie',
    Thailand: 'Thaïlande',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Kingdom': 'Royaume-Uni',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Viêt Nam',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Zimbabwe: 'Zimbabwe',
  };
  paysList: ICountry[] = [];

  stateList: any = [];
  /* formGroup */
  searchForm: FormGroup = this.createSearchForm();
  /* form data */
  formData = new FormData();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  pays = [
    { id: 1, name: 'France' },
    { id: 2, name: 'Tunisie' },
    { id: 3, name: 'Turquie' },
    { id: 4, name: 'Allemagne' },
  ];

  salarie = [
    { id: 1, name: '0 - 20' },
    { id: 2, name: '20 - 50' },
    { id: 3, name: '50 - 100' },
    { id: 3, name: '100 - 200' },
    { id: 4, name: '> 200' },
  ];

  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];

  desired_contract = [
    { id: 1, name: 'Alternance' },
    { id: 2, name: 'Autres type de contrat' },
    { id: 3, name: 'CDD' },
    { id: 4, name: 'CDI' },
    { id: 5, name: 'Freelance' },
    { id: 6, name: 'Portage salarial' },
    { id: 7, name: 'Stage' },
  ];

  constructor(
    private consultantService: ConsultantService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private paysService: PaysService,
    private sortDataService: ConsultantService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.paysList = this.paysService.getAllCountries();
    this.getListConsultant();
    this.getAllSkills();
    this.getListElementHeader();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchConsultant();
      }
      else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true

        this.getListConsultant();
      }
    }
  }
  translateCountryName(name: string): string {
    return this.translations[name] || name;
  }
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  getSortName(columnName: string): string {
    switch (columnName) {
      case 'Prénom':
        return 'first_name';
      case 'Poste':
        return 'desired_workstation';
      case 'TJM':
        return 'tjm';
      case 'Salaire':
        return 'desired_salary';
      case 'Compétence':
        return 'sub_categories_names';
      case 'Pays':
        return 'current_country';
      case 'Dernière connexion':
        return 'last_connection';
      default:
        return columnName;
    }
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.consultantService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => { },
        error: () => { },
      });
  }
  getListElementHeader() {
    this.consultantService
      .getHeaderElements(7)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => { },
      });
  }

  getContractLabel(contract: string | number): string {
    const labels: { [key: string]: string } = {
        "1": "Alternance",
        "2": "Autres type de contrat",
        "3": "CDD",
        "4": "CDI",
        "5": "Freelance",
        "6": "Portage salarial",
        "7": "Stage",
    };
    return labels[contract] || "Unknown";
}
  /* sort data */
  // sortData(columnName: string) {
  //   let sort = {
  //     sort: this.currentSortDirection,
  //     sortAttribute: this.currentSortColumn,
  //   };

  //   if (this.currentSortColumn === columnName) {
  //     this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1; // Toggle sort direction
  //   } else {
  //     this.currentSortColumn = columnName; // Change the sort column
  //     this.currentSortDirection = 1; // Reset sort direction to ascending
  //   }
  //   this.getListConsultant(sort);
  // }
  sortAttr: string = '';
  headerColumns: { checked: boolean; name: string; code: string }[] =
    headerColumns3;
  sortData(name?: string) {
    for (const iterator of this.headerColumns) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    if (this.searchForm.dirty)
      this.listConsultant = this.sortDataService.sortArray(
        this.listConsultant,
        name,
        this.currentSortDirection
      );
    else this.getListConsultant();
  }
  /* change country select and delete the string Governorate from state name*/

  onCountryChange(mode: string): void {
    this.searchForm.get('mobility')?.enable();
    this.countries = this.searchForm.get('destination_country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchForm.get('mobility')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.searchForm.get('mobility')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.searchForm.get('mobility')?.disable();
        this.searchForm.get('mobility')?.setValue(null);
      }
    }
  }

  onMobilityChange(mode: string) {
    this.mobilities = this.searchForm.get('mobility')?.value;
    let paysLists: any[] = [];
    this.countries = this.searchForm.get('destination_country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    this.mobilities = this.searchForm.get('mobility')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }

  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }

    return duplicates;
  }

  /* get all companies */
  getListConsultant(sort?: any) {
    this.spinner.show();
    // let dataPayload = !sort
    //   ? { per_page: this.itemsPerPage, page: this.currentPage }
    //   : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...sort };
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };

    this.consultantService
      .getListConsultant(dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          this.isLoadSpinner = false;
          this.listConsultant = response?.data?.users?.data;
          this.totalItems = response?.data?.users?.total;
          if (this.currentPage > 1 && !response?.data?.users?.data?.length) {
            this.currentPage = 1;
            this.getListConsultant();
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  cleanDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    return dateString.replace('admin', '').trim();
  }

  getSkillsArray(competence: any): string[] {
    if (competence && competence.sub_categories_names) {
      if (typeof competence.sub_categories_names === 'string') {
        return competence.sub_categories_names.split(',').map((s: string) => s.trim());
      } else if (Array.isArray(competence.sub_categories_names)) {
        return competence.sub_categories_names; 
      }
    }
    return []; 
  }

  /* create add consultant form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      desired_salary_range: [data ? data.desired_salary_range : ''],
      desired_salary: [data ? data.desired_salary : ''],
      skillsAnd: [data ? data.skillsAnd : []],
      skillsOr: [data ? data.skillsOr : []],
      tjm: [data ? data.tjm : ''],
      tjm_range: [data ? data.tjm_range : ''],
      desired_workstation: [data ? data.desired_workstation : ''],
      min_experience_years: [data ? data.min_experience_years : ''],
      max_experience_years: [data ? data.max_experience_years : ''],
      desired_contract: [data ? data.desired_contract : []],
      destination_country: [data ? data.destination_country : []],
      mobility: [data ? data.mobility : []],
      search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
    });
  }
  onSalaryRangeInput(event: any) {
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.searchForm.controls['desired_salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }

  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      return parseFloat(value.slice(0, -1)) * 1000;
    } else if (!isNaN(value)) {
      return parseFloat(value) * 1000;
    }
    return parseFloat(value);
  }
  /* search */
  transformSearch(search: string): string {
    search = search.replace(/\|\|/g, 'OR');
    search = search.replace(/&&/g, 'AND');
    search = search.replace(/!/g, 'NOT');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        // If the term is inside double quotes, keep it as is
        return `"${p1}"`;
      } else {
        // If the term is not inside double quotes, add double quotes around it
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `"${escapedTerm}"`;
      }
    });

    // Ensure that even number of quotes are present
    /*  const countQuotes = (search.match(/"/g) || []).length;
      if (countQuotes % 2 !== 0) {
        // If the number of quotes is odd, add a closing quote at the end
        search += '"';
      }
    */
    // Additional adjustment to remove extra backslashes before escaped double quotes
    // search = search.replace(/\\"/g, '"');

    return search;
  }

  isSimpleWord(value: string): boolean {
    // Utilisez une expression régulière pour vérifier si la valeur est un mot simple ou deux mots avec espace
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchConsultant() {
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      years_of_experience: this.searchForm.value.years_of_experience,
      skillsAnd: this.searchForm.value.skillsAnd,
      skillsOr: this.searchForm.value.skillsOr,
      desired_salary_range: this.convertKToNumber(
        this.searchForm.value.desired_salary_range.toString()
      ),
      desired_salary: !this.searchForm.value.desired_salary
        ? ''
        : this.searchForm.value.desired_salary,
      tjm: this.searchForm.value.tjm,
      tjm_range: !this.searchForm.value.tjm_range
        ? ''
        : this.searchForm.value.tjm_range,
      desired_workstation: !this.searchForm.value.desired_workstation
        ? ''
        : this.searchForm.value.desired_workstation,
      min_experience_years: !this.searchForm.value.min_experience_years
        ? ''
        : this.searchForm.value.min_experience_years,
      max_experience_years: !this.searchForm.value.max_experience_years
        ? ''
        : this.searchForm.value.max_experience_years,
      desired_contract: !this.searchForm.value.desired_contract
        ? ''
        : this.searchForm.value.desired_contract,
      destination_country: !this.searchForm.value.destination_country
        ? ''
        : this.searchForm.value.destination_country,
      mobility: !this.searchForm.value.mobility
        ? ''
        : this.searchForm.value.mobility,
      search: this.transformSearch(this.searchForm.value.search),
    };
    this.spinner.show();
    this.consultantService
      .searchConsultant(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchConsultant();
          } else {
            this.listConsultant = res?.data?.data;
            this.totalItems = res?.data?.total;
            this.spinner.hide();
          }
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListConsultant();
    }
    return (this.isRecherche = false);
  }

  getAllSkills() {
    this.consultantService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
        },
      });
  }

  profilConsultantNavigation(id: any) {
    this.router.navigate(['/wegestu/consultant/detailsConsultant', { id: id }]);
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
