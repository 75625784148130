import { Component, ElementRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environnements/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CompanyService } from '../.././../../../app/main/entreprise/services/company.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { headerColumns4 } from '../../../../main/entreprise/models/company';
@Component({
  selector: 'app-society-evaluation',
  templateUrl: './society-evaluation.component.html',
  styleUrls: ['./society-evaluation.component.css'],
})
export class SocietyEvaluationComponent {
  listNames: string[] = [];
  dataHeader: any = [];
  /* object */
  responsableCompany: any;
  /* arrays */
  listCampany: any;

  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  typeCompanyEsn: number = 2;
  pagination: boolean = false;
  /* string */
  mode: string = 'create';
  isLoadSpinner: boolean = true;

  url: string = environment.baseUrl + '/api';
  fileError: string = '';
  fileExtention: string = '';
  fileName: string = '';
  searchElement: string = '';
  linkedinPrefix = 'https://www.linkedin.com/company/';
  activeMenuIndex: number | null = null;
  /* formGroup */
  formData = new FormData();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  searchForm: FormGroup = this.createSearchForm();
  // Initial state
  isCollapsed = true;
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
 
  note: number = 4;
  stars: number[] = Array(5).fill(0);
  currentSortColumn: string = 'name';

  checkboxState: {
    [key: string]: { displayName: string; isChecked: boolean };
  } = {
      logo: { displayName: 'Logo', isChecked: true },
      societe: { displayName: 'Société', isChecked: true },
      type: { displayName: 'Type', isChecked: true },
      siteWeb: { displayName: 'Site Web', isChecked: true },
      avis: { displayName: 'Avis', isChecked: true },
    };

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private sortDataService: CompanyService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.getListCompanies();
    this.getListElementHeader();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    // if (dropdown) {
    //   dropdown.classList.remove('show');
    // }
  }


    /* get pagination items */
    getItems(event?: any) {
      if (event) {
        this.startIndex = event.startIndex;
        this.endIndex = event.endIndex;
        (this.itemsPerPage = event.itemsPerPage),
          (this.currentPage = event.currentPage);
        if (this.searchForm.dirty) {
          this.applyFilter();
        } 
        else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getListCompanies();
        }
      }
    }
  /* search form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_company: [data ? data.ID_company : ''],
      name: [data ? data.name : ''],
      note: [data ? data.note : 0],
      typeComp: [data ? data.typeComp : null],
      blocked: [data ? data.blocked : null],
      country: [data ? data.country : null],
      search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
    });
  }

  /* sort data */
  // sortData() {
  //   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  //   this.getListCompanies()
  // }

  // sortData(columnName: string) {
  //   let sort = { sort: this.currentSortDirection, sortAttribute: this.currentSortColumn }

  //   if (this.currentSortColumn === columnName) {
  //     this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1; // Toggle sort direction
  //   } else {
  //     this.currentSortColumn = columnName; // Change the sort column
  //     this.currentSortDirection = 1; // Reset sort direction to ascending
  //   }
  //   this.getListCompanies(sort);
  // }
  sortAttr: string = '';
  sortData(name?: string) {
    for (const iterator of this.headerColumns) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    if (this.searchForm.dirty)
      this.listCampany = this.sortDataService.sortArray(
        this.listCampany,
        name,
        this.currentSortDirection
      );
    else this.getListCompanies();
  }
  
  filterColumns(columnName: string): boolean {
    return this.checkboxState[columnName]?.isChecked || false;
  }

  updateCheckboxState(columnName: string): void {
    const checkbox = this.checkboxState[columnName];
    if (checkbox) {
      checkbox.isChecked = !checkbox.isChecked;
    }
  }

  getCheckboxStateEntries(): [
    string,
    { displayName: string; isChecked: boolean }
  ][] {
    return Object.entries(this.checkboxState);
  }

  getListCompanies(sort?: any) {

    // let dataPayload = !sort ? { per_page: this.itemsPerPage, page: this.currentPage, type: this.typeCompanyEsn } : { ...{ per_page: this.itemsPerPage, page: this.currentPage, type: this.typeCompanyEsn }, ...sort }
    let dataPyload = this.sortAttr == '' ? { per_page: this.itemsPerPage, page: this.currentPage, type: this.typeCompanyEsn } : { ...{ per_page: this.itemsPerPage, page: this.currentPage, type: this.typeCompanyEsn }, ...{ sort: this.currentSortDirection, sortAttribute: this.sortAttr } }

    this.spinner.show();

    this.companyService.listCompany(dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (response) => {
        this.isLoadSpinner = false;

        this.listCampany = response?.data?.data
        // .slice(0, this.endIndex);
        this.totalItems = response.data.total;
        if (this.currentPage > 1 && !response.data.data.length) {
          this.currentPage = 1
          this.getListCompanies()
        }
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      },
    });
  }
  company = {
    average_note: 1, // or the initial value of your note
  };
  headerColumns: { checked: boolean; name: string; code: string }[] =
  headerColumns4;
 

getSortName(columnName: string): string {
  switch (columnName) {
    case 'Logo entreprise':
      return 'Logo';
    case 'Nom entreprise':
      return 'name';
    case 'Type entreprise':
      return 'type';
    case 'Site Web':
      return 'website_link';
    case 'Avis':
      return 'average_note';
    default:
      return columnName;
  }
}

changeSelection(event: any, data: any, i: number) {
  this.listNames = [];
  data.checkAdmin = event.target.checked;

  for (const iterator of this.dataHeader) {
    if (iterator.checkAdmin) {
      this.listNames.push(iterator.name);
    }
  }
  this.changeSelectionHeadrElements(data);
}

getWebsiteLink(link: string | null): string | null {
  if (!link) {
    return null;
  }
  return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
}

changeSelectionHeadrElements(data: any) {
  let payload = {
    sub_page_id: data?.id,
  };
  this.companyService
    .changeDelectedElementHeader(payload)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: () => { },
      error: () => { },
    });
}
getListElementHeader() {
  this.companyService
    .getHeaderElements(28)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: (res) => {
        this.dataHeader = res.data;
        for (const iterator of this.dataHeader) {
          if (
            (iterator.checked == 'oui' && !iterator.checked_user) ||
            (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
            (iterator.checked == 'oui' && iterator.checked_user == 'oui')
          )
            this.listNames.push(iterator.name);
        }
        this.dataHeader.map((el: any) => {
          if (
            (el.checked == 'oui' && !el.checked_user) ||
            (el.checked_user == 'oui' &&
              (el.checked == 'non' || el.checked == 'oui'))
          ) {
            return (el.checkAdmin = true);
          } else {
            return (el.checkAdmin = false);
          }
        });
      },
      error: () => { },
    });
}
  onRatingChange(newRating: number) {
    if (newRating < 1) {
      this.company.average_note = 1;
    } else {
      this.company.average_note = newRating;
    }
  }
  /* filter data on key up input */
  /* search */
  clickCount: number = 0;

  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;

      let data = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        name: this.searchElement.trim().toLowerCase(),
      };

      this.spinner.show();

      this.companyService
        .searchCompany(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.currentPage = 1; // Ensure currentPage is reset to 1
            this.endIndex = 5; // Ensure endIndex is reset

            this.listCampany = res?.data?.data;
            this.totalItems = res.data.total;

            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
          },
        });
    }
  }


  /* ************************* navigation company ********************* */
  profilCompanyNavigation(id: any) {
    this.router.navigate(['/wegestu/entreprises/details', { id: id }]);
  }

  /* ************************* navigation contact responsable ********************* */
  reviewCompanyNavigation(id: any, redirection: any) {
    this.router.navigate(['/wegestu/avis/review-company', { idCompany: id, redirection: redirection }])
  }

  listAllReviewsCompanyNavigation(id: any) {
    this.router.navigate([
      '/wegestu/evaluations/all-reviews',
      { idCompany: id },
    ]);
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
