import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../../../main/entreprise/services/company.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { environment } from '../../../../../environnements/environment';
import { headerColumns4 } from '../../../../main/entreprise/models/company';
@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.css'],
})
export class MyReviewsComponent {
  listNames: string[] = [];
  listCampany: any
  dataHeader: any = [];
  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  note: number = 4;
  stars!: number;
  id: any;
  listDefaultReview: any;
  companyDetails: any;
  currentUser: any;
  idCandidate: number = 0;
  listCompany: any;
  searchElement: string = '';
  currentSortDirection: number = 1;
  pagination: boolean = false;
  isLoadSpinner: boolean = true;
  // searchForm: FormGroup = this.createSearchForm();
  url: string = environment.baseUrl + '/api';
  dataCompany: any = [];

  checkboxState: {
    [key: string]: { displayName: string; isChecked: boolean };
  } = {
      logo: { displayName: 'Logo', isChecked: true },
      societe: { displayName: 'Société', isChecked: true },
      type: { displayName: 'Type', isChecked: true },
      siteWeb: { displayName: 'Site Web', isChecked: true },
      avis: { displayName: 'Avis', isChecked: true },
    };

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private sortDataService: CompanyService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.getIdCandidate();
    this.getListElementHeader();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }
  getIdCandidate() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idCandidate = JSON.parse(this.currentUser).id;
    this.getListCompaniesReviews();
  }

  /*get list Companies reviews by candidate */
  getListCompaniesReviews() {
    let data = this.sortAttr == '' ? { user_id: this.idCandidate, per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ user_id: this.idCandidate, sort: this.currentSortDirection, sortAttribute: this.sortAttr } }

    this.spinner.show()
    this.companyService.getListCompaniesReviews(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res: any) => {
        this.isLoadSpinner = false;
        this.listCompany = res?.data?.data
        this.totalItems = res?.data?.total
        if (this.currentPage > 1 && !res.data.data.length) {
          this.currentPage = 1
          this.getListCompaniesReviews()
        }
        this.spinner.hide()
      },
      error: () => {
        this.spinner.hide()
      },
    })
  }
  headerColumns: { checked: boolean; name: string; code: string }[] =
  headerColumns4;
getSortName(columnName: string): string {
  switch (columnName) {
    case 'Logo':
      return 'Logo';
    case 'Société':
      return 'name';
    case 'Type':
      return 'type';
    case 'Site Web':
      return 'website_link';
    case 'Avis':
      return 'average_note';
   
    default:
      return columnName;
  }
}

changeSelection(event: any, data: any, i: number) {
  this.listNames = [];
  data.checkAdmin = event.target.checked;

  for (const iterator of this.dataHeader) {
    if (iterator.checkAdmin) {
      this.listNames.push(iterator.name);
    }
  }
  this.changeSelectionHeadrElements(data);
}

changeSelectionHeadrElements(data: any) {
  let payload = {
    sub_page_id: data?.id,
  };
  this.companyService
    .changeDelectedElementHeader(payload)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: () => { },
      error: () => { },
    });
}
getListElementHeader() {
  this.companyService
    .getHeaderElements(28)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: (res) => {
        this.dataHeader = res.data;
        for (const iterator of this.dataHeader) {
          if (
            (iterator.checked == 'oui' && !iterator.checked_user) ||
            (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
            (iterator.checked == 'oui' && iterator.checked_user == 'oui')
          )
            this.listNames.push(iterator.name);
        }
        this.dataHeader.map((el: any) => {
          if (
            (el.checked == 'oui' && !el.checked_user) ||
            (el.checked_user == 'oui' &&
              (el.checked == 'non' || el.checked == 'oui'))
          ) {
            return (el.checkAdmin = true);
          } else {
            return (el.checkAdmin = false);
          }
        });
      },
      error: () => { },
    });
}

  filterColumns(columnName: string): boolean {
    return this.checkboxState[columnName]?.isChecked || false;
  }

  updateCheckboxState(columnName: string): void {
    const checkbox = this.checkboxState[columnName];
    if (checkbox) {
      checkbox.isChecked = !checkbox.isChecked;
    }
  }

  getCheckboxStateEntries(): [
    string,
    { displayName: string; isChecked: boolean }
  ][] {
    return Object.entries(this.checkboxState);
  }

  /* ************************* navigation to reviews by company ********************* */
  reviewCandidateNavigation(idcompany: any) {
    this.router.navigate([
      '/wegestu/avis/review-company',
      { idCompany: idcompany },
    ]);
  }

  /* ************************* navigation to details company ********************* */
  companyDetailsNavigation(idcompany: any) {
    this.router.navigate(['/wegestu/entreprises/details', { id: idcompany }]);
  }





/* get pagination items */
getItems(event?: any) {
  if (event) {
    this.startIndex = event.startIndex;
    this.endIndex = event.endIndex;
    (this.itemsPerPage = event.itemsPerPage),
      (this.currentPage = event.currentPage);
    if (this.searchElement!= '') {
      this.applyFilter();
    } 
    else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
      this.pagination = true
      this.getListCompaniesReviews();
    }
  }
}

  /* sort data */
  // sortData() {
  //   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  //   this.getListCompaniesReviews()
  // }
  getWebsiteLink(link: string | null): string | null {
    if (!link) {
      return null;
    }
    return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
  }
  sortAttr: string = ''
  sortData(name?: string) {
    for (const iterator of this.headerColumns) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
   
      this.listCampany = this.sortDataService.sortArray(
        this.listCampany,
        name,
        this.currentSortDirection
      );
     this.getListCompaniesReviews();
  }
  clickCount: number = 0;
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      // if (this.clickCount == 1) this.currentPage = 1;

      let data = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        sort: this.currentSortDirection,
        user_id: this.idCandidate,
        search: this.searchElement?.trim().toLowerCase(),
      };

      this.spinner.show();
      this.companyService
        .searchCompanyreviewd(this.idCandidate, data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res: any) => {

            if (this.currentPage > 1) {
              this.currentPage = 1;

              this.endIndex = 5;
              this.applyFilter();
            } else {
              this.listCompany = res?.data?.data;
              this.totalItems = res?.data?.total
            }

            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
          },
        });
    }
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
