import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { ListJobsComponent } from './components/list-jobs/list-jobs.component';
import { CandidaturesComponent } from './components/candidatures/candidatures.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { JobsArchivedComponent } from './components/jobs-archived/jobs-archived.component';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { DetailsJobOfferComponent } from './components/details-job-offer/details-job-offer.component';
import { DetailsJobRequestComponent } from './components/details-job-request/details-job-request.component';
import { FieldErrorModule } from '../../shared/shared-field-error/field-error.module';
import { PipesModuleModule } from '../../shared/pipes/pipes-module.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JobEntrepriseComponent } from './components/job-entreprise/job-entreprise/job-entreprise.component';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    ListJobsComponent,
    CandidaturesComponent,
    JobsArchivedComponent,
    DetailsJobOfferComponent,
    DetailsJobRequestComponent,
    JobEntrepriseComponent,
  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    FormsModule,
    NgSelectModule,
    PaginationModule,
    NgxSpinnerModule,
    FieldErrorModule,
    PaginationModule,
    PipesModuleModule,
    ReactiveFormsModule,
    CalendarModule,
    TableModule,
    MenuModule,
    DropdownModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    NgbTooltipModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }]

})
export class JobsModule {}
