import { Component,ViewChild, ElementRef , TemplateRef} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../../environnements/environment';
import { CJobOffer } from '../../../models/jobOffer';
import { ICountry, State } from 'country-state-city';
import { JobsService } from '../../../services/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { applySuccess, serverError } from '../../../../../shared/shared-models/message';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { headerColumns5 } from '../../../models/jobOffer';
import { PaysService } from '../../../../../shared/services/pays.service';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from '../../../../../shared/shared-services/local-storage.service'
@Component({
  selector: 'app-job-entreprise',
  templateUrl: './job-entreprise.component.html',
  styleUrls: ['./job-entreprise.component.css'],
})
export class JobEntrepriseComponent {
  loadSpinner: boolean = false;
  isLoadSpinner: boolean = true;
  isRecherche: boolean = false;
  @ViewChild('send', { static: true }) send!: TemplateRef<any>;
  activeRowIndex: number | null = null;
  hasPassedTestEsn : boolean = false;
   modalRef?: BsModalRef;
  sortAttr: string = '';
  url: string = environment.baseUrl + '/api';
  searchForm: FormGroup = this.createSearchForm();
  applyForm: FormGroup = this.createForm();
  state: any;
  id: any;
  stateTest: any;
  /* number */
  seeMore: boolean = false;
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  paysList: ICountry[] = [];
  currentSortDirection: number = 2;
  /* objects */
  //jobOffer!: CJobOffer;
  jobOffer: any;
  /* arrays */
  search: boolean = false;
  listJobOffer: any;
  skillsList: { name_FR: string }[] = [];
  countries: any[] = [];
  mobilities: any[] = [];
  test: any;
  pagination: boolean = false;
  startSearching:boolean = false;
  isCollapsed = true;
  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  type = [
    { id: 1, name: 'Alternance'},
    { id: 2, name: 'Autres type de contrat'},
    { id: 3, name: 'CDD'},
    { id: 4, name: 'CDI'},
    { id: 5, name: 'Freelance'},
    { id: 6, name: 'Portage salarial'},
    { id: 7, name: 'Stage'},
  ];
  poste = [
    { id: 1, name: 'à distance' },
    { id: 2, name: 'Hybrid' },
    { id: 3, name: 'sur site' },
  ];
  typesCompanies = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ]
  StateOffre = [
    { id: 0, name: 'Activé' },
    { id: 1, name: 'Désactivé' },
  ]
  dataHeaderSort = [
    { name: 'ID', code: 'ID_jobOffer' },
    { name: 'Nom entreprise', code: 'esn_name' },
    { name: 'Poste', code: 'name' },
    { name: 'Type', code: 'type' },
    { name: 'Date de publication', code: 'start_date' },
    { name: 'Années d’expériences', code: 'years_of_experience' },
    { name: 'Type de contrat', code: 'contract_type' },
    { name: 'Salaire', code: 'salary' },
    { name: 'TJM', code: 'tjm' },
    { name: 'Type de poste', code: 'post_type' },
    { name: 'Compétences', code: 'skills_job' },
    { name: 'Pays', code: 'country' },
    { name: 'Ville', code: 'city' },
    { name: 'Statut', code: 'state' },
  ]
  getContractTypeList(contractTypes: number[]): string {
    const contractMap: { [key: string]: string } = {
        '1': 'Alternance',
        '2': 'Autres type de contrat',
        '3': 'CDD',
        '4': 'CDI',
        '5': 'Freelance',
        '6': 'Portage salarial',
        '7': 'Stage'
    };

    return Object.keys(contractTypes).map(type => contractMap[type.toString()] || '').join(', ');
  }
  states = [
    { id: 0, name: 'Actif' },
    { id: 1, name: 'Inactif' },
  ];
  passeport_talent = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  profile_abroad = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  stateList: any[] = []
  dataHeader: any = []
  listNames: string[] = []
  idJobs: any;
  jobs: any;
  postuled: any;
  mode: string = '';
  /* formGroup */
  idCompany!: number;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  currentUser: any;
  constructor(
    private jobOffreService: JobsService,
    private sortDataService: JobsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private paysServices: PaysService,
    private activatedRoute: ActivatedRoute,
    private elRef: ElementRef,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
  ) { }
  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    this.paysList = this.paysServices.getAllCountries();
    this.getIdCompany();
    this.getListElementHeader();
    this.getListSkills();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  closeModal() {
    this.modalRef!.hide();
    this.applyForm.reset();
  }
  applyOrNavigate(stateTest: number, job: any, template: TemplateRef<any>) {
    if (+stateTest === 0) {
      this.navigateToTest();
    } else {
      this.openModal(template, job);
    }
  }
  /* create apply form */
  createForm(data?: any) {
    return this.formBuilder.group({
      letter: [data ? data.letter : ''],
      salary: [data ? data.salary : ''],
      tjm: [data ? data.tjm : ''],
    });
  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['idResponsable'];
        this.getListOffre();
      },
    });
  }
  headerColumns: { checked: boolean; name: string; code: string }[] =
    headerColumns5;
    getSortName(columnName: string): string {
      switch (columnName) {
        case 'ID':
          return 'ID_jobOffer';
        case 'Nom entreprise':
          return 'esn_name';
        case 'Type entreprise':
          return 'type';
        case 'Poste':
          return 'name';
        case 'Date de publication':
          return 'start_date';
        case 'Années d’expériences':
          return 'years_of_experience';
        case 'Type de contrat':
          return 'contract_type';
        case 'Type de poste':
          return 'post_type';
        case 'Disponibilité':
          return 'availability';
        case 'Profil étranger':
            return 'profile_abroad';  
        case 'Passeport talent':
          return 'passeport_talent';  
        case 'scoreJobOffer':
          return 'Score';
        case 'TJM':
          return 'tjm';
        case 'Salaire':
          return 'salary';
        case 'Compétence':
          return 'skills';
        case 'Pays':
          return 'country';
        case 'Ville':
          return 'city';
        case 'Statut':
          return 'state';
        default:
          return columnName;
      }
    }

  
  navigateToTest() {
    this.router.navigate(['/wegestu/qcm']);
    this.toastr.info('Vous devez passer le test avant de postuler');
  } 
  profilCompanyNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }
  onCountryChange(mode: string): void {
    this.searchForm.get('city')?.enable();
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.searchForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.searchForm.get('city')?.disable();
        this.searchForm.get('city')?.setValue(null);
      }
    }
  }

  oncityChange(mode: string) {
    this.mobilities = this.searchForm.get('city')?.value;
    let paysLists: any[] = [];
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    this.mobilities = this.searchForm.get('city')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }
  openModal(
      template: TemplateRef<any>,
      selectedJobOffer: any
    ) {
      this.modalRef = this.modalService.show(template, { backdrop: 'static' });
      this.idJobs = selectedJobOffer.id;
      this.state = selectedJobOffer.state;
      this.postuled = selectedJobOffer.postuled;
      this.hasPassedTestEsn = selectedJobOffer.passed_test_esn
      if (this.mode == 'apply') {
        this.applyCandidateJob(this.idJobs, this.state, this.postuled, this.mode);
      }
    }
    contactResponsableNavigation(user : any,name : string) {
      this.router.navigate([
        '/wegestu/chat',
        { user: user,name:name},
      ]);
    }
    changeSelection(event: any, data: any, i: number) {
      this.listNames = [];
      data.checkAdmin = event.target.checked;
      for (const iterator of this.dataHeader) {
        if (iterator.checkAdmin) this.listNames.push(iterator.name);
      }
      this.changeSelectionHeadrElements(data);
    }
    changeSelectionHeadrElements(data: any) {
      let payload = {
        sub_page_id: data?.id,
      };
      this.jobOffreService
        .changeDelectedElementHeader(payload)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: () => {},
          error: () => {},
        });
    }
    getListElementHeader() {
      this.jobOffreService
        .getHeaderElements(30)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            this.isLoadSpinner = false;
            this.dataHeader = res.data;
            for (const iterator of this.dataHeader) {
              if (
                (iterator.checked == 'oui' && !iterator.checked_user) ||
                (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
                (iterator.checked == 'oui' && iterator.checked_user == 'oui')
              )
                this.listNames.push(iterator.name);
            }
            this.dataHeader.map((el: any) => {
              if (
                (el.checked == 'oui' && !el.checked_user) ||
                (el.checked_user == 'oui' &&
                  (el.checked == 'non' || el.checked == 'oui'))
              ) {
                return (el.checkAdmin = true);
              } else {
                return (el.checkAdmin = false);
              }
            });
          },
          error: () => {},
        });
    }
    createTest(idJobs: any) {
      const data = { job_offer_id: idJobs };
      this.jobOffreService.createTestEsn(data).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          if(!this.hasPassedTestEsn){
            this.showTestConfirmation(idJobs);
          }
        },
        error: () => {
          this.spinner.hide();
        },
      });
    }
     applyCandidateJob(idJobs: any, state: any, postuled: any, mode: string) {
        const data = { job_offer_id: idJobs, user_id: this.id };
        if (state === 0) {
          if (postuled === 0) {
            this.jobOffreService
              .applyCandidateJobOffer({ ...data, ...this.applyForm.value })
              .subscribe({
                next: (res: any) => {
                const index =this.listJobOffer?.findIndex((j : any) => j.id === idJobs)
                if(index !== -1){
                  this.listJobOffer[index].postuled = true
                }
                  
                  this.spinner.hide();
                  this.toastr.success(applySuccess);
                  this.closeModal();
                  setTimeout(() => {
                    this.createTest(idJobs);
                  }, 3000);
                },
                error: () => {
                  this.spinner.hide();
                  this.toastr.error(serverError);
                },
              });
          } else {
            this.toastr.error("Vous avez déjà postulé à cette offre d'emploi.");
          }
        } else {
          this.toastr.error("Cette offre d'emploi est actuellement désactivée.");
        }
      }
     
        showTestConfirmation(idJobs: any) {
          Swal.fire({
            title: 'Pourriez-vous passer un test pour cette offre d’emploi?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/test/welcome-test', { idOffre: idJobs }]);
            }
            const index =this.jobs?.findIndex((j : any) => j.id === idJobs)
            if(index !== -1){
                this.jobs[index].postuled = true
            }
          });
        }
  /* create search form */
  createSearchForm(data?: any) {
   return this.formBuilder.group({
         ID_jobOffer: [data ? data.ID_jobOffer : ''],
         company_type: [data ? data.ens.type : null],
         name: [data ? data?.name : ''],
         skillsAnd: [data ? data.skillsAnd : ''],
         skillsOr: [data ? data.skillsOr : ''],
         years_of_experience: [data ? data.years_of_experience : ''],
         post_type: [data ? data.post_type : ''],
         company_name: [data ? data?.company_name : ''],
         contract_type: [data ? data?.contract_type : null],
         country: [data ? data?.country : ''],
         city: [data ? data?.city : ''],
         salary: [data ? data?.salary : ''],
         salary_range: [data ? data?.salary_range : ''],
         tjm: [data ? data?.tjm : ''],
         tjm_range: [data ? data?.tjm_range : ''],
         date_range: data ? data?.date_range : '',
         availability: [data ? data?.availability : ''],
         typeEntreprise: [data ? data?.typeEntreprise : ''],
         start_date: [data ? data.start_date : ''],
         end_date: [data ? data.end_date : ''],
         profile_abroad: [data ? data.profile_abroad : ''],
         passeport_talent: [data ? data.passeport_talent : ''],
         state: [data ? data.state : null],
         search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
       });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
        if (this.searchForm.dirty) {
          this.searchJob();
        } 
      if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListOffre();
      }
    }
  }

  searchJob() {
    let formattedEndDate = this.datePipe.transform(this.searchForm.value.end_date, 'yyyy-MM-dd');
    let formattedStartDate = this.datePipe.transform(this.searchForm.value.start_date, 'yyyy-MM-dd');
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      candidate_id: this.id,
      ID_jobOffer: this.searchForm.value.ID_jobOffer,
      name: this.searchForm.value.name,
      typeEntreprise: this.searchForm.value.typeEntreprise,
      years_of_experience: this.searchForm.value.years_of_experience,
      experience_range: this.searchForm.value.experience_range,
      company_name: this.searchForm.value.company_name,
      skillsAnd: this.searchForm.value.skillsAnd,
      skillsOr: this.searchForm.value.skillsOr,
      availability: this.searchForm.value.availability,
      contract_type: this.searchForm.value.contract_type,
      country: this.searchForm.value.country,
      city: this.searchForm.value.city,
      post_type: this.searchForm.value.post_type,
      tjm: this.searchForm.value.tjm,
      tjm_range: this.searchForm.value.tjm_range,
      salary: this.searchForm.value.salary,
      salary_range: this.convertKToNumber(
        this.searchForm.value.salary_range?.toString()
      ),
      date_range: this.searchForm.value.date_range,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      profile_abroad: this.searchForm.value.profile_abroad,
      passeport_talent: this.searchForm.value.passeport_talent,
      state: this.searchForm.value.state,
      search: this.transformSearch(this.searchForm.value?.search),
    };
    this.spinner.show();
    this.isRecherche = true;
    this.jobOffreService
      .searchJobs(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (resSearch) => {
           this.isRecherche = true;
          this.listJobOffer= resSearch.data.data.slice(0, this.endIndex);
          if (resSearch.status == 200) {
            if (!resSearch.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.searchJob();
            } else {
              this.spinner.hide();
              this.loadSpinner = false;
              this.jobs = resSearch.data.data.slice(0, this.endIndex);
              this.totalItems = resSearch.data.total;
            }
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.loadSpinner = false;
        },
      });
  }
  transformSearch(search: string): string {
    search = search.replace(/\|\|/g, 'OR');
    search = search.replace(/&&/g, 'AND');
    search = search.replace(/!/g, 'NOT');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        // If the term is inside double quotes, keep it as is
        return `"${p1}"`;
      } else {
        // If the term is not inside double quotes, add double quotes around it
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `"${escapedTerm}"`;
      }
    });

    return search;
  }

  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById(
      'dateInput'
    ) as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchForm.value.start_date &&
        this.searchForm.value.start_date != ''
      )
        this.searchForm
          .get('start_date')
          ?.setValue(
            moment(this.searchForm.value.start_date).format('DD/MM/yyyy')
          );
    }
  }
  getListOffre() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.spinner.show();
    let dataPyload =
    this.sortAttr == ''
      ? {
          esn_id: this.id,
          per_page: this.itemsPerPage,
          page: this.currentPage,
          candidate_id: JSON.parse(this.currentUser).id,
        }
      : {
          ...{
            esn_id: this.id,
            per_page: this.itemsPerPage,
            page: this.currentPage,
            candidate_id: JSON.parse(this.currentUser).id,
          },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };
        if(this.isRecherche) {
          this.searchJob();
        }
        else {
          this.jobOffreService
          .getJobOfferList(this.idCompany, dataPyload)
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe({
            next: (res: any) => {
              this.spinner.hide();
              this.listJobOffer = res.data.data.slice(0, this.endIndex);
              this.totalItems = res.data.total;
              
              if (this.currentPage > 1 && !res.data.data.length) {
                this.currentPage = 1;
                this.getListOffre();
              }
              this.spinner.hide();
            },
            error: () => {
              this.spinner.hide();
            },
          });
        }
    
  }
  getSkillsArray(candidate: any): string[] {
    if (candidate && candidate.skills) {
      return candidate.skills.split(',').map((s: string) => s.trim());
    } else {
      return [];
    }
  }
  getListSkills() {
    this.jobOffreService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  onSalaryRangeInput(event: any) {
    this.preventNegativeInput(event)
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.searchForm.controls['salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }
  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      return parseFloat(value.slice(0, -1)) * 1000;
    } else if (!isNaN(value)) {
      return parseFloat(value) * 1000;
    }
    return parseFloat(value);
  }
  preventNegativeInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    
    if (inputElement.type === 'number') {
      const value = inputElement.value;
        inputElement.value = value.replace(/-/g, '');
    }
  
    if (inputElement.type === 'text') {
      inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
    }
  }
  detailsJobOfferNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }
  detailsJobRequestNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-request', { id: id }]);
  }
  ngOnDestroy() {
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  sortData(name?: string) {
    for (const iterator of this.headerColumns) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    if (this.searchForm.dirty)
      this.jobOffer = this.sortDataService.sortArray(
        this.jobOffer,
        name,
        this.currentSortDirection
      );
    else this.getListOffre();
  }
  reset() {
    this.isRecherche = false
    this.searchForm.reset();
    this.getListOffre();
  }
}
