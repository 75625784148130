<ngx-spinner class="custom-spinner" bdColor="rgba(255,255,255,1)" size="medium" color="#fff" type="ball-atom"
    [fullScreen]="true">
    <span class="loader">
        <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
    </span>
</ngx-spinner>
<div class="app-toolbar py-3 py-lg-6">

    <div class="app-container container-fluid d-flex flex-stack">

        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <img src="../../../../../assets/icons/cra.svg">
                    CRAs
                </h1>

            </div>

            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted"
                        routerLinkActive="router-link-active">Accueil</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted"><a class="text-muted" routerLink="/wegestu/pointage/cra">Liste
                        CRA</a>
                </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>

                <li class="breadcrumb-item text-muted">Activités </li>

            </ul>
        </div>

    </div>

</div>


<div *ngIf="show" >
    <div class="row cards mb-15">
        <div class="title-page mb-10">
            <h4>Activités </h4>
        </div>
        <div class="d-flex justify-content-end">
            <div class="col-6 actions mb-3">
                <button (click)="exportCra()" *ngIf="listDetailsCraForMonths?.desiredCRA.state_validation == '1'"
                    class="download">
                    <img src="../../../../../assets/icons/cra-1.svg"> Télécharger</button>
                <button [disabled]="!craAdded"
                    *ngIf="listDetailsCraForMonths?.desiredCRA.status != '2' "
                    class="validate" [ngClass]="{ 'hidden-btn': !hasSubscriptionAccessDisplay || hasValidStatus }"  (click)="validateCra()">
                    <img src="../../../../../assets/icons/cra-2.svg">
                    Envoyer </button>
                <button class="annuler" (click)="rejectCra()"   [ngClass]="{ 'hidden-btn': !hasSubscriptionAccessDisplay || hasValidStatus }" *ngIf="listDetailsCraForMonths?.desiredCRA.status == '2' ">
                    <i class="fa-solid fa-xmark iconeXmark"></i>
                    Annuler</button>
                    <button (click)="addCra()" [ngClass]="{ 'hidden-btn': !hasSubscriptionAccessDisplay || hasValidStatus }" class="saved" *ngIf="listDetailsCraForMonths?.desiredCRA.status != '2' ">
                    <img src="../../../../../assets/icons/cra-3.svg">
                    Enregistrer </button>
            </div>
        </div>
        <div id="downloadCra"   >
            <div class="d-flex cradtails">
                <div class="col-lg-3 padding-zero">
                    <div class="left">
                        <div class="title text-center">
                            <h4>
                                Client / Type d’activité 
                            </h4>
                        </div>
     <!--              <div class="item" *ngFor="let item1 of sortedListClientOfCra; let i1 = index">
                            <div class="item-name">
                              <h4>{{ item1?.client_final_e_s_n?.name }}</h4>
                            </div>
                            <div class="item-btn">
                              <button
                                class="remplir"
                                [ngClass]="{ 'hidden-btn': !hasSubscriptionAccessDisplay || hasValidStatus }"
                                *ngIf="!item1?.checked"
                                [disabled]="(activeRow.index !== null && activeRow.index !== i1 ) || listDetailsCraForMonths?.desiredCRA.status == '2'"
                                (click)="remplirLigne(item1?.client_final_e_s_n?.id, i1, 'project')"
                              >
                                Remplir
                              </button>
                              <button
                                class="remplir"
                              
                                (click)="removeLigne(item1?.client_final_e_s_n?.id, i1, 'project')"
                              >
                                Annuler
                              </button>
                            </div>
                          </div> 
                          
                          <div
                            class="item"
                            *ngFor="let item2 of listOfCra; let i2 = index"
                            [style.background-color]="getBackgroundColor(item2.family)"
                          >
                            <div class="item-name">
                              <h4>{{ item2?.name }}</h4>
                            </div>
                            <div class="item-btn">
                              <button
                                class="remplir"
                                *ngIf="!item2?.checked"
                                [ngClass]="{ 'hidden-btn': !hasSubscriptionAccessDisplay || hasValidStatus }"
                                [disabled]="(activeRow.index !== null && activeRow.index !== i2) || listDetailsCraForMonths?.desiredCRA.status == '2'"
                                (click)="remplirLigne(item2?.id, i2, 'timesheet')"
                              >
                                Remplir 
                              </button>
                              <button
                                class="remplir"
                             
                                (click)="removeLigne(item2?.id, i2, 'timesheet')"
                              >
                                Annuler
                              </button>
                              </div>
                            </div> -->
                           
                          
                    
                           
                            <div *ngIf="combinedList.length > 0">
                                <div class="item" *ngFor="let item of combinedList; let i = index; trackBy: trackById"
                                    [style.background-color]="item?.family ? getBackgroundColor(item.family) : null">
                                    <div class="item-name">
                                        <h4>{{  item?.type== 'project' ? item?.client_final_e_s_n?.name : item?.name }}</h4>
                                    </div>
                                    <div class="item-btn">
                                        <button class="remplir" *ngIf="!item?.checked"
                                            [disabled]="(activeRow.index !== null && activeRow.index !== i && !isEnvoyerDisabled ) || listDetailsCraForMonths?.desiredCRA.status == '2'"
                                            (click)="  item?.type== 'timesheet' ?remplirLigne(item?.id, i,  'timesheet'):remplirLigne(item?.client_final_e_s_n?.id, i,'project') ">
                                            Remplir
                                        </button>
                                        <button class="remplir" *ngIf="item?.checked" (click)="item?.type== 'timesheet' ?removeLigne(item?.id, i, 'timesheet'):removeLigne(item?.client_final_e_s_n?.id, i, 'project')  ">
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>

                        <div class="item-total">
                            <div class="item-name">
                                <h4> Total journalier:</h4>
                            </div>
                        </div>
    
                        <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listOfCra&&!listOfCra.length">
                            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                            <span class="empty-list">
                                La liste est vide
                            </span>
                        </div>
    
                    </div>
                </div>
                <div class="col-lg-9 padding-zero">
                    <h4 class="title-month">{{listDetailsCraForMonths?.desiredCRA?.date | date: 'MMMM y'}}</h4>
                    <div class="table-responsive">
    
                        <table class="table table-cra">
                            <thead>
                                <th *ngFor="let day of generateDayNumbersArray()" [class.weekend]="isWeekend(day)"
                                    [class.weekend]="isHoliday(day)">
                                    {{ day }}
                                </th>
                            </thead>
    
                            <tbody>
                                <tr *ngFor="let cra of listDetailsCraForMonths?.desiredCRA?.ligne_cra; let i = index; trackBy: trackByCra">
                                    <td *ngFor="let day of cra.days; let j = index; trackBy: trackByDay" [ngStyle]="{ 'background-color': getBackgroundColor(day) }">
                                      <select
                                        *ngIf="selectedValues[cra.type_id] && selectedValues[cra.type_id][j]"
                                        [disabled]="listDetailsCraForMonths?.desiredCRA.status == '2'"
                                        id="dispo-{{ cra?.type_id }}-{{ j }}"
                                        class="form-control"
                                        [(ngModel)]="selectedValues[cra.type_id][j].duration"
                                        (change)="onDurationChange($event, cra?.type_id, cra?.type, j, day); checkEnvoyerButton()"
                                        (focus)="storePreviousValue(cra?.type_id, j, selectedValues[cra?.type_id][j].duration)"
                                        [style.visibility]="getVisibility(day)"
                                        [ngClass]="getNgSelectClasses(cra?.type_id, j)"
                                      >
                                        <option
                                          *ngFor="let item of day?.values"
                                          [value]="item.id"
                                          [selected]="item.id === selectedValues[cra.type_id][j]?.duration"
                                        >
                                          {{ item.name }}
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                            </tbody>
                            <tfoot class="table-cra-total">
                                <tr>
                                    <td *ngFor="let total of sums; let dayIndex = index"
                                        [ngStyle]="getTotalCellStyles(dayIndex, total)">
                                        {{ total }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
    
                    </div>
    
                </div>
            </div>
        </div>



        <div class="card ">
            <div class="title mb-5">
                <h4>Totaux</h4>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <h4 class="prod" style="text-align: center;">Production</h4>
                    <div class="table-responsive">
                        <table class="table" id="totaux">
                            <tbody>
                                <tr>
                                    <td><span class="color-blue">Total</span></td>
                                    <td>{{ totalDurationForAllFamilies?.Production ? totalDurationForAllFamilies.Production :
                                        '---' }} jours
                                    </td>
                                </tr>
                                <tr *ngFor="let item of getTotalDurationByType('Production')">
                                    <td><span class="color-blue">{{ item.type_name }}</span></td>
                                    <td>{{ item.total_duration }} jours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h4 class="interne" style="text-align: center;">Interne</h4>
                    <div class="table-responsive">
                        <table class="table" id="totaux">
                            <tbody>
                                <tr>
                                    <td><span class="color-blue">Total</span></td>
                                    <td>{{ totalDurationForAllFamilies?.Interne ? totalDurationForAllFamilies.Interne : '---' }}
                                        jours</td>
                                </tr>
                                <tr *ngFor="let item of getTotalDurationByType('Interne')">
                                    <td><span class="color-blue">{{ item.type_name }}</span></td>
                                    <td>{{ item.total_duration }} jours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h4 class="absence" style="text-align: center;">Absence</h4>
                    <div class="table-responsive">
                        <table class="table" id="totaux">
                            <tbody>
                                <tr>
                                    <td><span class="color-blue">Total</span></td>
                                    <td>{{ totalDurationForAllFamilies?.Absence ? totalDurationForAllFamilies.Absence : '---' }}
                                        jours</td>
                                </tr>
                                <tr *ngFor="let item of getTotalDurationByType('Absence')">
                                    <td><span class="color-blue">{{ item.type_name }}</span></td>
                                    <td>{{ item.total_duration }} jours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h4 class="total" style="text-align: center;">Total</h4>
                    <div class="table-responsive">
                        <table class="table" id="totaux">
                            <tbody>
                                <tr>
                                    <td><span class="color-blue">Nombre de jours</span></td>
                                    <td><span class="color-blue">{{ listDetailsCraForMonths?.desiredCRA?.totalDuration ?
                                            listDetailsCraForMonths.desiredCRA.totalDuration : '---' }} jours</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    

</div>
